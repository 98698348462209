import { Button, Modal } from "antd"
import closeIcon from 'src/assets/images/close-circle.png'
interface ModalDeleteType {
    isShowModal: boolean,
    onDelete: () => void,
    onCancel: () => void,
    content: string,
    loadingButtonOk?: boolean
}
const ModalDelete = ({isShowModal, onDelete , onCancel, content, loadingButtonOk}: ModalDeleteType) => {
    const handleClickButtonOk = () => {
        onDelete()
    }
    const handleClickButtonCancel = () => {
        onCancel()
    }
    return (
        <Modal 
            title="" 
            open={isShowModal} 
            width={451}
            className="delete-Modal"
            footer={false}
            closable={false}
            centered={true}
        >
            <img src={closeIcon} alt="" className="close-modal cursor-pointer" onClick={handleClickButtonCancel}/>
            <p className='text-center text-[20px] text-[#272122] font-medium mb-[44px] whitespace-pre-line'>{content}</p>
            <div className='footer-form flex'>
                <Button className='button-form mr-[16px]' onClick={handleClickButtonCancel}>キャンセル</Button>
                <Button loading={loadingButtonOk} className='button-form button-form-Submit' onClick={handleClickButtonOk}>
                    OK
                </Button>
            </div>
        </Modal>
    )
}

export default ModalDelete