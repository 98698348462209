import React, { useState } from 'react'
import Header from '../common/layouts/header'
import './index.modules.scss'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { listMenus, role } from 'src/constants'
import constructionIcon from 'src/assets/images/back-icon.png'
import {useConstructionDetailContext } from 'src/context/ConstructionDetailContext'
import ModalConfirm from '../common/modal/modalConfirm'
import HeaderCompanyAdmin from '../common/layouts/headerCompanyAdmin'
type LayoutProps = {
    children: React.ReactNode
}
export const LayoutMoreNote: React.FC<LayoutProps> = ({ children }) => {
    const location = useLocation()
    const history = useHistory()
    const { id } = useParams<{ id: string }>()
    const { constructionDetail, isValueForm } = useConstructionDetailContext()
    const [isVisibleModelConfirmBack, setIsVisibleModelConfirmBack] = useState<boolean>(false)
    const handleActiveSideBar = (menu: any) => {
        if(menu.id === 1) {
            history.push(`${menu.path}/${id}`)
        }
        if(menu.id === 2) {
            history.push(`/construction-site/${id}${menu.path}`)
        }
    }

    const handleBackConstruction = () => {
        if(isValueForm){
            setIsVisibleModelConfirmBack(true)
        }
        else{
            handleGoBack()
        }
    }
    const handleCancel = () => {
        setIsVisibleModelConfirmBack(false);
    }
    const handleGoBack = () => {
        setIsVisibleModelConfirmBack(false);
        if(location.pathname.includes('location-site/create')) {
            history.push(`/construction-site/${id}/location-site`)
        }else {
            history.push('/construction-site')
        }
    }
    return (
        <>
            {
                Number(localStorage.getItem('role')) && Number(localStorage.getItem('role')) === role.COMPANY ?  <HeaderCompanyAdmin /> :  <Header />
            }
            <div className='civil-content-page'>
                <div className='detail-title'>
                    <img src={constructionIcon} alt='' className='mr-[16px] cursor-pointer' onClick={() => handleBackConstruction()}/>
                    <h2 className='page-note-text cursor-pointer line-1 break-all' onClick={() => handleBackConstruction()}>{constructionDetail?.name}</h2>
                </div>
                <div className='flex layout'>
                    <ul className='side-bar w-4/12'>
                        {
                            listMenus.map((item) => {
                                return (
                                    <li 
                                        key={item.id} 
                                        className={`side-bar-item ${location.pathname.includes(item.path) ? 'active' : ''}`} 
                                        onClick={() => handleActiveSideBar(item)}
                                    >
                                        <img  src={item.icon} alt=''/> {item.name}
                                    </li>
                                )
                            })
                        }
                    </ul> 
                    <div className='content-layout'>
                        {children}
                    </div>
                </div>
                <ModalConfirm 
                    isShowModal={isVisibleModelConfirmBack}
                    onCancel={handleCancel}
                    onOk={handleGoBack}
                    content='このページを離れますか？'
                    subContent='ページから移動すると、保存されていない変更は失われます。'
                />
            </div>
        </>
    )
}
