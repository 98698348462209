const LockIcon = ({ className }: any) => (
  <svg className={className} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.89417 10.6343V8.63428C6.89417 5.32428 7.89417 2.63428 12.8942 2.63428C17.8942 2.63428 18.8942 5.32428 18.8942 8.63428V10.6343"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.8942 22.6343H7.89417C3.89417 22.6343 2.89417 21.6343 2.89417 17.6343V15.6343C2.89417 11.6343 3.89417 10.6343 7.89417 10.6343H17.8942C21.8942 10.6343 22.8942 11.6343 22.8942 15.6343V17.6343C22.8942 21.6343 21.8942 22.6343 17.8942 22.6343Z"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M16.8906 16.6343H16.8996" stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.8896 16.6343H12.8986" stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.88867 16.6343H8.89765" stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default LockIcon;
