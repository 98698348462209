import React from 'react'
import logo from 'src/assets/images/logo.png'
import errorCircle from 'src/assets/images/confirm-circle.png'
import { useHistory } from 'react-router-dom'

const ConfirmLicenseExpired = () => {
    const history = useHistory()
    const onGoToHomepage = () => {
        history.push('/')
    }
    return (
        <div className='payment'>
            <div id='header' className='flex justify-between header' onClick={onGoToHomepage}>
                <div className='flex items-center cursor-pointer'>
                    <img className='header__icon mr-[13px]' src={logo} alt='logo'/>
                    <h1 className='font-bold text-[24px] text-[#253858] whitespace-nowrap cursor-pointer'>助さん</h1>
                </div>
            </div>
            <div className='position-center'>
                <img className='mx-auto mb-[16px]' src={errorCircle} alt='logo'/>
                <p className='text-[#272122] text-[20px] text-center font-medium'>見積書リンクの有効期限が切れています。</p>
            </div>
        </div>
    )
}

export default ConfirmLicenseExpired