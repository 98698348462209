export enum QUERY_KEY {
    CONSTRUCTION_LIST = 'construction-list',
    CONSTRUCTION_DETAIL = 'construction-detail',
    LOCATION_SITE_LIST = 'location-site-list',
    LOCATION_SITE_LIST_FILES = 'location-site-list-files',
    LIST_FILE_BY_TYPE = 'list-file-by-type',
    OVERLOAD_MANAGEMENT = 'overload-management',
    TONNAGEMIXING = 'tonnage-mixing',
    LOADFILESIMA = 'load-file-sima',
    PUNCHIE_DETAIL ='punchie-detail',
    LIST_AGENCY_DROP ='list-agency-drop',
    LIST_REQUEST_COMPANY ='list-request-company',
    DETAIL_REQUEST_COMPANY = 'detail-request-company',
    LICENSE_LIST_COMPANY = 'license-list-company',
    LIST_REQUEST_LICENSE ='list-request-license',
    DETAIL_REQUEST_LICENSE = 'detail-request-license',
    LIST_STAFF ='list-staff',
    DETAIL_STAFF ='detail-staff',
    LIST_CATEGORY ='list-category',
    LIST_NOTI ='list-notification',
    LIST_FAQ ='list-faq',
    DETAIL_CATEGORY ='detail-category',
    LIST_AGENCY_ADMIN ='list-agency-admin',
    DETAIL_AGENCY_ADMIN ='detail-agency-admin',
    DETAIL_COMPANY_ADMIN ='detail-company-admin',
    LIST_COMPANY_STAFF ='list-company-staff',
    DETAIL_COMPANY_STAFF ='detail-company-staff',
    LIST_STAFF_AGENCY ='list-staff-agency',
    COMPANY_LICENSE_LIST ='company-license-list',
    LICENSE_TERM_INFOR='license-term-infor',
    LICENSE_INVOICE='license-invoice',
    PUNCHIE_DETAIL_AT_REACHABLELINE ='punchie-detail-at-reachableline',
    LIST_COMPANY_STAFF_OF_ADMIN = 'list-company-staff-of-admin',
    LIST_AGENCY_STAFF_OF_ADMIN = 'list-agency-staff-of-admin',
    USER_INFO = 'user-info'
}