import Timeline, {
  TimelineHeaders,
  DateHeader,
} from 'react-calendar-timeline'
import 'react-calendar-timeline/lib/Timeline.css'
import moment from 'moment'
import { useEffect, useState } from 'react'
import dateNowIcon from 'src/assets/images/date-now-icon.png'


const LicenseTimeLineChart = ({licenseTermInfor}: any) => {
  const [groups, setGroups] = useState<any>()
  const [items, setItems] = useState<any>()
  const [earliestStartDate, setEarliestStartDate] = useState<any>('')
  const [latestEndDate, setLatestEndDate] = useState<any>('')
  useEffect(() => {
    const groupData = licenseTermInfor?.licenses?.map((license: any) => {
      return {
        id: license.id,
        title: license.start_time + ' - ' + license.end_time ,
        name: license.invoice_code ? license.invoice_code : ''
      }
    })

    const itemsData = licenseTermInfor?.licenses?.map((license: any) => {
      const startTime = moment(license.start_time, "DD/MM/YYYY").format("YYYY/MM/DD")
      const endTime = moment(license.end_time, "DD/MM/YYYY").format("YYYY/MM/DD")

      return {
        id: license.id,
        group: license.id,
        title: license.quantity + ' ライセンス',
        start_time: moment(startTime),
        end_time:moment(endTime),
      }
    })
    setGroups(groupData);
    setItems(itemsData)
    const startTimeline = moment(licenseTermInfor?.earliest_start_date, "YYYY-MM").year()
    const endTimeline = moment(licenseTermInfor?.latest_end_date, "YYYY-MM").year()
    setEarliestStartDate('01/01/' + startTimeline)
    setLatestEndDate('01/01/' + (endTimeline + 1))
    
  },[licenseTermInfor])


  const handleCustomHeader = ({ getIntervalProps, intervalContext, data }: any) => {
    const intervalDay = moment(intervalContext.interval.startTime).format('DD')
    const intervalMonth = moment(intervalContext.interval.startTime).format('MM')
    const intervalYear = moment(intervalContext.interval.startTime).format('YYYY')
    const dayNow = moment().format('DD')
    const monthNow = moment().format('MM')
    const yearNow = moment().format('YYYY')
    return intervalDay === dayNow && intervalMonth === monthNow && intervalYear === yearNow ? 
    <div {...getIntervalProps()} className='now-date-icon' onClick={(e) => handleClickIcon(e)}>
      <img src={dateNowIcon} alt='' className='now-date-icon'/>
    </div> :
    <div {...getIntervalProps()}> 
    </div>
  }

  const handleClickIcon = (e: any) => {
    e.preventDefault()
  }

  const groupRenderer = ({ group }: any) => {
    return (
      <div className="custom-group">
        <p className='text-[14px] font-bold h-[26px] leading-[26px]'>{group.name}</p>
        <span className="title h-[21px] leading-[21px] text-[#8D8D8D] text-[14px]">{group.title}</span>
      </div>
    )
  }

  const handleRenderDateHeader = ({ getIntervalProps, intervalContext, data }: any) => {
    if(licenseTermInfor) {
      const startTimeLine = moment(licenseTermInfor?.earliest_start_date, "YYYY-MM").year()
      const endTimeline = moment(licenseTermInfor?.latest_end_date, "YYYY-MM").year() + 1
      return <div {...getIntervalProps()} className={`rct-dateHeader  ${intervalContext.intervalText.includes(startTimeLine) ? 'start-time-line': intervalContext.intervalText.includes(endTimeline) ? 'end-time-line' : ''}`}>
        <span className='cursor-default'>{intervalContext.intervalText}</span>
      </div>
    } 
  }

  const customScroll = (visibleTimeStart:any, visibleTimeEnd:any, updateScrollCanvas:any) => {
    const minTime:any = moment(earliestStartDate)
    const maxTime:any = moment(latestEndDate)
    if (visibleTimeStart < minTime && visibleTimeEnd > maxTime) {
      updateScrollCanvas(minTime, maxTime)
    } else if (visibleTimeStart < minTime) {
      updateScrollCanvas(minTime, minTime + (visibleTimeEnd - visibleTimeStart))
    } else if (visibleTimeEnd > maxTime) {
      updateScrollCanvas(maxTime - (visibleTimeEnd - visibleTimeStart), maxTime)
    } else {
      updateScrollCanvas(visibleTimeStart, visibleTimeEnd)
    }
  }

  return (
    <>
      {
        groups && items &&   
        <Timeline
          groups={groups}
          items={items}
          minZoom={new Date(latestEndDate).getTime() - new Date(earliestStartDate).getTime()}
          maxZoom={new Date(latestEndDate).getTime() - new Date(earliestStartDate).getTime()}
          defaultTimeStart={moment(earliestStartDate)}
          defaultTimeEnd={moment(latestEndDate)}
          className='license-time-line'
          lineHeight={70}
          groupRenderer={groupRenderer}
          sidebarWidth={200}
          onTimeChange={customScroll}
        >
          <TimelineHeaders>
            <DateHeader labelFormat={'MM/YYYY'} unit="year" intervalRenderer={handleRenderDateHeader}/>
            <DateHeader
              unit="day"
              labelFormat="DD"
              style={{ height: 50 }}
              intervalRenderer={handleCustomHeader}
            />
          </TimelineHeaders>
        </Timeline>
      }
    </>
   
  )
}
export default LicenseTimeLineChart