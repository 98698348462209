export const validateFieldString = (rule: any, value: string, callback: any, name: string, length: number = 255) => {
  if (!value?.trim()) {
    callback(`${name}を入力してください`);
  } else if (length && value?.trim()?.length > length) {
    callback(`${name}は${length}文字以内で入力してください`);
  } else {
    callback();
  }
};

export const validatePhoneNumber = (rule: any, value: string, callback: any) => {
  const phoneRegex = /^[0-9]{10,11}$/; // Assuming 10-digit phone number format
  if (!value?.trim()) {
    callback('電話番号を入力してください');
  } else if (!phoneRegex.test(value.trim())) {
    callback('電話番号は半角数字10文字以上11文字以内で入力してください。');
  } else {
    callback();
  }
};

export const validatePhoneNumberLang = (rule: any, value: string, callback: any, t?: any) => {
  const phoneRegex = /^[0-9]{10,11}$/; // Assuming 10-digit phone number format
  if (!value?.trim()) {
    callback(t('enter_phone'));
  } else if (!phoneRegex.test(value.trim())) {
    callback(t('enter_phone_between'));
  } else {
    callback();
  }
};

export const validateEmail = (rule: any, value: string, callback: any, length: number = 255) => {
  const emailRegex = /^[a-zA-Z0-9._+-]+@([\w-]+\.)+([a-zA-Z]+)$/;
  if (!value?.trim()) {
    callback('メールアドレスを入力してください');
  } else if (!emailRegex.test(value.trim())) {
    callback('メールアドレスの形式が不正です');
  } else if (length && value?.trim()?.length > length) {
    callback(`メールアドレスは${length}文字以内で入力してください`);
  } else {
    callback();
  }
};

export const validateEmailLang = (rule: any, value: string, callback: any, length: number = 255, t?: any) => {
  const emailRegex = /^[a-zA-Z0-9._+-]+@([\w-]+\.)+([a-zA-Z]+)$/;
  if (!value?.trim()) {
    callback(t('enter_email'));
  } else if (!emailRegex.test(value.trim())) {
    callback(t('enter_email_invalid'));
  } else if (length && value?.trim()?.length > length) {
    callback(t('limit_255_email_invalid'));
  } else {
    callback();
  }
};

export const validatePassword = (rule: any, value: string, callback: any) => {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
  if (!value?.trim()) {
    callback('パスワードを入力してください');
  } else if (value?.trim()?.length < 8 || value?.trim()?.length > 25) {
    callback('パスワードは8文字以上25文字以内で入力してください');
  } else if (!passwordRegex.test(value?.trim())) {
    callback('パスワードは英大文字・英小文字・数字・記号それぞれを最低1文字ずつ含む必要があります');
  } else {
    callback();
  }
};
export const validateRangeNumber = (
  value: string,
  callback: any,
  name: string,
  min: number = 1,
  max: number = 2147483647
) => {
  if (!value) {
    callback(`${name}を入力してください`);
  } else if (Number(value) < min || Number(value) > max) {
    callback(`${name}は${min}～${max}で入力してください`);
  } else {
    callback();
  }
};
