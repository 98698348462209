import { Button, Spin } from 'antd'
import React, { useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import leftArrow from 'src/assets/images/back-icon.png'
import { detailCompanyOfLicense } from 'src/constants'
import { useDetailRequestLicense } from 'src/api/data/agency/useDetailRequestLicense'
import { api } from 'src/api/axios'

const detailLicenseKey = [
    {
        label: '数量',
        name: 'quantity',
        id: 1,
    },
    {
        label: '合計金額',
        name: 'price',
        id: 2
    },
    {
        label: '単価',
        name: 'unit_price',
        id: 3
    },
    {
        label: '支払方法',
        name: 'payment_method',
        id: 4
    },
    {
        label: '小計',
        name: 'subtotal',
        id: 5
    },
    {
        label: '開始日',
        name: 'start_time',
        id: 6
    },
    {
        label: '消費税10%',
        name: 'tax',
        id: 7
    },
    {
        label: '終了日',
        name: 'end_time',
        id: 8
    },
]
const LicenseDetailComponent = () => {
    const history = useHistory()
    const { id } = useParams<{ id: string }>()
    const { data: detailLicense, isLoading } = useDetailRequestLicense(Number(id)) 
    const handleGoBackCompanyList = () => {
        history.push('/agency/license')
    }

    const dataDetailLicense = useMemo(() => {
        if (detailLicense) {
            const unitPrice = (detailLicense?.license?.price/(detailLicense?.license?.quantity + detailLicense?.license?.quantity * 0.1)).toFixed(0)
            const subtotal = Number(unitPrice) * detailLicense?.license?.quantity
            return {
                ...detailLicense?.license,
                payment_method:  detailLicense?.license?.payment_method === 1 ? 'クレジットカード' : '銀行振込',
                unit_price: unitPrice?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '円',
                subtotal: subtotal?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '円',
                tax: (subtotal * 0.1)?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '円',
                price: detailLicense?.license?.price?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '円'
            }
        }
        return {}
      }, [detailLicense])

    const handleDownloadFilePdf = async () => {
        const res = await api.get(`/api/cms/license/export-pdf/${Number(id)}`)
        const linkSource = `data:application/pdf;base64,${res.data}`;
        const downloadLink = document.createElement("a");
        const fileName = "見積書.pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click()
    }
    
    return (
       <div className='p-[16px]'>
            {
                isLoading ? <Spin /> :
                <>
                    <div className='page-content-title border-bottom-title mb-[30px] flex items-center justify-between'>
                        <div>
                            <img src={leftArrow} alt='' className='inline-block mr-[17px] cursor-pointer' onClick={handleGoBackCompanyList}/>
                            <span onClick={handleGoBackCompanyList} className='cursor-pointer'>ライセンス申請詳細</span>
                        </div>
                        <Button 
                            className='button-add min-w-[173px]' 
                            onClick={handleDownloadFilePdf}
                        >
                            <span></span>出力
                        </Button>
                    </div>
                    <div className='license-detail mb-[24px]'>
                        <div className='license-detail-title'>会社情報 </div>
                        <div className='license-detail-content'>
                            {
                                detailCompanyOfLicense.map((item: any) => (
                                    <div className='mb-[16px] flex w-6/12' key={item.id}>
                                        <label className='labe w-3/12'>{item.label}</label>
                                        <div className='text-[16px] text-[#000000] break-all w-9/12 px-[10px]'>
                                            {
                                                detailLicense?.company?.[item.name]
                                            }
                                        </div>
                                    </div>
                                ))
                            } 
                             <div className='mb-[16px] w-6/12 flex'>
                                <label className='labe w-3/12'>住所</label>
                                <div className='text-[16px] text-[#000000] break-all w-9/12 px-[10px]'>
                                    {
                                        detailLicense?.company?.address
                                    }
                                </div>
                            </div>
                            <div className='mb-[16px] w-6/12 flex'>
                                <label className='labe w-3/12'>担当者名</label>
                                <div className='text-[16px] text-[#000000] break-all w-9/12 px-[10px]'>
                                    {
                                        detailLicense?.company?.agency_staff ? 
                                        detailLicense?.company?.agency_staff?.membership_code + '-' +  detailLicense?.company?.agency_staff?.name 
                                        : '-'
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='license-detail'>
                        <div className='license-detail-title'>ライセンス情報  </div>
                        <div className='license-detail-content'>
                            {
                                detailLicenseKey.map((item: any) => (
                                    <div className='mb-[16px] flex w-6/12' key={item.id}>
                                        <label className='labe w-3/12'>{item.label}</label>
                                        <div className='text-[16px] text-[#000000] break-all w-9/12 px-[10px]'>
                                            {
                                                dataDetailLicense?.[item.name]
                                            }
                                        </div>
                                    </div>
                                ))
                            } 
                        </div>
                    </div>
                </>
            }
       </div>
    )
}

export default LicenseDetailComponent