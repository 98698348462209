import { Button, Form, Input, Upload, message } from 'antd'
import React, { useCallback, useState } from 'react'
import './location.modules.scss'
import { useHistory, useParams } from 'react-router-dom'
import uploadIcon from 'src/assets/images/upload-icon.png'
import { createLocationSite } from 'src/api/locationSite'
import { useMutation } from 'react-query'
import deleteIcon from 'src/assets/images/delete-icon-white.png'
import ModalConfirm from 'src/components/common/modal/modalConfirm'
import { useConstructionDetailContext } from 'src/context/ConstructionDetailContext'
const LocationSiteCreateComponent = () => {
    const { id } = useParams<{ id: string }>()
    const [antForm] = Form.useForm()
    const history = useHistory()
    const [isDisableSubmit,setIsDisableSubmit] = useState(true)
    const [isHidenUploadSima, setIsHidenUploadSima] = useState({lineSima: false, slineSima: false})
    const [isVisibleModelConfirmBack,setIsVisibleModelConfirmBack] = useState(false)
    const {isValueForm, setIsValueForm} = useConstructionDetailContext();
    const renderUploadIcon = () => (
        <img className='inline-block mr-[8px]' src={uploadIcon} alt=''/>
    )
    const {isLoading: isloading, mutate: onCreateConstruction } = useMutation(
        (param: FormData) => createLocationSite(param),
        {
          onSuccess: () => {
            message.success('データ更新成功')
            history.push(`/construction-site/${id}/location-site`)
          },
          onError: (error: any) => {
            if (error.response?.status === 400) {
                message.error(error.response.data.messages[0])
                return
            }
            message.error('システムエラー')
          },
        },
    )

    const handleCreateLocationSite = useCallback(async() => {
        try {
            await antForm.validateFields()
        } catch { return }
        
        const values = antForm.getFieldsValue()
        const newFormData = new FormData()

        newFormData.append('name',values.name)
        newFormData.append('construction_id',id)
        values.point_files?.fileList?.forEach((pointFile: any) => {
            newFormData.append('point_files[]',pointFile?.originFileObj)
        });
        values.sim_linear_files?.fileList?.forEach((simLinearFile: any) => {
            newFormData.append('sim_linear_files',simLinearFile?.originFileObj)
        });
        values.sim_slice_files?.fileList?.forEach((simSliceFile: any) => {
            newFormData.append('sim_slice_files',simSliceFile?.originFileObj)
        });
        values.sim_common?.fileList?.forEach((simCommonFile: any) => {
            newFormData.append('sim_common[]',simCommonFile?.originFileObj)
        });
        values.cutting_thickness_files?.fileList?.forEach((cuttingThicknessFile: any) => {
            newFormData.append('cutting_thickness_files[]',cuttingThicknessFile?.originFileObj)
        });
        values.paving_thickness_files?.fileList?.forEach((pavingThicknessFile: any) => {
            newFormData.append('paving_thickness_files[]',pavingThicknessFile?.originFileObj)
        });

        onCreateConstruction(newFormData);
    },[antForm, id, onCreateConstruction])

    const handleChangeValue = (allvalues: []) => {
        const listValue = Object.keys(allvalues).filter((key: any) => key !== 'sim_common').map((key: any) => allvalues[key])
        const validateErrorValueList = []
        listValue?.forEach((value: any) => {
            if(value === undefined || value === '' || value?.fileList?.length === 0 ) {
                validateErrorValueList.push(value)
            }
        })
        if(validateErrorValueList.length > 0) {
            setIsDisableSubmit(true)
        }else{
            setIsDisableSubmit(false)
        }

        

        listValue?.some((value:any) => {
            if(value !== undefined && value !== '' && value != null && value?.fileList?.length !== 0) {
                setIsValueForm(true)
                return true
            }
            setIsValueForm(false)
            return false
        })
    }

    const renderUploadList = {
        removeIcon: () => (
          <img src={deleteIcon} alt='' />
        ),
      };

    const handleChangeFileSima = (value: any, type: number) => {
        if(value.fileList.length > 0) {
            if(type === 1) {
                setIsHidenUploadSima((prevState) => ({
                    ...prevState,
                    lineSima: true
                }))
            }else {
                setIsHidenUploadSima((prevState) => ({
                    ...prevState,
                    slineSima: true
                }))
            }
        }else {
            if(type === 1) {
                setIsHidenUploadSima((prevState) => ({
                    ...prevState,
                    lineSima: false
                }))
            }else {
                setIsHidenUploadSima((prevState) => ({
                    ...prevState,
                    slineSima: false
                }))
            }
        }

    }

    const validateFileExtension = (_: any, file: any, callback: any, extensions: string) => {
        const acceptedExtensions = extensions
        const fileExtensions: string[] = [];
        const fileSizes: number[] = [];
        file?.fileList?.forEach((e: {name: string, size: number}) => {
            fileExtensions.push(e.name.slice(e.name.lastIndexOf('.')))
            fileSizes.push(e.size / 1024 / 1024)
        })
        const isAcceptedExtensions = fileExtensions?.find((e) => e !== acceptedExtensions)
        
        const isSizeNotAccepted = fileSizes?.find((e) => e > 25)
        if(file?.fileList?.length === 0 || file === undefined) {
            if(_.field === 'sim_common'){
                callback();
            }
            else{
                callback('ファイルを選択してください')
            }
        }else if (isAcceptedExtensions) {
            callback(`アップロードするファイルは「${extensions}」のいずれかの形式にしてください`)
        }else if(isSizeNotAccepted) {
            callback(`ファイルサイズが25Mを超えています`)
        }else {
            callback()
        }
      };

    const validateFileExcel = (_: any, file: any, callback: any) => {
        const acceptedXls = 'application/vnd.ms-excel'
        const acceptedXlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        const fileExtensions: string[] = [];
        const fileSizes: number[] = [];
        
        file?.fileList?.forEach((e: {type: string, size: number}) => {
            fileExtensions.push(e.type)
            fileSizes.push(e.size / 1024 / 1024)
        })
        
        const isAcceptedXls = fileExtensions?.some((e) => {
           if(e !== acceptedXls && e !== acceptedXlsx) {
                return true
           }
           return false
        })
        const isSizeNotAccepted = fileSizes?.find((e) => e > 25)
        if(file?.fileList?.length === 0 || file === undefined) {
            callback('ファイルを選択してください')
        }else if (isAcceptedXls) {
            callback(`アップロードするファイルは「xls/ xlsx」のいずれかの形式にしてください`)
        }else if(isSizeNotAccepted) {
            callback(`ファイルサイズが25Mを超えています`)
        }else {
            callback()
        }
    };

    const handleCancel = () => {
        setIsVisibleModelConfirmBack(false)
    }

    const handleGoBack = () => {
        history.push(`/construction-site/${id}/location-site`)
    }

    const handleBack = () => {
        if(isValueForm) {
            setIsVisibleModelConfirmBack(true)
        }else{
            handleGoBack()
        }
    }

    return (
        <>
            <div className='location-create page-content-detail'>
                <h2 className='page-content-detail-title mb-[24px]'>
                    工区新規作成
                </h2>
                <Form form={antForm} onValuesChange={(value, allvalues) => handleChangeValue(allvalues)} className='form-create'>
                    <div>
                        <div className='mb-[16px]'>
                            <label className='labe-form'>工区名 <span>*</span></label>
                            <Form.Item 
                                name="name" 
                                rules={[
                                    { required: true, message: '工区名を入力してください' }, 
                                    {max: 255, message: '工区名は255文字以内で入力してください'}
                                ]}
                            >
                                <Input className='input-form'/>
                            </Form.Item>
                        </div>
                        <div className='mb-[16px]'>
                            <label className='labe-form'>点群データ <span>*</span></label>
                            <Form.Item 
                                name="point_files" 
                                rules={[
                                    {
                                        validator: (_: any, file: any, callback: any) => validateFileExtension(_, file, callback, '.las'),
                                    },
                                ]}
                            >
                                <Upload 
                                    accept=".las" 
                                    showUploadList={renderUploadList} 
                                    beforeUpload={(file) => Promise.reject(file)} 
                                    multiple 
                                    className='upload-file-form'
                                >
                                    <Button icon={renderUploadIcon()}>ファイルを選択して下さい</Button>
                                </Upload>
                            </Form.Item>
                        </div>

                        <div className='main-block p-[16px] mb-[16px]'>
                            <label className='labe-form'>基本SIMA</label>
                            <div className='mb-[16px] mt-[8px]'>
                                <label className='labe-form'>線形SIM <span>*</span></label>
                                <Form.Item 
                                    name="sim_linear_files" 
                                    rules={[
                                        {
                                            validator: (_: any, file: any, callback: any) => validateFileExtension(_, file, callback, '.sim'),
                                        },
                                    ]}
                                >
                                    <Upload 
                                        accept=".sim" 
                                        showUploadList={renderUploadList} 
                                        beforeUpload={(file) => Promise.reject(file)} 
                                        maxCount={1} 
                                        className={`upload-file-form ${isHidenUploadSima.lineSima ? 'upload-sima' : ''}`}
                                        onChange={(value) => handleChangeFileSima(value, 1)}
                                    >
                                        <Button icon={renderUploadIcon()}>ファイルを選択して下さい</Button>
                                    </Upload>
                                </Form.Item>
                            </div>
                            <div className='mb-[16px]'>
                                <label className='labe-form'>縦横断SIM <span>*</span></label>
                                <Form.Item 
                                    name="sim_slice_files" 
                                    rules={[
                                        {
                                            validator: (_: any, file: any, callback: any) => validateFileExtension(_, file, callback, '.sim'),
                                        },
                                    ]}
                                >
                                    <Upload 
                                        accept=".sim" 
                                        showUploadList={renderUploadList} 
                                        beforeUpload={(file) => Promise.reject(file)} 
                                        maxCount={1}
                                        className={`upload-file-form ${isHidenUploadSima.slineSima ? 'upload-sima' : ''}`}
                                        onChange={(value) => handleChangeFileSima(value, 2)}
                                    >
                                        <Button icon={renderUploadIcon()}>ファイルを選択して下さい</Button>
                                    </Upload>
                                </Form.Item>
                            </div>
                        </div>
                        
                        <div className='mb-[16px]'>
                            <label className='labe-form'>一般SIMA</label>
                            <Form.Item 
                                name="sim_common" 
                                rules={[
                                    {
                                        validator: (_: any, file: any, callback: any) => validateFileExtension(_, file, callback, '.sim'),
                                    },
                                ]}
                            >
                                <Upload 
                                    showUploadList={renderUploadList} 
                                    beforeUpload={(file) => Promise.reject(file)} 
                                    multiple 
                                    className='upload-file-form'
                                    accept=".sim" 
                                >
                                    <Button icon={renderUploadIcon()}>ファイルを選択して下さい</Button>
                                </Upload>
                            </Form.Item>
                        </div>
                        
                        <div className='mb-[16px]'>
                            <label className='labe-form'>切削厚データ <span>*</span></label>
                            <Form.Item 
                                name="cutting_thickness_files" 
                                rules={[
                                    {
                                        validator: validateFileExcel,
                                    },
                                ]}
                            >
                                <Upload 
                                    showUploadList={renderUploadList} 
                                    beforeUpload={(file) => Promise.reject(file)} 
                                    multiple 
                                    className='upload-file-form'
                                    accept={'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                                >
                                    <Button icon={renderUploadIcon()}>ファイルを選択して下さい</Button>
                                </Upload>
                            </Form.Item>
                        </div>
                        <div>
                            <label className='labe-form'>舗設厚データ <span>*</span></label>
                            <Form.Item 
                                name="paving_thickness_files" 
                                rules={[
                                    {
                                        validator: validateFileExcel,
                                    },
                                ]}
                            >
                                <Upload 
                                    showUploadList={renderUploadList} 
                                    beforeUpload={(file) => Promise.reject(file)} 
                                    multiple 
                                    className='upload-file-form'
                                    accept={'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                                >
                                    <Button icon={renderUploadIcon()}>ファイルを選択して下さい</Button>
                                </Upload>
                            </Form.Item>
                        </div>
                    </div>
                    <div className='footer-form mb-[160px] mt-[32px]'>
                        <Button className='mr-[10px] button-form' onClick={() =>handleBack()}>戻る</Button>
                        <Button loading={isloading} disabled={isDisableSubmit} className='button-form button-form-Submit' onClick={() => handleCreateLocationSite()}><span></span>作成</Button>
                    </div>
                </Form>
            </div>
            <ModalConfirm 
                isShowModal={isVisibleModelConfirmBack}
                onCancel={handleCancel}
                onOk={handleGoBack}
                content='このページを離れますか？'
                subContent='ページから移動すると、保存されていない変更は失われます。'
            />
        </>
    )
}

export default LocationSiteCreateComponent