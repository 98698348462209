import './footerLP.modules.scss';
import logoIcon from 'src/assets/images/landingPage/logo.svg';
import phoneIcon from 'src/assets/images/landingPage/phone.svg';
import mailIcon from 'src/assets/images/landingPage/mail.svg';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDetectWindowSize } from 'src/utils/useDetectWindowSize';

const FooterLP = () => {
  const history = useHistory();
  const queryParameters = new URLSearchParams(window.location.search);
  const lang = queryParameters.get('lang');
  const { t } = useTranslation();
  const windowSize = useDetectWindowSize();

  const handleBackRef = (id: string) => {
    history.push({ pathname: '/', search: lang ? `lang=${lang}` : '' });
    localStorage.setItem('backRef', id);
    localStorage.setItem('windowSize', JSON.stringify(windowSize));
  };

  return (
    <div className="footer_lp">
      <div className="footer_left">
        <a href={`/${lang ? `?lang=${lang}` : ''}`}>
          <div className="logo">
            <img src={logoIcon} alt="logo" />
            助さん
          </div>
        </a>

        <div className="contact_item">
          <div className="img_icon">
            <img src={phoneIcon} alt="" />
          </div>

          <div className="text">
            <p className="text_title">Hotline</p>
            <p className="text_content">(024) 4456 8888</p>
          </div>
        </div>
        <div className="contact_item">
          <div className="img_icon">
            <img src={mailIcon} alt="" />
          </div>

          <div className="text">
            <p className="text_title">Email</p>
            <p className="text_content">support_sukesan@synesthesias.jp</p>
          </div>
        </div>
      </div>

      <div className="footer_right">
        <ul className="menu">
          <li className="menu_item" onClick={() => handleBackRef('main_function')}>
            {t('main_function')}
          </li>
          <li className="menu_item" onClick={() => handleBackRef('cms')}>
            {t('cms')}
          </li>
          <li className="menu_item" onClick={() => handleBackRef('faq')}>
            {t('faq')}
          </li>
          <li className="menu_item" onClick={() => handleBackRef('notice')}>
            {t('notice')}
          </li>
          <li className="menu_item" onClick={() => handleBackRef('inquiry')}>
            {t('inquiry')}
          </li>
          <li className="menu_item">
            <a href={`/policy${lang ? `?lang=${lang}` : ''}`} target="_blank" rel="noopener noreferrer">
              {t('privacy_policy')}
            </a>
          </li>
          <li className="menu_item">
            <a href="https://synesthesias.jp/" target="_blank" rel="noopener noreferrer">
              {t('operating_company')}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FooterLP;
