import { staffCreateType } from "src/components/features/agency/type";
import { api } from "./axios";
export const companyCreateStaff = (params: staffCreateType) => api.post(
    `/api/cms/company/staff`,
    params,
)

export const companyEditStaff = (params: staffCreateType, id: number) => api.put(
    `/api/cms/company/staff/${id}`,
    params,
)

export const deleteStaffCompany = (id: number | undefined) => api.delete(
    `/api/cms/company/staff/${id}`,
)
  