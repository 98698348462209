import React from 'react'
import ConfirmEmail from 'src/components/features/forgotPassword/ConfirmEmail'
import { LayoutForgotPassword } from 'src/components/layouts/layoutForgotPassword'

const ConfirmEmailPage = () => (
    <LayoutForgotPassword>
       <ConfirmEmail />
    </LayoutForgotPassword>
)

export default ConfirmEmailPage