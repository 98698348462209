import { Button, Input, Pagination, Select, Spin, Table, message } from 'antd'
import React, { useMemo, useState } from 'react'
import './location.modules.scss'
import searchIcon from 'src/assets/images/search-icon.png'
import addIcon from 'src/assets/images/add-icon.png'
import locationSiteIcon from 'src/assets/images/location-site-icon.png'
import { useHistory, useParams } from 'react-router-dom'
import type { ColumnsType } from 'antd/es/table';
import { useLocationSiteList } from 'src/api/data/locationSite/useLocationSiteLists'
import TableNoteNoData from 'src/components/common/tableNoteNoData'
import { pageSizeList } from 'src/constants'
import copyIcon from 'src/assets/images/copy-icon.png'
import { useMutation, useQueryClient } from 'react-query'
import { duplicateConstruction } from 'src/api/construction'
import { QUERY_KEY } from 'src/api/data/query-key'
import deleteIcon from 'src/assets/images/delete-icon.png'
import { deleteLocationSite } from 'src/api/locationSite'
import ModalDelete from 'src/components/common/modalDelete'

interface DataType {
    key: string;
    name: string;
    updated_at: string;
}

const columns: ColumnsType<DataType> = [
    {
    title: '工区名',
    dataIndex: 'name',
    key: 'name',
    width: '45%'
    },
    {
    title: '更新日時',
    dataIndex: 'updated_at',
    key: 'updated_at',
    width: '45%'
    },
    {
    title: 'アクション',
    dataIndex: 'action',
    key: 'action',
    width: '10%'
    }
]
const LocationSiteComponent = () => {
    const { id } = useParams<{ id: string }>()
    const history = useHistory()
    const [keyword, setKeyword] = useState<string>('')
    const [keywordSearch, setKeywordSearch] = useState<string>('')
    const [page, setPage] = useState<number>(1)
    const [sizePage, setSizePage] = useState<number>(10)
    const [isShowModalDelete, setIsShowModalDelete] = useState<boolean>(false)
    const [idDelete, setIdDelete] = useState<number>()
    const { data: locationSiteLists, isLoading } = useLocationSiteList(Number(id),keywordSearch, page, sizePage)
    const queryClient = useQueryClient()
    const dataSource = useMemo(() => {
        if (locationSiteLists) {
          return locationSiteLists?.records?.map((locationSite: any) => ({
            ...locationSite,
            key: locationSite.id,
            name: (
                <div className='flex items-center cursor-pointer' onClick={() => handleGoToLocationSiteDetail(locationSite.id)}>
                    <img className='item-name-icon mr-[8px]' src={locationSiteIcon} alt="" />
                    <p className='site-name'>{locationSite.name}</p>
                </div>
            ),
            action: (
                <div className='flex'>
                    <img src = {copyIcon} className='cursor-pointer mr-[8px]' onClick={() => handleDuplicate(Number(locationSite.id))} alt=''/>
                    <img src = {deleteIcon} className='cursor-pointer' onClick={() => handleOpenModalDeleteConstruction(Number(locationSite.id))} alt=''/>
                </div>
            )
          }))
        }
        return []
      }, [history, id, locationSiteLists])

    const handleGoToLocationSiteDetail = (locationSiteId: number) => {
        queryClient.removeQueries(QUERY_KEY.LOCATION_SITE_LIST_FILES)
        history.push(`/construction-site/${id}/location-site/${locationSiteId}/list-file`)
    }

    const iconSearch = () => {
        return <div className='p-[5px] cursor-pointer' onClick={handleSearchLocationSite}><img src={searchIcon} alt='' /></div>
    }

    const handleChangeKeyword = (keyword: string) => {
        setKeyword(keyword)
    }

    const handleChangePage = (page: number) => {
        setPage(page)
    }

    const handleChangeSizePage = (value: number) => {
        setPage(1)
        setSizePage(value)
    }

    const handleCreateLocationSite = () => {
        history.push(`/construction-site/${id}/location-site/create`)
    }

    const handleSearchLocationSite = () => {
        setPage(1)
        setKeywordSearch(keyword)
    }
    const handleDuplicate = (id: number) => {
        onDuplicate(id)
    }

    const {mutate : onDuplicate, isLoading: isLoadingDuplicate} = useMutation((id: number) => duplicateConstruction(id), {
        onSuccess: async () => {
            await queryClient.invalidateQueries(QUERY_KEY.LOCATION_SITE_LIST)
            message.success('データ更新成功')
            setPage(1)
        },
        onError: (error: any) => {
            if (error.response?.status === 400) {
                message.error(error.response.data.messages[0])
                return
            }
            message.error('システムエラー')
          },
    })

    const handlingWhenDeletingAllCurrentPageRecords = async() => {
        if(page > 1 && locationSiteLists?.currentRow === 1) {
          setPage(page - 1)
        }else {
          queryClient.invalidateQueries(QUERY_KEY.LOCATION_SITE_LIST)
        }
      }

    const {mutate: onDelete, isLoading: isloadingDelete } = useMutation(
        () => deleteLocationSite(idDelete),
        {
          onSuccess: async() => {
            setIsShowModalDelete(false)
            await handlingWhenDeletingAllCurrentPageRecords()
            message.success('削除に成功しました')
          },
          onError: (error: any) => {
            if (error.response?.status === 400) {
                message.error(error.response.data.messages[0])
                return
            }
            message.error('システムエラー')
          },
        },
    )

    const handleDeleteLocationSite = () => {
        onDelete()
    }

    const handleOpenModalDeleteConstruction = (id: number) => {
        setIdDelete(id)
        setIsShowModalDelete(true)
    }

    const handleCancelDelete = () => {
        setIsShowModalDelete(false)
    }
    return (
        <div className='page-content-detail'>
            <Spin spinning = {isLoadingDuplicate}>
                <h2 className='page-content-detail-title mb-[24px]'>工区一覧</h2>
                <div className='page-content-table'>
                    <div className='flex justify-between items-center p-[16px]'>
                        <Input onPressEnter={handleSearchLocationSite} className='input-search' placeholder='工区名を入力' suffix={iconSearch()} onChange={(e) => handleChangeKeyword(e.target.value)}/>
                        <Button className='button-antd-custom' onClick={() => handleCreateLocationSite()}>
                            <img className='button-add-icon' src={addIcon} alt='' />
                            新規工区作成
                        </Button>
                    </div>
                    <div className='constuction-list'>
                    <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    loading={isLoading}
                    locale={{
                        emptyText: (
                        <TableNoteNoData />
                        )
                    }}
                    />
                    </div>
                    <div className='py-[20px] px-[12px] flex justify-between items-center'>
                        <div className='pagination-infor'>
                        {locationSiteLists?.limitFrom} - {locationSiteLists?.limitTo} 件表示/全 {locationSiteLists?.totalRow} 件中
                        </div>
                        <div className='flex'>
                        <Pagination 
                            current={page} 
                            total={locationSiteLists?.totalRow} 
                            className='pagination-main mr-[20px]' 
                            showSizeChanger={false}
                            pageSize={sizePage}
                            onChange={(page) => handleChangePage(page)}
                        />
                        <Select
                            defaultValue={10}
                            style={{ width: 153 }}
                            className='pagination-options'
                            onChange={(e) => handleChangeSizePage(e)}
                            options={pageSizeList}
                        />
                        </div>
                    </div>
                </div>
                <ModalDelete 
                    loadingButtonOk={isloadingDelete}
                    isShowModal={isShowModalDelete}
                    onCancel={handleCancelDelete}
                    onDelete={handleDeleteLocationSite}
                    content='この工区を削除します。よろしいですか？'
                />
            </Spin>
        </div>
    )
}

export default LocationSiteComponent