import { Button, Form, Input, Select, Spin, message } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import leftArrow from 'src/assets/images/back-icon.png'
import emailIcon from 'src/assets/images/icon-email.png'
import { companyCreateType } from 'src/components/features/agency/type'
import { agencyCreateCompany } from 'src/api/agency'
import ModalConfirm from 'src/components/common/modal/modalConfirm'
import { useListStaffAgency } from 'src/api/data/agency/useListStaffAgency'
import { validateEmail, validateFieldString, validatePhoneNumber } from 'src/utils/validateFields'

const CompanyCreateComponent = () => {
    const location = useLocation()
    const [antForm] = Form.useForm()
    const history = useHistory()
    const { id } = useParams<{ id: string }>()
    const { data: listAgencyStaff, isLoading } = useListStaffAgency(Number(localStorage.getItem('user_id'))) 
    const [isDisableSubmit,setIsDisableSubmit] = useState(true)
    const [isValueForm,setIsValueForm] = useState(false)
    const [isVisibleModelConfirmBack,setIsVisibleModelConfirmBack] = useState(false)

    const {isLoading: isloading, mutate: onCreateCompany } = useMutation(
        (param: companyCreateType) => agencyCreateCompany(param),
        {
          onSuccess: (res) => {
            message.success('会社申請登録に成功しました')
            history.push('/agency/company')
            antForm.resetFields()
          },
          onError: (error: any) => {
            if (error.response?.status === 400) {
                message.error(error.response.data.messages[0])
                return
            }
            message.error('システムエラー')
          },
        },
    )

    const handleCreateCompany = async() => {
        await trimmedValue()
        try {
            await antForm.validateFields()
        } catch { return }
        const params = antForm.getFieldsValue()
        onCreateCompany(params)
    }

    const handleChangeValue = (allvalues: []) => {
        const listValue = Object.keys(allvalues)?.filter((key) => key !== 'agency_staff_id')?.map((key: any) => allvalues[key])
        const validateErrorValueList = []
        listValue?.forEach((value: any) => {
            if(value === undefined || value === '') {
                validateErrorValueList.push(value)
            }
        })
        if(validateErrorValueList.length > 0) {
            setIsDisableSubmit(true)
        }else{
            setIsDisableSubmit(false)
        }
        listValue?.some((value:any) => {
            if(value !== undefined && value !== '' && value != null) {
                setIsValueForm(true)
                return true
            }
            setIsValueForm(false)
            return false
        })
    }

    useEffect(() => {
        if(id) {
            setIsDisableSubmit(false)
        }
    },[id])

    const renderEmailIcon = () => {
        return <img src={emailIcon} alt='' />
    }

    const handleGoBackCompanyList = () => {
        history.push('/agency/company')
    }

    const handleBackCompanyList = () => {
        if(isValueForm) {
            setIsVisibleModelConfirmBack(true)
        }else{
            history.push('/agency/company')
        }
    }

    const handleCancel = () => {
        setIsVisibleModelConfirmBack(false)
    }

    const optionAgencyStaff = useMemo(() => {
        return listAgencyStaff?.map((item: any) => (
            {
                value: item?.id,
                label: `${item?.membership_code ? item?.membership_code + '-': ''}${item?.name}`
            }
        ))
    },[listAgencyStaff])


    const trimmedValue = async() => {
        const allValueForm = antForm.getFieldsValue()
        Object.keys(allValueForm).forEach((key: any) => {
            if(typeof allValueForm[key] === typeof '') {
                antForm.setFieldsValue({ [key]: allValueForm[key]?.trim() })
            }
        })
    }

    const renderNotFoundContent = () => {
        return <p className='text-center p-[10px]'>データがありません</p>
    }
    
    return (
       <div className='construction-create p-[16px]'>
            <h2 className='page-content-title border-bottom-title mb-[30px] flex items-center'>
                <img src={leftArrow} alt='' className='inline-block mr-[17px] cursor-pointer' onClick={handleBackCompanyList}/>
                <span onClick={handleBackCompanyList} className='cursor-pointer'>会社アカウント申請作成</span>
            </h2>
            {
                isLoading ? <Spin /> :
                <Form form={antForm} onValuesChange={(value, allvalues) => handleChangeValue(allvalues)} className='form-create'>
                    <div className='px-[20px] py-[12px]'>
                        <div className='mb-[16px]'>
                            <label className='labe-form'>会社名 <span>*</span></label>
                            <Form.Item name='name' 
                                rules={[{validator: (rule, value, callback) => validateFieldString(rule,value,callback, '会社名')}]}>
                                <Input className='input-form'/>
                            </Form.Item>
                        </div>
                        <div className='mb-[16px]'>
                            <label className='labe-form'>代表者 <span>*</span></label>
                            <Form.Item name='presentative' rules={[{validator: (rule, value, callback) => validateFieldString(rule,value,callback, '代表者', 50)}]}>
                                <Input className='input-form'/>
                            </Form.Item>
                        </div>
                        <div className='mb-[16px]'>
                            <label className='labe-form'>住所 <span>*</span></label>
                            <Form.Item name='address' rules={[{validator: (rule, value, callback) => validateFieldString(rule,value,callback, '住所')}]}>
                                <Input className='input-form'/>
                            </Form.Item>
                        </div>
                        <div className='mb-[16px]'>
                            <label className='labe-form'>メールアドレス <span>*</span></label>
                            <Form.Item name='email' rules={[{ validator: (rule, value, callback) => validateEmail(rule,value,callback)}]}>
                                <Input className='input-form' suffix={renderEmailIcon()}/>
                            </Form.Item>
                        </div>
                        <div className='mb-[16px]'>
                            <label className='labe-form'>電話番号 <span>*</span></label>
                            <Form.Item 
                                name='phone' 
                                rules={
                                    [
                                        { validator: validatePhoneNumber}
                                    ]
                                }
                            >
                                <Input className='input-form'/>
                            </Form.Item>
                        </div>
                        <div className='mb-[16px]'>
                            <label className='labe-form'>担当者名</label>
                            <Form.Item name='agency_staff_id'>
                                <Select 
                                    className='company-input-select h-[51px]'
                                    showSearch={true}
                                    allowClear={true}
                                    options={optionAgencyStaff!}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '')?.toString().toLowerCase()?.includes(input?.trim()?.toLowerCase())
                                    }
                                    notFoundContent={renderNotFoundContent()}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className='footer-form flex'>
                        <Button className='button-form mr-[16px]' onClick={handleBackCompanyList}>戻る</Button>
                        <Button 
                            disabled={isDisableSubmit} 
                            className='button-form button-form-Submit' 
                            loading={isloading}
                            onClick={handleCreateCompany}
                        >
                            <span></span>{location.pathname?.includes('edit') ? '保存' :  '作成'}
                        </Button>
                    </div>
                </Form>
            }
            <ModalConfirm 
                isShowModal={isVisibleModelConfirmBack}
                onCancel={handleCancel}
                onOk={handleGoBackCompanyList}
                content='このページを離れますか？'
                subContent='ページから移動すると、保存されていない変更は失われます。'
            />
       </div>
    )
}

export default CompanyCreateComponent