import { Button, Spin, Switch, Input, Pagination, Select, Table, message } from 'antd'
import React, {useMemo, useState} from 'react'
import { useHistory, useParams } from 'react-router-dom'
import leftArrow from 'src/assets/images/back-icon.png'
import { companyInAdminStatusData, detailCompanyAdmin, staffAgencyStatusData } from 'src/constants'
import searchIcon from 'src/assets/images/search-icon.png'
import { pageSizeList, staffAgencyStatus } from 'src/constants'
import iconSelect from 'src/assets/images/arrow-down-2.png'
import TableNoteNoData from 'src/components/common/tableNoteNoData';
import type { ColumnsType } from 'antd/es/table';
import { companyCreateType } from 'src/components/features/agency/type';
import {getClassStatusStaffAgency, handleGetStatusStaffAgency } from 'src/utils';
import { useMutation, useQueryClient } from 'react-query'
import { changeStatusCompanyInAdmin } from 'src/api/admin'
import { QUERY_KEY } from 'src/api/data/query-key'
import { useDetailCompanyAdmin } from 'src/api/data/admin/useDetailCompanyAdmin'
import { useListCompanyStaffOfAdmin } from 'src/api/data/admin/useListCompanyStaffOfAdmin'

interface DataType {
    key: string;
    name: string;
    location: string;
    updated_at: string;
}
const columns: ColumnsType<DataType> = [
    {
        title: '会員コード',
        key: 'code',
        dataIndex: 'membership_code',
        width: 60
    },
    {
        title: '会員名',
        dataIndex: 'name',
        key: 'name',
        width: 388
    },
    {
        title: 'メールアドレス',
        dataIndex: 'email',
        key: 'email',
        width: 388
    },
    {
        title: '電話番号',
        key: 'phone',
        dataIndex:'phone',
        width:160
    },
    {
        title: '状態',
        key: 'status',
        dataIndex:'status',
        width:140
    },
]
const CompanyDetailComponent = () => {
    const history = useHistory()
    const { id } = useParams<{ id: string }>()
    const [keyword, setKeyword] = useState<string>('')
    const [keywordSearch, setKeywordSearch] = useState<string>('')
    const [status, setStatus] = useState<number | undefined>()
    const [page, setPage] = useState<number>(1)
    const [sizePage, setSizePage] = useState<number>(10)
    const queryClient = useQueryClient()
    const { data: detailData, isLoading } = useDetailCompanyAdmin(Number(id)) 
    const { data: listCompanyStaffOfAdmin, isLoading: isLoadingListStaff } = useListCompanyStaffOfAdmin(Number(id),keywordSearch,status, page, sizePage) 

    const handleGoBack = () => {
        history.push('/cms/company')
    }

    const dataSource = useMemo(() => {
        if (listCompanyStaffOfAdmin) {
          return listCompanyStaffOfAdmin?.records?.map((company: companyCreateType, index: number) => ({
            ...company,
            key: company?.id,
            code: <span className='line-1'>{company?.membership_code}</span>,
            name: (
              <span className='site-name line-1 cursor-pointer'>{company?.name}</span>
            ),
            email: (
              <span className='line-1 break-all'>{company?.email}</span>
            ),
            status: (
              <span className={`company-request-status inline-block ${getClassStatusStaffAgency(company?.status)}`}>
                {
                  handleGetStatusStaffAgency(company?.status)
                }
              </span>
            ),
          }))
        }
        return []
      }, [listCompanyStaffOfAdmin])

    const iconSearch = () => {
        return <div className='p-[5px] cursor-pointer' onClick={handleSearch}><img src={searchIcon} alt='' /></div>
    }

    const handleSearch = () => {
        setPage(1)
        setKeywordSearch(keyword?.trim())
        setKeyword(keyword?.trim())
    }

    const handleChangeKeyword = (value:string) => {
        setKeyword(value)
    }

    const handleSearchStatus = (status: number) => {
        setPage(1)
        setStatus(status)
    }

    const renderSelectIcon = () => {
        return <img src={iconSelect} alt='' />
    }

    const handleChangePage = (page: number) => {
        setPage(page)
    }

    const handleChangeSizePage = (value: number) => {
        setPage(1)
        setSizePage(value)
    }

    const {isLoading: isloadingStatus, mutate: onChangeCompanyStatus } = useMutation(
        (params:{status: number}) => changeStatusCompanyInAdmin(Number(id), params),
        {
          onSuccess: async() => {
            message.success('データ更新成功')
            await queryClient.invalidateQueries(QUERY_KEY.DETAIL_COMPANY_ADMIN)
          },
          onError: (error: any) => {
            if (error.response?.status === 400) {
                message.error(error.response.data.messages[0])
                return
            }
            message.error('システムエラー')
          },
        },
    )

    const handleChangeStatus = (status: number) => {
        const params = {
            status: status === companyInAdminStatusData?.APPROVED ? companyInAdminStatusData?.REJECT : companyInAdminStatusData?.APPROVED
        }
        onChangeCompanyStatus(params)
    }

    const handleGoToEdit = () => {
        history.push(`/cms/company/edit/${id}?redirectType=1`)
    }
  
    return (
       <div className='p-[16px]'>
            {
                <>
                    <div className='page-content-title border-bottom-title mb-[30px] flex items-center justify-between'>
                        <div className='flex items-center'>
                            <div className='mr-[17px]' onClick={handleGoBack}><img src={leftArrow} alt='' className='inline-block cursor-pointer min-w-[24px]'/></div>
                            <span onClick={handleGoBack} className='cursor-pointer line-1'>{detailData?.name}</span>
                        </div>
                        <Button 
                            className='button-add min-w-[173px]' 
                            onClick={handleGoToEdit}
                        >
                            <span></span>変更
                        </Button>
                    </div>
                    <div className='license-detail mb-[24px]'>
                        <div className='license-detail-title'>会社情報</div>
                        <div className='license-detail-content'>
                            {
                                detailCompanyAdmin.map((item: any) => (
                                    item.id === 6 ? 
                                    <div className='mb-[16px] w-6/12 flex' key={item.id}>
                                        <label className='labe w-[150px]'>代理店名</label>
                                        <div className='text-[16px] text-[#000000] break-all w-detail-content px-[10px]'>
                                            {
                                                detailData?.agency?.name
                                            }
                                        </div>
                                    </div> 
                                    : 
                                    <div className='mb-[16px] flex w-6/12' key={item.id}>
                                        <label className='labe w-[150px]'>{item.label}</label>
                                        <div className='text-[16px] text-[#000000] break-all w-detail-content px-[10px]'>
                                            {
                                                detailData?.[item.name]
                                            }
                                        </div>
                                    </div>
                                ))
                                
                            } 
                            <div className='mb-[16px] w-6/12 flex'>
                                <label className='labe w-[150px]'>担当者名</label>
                                <div className='text-[16px] text-[#000000] break-all w-detail-content px-[10px]'>
                                    {
                                       detailData?.agency_staff ? 
                                       detailData?.agency_staff?.membership_code + 
                                       ' - ' +  
                                       detailData?.agency_staff?.name
                                       : '-'
                                    }
                                </div>
                            </div> 
                            <div className='mb-[16px] w-6/12 flex'>
                                <label className='labe w-[150px]'>電話番号</label>
                                <div className='text-[16px] text-[#000000] break-all w-detail-content px-[10px]'>
                                    {
                                        detailData?.phone
                                    }
                                </div>
                            </div> 
                            <div className='mb-[16px] w-6/12 flex'>
                                <label className='labe w-[150px]'>有効</label>
                                <div className='w-detail-content px-[10px]'>
                                    <Switch 
                                        checked={detailData?.status === staffAgencyStatusData.ACTIVE ? true : false} 
                                        className='ar-switch' 
                                        onClick={() => handleChangeStatus(detailData?.status)}
                                        loading={isloadingStatus}
                                    />
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div className='page-content-table'>
                        <div className='flex justify-between items-center p-[16px]'>
                            <div className='flex items-center'>
                                    <Input 
                                        onPressEnter={handleSearch} 
                                        className='input-search mr-[8px]' 
                                        placeholder='会員名を入力' 
                                        suffix={iconSearch()} 
                                        onChange={(e) => handleChangeKeyword(e.target.value)}
                                        value={keyword}
                                    />
                                    <Select 
                                        options={staffAgencyStatus}
                                        placeholder="状態"
                                        className='company-select'
                                        allowClear={true}
                                        onChange={(value: number) => handleSearchStatus(value)}
                                        suffixIcon={renderSelectIcon()}
                                    />
                            </div>
                        </div>
                        <div className='constuction-list'>
                            {
                                isLoading ? <Spin className='position-center' /> :
                                <Table
                                    columns={columns}
                                    dataSource={dataSource}
                                    pagination={false}
                                    loading={isLoadingListStaff}
                                    locale={{
                                        emptyText: (
                                            <TableNoteNoData />
                                        )
                                    }}
                                />
                            }
                        </div>
                        <div className='py-[20px] px-[12px] flex justify-between items-center'>
                            <div className='pagination-infor'>
                            {listCompanyStaffOfAdmin?.limitFrom} - {listCompanyStaffOfAdmin?.limitTo} 件表示/全 {listCompanyStaffOfAdmin?.totalRow} 件中
                            </div>
                            <div className='flex'>
                            <Pagination 
                                current={page} 
                                total={listCompanyStaffOfAdmin?.totalRow} 
                                className='pagination-main mr-[20px]' 
                                showSizeChanger={false}
                                pageSize={sizePage}
                                onChange={(page) => handleChangePage(page)}
                            />
                            <Select
                                defaultValue={10}
                                style={{ width: 153 }}
                                className='pagination-options'
                                onChange={(e) => handleChangeSizePage(e)}
                                options={pageSizeList}
                            />
                            </div>
                        </div>
                    </div>
                </>
            }
       </div>
    )
}

export default CompanyDetailComponent