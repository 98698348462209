/* eslint-disable jsx-a11y/anchor-is-valid */
import './landingPage.modules.scss';
import logoIcon from 'src/assets/images/landingPage/logo.svg';
import logoBlueIcon from 'src/assets/images/landingPage/logo_blue.svg';
import globeIcon from 'src/assets/images/landingPage/globe.svg';
import globeBlackIcon from 'src/assets/images/landingPage/globe_black.svg';
import arrowDownIcon from 'src/assets/images/landingPage/arrow_down.svg';
import arrowDownBlackIcon from 'src/assets/images/landingPage/arrow_down_black.svg';
import logoutIcon from 'src/assets/images/landingPage/logout.svg';
import CmsIcon from 'src/assets/icon/cmsIcon';

import appStoreIcon from 'src/assets/images/landingPage/app_store.svg';
import imgBanner from 'src/assets/images/landingPage/banner_2.png';
import control1 from 'src/assets/images/landingPage/cms_1.png';
import arrowRight from 'src/assets/images/landingPage/arrow-right.svg';
import phoneIcon from 'src/assets/images/landingPage/phone.svg';
import mailIcon from 'src/assets/images/landingPage/mail.svg';
import avatarIcon from 'src/assets/images/avatar.svg';
import openMenuIcon from 'src/assets/images/landingPage/open_menu.svg';
import closeMenuIcon from 'src/assets/images/landingPage/close_menu.svg';
import mainFnCenter from 'src/assets/images/landingPage/main_fn_center.png';
import mainFnArrowLeft1 from 'src/assets/images/landingPage/main_fn_arrow_left_1.svg';
import mainFnArrowLeft2 from 'src/assets/images/landingPage/main_fn_arrow_left_2.svg';
import mainFnArrowRight1 from 'src/assets/images/landingPage/main_fn_arrow_right_1.svg';
import mainFnArrowRight2 from 'src/assets/images/landingPage/main_fn_arrow_right_2.svg';
import mainFnIconLeft1 from 'src/assets/images/landingPage/main_fn_icon_left_1.svg';
import mainFnIconLeft2 from 'src/assets/images/landingPage/main_fn_icon_left_2.svg';
import mainFnIconRight1 from 'src/assets/images/landingPage/main_fn_icon_right_1.svg';
import mainFnIconRight2 from 'src/assets/images/landingPage/main_fn_icon_right_2.svg';
import mainFnLeft1 from 'src/assets/images/landingPage/main_fn_left_1.png';
import mainFnLeft2 from 'src/assets/images/landingPage/main_fn_left_2.png';
import mainFnRight1 from 'src/assets/images/landingPage/main_fn_right_1.png';
import mainFnRight2 from 'src/assets/images/landingPage/main_fn_right_2.png';
import engineerLeft from 'src/assets/images/landingPage/engineer_left.png';
import engineerCenter from 'src/assets/images/landingPage/engineer_center.png';
import engineerMb1 from 'src/assets/images/landingPage/engineer_mb_1.svg';
import engineerMb2 from 'src/assets/images/landingPage/engineer_mb_2.svg';
import engineerMb3 from 'src/assets/images/landingPage/engineer_mb_3.svg';
import engineerMb4 from 'src/assets/images/landingPage/engineer_mb_4.svg';
import contactLeft from 'src/assets/images/landingPage/contact_left.png';
import formMessageIcon from 'src/assets/images/landingPage/form_message_icon.svg';

import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { role } from 'src/constants';
import { Button, Dropdown, Form, Input, MenuProps, Space, message } from 'antd';
import { validateEmailLang, validatePhoneNumberLang } from 'src/utils/validateFields';
import TextArea from 'antd/lib/input/TextArea';
import { useDetectWindowSize } from 'src/utils/useDetectWindowSize';

import { useTranslation } from 'react-i18next';
import FaqItem from 'src/components/common/faqItem/faqItem';
import { useMutation } from 'react-query';
import { createContactUs, createContactUsType } from 'src/api/landingPage';
import { useFaqLP } from 'src/api/data/admin/useFaqLP';
import { getCookie } from 'src/utils/cookie';
import LogoutIcon from 'src/assets/icon/logoutIcon';
import { logout } from 'src/api/auth';
import { clearToken } from 'src/utils/cookie';
import { AxiosError } from 'axios';
import { useNotiLP } from 'src/api/data/admin/useNotiLP';

import ReCAPTCHA from 'react-google-recaptcha';
import { useGetInfoUser } from 'src/api/data/admin/useGetInfoUser';
const siteKey = process.env.REACT_APP_SITE_KEY;

const LandingPageComponent = () => {
  const [antForm] = Form.useForm();
  const windowSize = useDetectWindowSize();
  const isTablet = windowSize <= 1199;
  const isMobile = windowSize <= 991;
  const isMobile767 = windowSize <= 767;
  const isMobile575 = windowSize <= 575;
  const isMobile380 = windowSize <= 380;
  const history = useHistory();
  const location = useLocation();
  const queryParameters = new URLSearchParams(window.location.search);
  const lang = queryParameters.get('lang');

  const [showLanguage, setShowLanguage] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [language, setLanguage] = useState('日本語');
  const [tokenCaptcha, setTokenCaptcha] = useState();
  const [seeMore, setSeeMore] = useState(true);
  const [notice, setNotice] = useState([]);
  const [isDisableSubmit, setIsDisableSubmit] = useState(true);

  const handleChangeValue = (allvalues: []) => {
    const listValue = Object.keys(allvalues)?.map((key: any) => allvalues[key]);
    const validateErrorValueList: any[] = [];
    listValue?.forEach((value: any) => {
      if (value === undefined || value === '') {
        validateErrorValueList.push(value);
      }
    });
    if (validateErrorValueList?.length > 0) {
      setIsDisableSubmit(true);
    } else {
      setIsDisableSubmit(false);
    }
  };

  const { i18n, t } = useTranslation();

  const bannerRef = useRef(null);
  const cmsRef = useRef(null);
  const faqRef = useRef(null);
  const eventRef = useRef(null);
  const contactRef = useRef(null);

  const recaptchaRef = useRef<any>();
  const onChange = (value: any) => {
    setTokenCaptcha(value);
  };

  const LANGUAGES = [
    { label: '日本語', code: 'jp' },
    { label: 'English', code: 'en' },
  ];

  const handleScroll = (ref: any, onTop?: number) => {
    setShowMenu(false);
    if (ref.current) {
      const targetPosition = ref.current.offsetTop - (onTop ? onTop : 50);
      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth',
      });
    }
  };

  const handleChangeLanguage = (value: string) => {
    setShowLanguage(false);
    antForm.resetFields();
    i18n.changeLanguage(value);
    setLanguage(LANGUAGES.find((item) => item.code === value)?.label || '日本語');
    history.replace({ pathname: location.pathname, search: `lang=${value}` });
  };

  useEffect(() => {
    if (lang) {
      if (lang !== i18n.language) {
        i18n.changeLanguage(lang);
        document.title = lang === 'en' ? 'AR Civil' : '助さん';
      }
      setLanguage(LANGUAGES.find((item) => item.code === lang)?.label || '日本語');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const { data, isLoading: isLoadingInfo } = useGetInfoUser();
  const { data: faqLP } = useFaqLP();
  const { data: notiLP } = useNotiLP();

  useEffect(() => {
    if (notiLP?.length > 0) {
      setNotice(notiLP.slice(0, 5));
    }
  }, [notiLP]);

  const { isLoading: isloading, mutate: onCreateContactUs } = useMutation(
    (param: createContactUsType) => createContactUs(param),
    {
      onSuccess: (res) => {
        message.success('メッセージが送信されました');
        antForm.resetFields();
        recaptchaRef.current.reset();
        setTokenCaptcha(undefined);
        setIsDisableSubmit(true);
      },
      onError: (error: any) => {
        if (error.response?.status === 400) {
          message.error(error.response.data.messages[0]);
          return;
        }
        message.error('システムエラー');
      },
    }
  );

  const handleSubmitForm = async (values: any) => {
    await trimmedValue();

    try {
      await antForm.validateFields();
    } catch {
      return;
    }

    const param = {
      ...values,
      'g-recaptcha-response': tokenCaptcha,
      lang: lang,
    };
    onCreateContactUs(
      lang && lang !== 'jp'
        ? param
        : {
            ...values,
            'g-recaptcha-response': tokenCaptcha,
          }
    );
  };

  const trimmedValue = async () => {
    const allValueForm = antForm.getFieldsValue();
    Object.keys(allValueForm).forEach((key: any) => {
      if (typeof allValueForm[key] === typeof '') {
        antForm.setFieldsValue({ [key]: allValueForm[key]?.trim() });
      }
    });
  };

  //handle menu when scroll down
  const [addClass, setAddClass] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleMenuScroll);

    return () => {
      window.removeEventListener('scroll', handleMenuScroll);
    };
  }, []);

  const handleMenuScroll = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 100 ? setAddClass(true) : setAddClass(false);
    }
  };

  useEffect(() => {
    const backRef = localStorage.getItem('backRef');
    const windowSize = localStorage.getItem('windowSize');
    let isMobile575 = false;

    if (Number(windowSize) > 0) {
      isMobile575 = Number(windowSize) <= 575;
    }

    if (backRef) {
      switch (backRef) {
        case 'main_function':
          handleScroll(bannerRef, isMobile575 ? 50 : 100);
          break;
        case 'cms':
          handleScroll(cmsRef, isMobile575 ? 50 : 550);
          break;
        case 'faq':
          handleScroll(faqRef, isMobile575 ? 50 : 400);
          break;
        case 'notice':
          handleScroll(eventRef, isMobile575 ? 50 : 500);
          break;
        case 'inquiry':
          handleScroll(contactRef, isMobile575 ? 50 : 500);
          break;
        default:
          break;
      }
    }
    localStorage.removeItem('backRef');
    localStorage.removeItem('windowSize');
  }, []);

  //hendle logout
  const { mutateAsync: onLogout } = useMutation(() => logout(), {
    onSuccess: async () => {
      history.push({ pathname: '/', search: lang ? `lang=${lang}` : '' });
      clearToken();
      localStorage.clear();
    },
    onError: (err: AxiosError) => {
      if (err.response?.status === 400) {
        message.error(err.response.data.messages[0]);
        return;
      }
      message.error('システムエラー');
    },
  });

  const handleLogout = () => {
    onLogout();
  };

  const handleDecodeUrl = (url: string) => {
    return decodeURIComponent(url);
  };

  const roleAcc = Number(localStorage.getItem('role'));
  const items: MenuProps['items'] = [
    {
      label: (
        <a
          className="user-infor-item"
          href={
            roleAcc === role.SYSTEM_ADMIN || roleAcc === role.ADMIN
              ? '/cms/request/company'
              : roleAcc === role.AGENCY || roleAcc === role.AGENCY_STAFF
              ? '/agency/company'
              : '/construction-site'
          }
        >
          <CmsIcon className={'mr-[12px] menu-icon-spec mt-1'} />
          <span>{t('go_to_cms')}</span>
        </a>
      ),
      key: 1,
    },
    {
      label: (
        <div className="user-infor-item" onClick={() => handleLogout()}>
          <LogoutIcon className={'mr-[12px] menu-icon'} />
          <span>{t('logout')}</span>
        </div>
      ),
      key: 2,
    },
  ];

  const handleGetDropdownContainer = () => {
    return document.getElementById('header')!;
  };

  useEffect(() => {
    if (showMenu && isMobile) {
      document.body.style.overflowY = 'hidden';
      document.body.style.position = 'relative';
    } else {
      document.body.style.overflowY = 'unset';
      document.body.style.position = 'unset';
    }
  }, [showMenu, isMobile]);

  return (
    <div className={`wrap_lp ${lang && lang !== 'jp' ? 'wrap_lp_lang' : ''}`}>
      <div className={`header_lp ${addClass ? 'header_lp_black' : ''}`}>
        <a href={`/${lang ? `?lang=${lang}` : ''}`}>
          <div className={`logo ${showMenu && isMobile ? 'logo_blue' : ''}`}>
            {showMenu && isMobile ? <img src={logoBlueIcon} alt="logo" /> : <img src={logoIcon} alt="logo" />}
            助さん
          </div>
        </a>

        {!isLoadingInfo && isMobile && (
          <div className="nav_btn">
            {!showMenu ? (
              <label className="show_menu_button" onClick={() => setShowMenu(true)}>
                <img src={openMenuIcon} alt="open" />
              </label>
            ) : (
              <label className="hide_menu_button" onClick={() => setShowMenu(false)}>
                <img src={closeMenuIcon} alt="close" />
              </label>
            )}
          </div>
        )}

        {isMobile ? (
          <div className={`menu_mobile ${showMenu && 'menu_mobile_show'}`}>
            <div className="wrap_login">
              {getCookie('token') && data ? (
                <>
                  {!isLoadingInfo && (
                    <div className="wrap_avatar">
                      <div className="avatar">
                        <img
                          className="header__icon-avatar"
                          src={data?.avatar ? handleDecodeUrl(data?.avatar) : avatarIcon}
                          alt=""
                        />
                      </div>
                      <div className="avatar_info">
                        <p className="name">{data?.name}</p>
                        <p className="mail">{data?.email}</p>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="button_login">
                  <button onClick={() => history.push('/login')}>{t('login')}</button>
                </div>
              )}
            </div>

            <div className="line"></div>

            <div className="menu">
              <div className="menu_item" onClick={() => handleScroll(bannerRef)}>
                {t('main_function')}
              </div>
              <div className="menu_item" onClick={() => handleScroll(cmsRef)}>
                {t('cms')}
              </div>
              <div className="menu_item" onClick={() => handleScroll(faqRef)}>
                {t('faq')}
              </div>
              <div className="menu_item" onClick={() => handleScroll(eventRef)}>
                {t('notice')}
              </div>
              <div className="menu_item" onClick={() => handleScroll(contactRef)}>
                {t('inquiry')}
              </div>
            </div>

            <div className="line"></div>

            <div className="language">
              <div className="language_base" onClick={() => setShowLanguage(!showLanguage)}>
                <div className="language_globe">
                  <img className="img-3" src={globeBlackIcon} alt="global" />
                  <div className="text-wrapper-25">{language}</div>
                </div>
                <img className="arrow_down" src={arrowDownBlackIcon} alt="arrow" />
              </div>

              {showLanguage && (
                <div className="language_popup">
                  {LANGUAGES.map((lang, index) => {
                    return (
                      <p
                        key={'lang' + index}
                        className="language_popup_item"
                        onClick={() => {
                          handleChangeLanguage(lang.code);
                          history.go(0);
                        }}
                      >
                        {lang.label}
                      </p>
                    );
                  })}
                </div>
              )}
            </div>

            {getCookie('token') && data && (
              <>
                <div className="line"></div>

                <a
                  className="go_to_cms"
                  href={
                    roleAcc === role.SYSTEM_ADMIN || roleAcc === role.ADMIN
                      ? '/cms/request/company'
                      : roleAcc === role.AGENCY || roleAcc === role.AGENCY_STAFF
                      ? '/agency/company'
                      : '/construction-site'
                  }
                >
                  <CmsIcon className={'mr-[12px] menu-icon'} />
                  <span>{t('go_to_cms')}</span>
                </a>

                <div
                  className="logout"
                  onClick={() => {
                    handleLogout();
                  }}
                >
                  <img src={logoutIcon} alt="logout" />
                  {t('logout')}
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="menu_button" id="header">
            <div className="menu">
              <div className="menu_item" onClick={() => handleScroll(bannerRef)}>
                {t('main_function')}
              </div>
              <div className="menu_item" onClick={() => handleScroll(cmsRef)}>
                {t('cms')}
              </div>
              <div className="menu_item" onClick={() => handleScroll(faqRef)}>
                {t('faq')}
              </div>
              <div className="menu_item" onClick={() => handleScroll(eventRef)}>
                {t('notice')}
              </div>
              <div className="menu_item" onClick={() => handleScroll(contactRef)}>
                {t('inquiry')}
              </div>
              <div className="menu_item language">
                <div className="language_base" onClick={() => setShowLanguage(!showLanguage)}>
                  <div className="language_globe">
                    <img className="img-3" src={globeIcon} alt="global" />
                    <div className="text-wrapper-25">{language}</div>
                  </div>
                  <img className="arrow_down" src={arrowDownIcon} alt="arrow" />
                </div>

                {showLanguage && (
                  <div className="language_popup">
                    {LANGUAGES.map((lang, index) => {
                      return (
                        <p
                          key={'lang' + index}
                          className="language_popup_item"
                          onClick={() => {
                            handleChangeLanguage(lang.code);
                            history.go(0);
                          }}
                        >
                          {lang.label}
                        </p>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>

            {!isLoadingInfo && (
              <>
                {getCookie('token') && data ? (
                  <div className="avatar">
                    <Dropdown
                      overlayClassName="user-infor"
                      menu={{ items }}
                      trigger={['click']}
                      getPopupContainer={handleGetDropdownContainer}
                    >
                      <a href="#" onClick={(e) => e.preventDefault()}>
                        <Space>
                          <img
                            className="header__icon-avatar"
                            src={data?.avatar ? handleDecodeUrl(data?.avatar) : avatarIcon}
                            alt=""
                          />
                        </Space>
                      </a>
                    </Dropdown>
                  </div>
                ) : (
                  <div className="button_login">
                    <button onClick={() => history.push('/login')}>{t('login')}</button>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>

      <div className="body_lp">
        <div className={`banner ${isTablet && 'banner_tablet'} ${isMobile575 && 'banner_mobile'}`}>
          <Container>
            <p className="title_banner">{t('banner_title')}</p>

            {i18n.language === 'jp' ? (
              <p className="des_banner">
                高精度な自己位置推定機能＆正確なAR表示で、切削{isMobile && <br />}オーバーレイ工事を効率化。マーキング
                {!isMobile && <br />}作業や廃棄合{isMobile && <br />}材を減らし、工事現場の生産性・品質向上
                {isMobile && <br />}を可能にします
              </p>
            ) : (
              <p className="des_banner">
                Highly accurate self-positioning estimation function & accurate AR display make cutting overlay
                construction more efficient. Marking Reduces work and waste compound material, enabling improved
                productivity and quality at construction sites.
              </p>
            )}

            <div className="img_app_store">
              <img src={appStoreIcon} alt="" />
            </div>

            <div className="img_banner">
              <img src={imgBanner} alt="" />
            </div>
          </Container>
        </div>

        <div className="main_function" id="main-function" ref={bannerRef}>
          <Container>
            <p className="title">
              {t('main_function_title')}
              <span className="rectangle"></span>
            </p>

            <div className="wrap_content">
              <div className="img_center">
                <img src={mainFnCenter} alt="" />
              </div>

              <div className="wrap_content_item wrap_content_item_left_1">
                <div className="content_item">
                  <div className="content_item_img">
                    <img src={mainFnLeft1} alt="" />
                  </div>

                  <div className="content_item_text">
                    <div className="content_item_icon">
                      <img src={mainFnIconLeft1} alt="" />
                    </div>

                    <p className="content_item_title">{t('AR_display')}</p>

                    <p className="content_item_desc">{t('AR_display_des')} </p>
                  </div>

                  {!isMobile767 && <img src={mainFnArrowLeft1} alt="" className="content_item_arrow" />}
                </div>
              </div>

              <div className="wrap_content_item wrap_content_item_left_2">
                <div className="content_item">
                  <div className="content_item_img">
                    <img src={mainFnLeft2} alt="" />
                  </div>

                  <div className="content_item_text">
                    <div className="content_item_icon">
                      <img src={mainFnIconLeft2} alt="" />
                    </div>

                    <p className="content_item_title">{t('toturial_title_1')}</p>

                    <p className="content_item_desc">{t('totuial_des_1')}</p>
                  </div>

                  {!isMobile767 && <img src={mainFnArrowLeft2} alt="" className="content_item_arrow" />}
                </div>
              </div>

              <div className="wrap_content_item wrap_content_item_right_1">
                <div className="content_item">
                  <div className="content_item_img">
                    <img src={mainFnRight1} alt="" />
                  </div>

                  <div className="content_item_text">
                    <div className="content_item_icon">
                      <img src={mainFnIconRight1} alt="" />
                    </div>

                    <p className="content_item_title">{t('toturial_title_2')}</p>

                    <p className="content_item_desc">{t('totuial_des_2')}</p>
                  </div>

                  {!isMobile767 && <img src={mainFnArrowRight1} alt="" className="content_item_arrow" />}
                </div>
              </div>

              <div className="wrap_content_item wrap_content_item_right_2">
                <div className="content_item">
                  <div className="content_item_img">
                    <img src={mainFnRight2} alt="" />
                  </div>

                  <div className="content_item_text">
                    <div className="content_item_icon">
                      <img src={mainFnIconRight2} alt="" />
                    </div>

                    <p className="content_item_title">{t('toturial_title_3')}</p>

                    <p className="content_item_desc">{t('totuial_des_3')}</p>
                  </div>

                  {!isMobile767 && <img src={mainFnArrowRight2} alt="" className="content_item_arrow" />}
                </div>
              </div>
            </div>
          </Container>
        </div>

        <div className={`control ${isMobile380 && 'control_mobile'}`} id="cms" ref={cmsRef}>
          <Container>
            <div className="control_inside">
              <div className="control_text">
                <p className="title">
                  {t('control_title')}
                  <span className="rectangle"></span>
                </p>

                <div className="slogan_content">
                  <p>{t('control_content_1')}</p>
                </div>
              </div>

              <div className="control_img">
                <img src={control1} alt="" />
              </div>
            </div>

            <div className="wrap_engineer">
              <div className="engineer">
                <div className="engineer_img_left">
                  <img src={engineerLeft} alt="" />
                </div>

                {!isTablet && (
                  <div className="engineer_img_center">
                    <img src={engineerCenter} alt="" />
                  </div>
                )}

                <div className="engineer_content_item engineer_content_item_1">
                  <div className="icon_title">
                    {isTablet && <img src={engineerMb1} alt="" />}
                    <span>{t('engineer_title_1')}</span>
                  </div>

                  <p className="desc">{t('engineer_content_1')}</p>
                </div>

                <div className="engineer_content_item engineer_content_item_2">
                  <div className="icon_title">
                    {isTablet && <img src={engineerMb2} alt="" />}
                    <span>{t('engineer_title_2')}</span>
                  </div>

                  <p className="desc">{t('engineer_content_2')}</p>
                </div>

                <div className="engineer_content_item engineer_content_item_3">
                  <div className="icon_title">
                    {isTablet && <img src={engineerMb3} alt="" />}
                    <span>{t('engineer_title_3')}</span>
                  </div>

                  <p className="desc">{t('engineer_content_3')}</p>
                </div>

                <div className="engineer_content_item engineer_content_item_4">
                  <div className="icon_title">
                    {isTablet && <img src={engineerMb4} alt="" />}
                    <span>{t('engineer_title_4')}</span>
                  </div>

                  <p className="desc">{t('engineer_content_4')}</p>
                </div>
              </div>
            </div>
          </Container>
        </div>

        <div className="faq" id="faq" ref={faqRef}>
          <Container>
            <p className="title">
              FAQ
              <span className="rectangle"></span>
            </p>

            <div className="content">
              {faqLP &&
                faqLP.map((item: any, index: number) => {
                  return (
                    <div className="content_item" key={'faq' + index}>
                      <FaqItem
                        title={i18n.language === 'jp' ? item.ja_title : item?.en_title}
                        content={i18n.language === 'jp' ? item.ja_content : item?.en_content}
                        color="white"
                      />
                    </div>
                  );
                })}
            </div>

            <div className="button_see_more">
              <button onClick={() => history.push({ pathname: '/faq', search: lang ? `lang=${lang}` : '' })}>
                {t('see_more')}
              </button>
            </div>
          </Container>
        </div>

        <div className={`notice ${isMobile380 && 'notice_mobile'}`} id="notice" ref={eventRef}>
          <Container>
            <p className="title">
              {t('notice_title')}
              <span className="rectangle"></span>
            </p>

            <div className="event">
              {notice?.length > 0 &&
                notice.map((item: any, index: number) => {
                  return (
                    <div className="event_item" key={'event' + index}>
                      <p className="event_item_date">{item?.created_at}</p>
                      <p className="event_item_content">{i18n.language === 'jp' ? item.ja_title : item.en_title}</p>
                    </div>
                  );
                })}
            </div>

            {notiLP?.length > 5 && seeMore && (
              <div className="button_see_more">
                <button
                  onClick={() => {
                    setSeeMore(false);
                    setNotice(notiLP);
                  }}
                >
                  {t('see_more')}
                </button>
              </div>
            )}
          </Container>
        </div>

        <div
          className={`wrap_contact ${isTablet && 'wrap_contact_tablet'} ${isMobile && 'wrap_contact_mobile'}`}
          id="contact"
          ref={contactRef}
        >
          <Container>
            <div className="contact">
              <p className="title">
                {t('contact_title')}
                <span className="rectangle"></span>
              </p>

              {i18n.language === 'jp' ? (
                <div className="des">
                  ※商品のご案内やサポートは弊社提携代理店が行う場合があります。お問い合わせの回答につきましても、代理店
                  {!isMobile && <br />}
                  よりご連絡させていただく場合がありますことをご了承の上、お問い合わせをお願いいたします。
                </div>
              ) : (
                <div className="des">
                  ※Product information and support may be provided by our affiliated agencies. Please be aware that our
                  agency may contact you regarding answers to your inquiries before contacting us.
                </div>
              )}

              <div className="wrap_form">
                <div className="form_img">
                  <img src={contactLeft} alt="" />
                </div>

                <Form
                  form={antForm}
                  className="form"
                  onFinish={(values) => {
                    handleSubmitForm(values);
                  }}
                  onValuesChange={(value, allvalues) => handleChangeValue(allvalues)}
                >
                  <Row>
                    <Col xl={6} lg={6} md={6} sm={6} xs={6} col={12}>
                      <div className="form_item">
                        <label className="label_form">
                          {t('name_company')} <span>*</span>
                        </label>
                        <Form.Item
                          name="company"
                          rules={[
                            {
                              validator: (rule, value, callback, length: number = 255) => {
                                if (!value?.trim()) {
                                  callback(t('enter_company_name'));
                                } else if (length && value?.trim()?.length > length) {
                                  callback(t('limit_255_company_name'));
                                } else {
                                  callback();
                                }
                              },
                            },
                          ]}
                        >
                          <Input className="input_form" placeholder={`${t('name_company')}`} />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={6} xs={6} col={12}>
                      <div className="form_item">
                        <label className="label_form">
                          {t('name')} <span>*</span>
                        </label>
                        <Form.Item
                          name="full_name"
                          rules={[
                            {
                              validator: (rule, value, callback, length: number = 255) => {
                                if (!value?.trim()) {
                                  callback(t('enter_name'));
                                } else if (length && value?.trim()?.length > length) {
                                  callback(t('limit_255_company_name'));
                                } else {
                                  callback();
                                }
                              },
                            },
                          ]}
                        >
                          <Input className="input_form" placeholder={`${t('name')}`} />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl={6} lg={6} md={6} sm={6} xs={6} col={12}>
                      <div className="form_item">
                        <label className="label_form">
                          {t('email')} <span>*</span>
                        </label>
                        <Form.Item
                          name="email"
                          rules={[
                            {
                              validator: (rule, value, callback) => validateEmailLang(rule, value, callback, 255, t),
                            },
                          ]}
                        >
                          <Input className="input_form" placeholder={`${t('email')}`} />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={6} xs={6} col={12}>
                      <div className="form_item">
                        <label className="label_form">
                          {t('phone')} <span>*</span>
                        </label>
                        <Form.Item
                          name="phone_number"
                          rules={[
                            { validator: (rule, value, callback) => validatePhoneNumberLang(rule, value, callback, t) },
                          ]}
                        >
                          <Input className="input_form" placeholder={`${t('phone')}`} />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>

                  <div className="form_item">
                    <label className="label_form">
                      {t('note')} <span>*</span>
                    </label>
                    <Form.Item
                      name="inquiry"
                      rules={[
                        {
                          validator: (rule, value, callback, length: number = 1000) => {
                            if (!value?.trim()) {
                              callback(t('enter_message'));
                            } else if (length && value?.trim()?.length > length) {
                              callback(t('limit_255_message'));
                            } else {
                              callback();
                            }
                          },
                        },
                      ]}
                    >
                      <TextArea className="input_form input_form_textarea" placeholder={`${t('note_placeholder')}`} />
                    </Form.Item>
                  </div>

                  <div className="wrap_recaptcha">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={siteKey ? siteKey : ''}
                      onChange={onChange}
                      hl={i18n.language === 'jp' ? 'ja' : 'en'}
                    />
                  </div>

                  <div className="button_submit">
                    <Form.Item>
                      <Button type="primary" htmlType="submit" disabled={!tokenCaptcha || isloading || isDisableSubmit}>
                        {t('button_submit')}
                        <img className="ml-2" src={arrowRight} alt="" />
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </div>

              <div className="message_fn">
                <div className="message_icon">
                  <img src={formMessageIcon} alt="" />
                </div>

                <div className="message_text">
                  <p className="message_text_title">
                    {t('message_function')}
                    <span className="rectangle"></span>
                  </p>

                  <div className="message_text_des">
                    <p className="message_text_des_item">・{t('message_function_des_1')}</p>
                    <p className="message_text_des_item">・{t('message_function_des_2')}</p>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>

      <div className="footer_lp">
        <div className="footer_left">
          <a href={`/${lang ? `?lang=${lang}` : ''}`}>
            <div className="logo">
              <img src={logoIcon} alt="logo" />
              助さん
            </div>
          </a>

          <div className="contact_item">
            <div className="img_icon">
              <img src={phoneIcon} alt="" />
            </div>

            <div className="text">
              <p className="text_title">Hotline</p>
              <p className="text_content">(024) 4456 8888</p>
            </div>
          </div>
          <div className="contact_item">
            <div className="img_icon">
              <img src={mailIcon} alt="" />
            </div>

            <div className="text">
              <p className="text_title">Email</p>
              <p className="text_content">support_sukesan@synesthesias.jp</p>
            </div>
          </div>
        </div>

        <div className="footer_right">
          <ul className="menu">
            <li className="menu_item" onClick={() => handleScroll(bannerRef)}>
              {t('main_function')}
            </li>
            <li className="menu_item" onClick={() => handleScroll(cmsRef)}>
              {t('cms')}
            </li>
            <li className="menu_item" onClick={() => handleScroll(faqRef)}>
              {t('faq')}
            </li>
            <li className="menu_item" onClick={() => handleScroll(eventRef)}>
              {t('notice')}
            </li>
            <li className="menu_item" onClick={() => handleScroll(contactRef)}>
              {t('inquiry')}
            </li>
            <li className="menu_item">
              <a href={`/policy${lang ? `?lang=${lang}` : ''}`} target="_blank" rel="noopener noreferrer">
                {t('privacy_policy')}
              </a>
            </li>
            <li className="menu_item">
              <a href="https://synesthesias.jp/" target="_blank" rel="noopener noreferrer">
                {t('operating_company')}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LandingPageComponent;
