import { Form, Spin } from 'antd'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import leftArrow from 'src/assets/images/back-icon.png'
import { useDetailRequestCompany } from 'src/api/data/agency/useDetailRequestCompany'
import { detailRequestCompany } from 'src/constants'

const CompanyDetailComponent = () => {
    const history = useHistory()
    const { id } = useParams<{ id: string }>()
    const { data: listAgencyStaff, isLoading } = useDetailRequestCompany(Number(id)) 
    const handleGoBackCompanyList = () => {
        history.push('/agency/company')
    }

    const handleRenderStaff = (staff: any) => {
        if(staff) {
            return staff?.name ? staff.membership_code + ' - ' + staff?.name : '-'
        }
        return '-';
    }
    
    return (
       <div className='construction-create p-[16px]'>
            <h2 className='page-content-title border-bottom-title mb-[30px] flex items-center'>
                <img src={leftArrow} alt='' className='inline-block mr-[17px] cursor-pointer' onClick={handleGoBackCompanyList}/>
                <span onClick={handleGoBackCompanyList} className='cursor-pointer'>会社アカウント申請詳細</span>
            </h2>
            {
                isLoading ? <Spin /> :
                <Form className='construction-detail-infor'>
                    {
                        detailRequestCompany.map((item: any) => (
                            <div className='mb-[25px]' key={item.id}>
                                <label className='labe-form'>{item.label}</label>
                                <div className='input-form break-all'>
                                    {
                                        listAgencyStaff?.[item.name]
                                    }
                                </div>
                            </div>
                        ))
                    } 
                    <div className='mb-[25px]'>
                        <label className='labe-form'>担当者名</label>
                        <div className='input-form break-all'>
                            {
                                handleRenderStaff(listAgencyStaff?.agency_staff)
                            }
                        </div>
                    </div>
                </Form>
            }
       </div>
    )
}

export default CompanyDetailComponent