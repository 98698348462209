import React from 'react'
import ResetPassword from 'src/components/features/forgotPassword/resetPassword'
import { LayoutForgotPassword } from 'src/components/layouts/layoutForgotPassword'

const ResetPasswordPage = () => (
    <LayoutForgotPassword>
       <ResetPassword />
    </LayoutForgotPassword>
)

export default ResetPasswordPage