import React from 'react'
import Header from '../common/layouts/header'

type LayoutProps = {
    children: React.ReactNode,
    className?: string;
}
export const LayoutOnlyHeader: React.FC<LayoutProps> = ({ children, className = '' }) => {
  return (
    <>
      <Header />
      <div className={`civil-content-page ${className}`}>
        {children}
      </div>
    </>
  )
}
