import React from 'react'
import ConstructionDetailComponent from 'src/components/features/constructionSite/constructionDetailComponent'
import { LayoutMoreNote } from 'src/components/layouts/layoutMoreNote'
import { ConstructionDetailProvider } from 'src/context/ConstructionDetailContext'

const ConstructionSiteDetailPage = () => (
    <ConstructionDetailProvider>
        <LayoutMoreNote>
            <ConstructionDetailComponent />
        </LayoutMoreNote>
    </ConstructionDetailProvider>
  
)

export default ConstructionSiteDetailPage