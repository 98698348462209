import React, {useCallback, useState, useEffect } from 'react'
import { useMutation } from 'react-query'
import { Input, Button, Form, message, Modal, Spin  } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import closeIcon from 'src/assets/images/close-circle.png'
import success from 'src/assets/images/tick-circle.png'
import passWordIcon from 'src/assets/images/password-icon.png'
import {checkResetPasswordExpired, resetPassword } from 'src/api/userInfor'

interface TypeFormResetPass {
    token: string,
    email: string,
    password: string,
    password_confirmation: string,
}
const ResetPassword = () => {
    const location = useLocation()
    const history = useHistory()
    const [antForm] = Form.useForm()
    const [messError, setMessError] = useState('')
    const [isOpenModalSuccess, setIsOpenModalSuccess] = useState<boolean>(false)
    const urlSearchParams = new URLSearchParams(location.search)
    const {isLoading: isloading, mutate: onResetPassword } = useMutation(
        (param: TypeFormResetPass) => resetPassword(param),
        {
          onSuccess: (res) => {
            setIsOpenModalSuccess(true)
          },
          onError: (error: any) => {
            if (error.response?.status === 400) {
                history.push('/expried/reset_password')
                return
            }
            message.error('システムエラー')
          },
        },
    )

    const {isLoading: isloadingChecking, mutate: onCheckResetPasswordExpired } = useMutation(
        (param: {token: string, email: string}) => checkResetPasswordExpired(param),
        {
          onError: (error: any) => {
            history.push('/expried/reset_password')
          },
        },
    )

    const handleSendEmailForgotPassword = useCallback(async() => {
        try {
            await antForm.validateFields()
        } catch { return }
        const values = antForm.getFieldsValue()
        const emailResetPassword = urlSearchParams.get('email')
        const tokenResetPassword = urlSearchParams.get('token')
        const params = {
            ...values,
            email: emailResetPassword,
            token: tokenResetPassword
        }
        return onResetPassword(params)
    },[antForm, onResetPassword, urlSearchParams])

    const handleChange = (value: string) => {
        setMessError('')
    }
    const handleEnterSubmit = (e: any) => {
        if (e.key === "Enter") {
            handleSendEmailForgotPassword()
        }
    }

    const validateNewPassword = (rule: any, value: string, callback: any) => {
        const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[_~?&!@#%|,.<>=:;\\/\-\$\^\[\]\{\}\+\'\"\*\(\)])[A-Za-z0-9_~?&!@#%|,.<>=:;\\/\-\$\^\[\]\{\}\+\'\"\*\(\)]+$/
        if (!value?.trim()) {
            callback(`新しいパスワードを入力してください`);
        }else if(value?.trim()?.length < 8 || value?.trim()?.length > 25) {
            callback(`パスワードは半角英数字8文字以上25文字以内で入力してください`);
        }else if(!passwordRegex.test(value?.trim())) {
            callback(`パスワードは英大文字・英小文字・数字・記号それぞれを最低1文字ずつ含む必要があります`);
        }else {
            callback()
        }
    }

    const validateConfirmPassword = (rule: any, value: string, callback: any) => {
        const valueForm = antForm.getFieldsValue()
        if (!value?.trim()) {
            callback(`スワード確認を入力してください`);
        }else if(valueForm['password'] !== value) {
            callback(`パスワードとパスワード確認用が一致しません`);
        }else {
            callback()
        }
    }

    const handleCloseModalSuccess = () => {
        setIsOpenModalSuccess(false)
        history.push('/login')
    }

    const PassIconReactNode = () => (
        <img src={passWordIcon} alt="" />
    )

    const handleCheckResetPasswordExpired = () => {
        const emailResetPassword = urlSearchParams.get('email')
        const tokenResetPassword = urlSearchParams.get('token') 
        const params = {
            token: tokenResetPassword!,
            email: emailResetPassword!
        }
        onCheckResetPasswordExpired(params)
    }

    useEffect(() => {
        handleCheckResetPasswordExpired()
    },[])

    return (
        <div className='login login-bgr'>
            {
                isloadingChecking ? <Spin className='position-center' /> : 
                <div className='login__form'>
                    <div className='login__form-content'>
                        <h1 className='form-title mb-[40px]'>新しいパスワードを設定する</h1>
                        <Form form={antForm} onKeyPress={(e) => handleEnterSubmit(e)}>
                            <div className='mb-[16px]'>
                                <Form.Item 
                                    name='password' 
                                    rules={
                                        [                                    
                                            { validator: (rule, value, callback) => validateNewPassword(rule,value,callback)}
                                        ]
                                    }
                                >
                                    <Input.Password 
                                    className='login__form__input' 
                                    prefix={<PassIconReactNode/>} 
                                    onChange={(e) => handleChange(e.target.value)}
                                    placeholder="新しいパスワード" />
                                </Form.Item>
                                <Form.Item 
                                    name='password_confirmation' 
                                    dependencies={['password']}
                                    rules={
                                        [                                    
                                            { validator: (rule, value, callback) => validateConfirmPassword(rule,value,callback)}
                                        ]
                                    }
                                >
                                    <Input.Password 
                                    className='login__form__input mt-[16px]' 
                                    prefix={<PassIconReactNode/>} 
                                    onChange={(e) => handleChange(e.target.value)}
                                    placeholder="新しいパスワード（確認）" />
                                </Form.Item>
                            </div>
                            <p className='invalid-feedback whitespace-pre-line'>{messError}</p>
                            <Button  onClick={() => handleSendEmailForgotPassword()} loading={isloading} className='login__form__button mx-auto block my-[32px]'>
                                <span></span>保存
                            </Button>
                        </Form>
                    </div>
                </div>
            }
            <Modal 
                title="" 
                open={isOpenModalSuccess} 
                width={451}
                className="modal-success"
                footer={false}
                closable={false}
                centered={true}
                afterClose={handleCloseModalSuccess}
            >
                <img src={closeIcon} alt="" className="close-modal cursor-pointer" onClick={handleCloseModalSuccess}/>
                <img src={success} alt='' className='mb-[16px] mx-auto'/>
                <div className='text-[#272122] text-[20px] font-medium text-center mb-[8px]'>パスワードが変更されました</div>
                <p className='text-[#272122] text-[14px] text-center mb-[44px]'>新しいパスワードで再ログインをお願い致します。</p>
                <Button className='button-add w-full' onClick={() => handleCloseModalSuccess()}>
                    OK
                </Button>
            </Modal>
        </div>
    )
}

export default ResetPassword