import React from 'react'
import AgencyDetailComponent from 'src/components/features/superAdmin/agencyDetailComponent'
import { LayoutSuperAdmin } from 'src/components/layouts/layoutSuperAdmin'

const AgencyDetailPage = () => (
    <LayoutSuperAdmin>
       <AgencyDetailComponent />
    </LayoutSuperAdmin>
)

export default AgencyDetailPage