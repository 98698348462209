import { Button, Form, Input, Spin, Switch, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import leftArrow from 'src/assets/images/back-icon.png'
import emailIcon from 'src/assets/images/icon-email.png'
import { staffCreateType } from 'src/components/features/agency/type'
import ModalConfirm from 'src/components/common/modal/modalConfirm'
import { staffAgencyStatusData } from 'src/constants'
import { companyCreateStaff, companyEditStaff } from 'src/api/companyManagement'
import { useDetailCompanyStaff } from 'src/api/data/companyManagement/useDetailCompanyStaff'
import { validateEmail, validateFieldString, validatePassword, validatePhoneNumber } from 'src/utils/validateFields'

const CompanyStaffCreateComponent = () => {
    const location = useLocation()
    const [antForm] = Form.useForm()
    const history = useHistory()
    const { id } = useParams<{ id: string }>()
    const [isDisableSubmit,setIsDisableSubmit] = useState(true)
    const [isValueForm,setIsValueForm] = useState(false)
    const [isVisibleModelConfirmBack,setIsVisibleModelConfirmBack] = useState(false)
    const { data: detailStaff, isLoading } = useDetailCompanyStaff(Number(id))
    const [status, setStatus] = useState(staffAgencyStatusData.ACTIVE)
    const queryClient = useQueryClient()
    const {isLoading: isloading, mutate: onCreateStaff } = useMutation(
        (param: staffCreateType) => companyCreateStaff(param),
        {
          onSuccess: (res) => {
            message.success('データ更新成功')
            history.push('/company/staff')
            antForm.resetFields()
            queryClient.clear()
          },
          onError: (error: any) => {
            if (error.response?.status === 400) {
                message.error(error.response.data.messages[0])
                return
            }
            message.error('システムエラー')
          },
        },
    )

    const {isLoading: isloadingEdit, mutate: onEditStaff } = useMutation(
        (param: staffCreateType) => companyEditStaff(param, Number(id)),
        {
          onSuccess: (res) => {
            message.success('データ更新成功')
            history.push('/company/staff')
            antForm.resetFields()
            queryClient.clear()
          },
          onError: (error: any) => {
            if (error.response?.status === 400) {
                message.error(error.response.data.messages[0])
                return
            }
            message.error('システムエラー')
          },
        },
    )

    const handleCreateEditStaffAgency = async() => {
        await trimmedValue()
        try {
            await antForm.validateFields()
        } catch { return }
        const value = antForm.getFieldsValue()
        const params = {
            ...value,
            status: status,
        }

        if(id) {
            onEditStaff(params)
        }else {
            onCreateStaff(params)
        }
    }

    const handleChangeValue = (allvalues: any) => {
        const allValuesExceptPaymentType = {...allvalues}
        delete allValuesExceptPaymentType['status']
        const listValue = Object.keys(allValuesExceptPaymentType).map((key: any) => allvalues[key])
        const validateErrorValueList = []
        listValue?.forEach((value: any) => {
            if(value === undefined || value === '') {
                validateErrorValueList.push(value)
            }
        })
        if(validateErrorValueList.length > 0) {
            setIsDisableSubmit(true)
        }else{
            setIsDisableSubmit(false)
        }
        listValue?.some((value:any) => {
            if(value !== undefined && value !== '' && value != null) {
                setIsValueForm(true)
                return true
            }
            setIsValueForm(false)
            return false
        })
    }

    useEffect(() => {
        if(id) {
            setIsDisableSubmit(false)
        }
    },[id])

    const renderEmailIcon = () => {
        return <img src={emailIcon} alt='' />
    }


    const handleGoBackStaffList = () => {
        history.goBack()
    }

    const handleBackStaffList = () => {
        if(isValueForm) {
            setIsVisibleModelConfirmBack(true)
        }else{
            handleGoBackStaffList()
        }
    }

    const handleCancel = () => {
        setIsVisibleModelConfirmBack(false)
    }

    const handleChangeStatus = (value: boolean) => {
        if(value) {
            setStatus(staffAgencyStatusData.ACTIVE)
        }else {
            setStatus(staffAgencyStatusData.INACTIVE)
        }
    }

    useEffect(() => {
        if(detailStaff) {
            setStatus(detailStaff?.status)
        }
    },[detailStaff])

    const trimmedValue = async() => {
        const allValueForm = antForm.getFieldsValue()
        Object.keys(allValueForm).forEach((key: any) => {
            if(typeof allValueForm[key] === typeof '') {
                antForm.setFieldsValue({ [key]: allValueForm[key]?.trim() })
            }
        })
    }
    
    return (
       <div className='construction-create p-[16px]'>
            <h2 className='page-content-title border-bottom-title mb-[30px] flex items-center'>
                <img src={leftArrow} alt='' className='inline-block mr-[17px] cursor-pointer' onClick={handleBackStaffList}/>
                <span onClick={handleBackStaffList} className='cursor-pointer'>{location.pathname?.includes('edit') ? '会員変更' :  '会員作成'}</span>
            </h2>
            {
                isLoading ? <Spin /> :
                <Form form={antForm} initialValues={detailStaff} onValuesChange={(value, allvalues) => handleChangeValue(allvalues)} className='form-create'>
                    <div className='px-[20px] py-[12px]'>
                        <div className='mb-[16px]'>
                            <label className='labe-form'>会員コード <span>*</span></label>
                            <Form.Item name='membership_code' 
                                rules={
                                    [
                                        { validator: (rule, value, callback) => validateFieldString(rule,value,callback, '会員コード', 10)}
                                    ]
                                }
                            >
                                <Input className='input-form'/>
                            </Form.Item>
                        </div>
                        <div className='mb-[16px]'>
                            <label className='labe-form'>会員名 <span>*</span></label>
                            <Form.Item name='name' 
                                rules={
                                    [
                                        { validator: (rule, value, callback) => validateFieldString(rule,value,callback, '会員名', 50)}
                                    ]
                                }
                            >
                                <Input className='input-form'/>
                            </Form.Item>
                        </div>
                        <div className='mb-[16px]'>
                            <label className='labe-form'>メールアドレス <span>*</span></label>
                            <Form.Item name='email' rules={[{ validator: (rule, value, callback) => validateEmail(rule,value,callback)},]}>
                                <Input disabled={location.pathname.includes('/edit') ? true : false} className='input-form' suffix={renderEmailIcon()}/>
                            </Form.Item>
                        </div>
                        <div className='mb-[16px]'>
                            <label className='labe-form'>電話番号 <span>*</span></label>
                            <Form.Item 
                                name='phone' 
                                rules={
                                    [
                                        { validator: validatePhoneNumber}
                                    ]
                                }
                            >
                                <Input className='input-form'/>
                            </Form.Item>
                        </div>
                        {
                            location?.pathname.includes('edit') ? '' :
                            <div className='mb-[16px]'>
                                <label className='labe-form'>パスワード <span>*</span></label>
                                <Form.Item name='password'
                                    rules={
                                        [
                                            { validator: validatePassword}
                                        ]
                                    }
                                >
                                    <Input className='input-form'/>
                                </Form.Item>
                            </div>
                        }
                        <div className='mb-[16px] flex items-center '>
                            <span className='text-[#2E2E2E] text-[16px] font-bold mr-[8px]'>有効</span>
                            <Switch 
                                disabled={location.pathname.includes('create') ? true : false}
                                checked={status === staffAgencyStatusData.ACTIVE ? true : false} 
                                onChange={(e) => handleChangeStatus(e)} 
                                className='ar-switch'
                            />
                        </div>
                    </div>
                    <div className='footer-form flex'>
                        <Button className='button-form mr-[16px]' onClick={handleBackStaffList}>戻る</Button>
                        <Button 
                            disabled={isDisableSubmit} 
                            className='button-form button-form-Submit' 
                            loading={isloading || isloadingEdit}
                            onClick={handleCreateEditStaffAgency}
                        >
                            <span></span>{location.pathname?.includes('edit') ? '保存' :  '作成'}
                        </Button>
                    </div>
                </Form>
            }
            <ModalConfirm 
                isShowModal={isVisibleModelConfirmBack}
                onCancel={handleCancel}
                onOk={handleGoBackStaffList}
                content='このページを離れますか？'
                subContent='ページから移動すると、保存されていない変更は失われます。'
            />
       </div>
    )
}

export default CompanyStaffCreateComponent