import React from 'react'
import CuttingOrderDiagram from 'src/components/features/locationSiteDetail/cuttingOrderDiagram'
import { LayoutConstruction } from 'src/components/layouts/layoutConstruction'

const CuttingOrderDiagramPage = () => (
    <LayoutConstruction>
        <CuttingOrderDiagram />
    </LayoutConstruction>
)

export default CuttingOrderDiagramPage