import { Button, Form, Input, Spin, Switch, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import leftArrow from 'src/assets/images/back-icon.png'
import emailIcon from 'src/assets/images/icon-email.png'
import ModalConfirm from 'src/components/common/modal/modalConfirm'
import { agencyCreate, agencyEdit } from 'src/api/admin'
import { agencyCreateType } from './type'
import { redirectTypeData, staffAgencyStatusData } from 'src/constants'
import { useDetailAgencyAdmin } from 'src/api/data/admin/useDetailAgencyAdmin'
import { validateEmail, validateFieldString, validatePhoneNumber } from 'src/utils/validateFields'

const AgencyCreateComponent = () => {
    const location = useLocation()
    const [antForm] = Form.useForm()
    const history = useHistory()
    const { id } = useParams<{ id: string }>()
    const queryClient = useQueryClient()
    const [isDisableSubmit,setIsDisableSubmit] = useState(true)
    const [isValueForm,setIsValueForm] = useState(false)
    const [isVisibleModelConfirmBack,setIsVisibleModelConfirmBack] = useState(false)
    const [status, setStatus] = useState(staffAgencyStatusData.ACTIVE)
    const { data: detailData, isLoading } = useDetailAgencyAdmin(Number(id)) 
    const urlSearchParams = new URLSearchParams(location.search).get("redirectType")
    const {isLoading: isloading, mutate: onCreateAgency } = useMutation(
        (param: agencyCreateType) => agencyCreate(param),
        {
          onSuccess: (res) => {
            message.success('アカウント登録に成功しました')
            history.push('/cms/agency')
            antForm.resetFields()
          },
          onError: (error: any) => {
            if (error.response?.status === 400) {
                message.error(error.response.data.messages[0])
                return
            }
            message.error('システムエラー')
          },
        },
    )

    const {isLoading: isloadingEdit, mutate: onEditAgency } = useMutation(
        (param: agencyCreateType) => agencyEdit(param, Number(id)),
        {
          onSuccess: (res) => {
            message.success('アカウント登録に成功しました')
            history.push('/cms/agency')
            antForm.resetFields()
            queryClient.clear()
          },
          onError: (error: any) => {
            if (error.response?.status === 400) {
                message.error(error.response.data.messages[0])
                return
            }
            message.error('システムエラー')
          },
        },
    )

    const handleCreateEditAgency = async() => {
        await trimmedValue()
        try {
            await antForm.validateFields()
        } catch { return }
        const valueForm = antForm.getFieldsValue()
        const params = {
            ...valueForm,
            status: status,
            presentative_name: valueForm.presentative
        }
        if(id) {
            onEditAgency(params)
        }else {
            onCreateAgency(params)
        }
    }

    const handleChangeValue = (allvalues: []) => {
        const listValue = Object.keys(allvalues).map((key: any) => allvalues[key])
        const validateErrorValueList = []
        listValue?.forEach((value: any) => {
            if(value === undefined || value === '') {
                validateErrorValueList.push(value)
            }
        })
        if(validateErrorValueList.length > 0) {
            setIsDisableSubmit(true)
        }else{
            setIsDisableSubmit(false)
        }
        listValue?.some((value:any) => {
            if(value !== undefined && value !== '' && value != null) {
                setIsValueForm(true)
                return true
            }
            setIsValueForm(false)
            return false
        })
    }

    useEffect(() => {
        if(id) {
            setIsDisableSubmit(false)
        }
    },[id])

    const renderEmailIcon = () => {
        return <img src={emailIcon} alt='' />
    }

    const handleGoBackAgencyList = () => {
        if(Number(urlSearchParams) === redirectTypeData.DETAIL) {
            history.push(`/cms/agency/${id}`)
        }else {
            history.push('/cms/agency')
        }
    }

    const handleBackAgencyList = () => {
        if(isValueForm) {
            setIsVisibleModelConfirmBack(true)
        }else{
            handleGoBackAgencyList()
        }
    }

    const handleCancel = () => {
        setIsVisibleModelConfirmBack(false)
    }

    const trimmedValue = async() => {
        const allValueForm = antForm.getFieldsValue()
        Object.keys(allValueForm).forEach((key: any) => {
            if(typeof allValueForm[key] === typeof '') {
                antForm.setFieldsValue({ [key]: allValueForm[key]?.trim() })
            }
        })
    }

    const handleChangeStatus = (value: boolean) => {
        if(value) {
            setStatus(staffAgencyStatusData.ACTIVE)
        }else {
            setStatus(staffAgencyStatusData.INACTIVE)
        }
    }

    useEffect(() => {
        if(detailData) {
            setStatus(detailData?.status)
        }
    },[detailData])
    
    return (
       <div className='construction-create p-[16px]'>
            <h2 className='page-content-title border-bottom-title mb-[30px] flex items-center'>
                <img src={leftArrow} alt='' className='inline-block mr-[17px] cursor-pointer' onClick={handleBackAgencyList}/>
                <span onClick={handleBackAgencyList} className='cursor-pointer'>代理店作成</span>
            </h2>
            {
                isLoading ? <Spin className='position-center' /> :
                <Form form={antForm} onValuesChange={(value, allvalues) => handleChangeValue(allvalues)} className='form-create'>
                    <div className='px-[20px] py-[12px]'>
                        <div className='mb-[16px]'>
                            <label className='labe-form'>代理店名 <span>*</span></label>
                            <Form.Item 
                                name='name' 
                                rules={[
                                    { validator: (rule, value, callback) => validateFieldString(rule,value,callback, '代理店名')}
                                ]}
                                initialValue={detailData?.name}
                            >
                                <Input className='input-form'/>
                            </Form.Item>
                        </div>
                        <div className='mb-[16px]'>
                            <label className='labe-form'>代表者 <span>*</span></label>
                            <Form.Item 
                                name='presentative' 
                                initialValue={detailData?.presentative_name}
                                rules={[
                                    { validator: (rule, value, callback) => validateFieldString(rule,value,callback, '代表者', 50)}
                                ]}
                            >
                                <Input className='input-form'/>
                            </Form.Item>
                        </div>
                        <div className='mb-[16px]'>
                            <label className='labe-form'>住所 <span>*</span></label>
                            <Form.Item 
                                name='address' 
                                initialValue={detailData?.address}
                                rules={[
                                    { validator: (rule, value, callback) => validateFieldString(rule,value,callback, '住所')}
                                ]}
                            >
                                <Input className='input-form'/>
                            </Form.Item>
                        </div>
                        <div className='mb-[16px]'>
                            <label className='labe-form'>メールアドレス <span>*</span></label>
                            <Form.Item 
                                name='email' 
                                initialValue={detailData?.email}
                                rules={[
                                    { validator: (rule, value, callback) => validateEmail(rule,value,callback)},
                                ]}
                            >
                                <Input disabled={location.pathname.includes('/edit') ? true : false} className='input-form' suffix={renderEmailIcon()}/>
                            </Form.Item>
                        </div>
                        <div className='mb-[16px]'>
                            <label className='labe-form'>電話番号 <span>*</span></label>
                            <Form.Item 
                                name='phone' 
                                initialValue={detailData?.phone}
                                rules={
                                    [
                                        { validator: validatePhoneNumber}
                                    ]
                                }
                            >
                                <Input className='input-form'/>
                            </Form.Item>
                        </div>
                        <div className='mb-[16px]'>
                            <label className='labe-form mr-[8px]'>有効</label>
                            <Switch disabled={location.pathname.includes('create') ? true : false} checked={status === staffAgencyStatusData.ACTIVE ? true : false} onChange={(e) => handleChangeStatus(e)} className='ar-switch' />
                        </div>
                    </div>
                    <div className='footer-form flex'>
                        <Button className='button-form mr-[16px]' onClick={handleBackAgencyList}>戻る</Button>
                        <Button 
                            disabled={isDisableSubmit} 
                            className='button-form button-form-Submit' 
                            loading={isloading || isloadingEdit}
                            onClick={handleCreateEditAgency}
                        >
                            <span></span>{location.pathname?.includes('edit') ? '保存' :  '作成'}
                        </Button>
                    </div>
                </Form>
            }
            <ModalConfirm 
                isShowModal={isVisibleModelConfirmBack}
                onCancel={handleCancel}
                onOk={handleGoBackAgencyList}
                content='このページを離れますか？'
                subContent='ページから移動すると、保存されていない変更は失われます。'
            />
       </div>
    )
}

export default AgencyCreateComponent