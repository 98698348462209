import React from 'react'
import TermsOfServiceComponent from 'src/components/features/payment/termsOfServiceComponent'
const TermsOfServicePage = () => {
    return (
        <div className='payment'>
            <TermsOfServiceComponent />
        </div>
    )
}

export default TermsOfServicePage