import HeaderLP from 'src/components/common/headerLP/headerLP';
import './policy.modules.scss';
import FooterLP from 'src/components/common/footerLP/footerLP';
import { Container } from 'styled-bootstrap-grid';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const PolicyComponent = () => {
  const { i18n, t } = useTranslation();
  const queryParameters = new URLSearchParams(window.location.search);
  const lang = queryParameters.get('lang');

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  useEffect(() => {
    if (window !== undefined) {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <div className="wrap_policy">
      <HeaderLP />

      <Container>
        <div className="policy-container">
          <div className="plc-title">
            <h5>{t('title')}</h5>
          </div>
          <div className="plc-content">
            <div className="content-item">
              <div className="content-title">{t('content_title_0')}</div>
              <div className="content-desc">
                <p>{t('content_desc_0')}</p>
              </div>
            </div>
            <div className="content-item">
              <div className="content-title">{t('content_title_1')}</div>
              <div className="content-desc">
                <p>{t('content_desc_1_0')}</p>
                <p>{t('content_desc_1_1')}</p>
                <p>{t('content_desc_1_2')}</p>
              </div>
            </div>
            <div className="content-item">
              <div className="content-title">{t('content_title_2')}</div>
              <div className="content-desc">
                <p>{t('content_desc_2_0')}</p>
                <p>{t('content_desc_2_1')}</p>
              </div>
            </div>
            <div className="content-item">
              <div className="content-title">{t('content_title_3')}</div>
              <div className="content-desc">
                <p className="plc-head">{t('content_desc_3_0')}</p>
                <p>{t('content_desc_3_1')}</p>
                <p>{t('content_desc_3_2')}</p>
                <p>{t('content_desc_3_3')}</p>
                <p>{t('content_desc_3_4')}</p>
                <p>{t('content_desc_3_5')}</p>
                <p>{t('content_desc_3_6')}</p>
                <p>{t('content_desc_3_7')}</p>
                <p>{t('content_desc_3_8')}</p>
                <p>{t('content_desc_3_9')}</p>
                <p>{t('content_desc_3_10')}</p>
              </div>
            </div>
            <div className="content-item">
              <div className="content-title">{t('content_title_4')}</div>
              <div className="content-desc">
                <p>{t('content_desc_4_0')}</p>
              </div>
            </div>
            <div className="content-item">
              <div className="content-title">{t('content_title_5')}</div>
              <div className="content-desc">
                <p className="plc-head">{t('content_desc_6_10')}</p>
                <p>{t('content_desc_6_1')}</p>
                <p>{t('content_desc_6_2')}</p>
                <p>{t('content_desc_6_3')}</p>
                <p>{t('content_desc_6_4')}</p>
                <p>{t('content_desc_6_5')}</p>
                <p>{t('content_desc_6_6')}</p>
              </div>
            </div>
            <div className="content-item">
              <div className="content-title">{t('content_title_6')}</div>
              <div className="content-desc">
                <p className="content_desc_6_1">{t('content_desc_6_0')}</p>
              </div>
            </div>
            <div className="content-item">
              <div className="content-title">{t('content_title_8')}</div>
              <div className="content-desc">
                <p>{t('content_desc_8_0')}</p>
              </div>
            </div>
            <div className="content-item">
              <div className="content-title">{t('content_title_9')}</div>
              <div className="content-desc">
                <p className="plc-head">{t('content_desc_9_0')}</p>
                <p>{t('content_desc_9_1')}</p>
                <p className="plc-head">{t('content_desc_9_2')}</p>
                <p className="plc-head">{t('content_desc_9_3')}</p>
                <p>{t('content_desc_9_4')}</p>
                <p>{t('content_desc_9_5')}</p>
                <p className="plc-head">{t('content_desc_9_6')}</p>
                <p>{t('content_desc_9_7')}</p>
              </div>
            </div>
            <div className="content-item">
              <div className="content-title">{t('content_title_10')}</div>
              <div className="content-desc">
                <p>{t('content_desc_10_0')}</p>
              </div>
            </div>
            <div className="content-item">
              <div className="content-title">{t('content_title_11')}</div>
              <div className="content-desc">
                <p>{t('content_desc_11_0')}</p>
              </div>
            </div>
            <div className="content-item">
              <div className="content-title">{t('content_title_12')}</div>
              <div className="content-desc">
                <p>{t('content_desc_12_0')}</p>
              </div>
            </div>
            <div className="content-item">
              <div className="content-title">{t('content_title_13')}</div>
              <div className="content-desc">
                <p>{t('content_desc_13_0')}</p>
              </div>
            </div>
            <p>{t('date_policy')}</p>
          </div>
        </div>
      </Container>

      <FooterLP />
    </div>
  );
};

export default PolicyComponent;
