import { Button, Form, Spin } from 'antd'
import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import leftArrow from 'src/assets/images/back-icon.png'
import { detailStaffAgency, role } from 'src/constants'
import { useDetailStaff } from 'src/api/data/agency/useDetailStaff'
import { handleGetStatusStaffAgency } from 'src/utils'
const detailStaffAdmin = [
    {
        label: '管理者コード',
        name: 'membership_code',
        id: 1,
    },
    {
        label: '管理者名',
        name: 'name',
        id: 2
    },
    {
        label: 'メールアドレス',
        name: 'email',
        id: 3
    },
    {
        label: '電話番号',
        name: 'phone',
        id: 4
    },
    {
        label: '有効',
        name: 'status',
        id: 5
    },
]
const StaffDetailComponent = () => {
    const location = useLocation()
    const history = useHistory()
    const { id } = useParams<{ id: string }>()
    const { data: detailStaff, isLoading } = useDetailStaff(Number(id)) 
    const handleGoBackStaffList = () => {
        if(location.pathname.includes('/cms/')) {
            history.push('/cms/staff')
        }else {
            history.push('/agency/staff')
        }
    }

    const handleGoEditStaff = (id: number | undefined) => {
        if(location.pathname.includes('/cms/')) {
            history.push(`/cms/staff/edit/${id}`)
          }else {
            history.push(`/agency/staff/edit/${id}`)
          }
      }
  
    
    return (
       <div className='construction-create p-[16px]'>
            <div className='page-content-title border-bottom-title mb-[30px] flex items-center justify-between'>
                <div>
                    <img src={leftArrow} alt='' className='inline-block mr-[17px] cursor-pointer' onClick={handleGoBackStaffList}/>
                    <span onClick={handleGoBackStaffList} className='cursor-pointer'>{Number(localStorage.getItem('role')) === role.AGENCY ? '担当者詳細' : '管理者詳細'}</span>
                </div>
                <Button 
                    className='button-add min-w-[173px]' 
                    onClick={() =>handleGoEditStaff(Number(id))}
                >
                    <span></span>変更
                </Button>
            </div>
            {
                isLoading ? <Spin /> :
                <Form className='construction-detail-infor'>

                    {
                        Number(localStorage.getItem('role')) === role.AGENCY ?
                        detailStaffAgency.map((item: any) => (
                            <div className='mb-[25px]' key={item.id}>
                                <label className='labe-form'>{item.label}</label>
                                <div className='input-form break-all'>
                                    {
                                        item.id === 5 ? handleGetStatusStaffAgency(detailStaff?.[item.name]) : detailStaff?.[item.name]
                                    }
                                </div>
                            </div>
                        ))
                        : detailStaffAdmin.map((item: any) => (
                            <div className='mb-[25px]' key={item.id}>
                                <label className='labe-form'>{item.label}</label>
                                <div className='input-form break-all'>
                                    {
                                        item.id === 5 ? handleGetStatusStaffAgency(detailStaff?.[item.name]) : detailStaff?.[item.name]
                                    }
                                </div>
                            </div>
                        ))
                    } 
                </Form>
            }
       </div>
    )
}

export default StaffDetailComponent