import { useQuery } from 'react-query'
import { api } from 'src/api/axios'
import { QUERY_KEY } from '../query-key'


export const useLocationSiteList = (constructionId: number, keyWord: string, page: number, size:number) => useQuery(
  [QUERY_KEY.LOCATION_SITE_LIST, keyWord, page, size],
  async () => {
    const res = await api.get('/api/admin/construction-zone', {
      params: {
        construction_id: constructionId,
        keyword: keyWord,
        page:page, 
        size: size
      },
    })
    return res.data.data
  },
  {
    retry: 0,
  }
)
