import { useQuery } from 'react-query'
import { api } from 'src/api/axios'
import { QUERY_KEY } from '../query-key'


export const useUserInFo = () => useQuery(
  [QUERY_KEY.USER_INFO],
  async () => {
    const res = await api.get(`/api/info-user`)
    return res.data.data
  }
)