/* eslint-disable react-hooks/exhaustive-deps */
import 'moment/locale/ja';
import { Button, Form, Input, Modal, Select, Pagination, Switch, Table, message } from 'antd';
import { useMemo, useState } from 'react';
import searchIcon from 'src/assets/images/search-icon.png';
import addIcon from 'src/assets/images/add-icon.png';
import iconSelect from 'src/assets/images/arrow-down-2.png';
import type { ColumnsType } from 'antd/es/table';
import { notificationCreateType } from 'src/components/features/agency/type';
import TableNoteNoData from 'src/components/common/tableNoteNoData';
import editIcon from 'src/assets/images/edit-icon.png';
import deleteIcon from 'src/assets/images/delete-icon.png';
import { useMutation, useQueryClient } from 'react-query';
import { createNotification, deleteNotification, editNotification } from 'src/api/admin';
import { QUERY_KEY } from 'src/api/data/query-key';
import ModalConfirm from 'src/components/common/modal/modalConfirm';
import { useListNotification } from 'src/api/data/admin/useListNotification';
import { notiStatus, notiStatusData, pageSizeList } from 'src/constants';
import { getClassStatusNoti, handleGetStatusNoti } from 'src/utils';
import TextArea from 'antd/lib/input/TextArea';

interface DataType {
  key: string;
  name: string;
  location: string;
  updated_at: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'ID',
    key: 'no',
    dataIndex: 'no',
    width: 60,
  },
  {
    title: '更新日',
    key: 'date',
    dataIndex: 'date',
    width: 120,
  },
  {
    title: '内容',
    dataIndex: 'name',
    key: 'name',
    width: 388,
  },
  {
    title: '状態',
    key: 'status',
    dataIndex: 'status',
    width: 120,
  },
  {
    title: () => {
      return <p className="text-center text-[16px] text-[#2E2E2E]">アクション</p>;
    },
    key: 'action',
    dataIndex: 'action',
    width: 100,
  },
];

const NotificationListComponent = () => {
  const queryClient = useQueryClient();
  const [antForm] = Form.useForm();
  const [keyword, setKeyword] = useState<string>('');
  const [keywordSearch, setKeywordSearch] = useState<string>('');
  const [isModalCreateEdit, setIsModalCreateEdit] = useState(false);
  const [isDisableSubmit, setIsDisableSubmit] = useState(true);
  const [idNoti, setIdNoti] = useState<any>();
  const [isVisibleModalConfirm, setIsVisibleModalConfirm] = useState(false);
  const [notiDelete, setNotiDelete] = useState<any>();
  const [status, setStatus] = useState<number>(0);
  const [statusTable, setStatusTable] = useState<number | undefined>();
  const [page, setPage] = useState<number>(1);
  const [sizePage, setSizePage] = useState<number>(10);

  const handleCancel = () => {
    setIsVisibleModalConfirm(false);
  };

  const iconSearch = () => {
    return (
      <div className="p-[5px] cursor-pointer" onClick={handleSearch}>
        <img src={searchIcon} alt="" />
      </div>
    );
  };

  const { data: listNoti, isLoading, refetch } = useListNotification(keywordSearch, statusTable, page, sizePage);

  const { isLoading: isLoadingDeleta, mutate: onDelete } = useMutation(
    (id: number | undefined) => deleteNotification(id),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QUERY_KEY.LIST_NOTI);
        message.success('データ更新成功');
        setIsVisibleModalConfirm(false);
      },
      onError: (error: any) => {
        if (error.response?.status === 400) {
          message.error(error.response.data.messages[0]);
          return;
        }
        message.error('システムエラー');
      },
    }
  );

  const dataSource = useMemo(() => {
    if (listNoti) {
      return listNoti?.records?.map((noti: notificationCreateType) => ({
        ...noti,
        key: noti?.id,
        no: noti?.id,
        date: noti?.created_at,
        name: noti?.ja_title,
        status: (
          <span
            className={`company-request-status inline-block text-center ${getClassStatusNoti(noti?.is_shows ? 1 : 0)}`}
          >
            {handleGetStatusNoti(noti?.is_shows ? 1 : 0)}
          </span>
        ),
        action: (
          <div className="flex justify-center">
            <img
              className="item-name-icon cursor-pointer mr-[10px]"
              src={editIcon}
              onClick={() => handleGoEditNoti(noti)}
              alt=""
            />
            <img
              className="item-name-icon cursor-pointer mr-[10px]"
              src={deleteIcon}
              alt=""
              onClick={() => handleOpenModalDelete(noti)}
            />
          </div>
        ),
      }));
    }
    return [];
  }, [listNoti]);

  const handleConfirmDelete = () => {
    onDelete(notiDelete?.id);
  };

  const handleOpenModalDelete = (notiDelete: any) => {
    setIsVisibleModalConfirm(true);
    setNotiDelete(notiDelete);
  };

  const handleChangeKeyword = (value: string) => {
    setKeyword(value);
  };

  const handleChangeSizePage = (value: number) => {
    setPage(1);
    setSizePage(value);
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleSearch = () => {
    setPage(1);
    setKeywordSearch(keyword?.trim());
    setKeyword(keyword?.trim());
  };

  const handleGoToNotiCreate = () => {
    antForm.resetFields();
    setIdNoti(undefined);
    setStatus(0);
    setIsDisableSubmit(true);
    setIsModalCreateEdit(true);
  };

  const handleGoEditNoti = (noti: notificationCreateType) => {
    antForm.setFields([
      {
        name: 'ja_title',
        value: noti?.ja_title,
        errors: [],
      },
      {
        name: 'en_title',
        value: noti?.en_title,
        errors: [],
      },
    ]);
    setStatus(noti?.is_shows ? 1 : 0);
    handleChangeValue(antForm.getFieldsValue());
    setIdNoti(noti.id);
    setIsModalCreateEdit(true);
  };

  const handleCloseModal = () => {
    setIsModalCreateEdit(false);
  };

  const handleChangeValue = (allvalues: []) => {
    const listValue = Object.keys(allvalues)?.map((key: any) => allvalues[key]);
    const validateErrorValueList: any[] = [];
    listValue?.forEach((value: any) => {
      if (value === undefined || value === '') {
        validateErrorValueList.push(value);
      }
    });
    if (validateErrorValueList?.length > 0) {
      setIsDisableSubmit(true);
    } else {
      setIsDisableSubmit(false);
    }
  };

  const handleSubmitForm = async (values: any) => {
    await trimmedValue();
    try {
      await antForm.validateFields();
    } catch {
      return;
    }
    const params = {
      ...values,
      is_shows: status,
      id: idNoti,
    };
    if (idNoti) {
      onEditNoti(params);
    } else {
      onCreateNoti({
        ...values,
        is_shows: status,
      });
    }
  };

  const { isLoading: isloadingCreate, mutate: onCreateNoti } = useMutation(
    (param: notificationCreateType) => createNotification(param),
    {
      onSuccess: (res) => {
        message.success('データ更新成功');
        setIsModalCreateEdit(false);
        refetch();
      },
      onError: (error: any) => {
        if (error.response?.status === 400) {
          message.error(error.response.data.messages[0]);
          return;
        }
        message.error('システムエラー');
      },
    }
  );

  const { isLoading: isloadingEdit, mutate: onEditNoti } = useMutation(
    (param: notificationCreateType) => editNotification(param, idNoti),
    {
      onSuccess: (res) => {
        message.success('データ更新成功');
        setIsModalCreateEdit(false);
        refetch();
      },
      onError: (error: any) => {
        if (error.response?.status === 400) {
          message.error(error.response.data.messages[0]);
          return;
        }
        message.error('システムエラー');
      },
    }
  );

  const trimmedValue = async () => {
    const allValueForm = antForm.getFieldsValue();
    Object.keys(allValueForm).forEach((key: any) => {
      if (typeof allValueForm[key] === typeof '') {
        antForm.setFieldsValue({ [key]: allValueForm[key]?.trim() });
      }
    });
  };

  const handleChangeStatus = (status: number) => {
    setPage(1);
    setStatusTable(status);
  };

  const handleChangeStatusButton = (value: boolean) => {
    if (value) {
      setStatus(notiStatusData.ACTIVE);
    } else {
      setStatus(notiStatusData.INACTIVE);
    }
  };

  const renderSelectIcon = () => {
    return <img src={iconSelect} alt="" />;
  };

  return (
    <div className="page-content company-page">
      <div className="mb-[16px]">
        <h2 className="page-content-title border-bottom-title">お知らせ管理</h2>
      </div>

      <div className="page-content-table">
        <div className="flex justify-between items-center p-[16px]">
          <div className="flex items-center">
            <Input
              onPressEnter={handleSearch}
              className="input-search mr-[8px]"
              placeholder="内容"
              suffix={iconSearch()}
              onChange={(e) => handleChangeKeyword(e.target.value)}
              value={keyword}
            />
            <Select
              options={notiStatus}
              placeholder="状態"
              className="company-select"
              allowClear={true}
              onChange={(value: number) => handleChangeStatus(value)}
              suffixIcon={renderSelectIcon()}
            />
          </div>

          <Button
            className="button-add"
            onClick={() => {
              handleGoToNotiCreate();
            }}
          >
            <img className="button-add-icon" src={addIcon} alt="" />
            お知らせを追加
          </Button>
        </div>

        <div className="constuction-list notification-list">
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            loading={isLoading}
            locale={{
              emptyText: <TableNoteNoData />,
            }}
          />
        </div>

        <div className="py-[20px] px-[12px] flex justify-between items-center">
          <div className="pagination-infor">
            {listNoti?.limitFrom} - {listNoti?.limitTo} 件表示/全 {listNoti?.totalRow} 件中
          </div>
          <div className="flex">
            <Pagination
              current={page}
              total={listNoti?.totalRow}
              className="pagination-main mr-[20px]"
              showSizeChanger={false}
              pageSize={sizePage}
              onChange={(page) => handleChangePage(page)}
            />
            <Select
              defaultValue={10}
              style={{ width: 153 }}
              className="pagination-options"
              onChange={(e) => handleChangeSizePage(e)}
              options={pageSizeList}
            />
          </div>
        </div>
      </div>

      <Modal
        centered
        className="modal-category"
        title={idNoti ? 'お知らせを編集' : 'お知らせを追加'}
        width={832}
        open={isModalCreateEdit}
        onCancel={handleCloseModal}
        footer={false}
        maskClosable={false}
      >
        <Form
          form={antForm}
          className="form"
          onFinish={(values) => handleSubmitForm(values)}
          onValuesChange={(value, allvalues) => handleChangeValue(allvalues)}
        >
          <div className="form_item">
            <div className="language text-base font-bold leading-8 tracking-wider mb-2">日本語</div>

            <label className="label_form text-base font-bold leading-6 tracking-wide inline-block mb-1">
              内容（日本語） <span>*</span>
            </label>

            <Form.Item
              name="ja_title"
              rules={[
                {
                  validator: (rule, value, callback, length: number = 1000) => {
                    if (!value?.trim()) {
                      callback('内容（日本語）を入力してください');
                    } else if (length && value?.trim()?.length > length) {
                      callback('内容（日本語）は1000文字以内で入力してください ');
                    } else {
                      callback();
                    }
                  },
                },
              ]}
            >
              <TextArea className="input_form input_form_textarea" />
            </Form.Item>
          </div>

          <div className="form_item">
            <div className="language text-base font-bold leading-8 tracking-wider mb-2">英語</div>

            <label className="label_form text-base font-bold leading-6 tracking-wide inline-block mb-1">
              内容（英語） <span>*</span>
            </label>

            <Form.Item
              name="en_title"
              rules={[
                {
                  validator: (rule, value, callback, length: number = 1000) => {
                    if (!value?.trim()) {
                      callback('内容（英語）を入力してください');
                    } else if (length && value?.trim()?.length > length) {
                      callback('内容（英語）は1000文字以内で入力してください ');
                    } else {
                      callback();
                    }
                  },
                },
              ]}
            >
              <TextArea className="input_form input_form_textarea" />
            </Form.Item>
          </div>

          <div className="mb-[30px] flex items-center ">
            <span className="text-[#2E2E2E] text-[16px] font-bold mr-[8px]">公開</span>
            <Switch
              checked={status === notiStatusData.ACTIVE ? true : false}
              onChange={(e) => handleChangeStatusButton(e)}
              className="ar-switch"
            />
          </div>

          <div className="footer-form flex">
            <Button className="button-form mr-[16px]" onClick={handleCloseModal}>
              戻る
            </Button>

            <Button
              loading={isloadingCreate || isloadingEdit}
              disabled={isDisableSubmit}
              className="button-form button-form-Submit"
              htmlType="submit"
            >
              <span></span>
              {idNoti ? '保存' : '追加'}
            </Button>
          </div>
        </Form>
      </Modal>

      <ModalConfirm
        isShowModal={isVisibleModalConfirm}
        onCancel={handleCancel}
        onOk={handleConfirmDelete}
        content={'お知らせの削除確認'}
        subContent={`選択した「${
          notiDelete?.ja_title?.length > 10 ? `${(notiDelete?.ja_title).substring(0, 10)}...` : notiDelete?.ja_title
        }」を削除してもよろしいですか？`}
        loadingButtonOk={isLoadingDeleta}
        isIcon={false}
      />
    </div>
  );
};

export default NotificationListComponent;
