
import { Button, Checkbox, Form, Input, InputNumber, Select, Spin, message } from 'antd'
import React, {useEffect, useMemo, useRef, useState, useCallback} from 'react'
import { useParams } from 'react-router-dom'
import backIcon from 'src/assets/images/back-icon.png'
import arrowDownIcon from 'src/assets/images/arrow-down.png'
import copyIcon from 'src/assets/images/copy-icon.png'
import deleteIcon from 'src/assets/images/delete-icon.png'
import addIcon from 'src/assets/images/add-icon-2.png'
import { useHistory } from 'react-router'
import { fabric } from 'fabric';
import AddIcon from 'src/assets/icon/addIcon'
import selectIcon from 'src/assets/images/select-icon.png'
import { DEFAULT_PONCHIE_WIDTH, PADDING_BOTTOM_CANVAS, UNIT_CANVAS, direction, listTypeNameFileConstuctionZone, paddingPunch, punchieUploadType } from 'src/constants'
import ModalDelete from 'src/components/common/modalDelete'
import { useMutation, useQueryClient } from 'react-query'
import { editPunchie, uploadPunchie } from 'src/api/locationSite'
import { findRepeatedObjects, handleZoomCanvas } from 'src/utils'
import { usePunchieDetail } from 'src/api/data/locationSite/usePunchieDetail'
import ModalConfirm from 'src/components/common/modal/modalConfirm'
import Module from "src/libraryC";
import { api } from 'src/api/axios'
import { useLocationSiteListFiles } from 'src/api/data/locationSite/useLocationSiteListFiles'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

interface BlockType {
    id: number,
    pointList: [number]
}
let canvas: any;
let minimap: any;
const drawPunchieType = {
    loads: 1,
    draw: 2
}

interface simaSliceType {
    sliceList: number[];
    name: string;
}
const baseURL = process.env.REACT_APP_API
const CreatePonchiePicture = () => {
    const history = useHistory()
    const { idConstructionZone } = useParams<{ idConstructionZone: string }>()
    const { id } = useParams<{ id: string }>()
    const { punchieId } = useParams<{ punchieId: string }>()
    const [ponchieBlockList, setPonchieBlockList] = useState<any>([{
        id: 0,
        pointList: [1]
    }])
    const [idBlockCopy, setIdBlockCopy] = useState<number>()
    const [idPointCopy, setIdPointCopy] = useState<number>()
    const [idPointPate, setIdPointPate] = useState<number>()
    const [idHidenList, setIdHidenList] = useState<any>([])
    const [blockIdDelete, setBlockIdDelete] = useState<number>()
    const [isShowModalDelete, setIsShowModalDelete] = useState(false)
    const [isLoadPunchieFirstTime, setIsLoadPunchieFirstTime] = useState(true)
    const [isVisibleModelConfirmBack,setIsVisibleModelConfirmBack] = useState(false)
    const [isValueForm,setIsValueForm] = useState(false)
    const [simaCenterLine, setSimaCenterLine] = useState<number[]>([])
    const [simaSlice, setSimaSlice] = useState<simaSliceType[]>([])
    const [minYSlice, setMinYSlice] = useState<number>()
    const [punchieHeight, setPunchieHeight] = useState<number>()
    const queryClient = useQueryClient()
    const SelectIcon = () => (
        <img src={selectIcon} alt='' />
    )
    const { data: punchieDetailData, isLoading:isloadingPunchie} = usePunchieDetail(Number(idConstructionZone), Number(punchieId))
    const { data: locationSiteListFiles, isLoading } = useLocationSiteListFiles(Number(idConstructionZone))

    const canvasRef = useRef(null);
    const minimapRef = useRef(null);
    const handleDeletePoint = (blockDeletePoint: BlockType, point: number) => {
        setIsLoadPunchieFirstTime(false)
        const blockDeletePointInArray = ponchieBlockList?.findIndex((block: BlockType) => block?.id === blockDeletePoint?.id)
        let items = [...ponchieBlockList]
        let item = {...items[blockDeletePointInArray]}
        const removePoint = item.pointList.filter((item: number) => item !== point)
        item.pointList = [...removePoint]
        items[blockDeletePointInArray] = item
        setPonchieBlockList(items);
    }

    const handleAddBlock = (value: number) => {
        setIsLoadPunchieFirstTime(false)
        const ponchieBlock = {
            id:value,
            pointList: [value + 1]
        }
        setPonchieBlockList((prevState: any) => [...prevState, ponchieBlock])
    }

    const handleAddPoint = (blockAddPoint: BlockType, point: number, index?: number) => {
        setIsLoadPunchieFirstTime(false)
        const blockAddPointInArray = ponchieBlockList?.findIndex((block: BlockType) => block?.id === blockAddPoint?.id)
        let items = [...ponchieBlockList]
        let item = {...items[blockAddPointInArray]}
        item.pointList.splice(index, 0, point);
        items[blockAddPointInArray] = item
        setPonchieBlockList(items);
    }

    const handleDeleteBlock = () => {
        setIsLoadPunchieFirstTime(false)
        const listArrayRemoveBlock = ponchieBlockList?.filter((block: BlockType) => block?.id !== blockIdDelete)
        setPonchieBlockList((prevState: any) => [...listArrayRemoveBlock])
        setIsShowModalDelete(false)
    }

    const [antForm] = Form.useForm()


    useEffect(() => {
        const canvasWidth = DEFAULT_PONCHIE_WIDTH
        const canvasHeight = document.getElementById('ponchie')?.offsetHeight
        canvas  = new fabric.Canvas(canvasRef.current, {preserveObjectStacking: true});
        canvas.setDimensions({width:canvasWidth, height:canvasHeight});
        canvas.selection = false
        handleZoomCanvas(canvas, updateMiniMapVP)
        const centerLineX = canvas.width / 2 - 1.5;
        const centerLineY = canvas.height;
        
        const centerLine = new fabric.Line([centerLineX, 30, centerLineX, centerLineY], {
            fill: '#C2C2C2',
            stroke: '#C2C2C2',
            strokeWidth: 3,
            strokeDashArray: [5, 5],
            originX: 'left',
            originY: 'top',
        });
        const centerLineName = new fabric.IText('CL', {
            fontSize: 14,
            fill: '#000000',
            top: 0,
            left: centerLineX - 5,
            originX: 'left',
            originY: 'top',
        })
        const groupCenterLine = new fabric.Group([centerLine, centerLineName],{
            hasControls: false,
            lockMovementY:true,
            lockMovementX: true,
        });
        canvas.add(groupCenterLine);
        canvas.bringToFront(centerLine)
        canvas.renderAll();
        return () => {
          canvas.dispose();
        };
    }, [canvasRef.current]);


    async function readContentFile(url: string) {
        const response = await api.get(`${baseURL}/api/get-file?path=${url}`)
        return response.data;
    }

    let ponchieData: any = []
    let parameterPonchie: any = []
    let PonchieDataApp: any = []
    let parameterPonchieApp: any = []
    const drawPonchie = async(Type?: number) => {
        const canvasWidth = DEFAULT_PONCHIE_WIDTH
        console.log('aa');
        await trimmedValue()
        try {
            await antForm.validateFields()
        } catch { return }
        ponchieData = []
        parameterPonchie = []
        PonchieDataApp= []
        parameterPonchieApp = []
        const listPointX: any = []
        const listPointY: any = []
        const valuesSetting = antForm.getFieldsValue()

        ponchieBlockList?.forEach((block: BlockType) => {
            block?.pointList?.forEach((point: number) => {
                listPointX.push(valuesSetting[`point-x[${block?.id}][${point}]`] * UNIT_CANVAS)
                listPointY.push(valuesSetting[`point-y[${block?.id}][${point}]`] * UNIT_CANVAS)
            })
        });

        const maxX = Math.max(...listPointX)
        const minY = minYSlice! * 10
        const yMaxPunchie = Math.max(...listPointY)
        const yMinPunchie = Math.min(...listPointY)
        setPunchieHeight(yMaxPunchie - yMinPunchie + 100)
        canvas.setDimensions({width:DEFAULT_PONCHIE_WIDTH});

        
        if( maxX * 2 > canvasWidth!) {
            canvas.setDimensions({width:maxX * 2 + 100});
        }
        // if(maxY > canvasHeight!) {
        //     canvas.setDimensions({height:maxY - minY + 100});
        // }else{
        //     canvas.setDimensions({height:document.getElementById('ponchie')?.offsetHeight});
        // }
        ponchieBlockList?.forEach((block: BlockType) => {
            const blockData: any = []
            const blockDataApp: any = []
            block?.pointList?.forEach((point: number) => {
                blockData.push({
                    x: (valuesSetting[`point-x[${block?.id}][${point}]`] * UNIT_CANVAS * (valuesSetting[`direction[${block?.id}][${point}]`] === direction.LEFT ? -1 : 1)) + canvas.width/2,
                    y: (valuesSetting[`point-y[${block?.id}][${point}]`] * UNIT_CANVAS)
                })
                parameterPonchie.push({
                    x: (valuesSetting[`point-x[${block?.id}][${point}]`] * UNIT_CANVAS * (valuesSetting[`direction[${block?.id}][${point}]`] === direction.LEFT ? -1 : 1)) + canvas.width/2,
                    y: (valuesSetting[`point-y[${block?.id}][${point}]`] * UNIT_CANVAS),
                    name: valuesSetting[`name-point[${block?.id}][${point}]`]
                })
                blockDataApp.push(
                    {
                        x: (valuesSetting[`point-x[${block?.id}][${point}]`] * (valuesSetting[`direction[${block?.id}][${point}]`] === direction.LEFT ? -1 : 1)) + (canvas.width/2)/UNIT_CANVAS,
                        y: (valuesSetting[`point-y[${block?.id}][${point}]`])
                    }
                )
                parameterPonchieApp.push({
                    x: (valuesSetting[`point-x[${block?.id}][${point}]`] * (valuesSetting[`direction[${block?.id}][${point}]`] === direction.LEFT ? -1 : 1)) + (canvas.width/2/UNIT_CANVAS),
                    y: (valuesSetting[`point-y[${block?.id}][${point}]`]),
                    name: valuesSetting[`name-point[${block?.id}][${point}]`]
                })
            })
            blockDataApp.push(blockDataApp?.[0])
            blockData.push(blockData?.[0])
            ponchieData.push(blockData)
            PonchieDataApp.push(blockDataApp)
        });
    
        canvas._objects = canvas._objects?.filter((object: any) => object?.type === 'sima')
        const centerLine = new fabric.Line([canvas.width/2 - 1.5, 30, canvas.width/2  - 1.5 , canvas.height], {
            fill: '#C2C2C2',
            stroke: '#C2C2C2',
            strokeWidth: 3,
            strokeDashArray: [5, 5],
            originX: 'left',
            originY: 'top',
            hasControls: false,
            lockMovementY:true,
            lockMovementX: true,
        });
        const centerLineName = new fabric.IText('CL', {
            fontSize: 14,
            fill: '#000000',
            top: 0,
            left: canvas.width/2 - 5,
            originX: 'left',
            originY: 'top',
        })
        const groupCenterLine = new fabric.Group([centerLine, centerLineName],{
            hasControls: false,
            lockMovementY:true,
            lockMovementX: true,
            hasBorders: false,
            globalCompositeOperation: 'destination-over'
        });
        canvas.add(groupCenterLine);
        const currentCanvasHeight = canvas.getHeight()
        const listDistancesPoints = findRepeatedObjects(parameterPonchie)
        if(minYSlice || minYSlice === 0) {
            listDistancesPoints?.forEach((point: any, index: number) => {
                const line = new fabric.Line([paddingPunch,currentCanvasHeight - point.y + minY - PADDING_BOTTOM_CANVAS - 1.5, canvas.getWidth() - paddingPunch, currentCanvasHeight - point.y + minY - PADDING_BOTTOM_CANVAS - 1.5], {
                    fill: '#C2C2C2',
                    stroke: '#C2C2C2',
                    strokeWidth: 3,
                    strokeDashArray: [5, 5],
                    originX: 'left',
                    originY: 'top',
                })
                let text = new fabric.IText(point?.name, {
                    fontSize: 14,
                    fill: '#000000',
                    top:currentCanvasHeight - point.y + minY - PADDING_BOTTOM_CANVAS - 8,
                    originX: 'left',
                    originY: 'top',
                })
                text.left = 0  
                const group = new fabric.Group([line, text],{
                    hasControls: false,
                    lockMovementY:true,
                    lockMovementX: true,
                    hasBorders: false,
                    globalCompositeOperation: 'destination-over',
                    type: 'measuring',
                    visible: false
                });
                canvas.add(group)
            });
            
            ponchieData?.forEach((data: any) => {
                console.log( currentCanvasHeight - data[0].y + minY - PADDING_BOTTOM_CANVAS);
                canvas.add(new fabric.Polygon(data.map((item: any) => ({...item, y: currentCanvasHeight - item.y + minY - PADDING_BOTTOM_CANVAS})), {
                    originX: 'left',
                    originY: 'bottom',
                    fill : '#ffffff',
                    hasControls: false,
                    lockMovementY:true,
                    lockMovementX: true,
                    strokeWidth: 3,
                    stroke: '#000000',
                    hasBorders: false,
                    type: 'ponchie'
              }))
            });
            canvas.renderAll();
            console.log(canvas);
            
            canvas._objects?.forEach(async(object: any) => {
                if(object?.name === 'sima_slice') {
                    object.bringToFront()
                   canvas.renderAll();
                }else {
                    object.sendToBack()
                   canvas.renderAll();
                }
                
            })
            updateMiniMap()
        }
        if(Type !== drawPunchieType.loads) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
        }
    };

    const handleCopyPoint = (block: BlockType, point: number,index: number) => {
        const idNewPoint = Date.now()
        handleAddPoint(block, idNewPoint, index)
        setIdPointPate(idNewPoint)
        setIdBlockCopy(block.id)
        setIdPointCopy(point)
    }


    const handleHidenBlockPoint = (id: number) => {
        const isHiden = idHidenList?.find((item: number) => item === id)
        if(isHiden !== undefined) {
            const removeIdHiden = idHidenList.filter((item: number) => item !== id)
            setIdHidenList(removeIdHiden)
        }else {
            setIdHidenList((prevState: any) => [...prevState, id])
        }
    }

    const isHiden = (id: number) => {
        return idHidenList?.find((item: number) => item === id)
    }
    const handleOpenBlockPoint = (id: number, indexParent: number) => {
        const isHiden = idHidenList?.find((item: number) => item === id);
        const isHidenParent = idHidenList?.find((item: number) => item === indexParent);
        if(isHiden){
            setIdHidenList((prev: any) => prev.filter((item: number) => item !== id));
        }
        if(isHidenParent >= 0){
            setIdHidenList((prev: any) => prev.filter((item: number) => item !== isHidenParent));
        }
    }
    useEffect(() => {
        const valuesSetting = antForm.getFieldsValue()
        antForm.setFieldsValue(
            {
                [`point-x[${idBlockCopy}][${idPointPate}]`]: valuesSetting[`point-x[${idBlockCopy}][${idPointCopy}]`],
                [`point-y[${idBlockCopy}][${idPointPate}]`]: valuesSetting[`point-y[${idBlockCopy}][${idPointCopy}]`],
                [`name-point[${idBlockCopy}][${idPointPate}]`]: valuesSetting[`name-point[${idBlockCopy}][${idPointCopy}]`],
                [`direction[${idBlockCopy}][${idPointPate}]`]: valuesSetting[`direction[${idBlockCopy}][${idPointCopy}]`]
            }
        )
    },[idPointCopy, idBlockCopy, idPointPate])

    const handleOpenModalDeleteBlock = (id: number) => {
        setBlockIdDelete(id)
        setIsShowModalDelete(true)
    }

    const handleCloseModalDeleteBlock = () => {
        setIsShowModalDelete(false)
    }


    const {isLoading: isloading, mutate: onUploadPunchie } = useMutation(
        (param: any) => uploadPunchie(Number(idConstructionZone),param),
        {
          onSuccess: async() => {
            message.success('データ更新成功')
            history.push(`/construction-site/${id}/location-site/${idConstructionZone}/list-file`)
            queryClient.clear()
          },
          onError: (error: any) => {
            if (error.response?.status === 400) {
                message.error(error.response.data.messages[0])
                return
            }
            message.error('システムエラー')
          },
        },
    )

    const {isLoading: isloadingEdit, mutate: onEditPunchie } = useMutation(
        (param: any) => editPunchie(Number(idConstructionZone),Number(punchieId),param),
        {
          onSuccess: async() => {
            message.success('データ更新成功')
            history.push(`/construction-site/${id}/location-site/${idConstructionZone}/list-file`)
            queryClient.clear()
          },
          onError: (error: any) => {
            if (error.response?.status === 400) {
                message.error(error.response.data.messages[0])
                return
            }
            message.error('システムエラー')
          },
        },
    )

    const handleUploadPunchie = async() => {
        try {
            await antForm.validateFields()
        } catch { return }
        await drawPonchie()
        const valuesSetting = antForm.getFieldsValue()
        const pointData = {
            blocklist: ponchieBlockList,
            blockValue: valuesSetting
        }
        const drawData = {
            ponchiePoints: ponchieData,
            measuringPoints: findRepeatedObjects(parameterPonchie),
            centerLine: {
                name: 'CL',
                points: [
                    {
                        x: canvas.width/2,
                        y: 0
                    },
                    {
                        x: canvas.width/2,
                        y: canvas.height
                    }
                ]
            },
            canvasWidth: canvas.width,
            canvasHeight: punchieHeight
        }

        const drawDataForApp = {
            ponchiePoints: PonchieDataApp,
            measuringPoints: findRepeatedObjects(parameterPonchieApp),
            centerLine: {
                name: 'CL',
                points: [
                    {
                        x: (canvas.width/2)/UNIT_CANVAS,
                        y: 0
                    },
                    {
                        x: (canvas.width/2)/UNIT_CANVAS,
                        y: canvas.height/UNIT_CANVAS
                    }
                ]
            },
            canvasWidth: canvas.width/UNIT_CANVAS,
            canvasHeight: canvas.height/UNIT_CANVAS
        }
        const params = {
            name: valuesSetting.name,
            type: punchieUploadType.PUNCH_PICTURE,
            point_datas: JSON.stringify(pointData),
            draw_datas: {
                web: JSON.stringify(drawData),
                app: JSON.stringify(drawDataForApp)
            }
        }
        
        if(punchieId) {
            onEditPunchie(params)
        }else {
            onUploadPunchie(params)
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    },[])

    const blockListValue = useMemo(() => {
        if(punchieDetailData?.point_datas) {
            return JSON.parse(punchieDetailData?.point_datas)?.blockValue
        }
    },[punchieDetailData])

    useEffect(() => {
        if(punchieDetailData) {
            const blockList = JSON.parse(punchieDetailData?.point_datas)?.blocklist
            setPonchieBlockList(blockList)
        }
    },[punchieDetailData])

    useEffect(() => {
        if(isLoadPunchieFirstTime) {
            if(ponchieBlockList && blockListValue && (minYSlice || minYSlice === 0)) {
                drawPonchie(drawPunchieType?.loads)
            }
        } 
    },[ponchieBlockList, blockListValue, isLoadPunchieFirstTime, minYSlice])

    const trimmedValue = async() => {
        const allValueForm = antForm.getFieldsValue()
        Object.keys(allValueForm).forEach((key: any) => {
            if(typeof allValueForm[key] === typeof '') {
                antForm.setFieldsValue({ [key]: allValueForm[key]?.trim() })
            }
        })
    }

    const handlePreviewPunchie = () => {
        drawPonchie()
    }

    const handleChangeValue = (allvalues: any) => {
        const allValuesExceptPaymentType = {...allvalues}
        const listValue = Object.keys(allValuesExceptPaymentType)?.filter((key) => !key?.includes('direction'))?.map((key: any) => allvalues[key])
        listValue?.some((value:any) => {
            if(value !== undefined && value !== '' && value != null) {
                setIsValueForm(true)
                return true
            }
            setIsValueForm(false)
            return false
        })
    }

    const handleGoBack = () => {
        history.push(`/construction-site/${id}/location-site/${idConstructionZone}/list-file`)
    }

    const handleBack = () => {
        if(isValueForm) {
            setIsVisibleModelConfirmBack(true)
        }else{
            handleGoBack()
        }
    }

    const handleCancel = () => {
        setIsVisibleModelConfirmBack(false)
    }

    const openValidateField = (position?: number, indexParent: number = -1) => {
        if(position && indexParent >= 0){
            handleOpenBlockPoint(position, indexParent);
        }
    }
    const validateFields = (rule: any, value: string, callback: any, name: string, length?: number, position ?: number, indexParent?: number) => {
        if (!value?.trim()) {
            if(position)
                openValidateField(position, indexParent)
            callback(`${name}を入力してください`)
        }else if( length && value?.trim()?.length > length) {
            if(position)
                openValidateField(position, indexParent)
            callback(`${name}は${length}文字以内で入力してください`)
        }else {
            callback()
        }
    }
    const validateNumber = (value: number, callback: any, name: string, position ?: number, indexParent?: number) => {
        if(typeof value === 'number' && !isNaN(value) && value >= 0){
            callback();
        }
        else{
            if(position)
                openValidateField(position, indexParent)
            callback(`${name}を入力してください`)
        }
    }

    const handleGetDataDrawSima = useCallback(() => {
        const pathLineSima = locationSiteListFiles?.files?.find((file: {file_type: number}) => file.file_type === listTypeNameFileConstuctionZone.SIM_LINEAR_FILES)?.file_path
        const pathSlineSima = locationSiteListFiles?.files?.find((file: {file_type: number}) => file.file_type === listTypeNameFileConstuctionZone.SIM_SLICE_FILES)?.file_path
        if(pathLineSima && pathSlineSima) {
          Module().then(async(module: any) => {
            const content1 = await readContentFile(pathLineSima);
            const content2 = await readContentFile(pathSlineSima);
            const sliceSIMA = module.get2DSliceSIMA(content1, content2);
            const centerLineSimaX = canvas.width / 2 - 0.5 
            const startDistance = sliceSIMA.get(0).distance
            setMinYSlice(startDistance)
            const yEndSimaCenterLine = sliceSIMA.get(sliceSIMA.size() - 1).distance
            const yEndSimaCenterLineWithCoordinateOrigin0 = yEndSimaCenterLine - startDistance 
            canvas.setDimensions({height:yEndSimaCenterLineWithCoordinateOrigin0 * UNIT_CANVAS + 100});
            const yStartSliceChangeCoordinateOrigin = canvas.height - yEndSimaCenterLine * UNIT_CANVAS  + startDistance * UNIT_CANVAS - PADDING_BOTTOM_CANVAS - 0.5
            const yEndSliceChangeCoordinateOrigin = canvas.height - startDistance * UNIT_CANVAS  + startDistance * UNIT_CANVAS - PADDING_BOTTOM_CANVAS - 0.5
            setSimaCenterLine((prevState: number[]) => [...prevState, centerLineSimaX, yStartSliceChangeCoordinateOrigin, centerLineSimaX, yEndSliceChangeCoordinateOrigin])
            for (let i = 0; i < sliceSIMA.size(); i++) {
                const {distance,right, left, name} = sliceSIMA.get(i);
                const xStartPointSlice = Number((centerLineSimaX - right * UNIT_CANVAS).toFixed(2))
                const yPointSliceChangeCoordinateOrigin = canvas.height - distance * UNIT_CANVAS  + startDistance * UNIT_CANVAS - PADDING_BOTTOM_CANVAS - 0.5
                const xEndPointSlice = centerLineSimaX - left * UNIT_CANVAS
                setSimaSlice((prevState: any) => [...prevState, {
                    sliceList: [xStartPointSlice,yPointSliceChangeCoordinateOrigin, xEndPointSlice, yPointSliceChangeCoordinateOrigin],
                    name: name
                }])
            }
            sliceSIMA.delete();
          });
        }
    },[locationSiteListFiles?.files])

    useEffect(() => {
        if(locationSiteListFiles && canvas) {
            handleGetDataDrawSima()
        }
    },[locationSiteListFiles, handleGetDataDrawSima])

    const handleDrawSima = useCallback((isLoadSima?: boolean) => {
        const simaCenterLineCanvas = new fabric.Line(simaCenterLine, {
            fill: '#ff0000',
            stroke: '#ff0000',
            strokeWidth: 1,
            originX: 'left',
            originY: 'top',
            hasControls: false,
            lockMovementY:true,
            lockMovementX: true,
            type: 'sima',
            name: 'sima_slice'
        });
        canvas.add(simaCenterLineCanvas);

        simaSlice?.forEach((slice) => {
            const simaSliceCanvas = new fabric.Line(slice?.sliceList, {
                fill: '#1d89f9',
                stroke: '#1d89f9',
                strokeWidth: 1,
                originX: 'left',
                originY: 'top',
                hasControls: false,
                lockMovementY:true,
                lockMovementX: true,
                type: 'sima',
                name: 'sima_slice'
            });
            canvas.add(simaSliceCanvas)

            const xStartMeasuring = paddingPunch
            const xEndMeasuring = canvas.getWidth() - paddingPunch
            const yMeasuring = slice?.sliceList[1] - 1.5
            const simaMeasuringCanvas = new fabric.Line([xStartMeasuring,yMeasuring, xEndMeasuring, yMeasuring], {
                fill: '#C2C2C2',
                stroke: '#C2C2C2',
                strokeWidth: 3,
                strokeDashArray: [5, 5],
                originX: 'left',
                originY: 'top',
            });
            
            let text = new fabric.IText(slice?.name, {
                fontSize: 14,
                fill: '#000000',
                top:slice?.sliceList[1] - 8,
                originX: 'left',
                originY: 'top',
            })
            text.left = 0  

            const group = new fabric.Group([simaMeasuringCanvas, text],{
                hasControls: false,
                lockMovementY:true,
                lockMovementX: true,
                hasBorders: false,
                type: 'sima',
                globalCompositeOperation: 'destination-over',
            });
            canvas.add(group)
        })

        canvas.renderAll();
        if(isLoadSima) {
            initMinimap()
        }else {
            updateMiniMap()
        }
    },[simaCenterLine, simaSlice])

    const handleHideSima = () => {
        canvas.getObjects()?.forEach((object: any) => {
            if(object?.type === 'sima') {
              canvas.remove(object);
            }
          })
        canvas.renderAll();
    }


    const handleToggleSima = (value:  CheckboxChangeEvent) => {
        if(value.target.checked) {
            canvas.getObjects()?.forEach((object: any) => {
                if(object?.type === 'measuring') {
                  object.visible = false
                }
              })
            canvas.renderAll();
            handleDrawSima()
        }else {
            canvas.getObjects()?.forEach((object: any) => {
                if(object?.type === 'measuring') {
                  object.visible = true
                }
              })
            canvas.renderAll();
            handleHideSima()
        }
    }

    useEffect(() => {
        if(simaCenterLine?.length > 0 && simaSlice?.length > 0) {
            const isLoadSima = true
            handleDrawSima(isLoadSima)
        }
    },[handleDrawSima, simaCenterLine, simaSlice])


    useEffect(() => {
        minimap  = new fabric.Canvas(minimapRef.current, {
          preserveObjectStacking: true,
          selection: false
        })
        return () => {
          minimap.dispose();
        };
      }, [minimapRef.current]);
    
      const createCanvasEl = () => {
        const designSize = { width: canvas?.width, height: canvas?.height };
        const originalVPT = canvas?.viewportTransform;
        const fabricUtil: any = fabric.util
        const designRatio = fabricUtil.findScaleToFit(designSize, canvas);
        const minimapRatio: any = fabricUtil.findScaleToFit(canvas, minimap);
    
        const scaling = minimap.getRetinaScaling();
    
        const finalWidth =  designSize.width * designRatio;
        const finalHeight =  designSize.height * designRatio;
    
        canvas.viewportTransform = [
          designRatio, 0, 0, designRatio,
          (canvas?.getWidth() - finalWidth) / 2,
          (canvas?.getHeight() - finalHeight) / 2
        ];
        const canvasElement = canvas?.toCanvasElement(minimapRatio * scaling);
        canvas.viewportTransform = originalVPT;
        return canvasElement;
      }
    
      function initMinimap() {
        const canvasEl = createCanvasEl();
        const backgroundImage = new fabric.Image(canvasEl);
        backgroundImage.scaleX = 1 / canvas.getRetinaScaling();
        backgroundImage.scaleY = 1 / canvas.getRetinaScaling();
        minimap.centerObject(backgroundImage);
        minimap.backgroundColor = 'white';
        minimap.backgroundImage = backgroundImage;
        minimap.requestRenderAll();
        minimap.getObjects()?.forEach((object: any) => {
          if(object?.type === 'minimap') {
            minimap.remove(object);
          }
        })
        const frameHeight = document.getElementById('ponchie')?.offsetHeight
        const ratioHeightCanvas = frameHeight! / canvas.height
        const frameWidth = document.getElementById('ponchie')?.offsetWidth
        const ratioWidthCanvas = frameWidth! / canvas.width
        const minimapView = new fabric.Rect({
          top: backgroundImage.top,
          left: backgroundImage.left,
          width: backgroundImage.width! * ratioWidthCanvas / canvas.getRetinaScaling(),
          height: backgroundImage.height! * ratioHeightCanvas / canvas.getRetinaScaling(),
          fill: 'rgba(0, 0, 255, 0.3)',
          strokeWidth: 0,
          lockScalingY: true,
          lockScalingX: true,
          lockRotation: true,
          hasBorders: false,
          type:'minimap'
        });
        minimapView.setControlsVisibility({
          mt: false,
          mb: false,
          ml: false,
          mr: false,
          tl:false,
          bl: false,
          tr: false,
          br: false,
          mtr: false,
        });
        minimap.add(minimapView);
      }
    
      function updateMiniMap() {
        var canvasEl = createCanvasEl();
        minimap.backgroundImage._element = canvasEl;
        minimap.requestRenderAll();
      }
    
      function updateMiniMapVP() {
        const designSize = { width: canvas.width, height: canvas.height };
        const rect = minimap.getObjects()[0];
        const fabricUtil: any = fabric.util
        const designRatio = fabricUtil.findScaleToFit(designSize, canvas);
        const totalRatio = fabricUtil.findScaleToFit(designSize, minimap);
        const finalRatio = designRatio / canvas.getZoom();
        if(rect) {
          rect.scaleX = finalRatio;
          rect.scaleY = finalRatio;
          rect.top = minimap.backgroundImage.top - canvas.viewportTransform[5] * totalRatio / canvas.getZoom();
          rect.left = minimap.backgroundImage.left - canvas.viewportTransform[4] * totalRatio / canvas.getZoom();
        }
        minimap.requestRenderAll();
      }
     
      useEffect(()=> {
        minimap?.on('object:moving', function (e: any) {
          const obj = e.target;
          if(obj?.type !== 'minimap') {
            return
          }
          obj.setCoords();
          const designSize = { width: canvas.width, height: canvas.height };
          const fabricUtil: any = fabric.util
          const totalRatio = fabricUtil.findScaleToFit(designSize, minimap);
          canvas.viewportTransform[5] = ((minimap.backgroundImage.top - obj.top) * canvas.getZoom()) / totalRatio
          canvas.viewportTransform[4] = ((minimap.backgroundImage.left - obj.left) * canvas.getZoom()) / totalRatio
          canvas.requestRenderAll();
        });
    
      
      },[minimap, canvas])
    return (
        <>
         <div className='page-only-header-title mb-[16px] justify-between'>
                <div className='flex items-center'>
                    <img src={backIcon} alt='' className='mr-[16px] cursor-pointer w-[24px] h-[24px]' onClick={handleBack}/>
                    <h2 className='page-detail-note cursor-pointer' onClick={() =>handleBack()}>工区ファイル一覧 </h2>
                    <span className='page-detail-note whitespace-nowrap text-blue'>/ポンチ絵</span>
                </div>
                <Button className='button-add min-w-[128px]' loading={isloading || isloadingEdit} onClick={handleUploadPunchie}><span></span>保存</Button>
            </div>   
   
       <div className='ponchie-picture'>
           
            {
                isloadingPunchie ? <Spin/> : 
                <div className='flex'>
                    <div className='w-4/12 mt-[76px]'>
                        <Form form={antForm} onValuesChange={(value, allvalues) => handleChangeValue(allvalues)} scrollToFirstError={{
                            behavior: 'smooth',
                            block: 'center',
                            inline: 'center',
                        }} onFinish={handlePreviewPunchie}>
                            <div  className='form-ponchie'>
                                <div className='ponchie-setting ponchie-setting-name mb-[12px]'>
                                    <label className='mt-[8px]'>ポンチ絵名</label>
                                    <Form.Item 
                                        initialValue={punchieDetailData?.name} 
                                        name='name' 
                                        className='w-full' 
                                        rules={
                                            [                                    
                                                { validator: (rule, value, callback) => validateFields(rule,value,callback, 'ポンチ絵名', 255)}
                                            ]
                                        }
                                    >
                                        <Input className='input-form w-full'/>
                                    </Form.Item>
                                </div>
                                {
                                    ponchieBlockList?.map((block: BlockType, blockIndex: number) => (
                                        <div key={block.id} className='ponchie-setting mb-[8px]'>
                                            <div className='ponchie-setting-header flex items-center justify-between'>
                                                ブロック {blockIndex + 1}
                                                <div className='flex items-center'>
                                                    {
                                                        ponchieBlockList?.length === 1 ? '' : <>
                                                            <img src={deleteIcon} alt='' className='cursor-pointer mx-[10px]' onClick={() =>handleOpenModalDeleteBlock(block.id)}/>
                                                        </>
                                                    }
                                                    <img 
                                                        src={arrowDownIcon} 
                                                        alt='' 
                                                        className={`cursor-pointer drop-arrow ${isHiden(block.id) !== undefined ? 'rotate-180' : 'rotate-0'}`}
                                                        onClick={() => handleHidenBlockPoint(block.id)}
                                                    />
                                                </div>
                                            </div>
                                            <div className={`ponchie-block ${isHiden(block.id) !== undefined ? 'hiden-block' : ''}`}>
                                                <div className='ponchie-setting-content'>
                                                    {
                                                        block?.pointList?.map((point: number, index: number) => (
                                                            <div className='ponchie-setting-content-item' key={point}>
                                                                <div className='flex items-center justify-between text-[16px] font-bold text-[#000000]'>
                                                                    折れ点情報 {index + 1}
                                                                    <div className='flex items-center'>
                                                                        <img className='button-add-icon cursor-pointer' src={addIcon} alt='' onClick={() => handleAddPoint(block, Date.now(), index + 1)}/>
                                                                        <img src={copyIcon} alt='' className='cursor-pointer' onClick={() => handleCopyPoint(block, point, index+1)}/>
                                                                        {
                                                                            block?.pointList?.length === 1 ? '' :
                                                                            <img src={deleteIcon} alt='' className='cursor-pointer mx-[10px]' onClick={() =>handleDeletePoint(block, point)}/>
                                                                        }
                                                                        <img src={arrowDownIcon} alt='' className={`cursor-pointer drop-arrow ${isHiden(point) !== undefined ? 'rotate-180' : 'rotate-0'}`} onClick={() => handleHidenBlockPoint(point)}/>
                                                                    </div>
                                                                </div>
                                                                <div className={`mt-[16px] data-point ${isHiden(point) !== undefined ? 'hiden-block' : ''}`}>
                                                                    <div className='flex mb-[12px]'>
                                                                        <div className='text-[#2E2E2E] text-[16px] whitespace-nowrap min-w-[100px] mt-[8px]'>測点</div>
                                                                        <Form.Item 
                                                                            initialValue={blockListValue?.[`name-point[${block?.id}][${point!}]`]} 
                                                                            name={`name-point[${block?.id}][${point}]`} 
                                                                            className='w-full' 
                                                                            rules={
                                                                                [                                    
                                                                                    { validator: (rule, value, callback) => validateFields(rule,value,callback, '測点', 10, point, block.id)}
                                                                                ]
                                                                            }
                                                                        >
                                                                            <Input className='input-form h-[40px]'/>
                                                                        </Form.Item>
                                                                        <div className='text-[#2E2E2E] text-[16px] whitespace-nowrap ml-[20px] mr-[12px] min-w-[50px] mt-[8px]'>距離</div>
                                                                        <Form.Item 
                                                                            initialValue={blockListValue?.[`point-y[${block?.id}][${point!}]`]} 
                                                                            name={`point-y[${block?.id}][${point}]`} 
                                                                            className='w-full' 
                                                                            rules={[{ validator: (rule, value, callback) => validateNumber(value, callback , '距離', point, block.id)}]}
                                                                        >
                                                                            <InputNumber min={0} className='input-form w-full'/>
                                                                        </Form.Item>
                                                                        <span className='ml-[8px] mt-[8px]'>m</span>
                                                                    </div>
                                                                    <div className='flex'>
                                                                        <div className='text-[#2E2E2E] text-[16px] whitespace-nowrap min-w-[100px] mt-[8px]'>方向</div>
                                                                        <Form.Item 
                                                                            initialValue={blockListValue?.[`direction[${block?.id}][${point!}]`] || 1} 
                                                                            name={`direction[${block?.id}][${point}]`} 
                                                                            className='w-full' 
                                                                        >
                                                                            <Select
                                                                                suffixIcon={SelectIcon()}
                                                                                options={[
                                                                                    {
                                                                                        value: 1,
                                                                                        label: '左',
                                                                                    },
                                                                                    {
                                                                                        value: 2,
                                                                                        label: '右',
                                                                                    },
                                                                                ]}
                                                                            />
                                                                        </Form.Item>
                                                                        <div className='text-[#2E2E2E] text-[16px] whitespace-nowrap ml-[20px] mr-[12px] min-w-[50px] mt-[8px]'>位置</div>
                                                                        <Form.Item 
                                                                            initialValue={blockListValue?.[`point-x[${block?.id}][${point!}]`]} 
                                                                            name={`point-x[${block?.id}][${point}]`} 
                                                                            className='w-full' 
                                                                            rules={[{ validator: (rule, value, callback) => validateNumber(value, callback , '位置', point, block.id)}]}

                                                                        >
                                                                            <InputNumber min={0} className='input-form w-full'/>
                                                                        </Form.Item>
                                                                        <span className='ml-[8px] mt-[8px]'>m</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className='ponchie-setting-button mt-[38px]'>
                                <Button className='button-form flex items-center justify-center mr-[8px]' onClick={() => handleAddBlock(Date.now())}>
                                    <AddIcon />
                                    ブロック追加
                                </Button>
                                <Button className='button-add' htmlType="submit"><span></span>更新</Button>
                            </div>
                        </Form>
                    </div>
                    <div className='w-8/12 mt-[76px]'>
                        <div className='text-end'>
                            <Checkbox defaultChecked onChange={handleToggleSima} className='text-[#272122] text-[16px]'>基本SIMAを見る</Checkbox>
                        </div>
                        <div id='ponchie' className='ponchie-canvas form-diagram-content scroll-punchie overflow-hidden relative'>
                            <canvas ref={canvasRef}/>
                            <div className='minimap'>
                                <canvas id="minimap" ref={minimapRef} width="200" height="200"></canvas>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <ModalDelete 
                isShowModal={isShowModalDelete}
                onCancel={handleCloseModalDeleteBlock}
                onDelete={handleDeleteBlock}
                content='このブロックを削除します。よろしいですか？'
            />
            <ModalConfirm 
                isShowModal={isVisibleModelConfirmBack}
                onCancel={handleCancel}
                onOk={handleGoBack}
                content='このページを離れますか？'
                subContent='ページから移動すると、保存されていない変更は失われます。'
            />
       </div>
       </>
    )
}

export default CreatePonchiePicture