import { useQuery } from 'react-query'
import { api } from 'src/api/axios'
import { QUERY_KEY } from '../query-key'


export const useConstructionDetail = (id: number) => useQuery(
  [QUERY_KEY.CONSTRUCTION_DETAIL, id],
  async () => {
    if(id) {
      const res = await api.get(`/api/admin/construction/${id}`)
      return res.data.data
    }
    return
  },
  {
    retry: 0,
  }
)
