import {useState} from 'react'
import {useLocation} from 'react-router-dom'
import { Button, Modal, message } from 'antd'
import { useLicenseInvoice } from 'src/api/data/companyManagement/useLicenseInvoice'
import { Spin } from 'antd'
import { useHistory } from 'react-router-dom'
import logo from 'src/assets/images/logo.png'
import success from 'src/assets/images/tick-circle.png'
import { useMutation } from 'react-query'
import { confirmLicense } from 'src/api/payment'
const LicenseInvoiceComponent = () => {
    const location = useLocation()
    const token = new URLSearchParams(location.search).get("token")
    const {data, isLoading} = useLicenseInvoice(token!)
    const history = useHistory()
    const [isOpenModalSuccess, setIsOpenModalSuccess] = useState<boolean>(false)
   
    const onGobackTermsOfService = () => {
        history.push(`/license/terms-of-service/token=${token}?isChecked=true`)
    }

    const {isLoading: isloadingBtn, mutate: onConfirmLicense } = useMutation(
        (param: string) => confirmLicense(param),
        {
          onSuccess: (res) => {
            setIsOpenModalSuccess(true)
          },
          onError: (error: any) => {
            if (error.response?.status === 400) {
                message.error(error.response.data.messages[0])
                return
            }
            message.error('システムエラー')
          },
        },
    )

    const handleConfirmLicense = () => {
        if(token){
            onConfirmLicense(token)
        }
    }

    const handleCloseModalSuccess = () => {
        setIsOpenModalSuccess(false)
    }
    return (
        <>
            {
                isLoading ? <Spin className='position-center' /> :
                <>
                    <div id='header' className='flex justify-between header'>
                        <div className='flex items-center cursor-pointer'>
                            <img className='header__icon mr-[13px]' src={logo} alt='logo'/>
                            <h1 className='font-bold text-[24px] text-[#253858] whitespace-nowrap cursor-pointer'>助さん</h1>
                        </div>
                        <div className='flex w-[400px]'>
                            <Button className='button-form mr-[26px]' onClick={onGobackTermsOfService}>戻る</Button>
                            <Button 
                                className='button-form button-form-Submit' 
                                onClick={handleConfirmLicense}
                                loading={isloadingBtn}
                            >
                                <span></span>確認
                            </Button>
                        </div>
                    </div>
                    <div className='mt-[40px] text-center '>
                        <embed src={`data:application/pdf;base64,${data}#toolbar=0&navpanes=0&scrollbar=0`} width="900" height="1300" className='mx-auto'/> 
                    </div>
                    <Modal 
                        title="" 
                        open={isOpenModalSuccess} 
                        width={451}
                        className="modal-success"
                        footer={false}
                        closable={false}
                        centered={true}
                        afterClose={handleCloseModalSuccess}
                    >
                        <img src={success} alt='' className='mb-[16px] mx-auto'/>
                        <div className='text-[#272122] text-[20px] font-medium text-center mb-[8px]'>確認成功</div>
                        <p className='text-[#272122] text-[14px] text-center mb-[30px]'>見積もりの確認が完了しました。会社のメールアドレスに送信された請求書をご確認ください。</p>
                    </Modal>
                </>
            }
        </>
    )
}

export default LicenseInvoiceComponent