import React from 'react'
import StaffListComponent from 'src/components/features/agency/staffListComponent'
import { LayoutSuperAdmin } from 'src/components/layouts/layoutSuperAdmin'

const AdminStaffListPage = () => (
    <LayoutSuperAdmin>
       <StaffListComponent isAdmin={true}/>
    </LayoutSuperAdmin>
)

export default AdminStaffListPage