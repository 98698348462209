import { api } from "./axios";

interface TypeFormChangePass {
    current_password: string,
    new_password: string,
    confirm_password: string,
}

interface TypeFormResetPass {
    token: string,
    email: string,
    password: string,
    password_confirmation: string,
}
export const changePassword = (params: TypeFormChangePass) => api.post(
    `/api/admin/change-password`,
    params,
)

export const forgotPassword = (params: {email: string}) => api.post(
    `/api/forgot-password`,
    params,
)

export const resetPassword = (params: TypeFormResetPass) => api.post(
    `/api/reset-password`,
    params,
)

export const checkResetPasswordExpired = (params: {token: string, email: string}) => api.post(
    `/api/check-expire-token`,
    params,
)

export const editAvatar = (params: FormData) => api.post(
    `/api/update-info-user`,
    params,
)
