import React from 'react'
import ConstructionSiteComponent from 'src/components/features/constructionSite/constructionSiteComponent'
import { LayoutConstruction } from 'src/components/layouts/layoutConstruction'

const ConstructionSitePage = () => (
    <LayoutConstruction>
       <ConstructionSiteComponent />
    </LayoutConstruction>
)

export default ConstructionSitePage