import React, { useMemo } from 'react'
import './header.modules.scss'
import logo from 'src/assets/images/logo.png'
import { useHistory, useLocation } from 'react-router-dom'
import UserInfor from './userInfor'
import { menuAdmin, menuAgency, menuAgencyStaff, menuCompany, menuSuperAdmin, role } from 'src/constants'

const HeaderProfile = () => {
    const history = useHistory()
    const location = useLocation()
    const userRole = Number(localStorage.getItem('role'))
    const handleGoHomePage = () => {
        if(location?.pathname === '/login') {
            return
        }
        if(userRole === role.ADMIN || userRole === role.SYSTEM_ADMIN) {
            history.push('/cms/company')
        }else if(userRole === role.AGENCY || userRole === role.AGENCY_STAFF) {
            history.push('/agency/company')
        }else if(userRole === role.COMPANY || userRole === role.COMPANY_STAFF) {
            history.push('/construction-site')
        }
    }

    const handleClickMenu = (path: string) => {
        history.push(path)
    }

    const menu = useMemo(() => {
        switch(userRole) {
            case role.SYSTEM_ADMIN:
                return menuSuperAdmin
            case role.ADMIN:
                return menuAdmin
            case role.AGENCY:
                return menuAgency
            case role.AGENCY_STAFF:
                return menuAgencyStaff
            case role.COMPANY:
                return menuCompany
            default:
              return []
        }
    },[userRole])
    return (
        <>
            <div id='header' className='flex justify-between header'>
                <div className='flex items-center'>
                    <div className='flex items-center cursor-pointer mr-[80px]' onClick={handleGoHomePage}>
                        <img className='header__icon mr-[13px]' src={logo} alt='logo'/>
                        <h1 className='font-bold text-[24px] text-[#253858] whitespace-nowrap cursor-pointer'>助さん</h1>
                    </div>
                    <ul className='flex items-center'>
                        {
                            menu?.map((item:any) => (
                                <li 
                                    className={`cursor-pointer menu-item ${location.pathname?.includes(item?.path) ? 'menu-item-active' : ''}`} 
                                    key={item?.id} 
                                    onClick={() =>handleClickMenu(item?.path)}
                                >
                                    {item?.name}
                                </li>
                            ))
                        }
                    </ul>
                </div>
                <UserInfor />
            </div>
        </>
    )
}

export default HeaderProfile