import { Button, Modal } from "antd"
import closeIcon from 'src/assets/images/close-circle.png'
import './index.scss'
interface ModalConfirmType {
    isShowModal: boolean,
    onOk: () => void,
    onCancel: () => void,
    content: string,
    loadingButtonOk?: boolean,
    subContent?: string,
    iconModal: string
}
const ModalPayment = ({isShowModal, onOk , onCancel, content, loadingButtonOk, subContent,iconModal}: ModalConfirmType) => {
    const handleClickButtonOk = () => {
        onOk()
    }
    const handleClickButtonCancel = () => {
        onCancel()
    }
    return (
        <Modal 
            title="" 
            open={isShowModal} 
            width={451}
            className="main-modal payment-modal"
            footer={false}
            closable={false}
            centered={true}
        >
            <img src={closeIcon} alt="" className="close-modal cursor-pointer" onClick={handleClickButtonCancel}/>
            <img src={iconModal} alt="" className="mx-auto mb-[16px]"/>
            <p className='text-center text-[20px] text-[#272122] font-medium whitespace-pre-line mb-[8px]'>{content}</p>
            <p className="text-center mb-[44px] text-[#2E2E2E] whitespace-pre-line">{subContent}</p>
            <div className='footer-form flex'>
                <Button loading={loadingButtonOk} className='button-form button-form-Submit' onClick={handleClickButtonOk}>
                    OK
                </Button>
            </div>
        </Modal>
    )
}

export default ModalPayment