import { useQuery } from 'react-query';
import { api } from 'src/api/axios';
import { QUERY_KEY } from '../query-key';

export const useListNotification = (keyWord: string, status: number | undefined, page: number, size: number) =>
  useQuery([QUERY_KEY.LIST_NOTI, keyWord, status, page, size], async () => {
    const res = await api.get('/api/cms/notify', {
      params: {
        keyword: keyWord,
        status,
        page: page,
        size: size,
      },
    });
    return res.data.data;
  });
