import React, { useCallback, useEffect, useRef } from 'react';
import { read} from 'xlsx';
import Spreadsheet from 'x-data-spreadsheet';
import { apiResBlob } from 'src/api/axios';
import { stox } from 'src/utils/ExcelUtility';
const baseURL = process.env.REACT_APP_API
function SheetJSXSpread({urlFileExcelPrivew}:any) {
  const ref = useRef(null);
  let loadFirstTime = false

  const handleLoadFileExcel = useCallback(async() => {
    loadFirstTime = true
    const res: any = await apiResBlob.get(`${baseURL}/api/get-file?path=${urlFileExcelPrivew}`)
    const wb = read(await res?.data.arrayBuffer());
    const data = stox(wb)
    let spreadsheet = new Spreadsheet(ref.current!, 
      { 
        mode: 'read', 
        view: {
          height: () => document.getElementById('excel')!.clientHeight,
          width: () => document.getElementById('excel')!.clientWidth + 50,
        },showToolbar: false
      }
    ).loadData([{
            cols: {
                len: 200,
                2: {width: 200},
            },
            rows: {},
    },])
    const dataLoaded: any = []
    data.forEach(function (data:any) {
      var maxColumn: any = 26;
      var rows: any = [];
      rows['len'] = Object.keys(data.rows).length;
      Object.keys(data.rows).forEach(key => {
          var column = Object.keys(data.rows[key]['cells'])[Object.keys(data.rows[key]['cells']).length - 1];
          maxColumn = maxColumn <= column ? column : maxColumn;
          rows[key] = data.rows[key];
      });
      dataLoaded.push( {
          styles: [
              {

              },
          ],
          cols: {
              len: parseInt(maxColumn) + 5,
              2: { width: 200 },
          },
          rows,
          name: data.name
      });
    })
      spreadsheet.loadData(dataLoaded);
  }, [urlFileExcelPrivew])

  useEffect(() => {
    if(!loadFirstTime) {
        handleLoadFileExcel()
    }
  },[])

  return (
    <>
      <div id='excel' className='modal-excel-content height-content-modal-preview' ref={ref}/>
    </>
  );
}

export default SheetJSXSpread;
