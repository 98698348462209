
import 'moment/locale/ja';
import { Button, Input, Pagination, Select, Table, Checkbox, message } from 'antd'
import React, { useMemo, useState } from 'react'
import searchIcon from 'src/assets/images/search-icon.png'
import acceptIcon from 'src/assets/images/accept-icon.png'
import rejectIcon from 'src/assets/images/reject-icon.png'
import type { ColumnsType } from 'antd/es/table';
import { licenseRequestStatus, licenseRequestStatusData, pageSizeList, paymentType } from 'src/constants'
import { licenseCreateType } from 'src/components/features/agency/type';
import iconSelect from 'src/assets/images/arrow-down-2.png'
import TableNoteNoData from 'src/components/common/tableNoteNoData';
import './superAdmin.modules.scss'
import ModalConfirm from 'src/components/common/modal/modalConfirm';
import { useMutation, useQueryClient } from 'react-query';
import { ratifyLicenseRequest } from 'src/api/admin';
import { QUERY_KEY } from 'src/api/data/query-key';
import { useListRequestLicense } from 'src/api/data/agency/useListRequestLicense';
interface DataType {
    key: string;
    name: string;
    location: string;
    updated_at: string;
}

const ratifyTypeData = {
  accept: 1,
  reject: 2,
  acceptMultipleRequests: 3,
  rejectMultipleRequests: 4
}
const LicenseRequestListComponent = () => {
    const queryClient = useQueryClient()
    const [keyword, setKeyword] = useState<string>('')
    const [keywordSearch, setKeywordSearch] = useState<string>('')
    const [status, setStatus] = useState<number | undefined>()
    const [page, setPage] = useState<number>(1)
    const [sizePage, setSizePage] = useState<number>(10)
    const [listIdRequest,setListIdRequest] = useState<any>([])
    const [isVisibleModalConfirm, setIsVisibleModalConfirm] = useState(false)
    const [idRatify, setIdRatify] = useState<number | undefined>()
    const [ratifyType, setRatifyType] = useState<number | undefined>()
    const [paymentMethod, setPaymentMethod] = useState<number | undefined>()
    const { data: listLicenseRequest, isLoading } = useListRequestLicense(keywordSearch,status, page, sizePage, paymentMethod)
    const columns: ColumnsType<DataType> = useMemo(() => {
      return [
        {
            title: () => <Checkbox 
              checked={(listIdRequest?.length === listLicenseRequest?.records?.filter(
                (request: any) => request.status === licenseRequestStatusData.INPROGRESS)?.length 
                && listIdRequest?.length > 0) ? true : false
              } 
              onChange={(e) => handleCheckAll(e)} 
              className='company-request-checkbox' 
            />,
            key: 'no',
            dataIndex: 'no',
            width: 60
        },
        {
          title: '会社名',
          dataIndex: 'company',
          key: 'company',
          width: 388
        },
        {
          title: '数量',
          dataIndex: 'quantity',
          key: 'quantity',
          width: 230
        },
        {
          title: '合計価格(円)',
          key: 'price',
          dataIndex:'price',
          width:230
        },
        {
          title: '開始日',
          key: 'start_time',
          dataIndex:'start_time',
          width:230
        },
        {
          title: '終了日',
          key: 'end_time',
          dataIndex:'end_time',
          width:230
        },
        {
          title: '支払方法',
          key: 'payment_method',
          dataIndex:'payment_method',
          width:230
        },
        {
          title: '代理店',
          key: 'agency',
          dataIndex:'agency',
          width:140
        },
        {
          title: '',
          key: 'action',
          dataIndex:'action',
          width:100
        }
      ]
    }, [listIdRequest, listLicenseRequest])
    const handleRenderAction = (status: number | undefined, idRequest: number | undefined) => {
        if(status === licenseRequestStatusData.APPROVED) {
            return <div className='status-request status-accept mx-auto'>承認済</div>
        }
        if(status === licenseRequestStatusData.REJECT) {
            return <div className='status-request status-reject mx-auto'>拒否済 </div>
        }
        if(status === licenseRequestStatusData.EXPIRED) {
          return <div className='status-request status-expired mx-auto'>無効 </div>
      }
        return (
            <div className='flex justify-center'>
                <Button 
                  onClick={() => handleOpenModalRatify( ratifyTypeData.accept, idRequest)} 
                  className='accept-icon mr-[20px]'
                >
                  <img src={acceptIcon} alt='' />
                </Button>
                <Button 
                  onClick={() => handleOpenModalRatify(ratifyTypeData.reject, idRequest)} 
                  className='reject-icon'
                >
                  <img src={rejectIcon} alt='' />
                </Button>
            </div>
        )  
    }

    const handleChangeCheckBox = (event: any) => {
        if(event.target.checked) {
          setListIdRequest((idRequest: any) => [...idRequest,event.target.value])
        }else {
          setListIdRequest((idRequest: any) => idRequest.filter((id: any) => id !==  event.target.value))
        }
    }

    const handleCheckAll = (event: any) => {
      if(event.target.checked) {
        setListIdRequest(listLicenseRequest?.records?.filter(
          (request: any) => request.status === licenseRequestStatusData.INPROGRESS
        )?.map((request: any) => request?.id))
      }else {
        setListIdRequest([])
      }
    }

    const dataSource = useMemo(() => {
      if (listLicenseRequest) {
        return listLicenseRequest?.records?.map((license: licenseCreateType, index: number) => {
          return ({
            ...license,
            key: license?.id,
            price: license?.price?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            no: <Checkbox 
              disabled={license?.status !== licenseRequestStatusData.INPROGRESS} 
              checked={listIdRequest?.find((id: number) => id === license?.id) ? true : false} 
              onChange={(e) => handleChangeCheckBox(e)} value={license?.id} 
              className='company-request-checkbox' 
            />,
            company: (
              <span className='site-name line-1'>{license?.company}</span>
            ),
            payment_method: (
              <span>
                {
                  paymentType.find((type: {value: number, label: string}) => type.value === license?.payment_method)?.label
                }
              </span>
            ),
            agency: (
              <span className='line-1'>{license?.agency}</span>
            ),
            action: (
                handleRenderAction(license?.status, license?.id)
            ),
          })
        })
      }
      return []
    }, [listLicenseRequest, listIdRequest])

    const handleChangeKeyword = (value:string) => {
      setKeyword(value)
    }

    const iconSearch = () => {
      return <div className='p-[5px] cursor-pointer' onClick={handleSearch}><img src={searchIcon} alt='' /></div>
    }

    const handleChangeSizePage = (value: number) => {
      setPage(1)
      setSizePage(value)
      setListIdRequest([])
    }

    const handleChangePage = (page: number) => {
      setPage(page)
      setListIdRequest([])
    }

    const handleSearch = () => {
        setPage(1)
        setKeywordSearch(keyword?.trim())
        setKeyword(keyword?.trim())
        setListIdRequest([])
    }

    const renderSelectIcon = () => {
      return <img src={iconSelect} alt='' />
    }

    const handleChangeStatus = (status: number) => {
      setStatus(status)
      setListIdRequest([])
      setPage(1)
    }

    const handleCancel = () => {
        setIsVisibleModalConfirm(false)
    }

    const {isLoading: isloadingRatify, mutate: onRatifyRequest } = useMutation(
      (param: any) => ratifyLicenseRequest(param),
      {
        onSuccess: async() => {
          message.success(
            (ratifyType === ratifyTypeData.accept || ratifyType === ratifyTypeData.acceptMultipleRequests) ?
             '承認されました' 
             : '拒否されました'
            )
          setIsVisibleModalConfirm(false)
          setPage(1)
          setListIdRequest([])
          await queryClient.invalidateQueries(QUERY_KEY.LIST_REQUEST_LICENSE)
        },
        onError: (error: any) => {
          if (error.response?.status === 400) {
              message.error(error.response.data.messages[0])
              return
          }
          message.error('システムエラー')
        },
      },
    )


    const handleOpenModalRatify = (ratifyType: number | undefined, id?: number | undefined) => {
      setIsVisibleModalConfirm(true)
      setIdRatify(id)
      setRatifyType(ratifyType)
    }

    const handleRatifyRequest = () => {
      let params
      switch(ratifyType) {
        case ratifyTypeData.accept:
          params = {
            status: licenseRequestStatusData.APPROVED,
            ids: [...[],idRatify]
          }
          break;
        case ratifyTypeData.reject:
          params = {
            status: licenseRequestStatusData.REJECT,
            ids: [...[],idRatify]
          }
          break;
        case ratifyTypeData.acceptMultipleRequests:
          params = {
            status: licenseRequestStatusData.APPROVED,
            ids: listIdRequest
          }
          break;
        case ratifyTypeData.rejectMultipleRequests:
          params = {
            status: licenseRequestStatusData.REJECT,
            ids: listIdRequest
          }
          break;
        default:
      }
      onRatifyRequest(params)
    }

    const handleChangePayment = (payment: number) => {
      setPage(1)
      setPaymentMethod(payment)
      setListIdRequest([])
    }
    
    return (
        <div className='page-content company-page'>
            <div className='mb-[16px]'>
                <h2 className='page-content-title border-bottom-title'>ライセンス申請一覧 </h2>
            </div>
            <div className='page-content-table'>
              <div className='flex justify-between items-center p-[16px]'>
                    <div className='flex items-center'>
                            <Input 
                                onPressEnter={handleSearch} 
                                className='input-search mr-[8px]' 
                                placeholder='会社名を入力' 
                                suffix={iconSearch()} 
                                onChange={(e) => handleChangeKeyword(e.target.value)}
                                value={keyword}
                            />
                            <Select 
                                options={licenseRequestStatus}
                                placeholder="状態"
                                className='company-select mr-[8px]'
                                allowClear={true}
                                onChange={(value: number) => handleChangeStatus(value)}
                                suffixIcon={renderSelectIcon()}
                            />
                            <Select 
                              options={paymentType}
                              placeholder="支払方法"
                              className='company-select'
                              allowClear={true}
                              onChange={(value: number) => handleChangePayment(value)}
                              suffixIcon={renderSelectIcon()}
                            />
                    </div>
                    <div className='flex'>
                        <Button 
                          onClick={() => handleOpenModalRatify(ratifyTypeData.rejectMultipleRequests)} 
                          className='reject-button mr-[16px]' 
                          disabled={listIdRequest?.length > 0 ? false : true}
                        >
                            拒否済 
                        </Button>
                        <Button 
                          onClick={() => handleOpenModalRatify(ratifyTypeData.acceptMultipleRequests)} 
                          className='button-add accept-button' 
                          disabled={listIdRequest?.length > 0 ? false : true}
                        >
                            承認済
                        </Button>
                    </div>
              </div>
              <div className='constuction-list'>
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    loading={isLoading}
                    locale={{
                      emptyText: (
                        <TableNoteNoData />
                      )
                    }}
                  />
              </div>
              <div className='py-[20px] px-[12px] flex justify-between items-center'>
                <div className='pagination-infor'>
                  {listLicenseRequest?.limitFrom} - {listLicenseRequest?.limitTo} 件表示/全 {listLicenseRequest?.totalRow} 件中
                </div>
                <div className='flex'>
                  <Pagination 
                    current={page} 
                    total={listLicenseRequest?.totalRow} 
                    className='pagination-main mr-[20px]' 
                    showSizeChanger={false}
                    pageSize={sizePage}
                    onChange={(page) => handleChangePage(page)}
                  />
                  <Select
                      defaultValue={10}
                      style={{ width: 153 }}
                      className='pagination-options'
                      onChange={(e) => handleChangeSizePage(e)}
                      options={pageSizeList}
                  />
                </div>
              </div>
            </div>
          <ModalConfirm 
            isShowModal={isVisibleModalConfirm}
            onCancel={handleCancel}
            onOk={handleRatifyRequest}
            content={(ratifyType === ratifyTypeData.accept || ratifyType === ratifyTypeData.acceptMultipleRequests) ? 'この申請を承認します。よろしいですか？' : 'この申請を拒否します。よろしいですか？'}
            loadingButtonOk={isloadingRatify}
            className={isloadingRatify ? "loading-modal" : ""}
            isIcon={false}
          />
        </div>
    )
}

export default LicenseRequestListComponent