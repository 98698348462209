
import React from 'react'
import LicenseInvoiceComponent from 'src/components/features/payment/licenseInvoiceComponent'
const LicenseInvoicePage = () => {
    return (
        <div className='payment'>
            <LicenseInvoiceComponent />
        </div>
    )
}

export default LicenseInvoicePage