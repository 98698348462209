import { Button, Modal } from "antd"
import closeIcon from 'src/assets/images/close-circle.png'
import confirmIcon from 'src/assets/images/confirm-circle.png'
interface ModalConfirmType {
    isShowModal: boolean,
    onOk: () => void,
    onCancel: () => void,
    content: string,
    loadingButtonOk?: boolean,
    subContent?: string,
    isIcon?: boolean,
    className?: string,
    width?: number
}
const ModalConfirm = ({isShowModal, onOk , onCancel, content, loadingButtonOk, subContent, isIcon, className, width}: ModalConfirmType) => {
    const handleClickButtonOk = () => {
        onOk()
    }
    const handleClickButtonCancel = () => {
        onCancel()
    }
    return (
        <Modal 
            title="" 
            open={isShowModal} 
            width={width ? width : 451}
            className={`delete-Modal ${className}`}
            footer={false}
            closable={false}
            centered={true}
        >
            <img src={closeIcon} alt="" className="close-modal cursor-pointer" onClick={handleClickButtonCancel}/>
            {
                isIcon &&  <img src={confirmIcon} alt="" className="mx-auto mb-[16px]"/>
            }
            <p className='text-center text-[20px] text-[#272122] font-medium whitespace-pre-line mb-[8px]'>{content}</p>
            <p className="text-center mb-[44px] text-[#2E2E2E]">{subContent}</p>
            <div className='footer-form flex'>
                <Button className='button-form mr-[16px]' onClick={handleClickButtonCancel}>キャンセル</Button>
                <Button loading={loadingButtonOk} className='button-form button-form-Submit' onClick={handleClickButtonOk}>
                    OK
                </Button>
            </div>
        </Modal>
    )
}

export default ModalConfirm