import { useQuery } from 'react-query'
import { api } from 'src/api/axios'
import { QUERY_KEY } from '../query-key'


export const useListFileByType = (idConstructionZone:number,typeFile?: number) => useQuery(
  [QUERY_KEY.LIST_FILE_BY_TYPE],
  async () => {
    const res = await api.get(`/api/admin/construction-zone/${idConstructionZone}/ar-file?type=${typeFile || ''}`)
    return res.data.data
  },
  {
    retry: 0,
  }
)
