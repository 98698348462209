import axios from 'axios'
import { getCookie } from 'src/utils/cookie'

export const api = axios.create({
  baseURL: process.env.REACT_APP_API,
  timeout: 120000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Methods': 'GET, POST',
    'Access-Control-Allow-Headers': 'Content-Type, *',
  },
})

export const apiResBlob = axios.create({
  baseURL: process.env.REACT_APP_API,
  timeout: 120000,
  responseType: "blob",
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Methods': 'GET, POST',
    'Access-Control-Allow-Headers': 'Content-Type, *',
  },
})



export const setHeaderAuthorization = (token: string) => {
  api.defaults.headers.common.Authorization = `Bearer ${token}`
}

export const setToken = (token: string, id: string) => {
  setHeaderAuthorization(token)
}

export const clearToken = () => setToken('', '')

apiResBlob.interceptors.request.use((config) => {
  config.headers!.Authorization = `Bearer ${getCookie('token')}`
  return config
}, (error) => Promise.reject(error))

api.interceptors.request.use((config) => {
  config.headers!.Authorization = `Bearer ${getCookie('token')}`
  return config
}, (error) => Promise.reject(error))

api.interceptors.response.use((response) => {
  if (response.status === 401) {
    clearToken()
    window.location.href = '/login'
  }

  if(response.status === 403) {
    window.location.href = '/forbidden'
  }

  if(response.status === 404) {
    window.location.href = '/404'
  }
  return response
}, (error) => {
  if (error.response.status === 401) {
    clearToken()
    window.location.href = '/login'
  }

  if(error.response.status === 403) {
    window.location.href = '/forbidden'
  }

  if(error.response.status === 404) {
    window.location.href = '/404'
  }
  
  if (error?.message) {
    if (error?.message.includes('Invalid token')) {
      clearToken()
      window.location.href = '/login'
    }
  }
  return Promise.reject(error)
})