/* eslint-disable react-hooks/exhaustive-deps */
import 'moment/locale/ja';
import locale from 'antd/es/date-picker/locale/ja_JP';
import { Button, Input, Pagination, Select, Table, DatePicker } from 'antd'
import React, { useMemo, useState } from 'react'
import searchIcon from 'src/assets/images/search-icon.png'
import addIcon from 'src/assets/images/add-icon.png'
import constructionViewIcon from 'src/assets/images/view-icon.png'
import { useHistory } from 'react-router-dom'
import type { ColumnsType } from 'antd/es/table';
import {companyInAdminStatus, pageSizeList } from 'src/constants'
import iconDatePicker from 'src/assets/images/icon-calendar.png'
import { useListRequestCompany } from 'src/api/data/agency/useListRequestCompany';
import { getClassStatusCompanyInAdmin, handleGetStatusCompanyInAdmin} from 'src/utils';
import { companyCreateType } from 'src/components/features/agency/type';
import iconSelect from 'src/assets/images/arrow-down-2.png'
import moment from 'moment';
import TableNoteNoData from 'src/components/common/tableNoteNoData';
import editIcon from 'src/assets/images/edit-icon.png'
interface DataType {
    key: string;
    name: string;
    location: string;
    updated_at: string;
}

const columns: ColumnsType<DataType> = [
    {
        title: 'ID',
        key: 'no',
        dataIndex: 'no',
        width: 60
    },
    {
        title: '会社名',
        dataIndex: 'name',
        key: 'name',
        width: 388
    },
    {
        title: 'メールアドレス',
        dataIndex: 'email',
        key: 'email',
        width: 388
    },
    {
        title: '電話番号',
        key: 'phone',
        dataIndex:'phone',
        width:160
    },
    {
        title: '代表者',
        key: 'presentative',
        dataIndex:'presentative',
        width:160
    },
    {
        title: '登録日',
        key: 'approved_at',
        dataIndex:'approved_at',
        width:160
    },
    {
        title: '代理店名',
        key: 'agency',
        dataIndex:'agency',
        width:160
    },
    {
        title: '状態',
        key: 'status',
        dataIndex:'status',
        width:140
    },
    {
        title: () => {
          return <p className='text-center text-[16px] text-[#2E2E2E]'>アクション</p>
        },
        key: 'action',
        dataIndex:'action',
        width:100
    }
]

const { RangePicker } = DatePicker;

const CompanyListComponent = () => {
    const isRequest = false
    const history = useHistory()
    const [keyword, setKeyword] = useState<string>('')
    const [keywordSearch, setKeywordSearch] = useState<string>('')
    const [agencyName, setAgencyName] = useState<string>('')
    const [agencyNameSearch, setAgencyNameSearch] = useState<string>('')
    const [status, setStatus] = useState<number | undefined>()
    const [date, setDate] = useState<string>('')
    const [dateValue, setDateValue] = useState<any>('')
    const [page, setPage] = useState<number>(1)
    const [sizePage, setSizePage] = useState<number>(10)
    const { data: listCompanyRequest, isLoading } = useListRequestCompany(keywordSearch,status,date, page, sizePage, agencyNameSearch, isRequest)
    const dataSource = useMemo(() => {
      if (listCompanyRequest) {
        return listCompanyRequest?.records?.map((company: companyCreateType, index: number) => ({
          ...company,
          key: company?.id,
          no: company?.id,
          name: (
            <span onClick={() =>handleGoCompanyDetail(company?.id)} className='site-name line-1 cursor-pointer'>{company?.name}</span>
          ),
          email: (
            <span className='line-1 break-all'>{company?.email}</span>
          ),
          presentative: (
            <span className='line-1 break-all'>{company?.presentative}</span>
          ),
          agency: (
            <span className='line-1 break-all'>{company?.agency?.name}</span>
          ),
          created_at: (
            <span className='whitespace-nowrap'>{company?.created_at}</span>
          ),
          status: (
            <span className={`company-request-status inline-block ${getClassStatusCompanyInAdmin(company?.status)}`}>
              {
                 handleGetStatusCompanyInAdmin(company?.status)
              }
            </span>
          ),
          action: (
            <div className='flex justify-center'>
              <img 
                className='item-name-icon cursor-pointer mr-[10px]' 
                src={constructionViewIcon} 
                alt=""
                onClick={() =>handleGoCompanyDetail(company?.id)}
              />
              <img 
                className='item-name-icon cursor-pointer mr-[10px]' 
                src={editIcon} 
                onClick={() =>handleGoEditCompany(company?.id)}
                alt=""
              />
            </div>
          ),
        }))
      }
      return []
    }, [listCompanyRequest])

    const handleChangeKeyword = (value:string) => {
      setKeyword(value)
    }

    const iconSearch = () => {
      return <div className='p-[5px] cursor-pointer' onClick={handleSearch}><img src={searchIcon} alt='' /></div>
    }

    const iconSearchAgencyName = () => {
        return <div className='p-[5px] cursor-pointer' onClick={handleSearchAgencyName}><img src={searchIcon} alt='' /></div>
      }

    const handleChangeSizePage = (value: number) => {
      setPage(1)
      setSizePage(value)
    }

    const handleChangePage = (page: number) => {
      setPage(page)
    }

    const handleGoCompanyDetail = (id: number | undefined) => {
      history.push(`/cms/company/${id}`)
    }

    const handleSearch = () => {
        setPage(1)
        setKeywordSearch(keyword?.trim())
        setKeyword(keyword?.trim())
    }

    const handleSearchAgencyName = () => {
        setPage(1)
        setAgencyNameSearch(agencyName?.trim())
        setAgencyName(agencyName?.trim())
    }

    const handleGoToCompanyCreatePage = () => {
        history.push('/cms/company/create')
    }

    const renderIconDatePicker = () => {
        return <img src={iconDatePicker} alt='' />
    }

    const renderSeparator = () => {
        return <span>~</span>
    }

    const renderSelectIcon = () => {
      return <img src={iconSelect} alt='' />
    }

    const handleChangeStatus = (status: number) => {
      setStatus(status)
      setPage(1)
    }

    const handleChangeAgencyName = (value:string) => {
        setAgencyName(value)
      }

      const handleGoEditCompany = (id: number | undefined) => {
        history.push(`/cms/company/edit/${id}`)
      }
    
    const handleCalendarChange = (date: any) => {
      setDateValue(date)
      const startDate = date?.[0] == null ? '1970/01/01' : moment(date?.[0])?.format('YYYY-MM-DD')
      const endDate = date?.[1] == null ? moment(date?.[0])?.add(1200,'M').format('YYYY-MM-DD') : moment(date?.[1])?.format('YYYY-MM-DD')
      const dateParam = startDate + ' ~ ' + endDate
      setPage(1)
      if(date) {
        setDate(dateParam)
      }else {
        setDate('')
      }
      
    }
    return (
        <div className='page-content company-page'>
            <div className='mb-[16px]'>
                <h2 className='page-content-title border-bottom-title'>会社一覧</h2>
            </div>
            <div className='page-content-table'>
              <div className='flex justify-between items-center p-[16px]'>
                    <div className='flex items-center'>
                            <Input 
                                onPressEnter={handleSearch} 
                                className='input-search mr-[8px]' 
                                placeholder='会社名を入力' 
                                suffix={iconSearch()} 
                                onChange={(e) => handleChangeKeyword(e.target.value)}
                                value={keyword}
                            />
                            <Input 
                                onPressEnter={handleSearchAgencyName} 
                                className='input-search mr-[8px]' 
                                placeholder='代理店名を入力' 
                                suffix={iconSearchAgencyName()} 
                                onChange={(e) => handleChangeAgencyName(e.target.value)}
                                value={agencyName}
                            />
                            <Select 
                                options={companyInAdminStatus}
                                placeholder="状態"
                                className='company-select'
                                allowClear={true}
                                onChange={(value: number) => handleChangeStatus(value)}
                                suffixIcon={renderSelectIcon()}
                            />
                            <RangePicker 
                                className={`ml-[8px] range-picker-search`}
                                suffixIcon={renderIconDatePicker()}
                                placeholder={['yyyy-mm-dd', 'yyyy-mm-dd']}
                                separator={renderSeparator()}
                                locale={locale}
                                onCalendarChange={handleCalendarChange}
                                format={['YYYY-MM-DD', 'YYYY-MM-DD']}
                                value={dateValue}
                                inputReadOnly={true}
                            />
                    </div>
                    <Button className='button-add' onClick={() => {handleGoToCompanyCreatePage()}}>
                        <img className='button-add-icon' src={addIcon} alt='' />
                        会社作成
                    </Button>
              </div>
              <div className='constuction-list'>
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    loading={isLoading}
                    locale={{
                      emptyText: (
                        <TableNoteNoData />
                      )
                    }}
                  />
              </div>
              <div className='py-[20px] px-[12px] flex justify-between items-center'>
                <div className='pagination-infor'>
                  {listCompanyRequest?.limitFrom} - {listCompanyRequest?.limitTo} 件表示/全 {listCompanyRequest?.totalRow} 件中
                </div>
                <div className='flex'>
                  <Pagination 
                    current={page} 
                    total={listCompanyRequest?.totalRow} 
                    className='pagination-main mr-[20px]' 
                    showSizeChanger={false}
                    pageSize={sizePage}
                    onChange={(page) => handleChangePage(page)}
                  />
                  <Select
                      defaultValue={10}
                      style={{ width: 153 }}
                      className='pagination-options'
                      onChange={(e) => handleChangeSizePage(e)}
                      options={pageSizeList}
                  />
                </div>
              </div>
            </div>
        </div>
    )
}

export default CompanyListComponent