import { Spin } from "antd"
import { createContext, useState, useContext } from "react"
import { useParams } from "react-router-dom"
import { useConstructionDetail } from "src/api/data/construction/useConstructionDetail"
const initialState = {
    constructionDetail: {
        company_name: "",
        created_at: "",
        id: 0,
        location: "",
        name: "",
        orderer_name: "",
        updated_at: ""
    },
    isValueForm: false,
    setIsValueForm: () => {}
}
type TypeConstructionContext = {
    isValueForm: boolean,
    constructionDetail: any,
    setIsValueForm: (value: boolean) => void
}
export const ConstructionDetailContext = createContext<TypeConstructionContext>(initialState)
type ProviderProps = {
    children: React.ReactNode
}
export const useConstructionDetailContext = () => {
    return useContext(ConstructionDetailContext) as TypeConstructionContext
}
export const ConstructionDetailProvider: React.FC<ProviderProps> = ({children}) => {
    const { id } = useParams<{ id: string }>()
    const { data: constructionDetail, isLoading } = useConstructionDetail(Number(id))
    const [isValueForm, setIsValueForm] = useState<boolean>(false);
    return (
        <ConstructionDetailContext.Provider value={{isValueForm, constructionDetail, setIsValueForm}}>
            {
                isLoading ? <Spin>
                {children}
            </Spin>
            :  children
            }
            
        </ConstructionDetailContext.Provider>
    )
}