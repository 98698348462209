import { useQuery } from 'react-query'
import { api } from 'src/api/axios'
import { QUERY_KEY } from '../query-key'
export const useLicenseTermInfor = () => useQuery(
    [QUERY_KEY.LICENSE_TERM_INFOR],
    async () => {
      const res = await api.get('/api/admin/license-info', {
      })
      return res.data.data
    },
  )
  