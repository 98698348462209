import { Button, Form, Input, Spin, message } from 'antd';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import leftArrow from 'src/assets/images/back-icon.png';
import { companyCreateType } from 'src/components/features/agency/type';
import { createFaq, editFaq } from 'src/api/admin';
import TextArea from 'antd/lib/input/TextArea';
import { useDetailFaq } from 'src/api/data/admin/useDetailFaq';
import { useDetailCategory } from 'src/api/data/admin/useDetailCategory';
import ModalConfirm from 'src/components/common/modal/modalConfirm';

const FaqCreateEditComponent = () => {
  const location = useLocation();
  const [antForm] = Form.useForm();
  const history = useHistory();
  const { id, idFaq } = useParams<{ id: string; idFaq: string }>();
  const queryClient = useQueryClient();
  const [isDisableSubmit, setIsDisableSubmit] = useState(true);
  const [isValueForm, setIsValueForm] = useState(false);
  const [isVisibleModelConfirmBack, setIsVisibleModelConfirmBack] = useState(false);

  const { data: detailCategory } = useDetailCategory(Number(id));
  const { data: detailData, isLoading: isLoadingDetail } = useDetailFaq(Number(idFaq));

  const { isLoading: isloading, mutate: onCreateFaq } = useMutation((param: companyCreateType) => createFaq(param), {
    onSuccess: (res) => {
      message.success('データ更新成功');
      history.push(`/cms/categories/${id}`);
      antForm.resetFields();
    },
    onError: (error: any) => {
      if (error.response?.status === 400) {
        message.error(error.response.data.messages[0]);
        return;
      }
      message.error('システムエラー');
    },
  });

  const { isLoading: isloadingEdit, mutate: onEditFaq } = useMutation(
    (param: companyCreateType) => editFaq(param, Number(idFaq)),
    {
      onSuccess: (res) => {
        message.success('データ更新成功');
        history.push(`/cms/categories/${id}`);
        antForm.resetFields();
        queryClient.clear();
      },
      onError: (error: any) => {
        if (error.response?.status >= 400) {
          message.error(error.response.data.messages[0]);
          return;
        }
        message.error('システムエラー');
      },
    }
  );

  const handleCreateFaq = async () => {
    await trimmedValue();
    try {
      await antForm.validateFields();
    } catch {
      return;
    }
    const valueForm = antForm.getFieldsValue();
    const params = {
      ...valueForm,
      category_id: Number(id),
    };
    if (idFaq) {
      onEditFaq(params);
    } else {
      onCreateFaq(params);
    }
  };

  const handleChangeValue = (allvalues: []) => {
    const listValue = Object.keys(allvalues)?.map((key: any) => allvalues[key]);
    const validateErrorValueList = [];
    listValue?.forEach((value: any) => {
      if (value === undefined || value === '') {
        validateErrorValueList.push(value);
      }
    });
    if (validateErrorValueList.length > 0) {
      setIsDisableSubmit(true);
    } else {
      setIsDisableSubmit(false);
    }

    listValue?.some((value: any) => {
      if (value !== undefined && value !== '' && value != null) {
        setIsValueForm(true);
        return true;
      }
      setIsValueForm(false);
      return false;
    });
  };

  useEffect(() => {
    if (idFaq) {
      setIsDisableSubmit(false);
    }
  }, [idFaq]);

  const handleGoBackFaqList = () => {
    history.push(`/cms/categories/${id}`);
  };

  const handleBackFaqList = () => {
    if (isValueForm) {
      setIsVisibleModelConfirmBack(true);
    } else {
      history.push(`/cms/categories/${id}`);
    }
  };

  const handleCancel = () => {
    setIsVisibleModelConfirmBack(false);
  };

  const trimmedValue = async () => {
    const allValueForm = antForm.getFieldsValue();
    Object.keys(allValueForm).forEach((key: any) => {
      if (typeof allValueForm[key] === typeof '') {
        antForm.setFieldsValue({ [key]: allValueForm[key]?.trim() });
      }
    });
  };

  return (
    <div className="construction-create p-[16px]">
      <h2 className="page-content-title border-bottom-title mb-[30px] flex items-center">
        <img src={leftArrow} alt="" className="inline-block mr-[17px] cursor-pointer" onClick={handleBackFaqList} />

        <span onClick={handleBackFaqList} className="cursor-pointer">
          {detailCategory?.ja_name} / {!location.pathname.includes('edit') ? 'FAQ追加' : 'FAQ編集'}
        </span>
      </h2>

      {isLoadingDetail ? (
        <Spin />
      ) : (
        <Form
          form={antForm}
          onValuesChange={(value, allvalues) => handleChangeValue(allvalues)}
          className="form-create-faq form-create"
        >
          <div className="px-[20px] py-[12px]">
            <div className="language text-base font-bold leading-8 tracking-wider mb-2">日本語</div>

            <div className="mb-[16px]">
              <label className="labe-form">
                質問内容 <span>*</span>
              </label>
              <Form.Item
                name="ja_title"
                rules={[
                  {
                    validator: (rule, value, callback, length: number = 255) => {
                      if (!value?.trim()) {
                        callback('質問内容を入力してください');
                      } else if (length && value?.trim()?.length > length) {
                        callback('質問内容は255文字以内で入力してください');
                      } else {
                        callback();
                      }
                    },
                  },
                ]}
                initialValue={detailData?.ja_title}
              >
                <Input className="input-form" />
              </Form.Item>
            </div>

            <div className="mb-[16px]">
              <label className="labe-form">
                回答内容 <span>*</span>
              </label>
              <Form.Item
                name="ja_content"
                rules={[
                  {
                    validator: (rule, value, callback, length: number = 10000) => {
                      if (!value?.trim()) {
                        callback('回答内容を入力してください');
                      } else if (length && value?.trim()?.length > length) {
                        callback('回答内容は10000文字以内で入力してください');
                      } else {
                        callback();
                      }
                    },
                  },
                ]}
                initialValue={detailData?.ja_content}
              >
                <TextArea className="input-form input_form_textarea" />
              </Form.Item>
            </div>
          </div>

          <div className="px-[20px] py-[12px]">
            <div className="language text-base font-bold leading-8 tracking-wider mb-2">英語</div>

            <div className="mb-[16px]">
              <label className="labe-form">
                質問内容（英語） <span>*</span>
              </label>
              <Form.Item
                name="en_title"
                rules={[
                  {
                    validator: (rule, value, callback, length: number = 255) => {
                      if (!value?.trim()) {
                        callback('質問内容（英語）を入力してください');
                      } else if (length && value?.trim()?.length > length) {
                        callback('質問内容（英語）は255文字以内で入力してください');
                      } else {
                        callback();
                      }
                    },
                  },
                ]}
                initialValue={detailData?.en_title}
              >
                <Input className="input-form" />
              </Form.Item>
            </div>

            <div className="mb-[16px]">
              <label className="labe-form">
                回答内容（英語） <span>*</span>
              </label>
              <Form.Item
                name="en_content"
                rules={[
                  {
                    validator: (rule, value, callback, length: number = 10000) => {
                      if (!value?.trim()) {
                        callback('回答内容（英語）を入力してください');
                      } else if (length && value?.trim()?.length > length) {
                        callback('回答内容（英語）は10000文字以内で入力してください');
                      } else {
                        callback();
                      }
                    },
                  },
                ]}
                initialValue={detailData?.en_content}
              >
                <TextArea className="input-form input_form_textarea" />
              </Form.Item>
            </div>
          </div>

          <div className="footer-form flex">
            <Button className="button-form mr-[16px]" onClick={handleBackFaqList}>
              戻る
            </Button>
            <Button
              disabled={isDisableSubmit}
              className="button-form button-form-Submit"
              loading={isloading || isloadingEdit}
              onClick={handleCreateFaq}
            >
              <span></span>
              {location.pathname?.includes('edit') ? '保存' : '追加'}
            </Button>
          </div>
        </Form>
      )}

      <ModalConfirm
        isShowModal={isVisibleModelConfirmBack}
        onCancel={handleCancel}
        onOk={handleGoBackFaqList}
        content="このページを離れますか？"
        subContent="ページから移動すると、保存されていない変更は失われます。"
      />
    </div>
  );
};

export default FaqCreateEditComponent;
