import React from 'react'
import LicenseRequestListComponent from 'src/components/features/superAdmin/licenseRequestListComponent'
import { LayoutSuperAdmin } from 'src/components/layouts/layoutSuperAdmin'

const LicenseRequestListPage = () => (
    <LayoutSuperAdmin>
       <LicenseRequestListComponent />
    </LayoutSuperAdmin>
)

export default LicenseRequestListPage