import React from 'react'
import './index.modules.scss'
import HeaderAgency from '../common/layouts/headerAgency'
type LayoutProps = {
    children: React.ReactNode
}
export const LayoutAgency: React.FC<LayoutProps> = ({ children }) => {
    return (
        <>
            <HeaderAgency />
            <div className='flex layout civil-content-page'>
            <div className='w-full'>
                {children}
            </div>
            </div>
        </>
    )
}
