import React from 'react'
import CreateConstructionSiteComponent from 'src/components/features/constructionSite/createComponent'
import { LayoutConstruction } from 'src/components/layouts/layoutConstruction'

const CreateConstructionSite = () => (
    <LayoutConstruction>
       <CreateConstructionSiteComponent />
    </LayoutConstruction>
)

export default CreateConstructionSite