/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input, Pagination, Select, Table, message } from 'antd'
import React, {  useMemo, useState } from 'react'
import './constuction.modules.scss'
import constructionSiteIcon from 'src/assets/images/construction-site-icon.png'
import searchIcon from 'src/assets/images/search-icon.png'
import addIcon from 'src/assets/images/add-icon.png'
import constructionViewIcon from 'src/assets/images/view-icon.png'
import { useHistory } from 'react-router-dom'
import type { ColumnsType } from 'antd/es/table';
import { useConstructionList } from 'src/api/data/construction/useConstructionLists'
import deleteIcon from 'src/assets/images/delete-icon.png'
import editIcon from 'src/assets/images/edit-icon.png'
import { useMutation, useQueryClient } from 'react-query'
import { deleteConstruction } from 'src/api/construction'
import { QUERY_KEY } from 'src/api/data/query-key'
import ModalDelete from 'src/components/common/modalDelete'
import TableNoteNoData from 'src/components/common/tableNoteNoData'
import { pageSizeList } from 'src/constants'
interface DataType {
    key: string;
    name: string;
    location: string;
    updated_at: string;
}

const columns: ColumnsType<DataType> = [
    {
      title: '工事名',
      dataIndex: 'name',
      key: 'name',
      width: 550
    },
    {
      title: '工事所在地',
      dataIndex: 'location',
      key: 'location',
      render: (location) => (
        <p className='text-[#272122] text-[16px] line-1'>{location}</p>
      ),
      width: 350
    },
    {
      title: '更新日時',
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: 300
    },
    {
      title: 'アクション',
      key: 'action',
      dataIndex:'action',
      width:150
    }
]
const ConstructionSiteComponent = () => {
    const history = useHistory()
    const [keyword, setKeyword] = useState<string>('')
    const [keywordSearch, setKeywordSearch] = useState<string>('')
    const [page, setPage] = useState<number>(1)
    const [sizePage, setSizePage] = useState<number>(10)
    const { data: constructionLists, isLoading } = useConstructionList(keywordSearch, page, sizePage)
    const queryClient = useQueryClient()
    const [isShowModalDeleteConstruction, setIsShowModalDeleteConstruction] = useState(false)
    const [idContructionDelete, setIdContructionDelete] = useState(0)
    const dataSource = useMemo(() => {
      if (constructionLists) {
        return constructionLists?.records?.map((construction: any) => ({
          ...construction,
          key: construction.id,
          name: (
            <div className='inline-flex items-center cursor-pointer' onClick={() => handleGoConstructionDetail(construction.id)}>
                <img className='item-name-icon mr-[8px]' src={constructionSiteIcon} alt="" />
                <p className='site-name line-1 break-all'>{construction.name}</p>
            </div>
          ),
          action: (
            <div className='flex'>
              <img className='item-name-icon cursor-pointer mr-[10px]' src={constructionViewIcon} alt="" onClick={() => handleGoConstructionDetail(construction.id)}/>
              <img className='item-name-icon cursor-pointer mr-[10px]' src={editIcon} alt="" onClick={() => handleEditConstruction(construction.id)}/>
              <img className='item-name-icon cursor-pointer' src={deleteIcon} alt="" onClick={() => handleOpenModalDeleteConstruction(construction.id)}/>
            </div>
          ),
        }))
      }
      return []
    }, [constructionLists])

    const handleChangeKeyword = (value:string) => {
      setKeyword(value)
    }

    const {mutate: onDelete, isLoading: isloadingDelete } = useMutation(
      (id: number) => deleteConstruction(id),
      {
        onSuccess: async() => {
          await handlingWhenDeletingAllCurrentPageRecords()
          setIsShowModalDeleteConstruction(false)
          message.success('削除に成功しました')
        },
        onError: (error: any) => {
          if (error.response?.status === 400) {
              message.error(error.response.data.messages[0])
              return
          }
          message.error('システムエラー')
        },
      },
    )

    const handlingWhenDeletingAllCurrentPageRecords = async() => {
      if(page > 1 && constructionLists?.currentRow === 1) {
        setPage(page - 1)
      }else {
        queryClient.invalidateQueries(QUERY_KEY.CONSTRUCTION_LIST)
      }
    }
    
    const handleOpenModalDeleteConstruction = (id: number) => {
      setIdContructionDelete(id)
      setIsShowModalDeleteConstruction(true)
    }

    const handleDeleteConstruction = () => {
      onDelete(idContructionDelete)
    }

    const handleCancelDelete = () => {
      setIsShowModalDeleteConstruction(false)
    }

    const iconSearch = () => {
      return <div className='p-[5px] cursor-pointer' onClick={handleSearchConstruction}><img src={searchIcon} alt='' /></div>
    }

    const handleChangeSizePage = (value: number) => {
      setPage(1)
      setSizePage(value)
    }

    const handleChangePage = (page: number) => {
      setPage(page)
    }

    const handleGoConstructionDetail = (id: number) => {
      history.push(`/construction-site/detail/${id}`)
    }

    const handleEditConstruction = (id: number) => {
      history.push(`/construction-site/edit/${id}`)
  }

  const handleSearchConstruction = () => {
    setPage(1)
    setKeywordSearch(keyword)
  }
    
    return (
        <div className='page-content'>
            <div className='mb-[16px]'>
                <h2 className='page-content-title border-bottom-title'>工事一覧</h2>
            </div>
            <div className='page-content-table'>
              <div className='flex justify-between items-center p-[16px]'>
                  <Input 
                    onPressEnter={handleSearchConstruction} 
                    className='input-search' 
                    placeholder='工事名もしくは会社名を入力' 
                    suffix={iconSearch()} 
                    onChange={(e) => handleChangeKeyword(e.target.value)}
                  />
                  <Button className='button-add' onClick={() => {history.push('/construction-site/create')}}>
                      <img className='button-add-icon' src={addIcon} alt='' />
                      新規工事作成
                  </Button>
              </div>
              <div className='constuction-list'>
                  <Table
                  columns={columns}
                  dataSource={dataSource}
                  pagination={false}
                  loading={isLoading}
                  locale={{
                    emptyText: (
                      <TableNoteNoData />
                    )
                  }}
                  />
              </div>
              <div className='py-[20px] px-[12px] flex justify-between items-center'>
                <div className='pagination-infor'>
                  {constructionLists?.limitFrom} - {constructionLists?.limitTo} 件表示/全 {constructionLists?.totalRow} 件中
                </div>
                <div className='flex'>
                  <Pagination 
                    current={page} 
                    total={constructionLists?.totalRow} 
                    className='pagination-main mr-[20px]' 
                    showSizeChanger={false}
                    pageSize={sizePage}
                    onChange={(page) => handleChangePage(page)}
                  />
                  <Select
                      defaultValue={10}
                      style={{ width: 153 }}
                      className='pagination-options'
                      onChange={(e) => handleChangeSizePage(e)}
                      options={pageSizeList}
                  />
                </div>
              </div>
            </div>
            <ModalDelete 
              loadingButtonOk={isloadingDelete}
              isShowModal={isShowModalDeleteConstruction}
              onCancel={handleCancelDelete}
              onDelete={handleDeleteConstruction}
              content='この工事を削除します。よろしいですか？'
            />
        </div>
    )
}

export default ConstructionSiteComponent