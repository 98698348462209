/* eslint-disable react-hooks/exhaustive-deps */
import './agency.modules.scss'
import { Button, Input, Pagination, Select, Table } from 'antd'
import React, { useMemo, useState } from 'react'
import searchIcon from 'src/assets/images/search-icon.png'
import addIcon from 'src/assets/images/add-icon.png'
import constructionViewIcon from 'src/assets/images/view-icon.png'
import { useHistory } from 'react-router-dom'
import type { ColumnsType } from 'antd/es/table';
import { licenseRequestStatus, pageSizeList, paymentType } from 'src/constants'
import { getClassStatusLicense, handleGetStatusLicense } from 'src/utils';
import {  licenseCreateType } from 'src/components/features/agency/type';
import { useListRequestLicense } from 'src/api/data/agency/useListRequestLicense';
import TableNoteNoData from 'src/components/common/tableNoteNoData'
import iconSelect from 'src/assets/images/arrow-down-2.png'
interface DataType {
    key: string;
    name: string;
    location: string;
    updated_at: string;
}

const columns: ColumnsType<DataType> = [
    {
        title: 'ID',
        key: 'no',
        dataIndex: 'no',
        width: 60
    },
    {
        title: '会社名',
        dataIndex: 'company',
        key: 'company',
        width: 388
    },
    {
        title: '数量',
        dataIndex: 'quantity',
        key: 'quantity',
        width: 230
    },
    {
        title: '合計価格(円)',
        key: 'price',
        dataIndex:'price',
        width:230
    },
    {
        title: '開始日',
        key: 'start_time',
        dataIndex:'start_time',
        width:230
    },
    {
      title: '終了日',
      key: 'end_time',
      dataIndex:'end_time',
      width:230
    },
    {
      title: '支払方法',
      key: 'payment_method',
      dataIndex:'payment_method',
      width:230
    },
    {
      title: '状態',
      key: 'status',
      dataIndex:'status',
      width:140
    },
    {
      title: () => {
        return <p className='text-center text-[16px] text-[#2E2E2E]'>アクション</p>
      },
      key: 'action',
      dataIndex:'action',
      width:100
    }
]
const LicenseListComponent = () => {
    const history = useHistory()
    const [keyword, setKeyword] = useState<string>('')
    const [keywordSearch, setKeywordSearch] = useState<string>('')
    const [status, setStatus] = useState<number | undefined>()
    const [paymentMethod, setPaymentMethod] = useState<number | undefined>()
    const [page, setPage] = useState<number>(1)
    const [sizePage, setSizePage] = useState<number>(10)
    const { data: listLicenseRequest, isLoading } = useListRequestLicense(keywordSearch,status, page, sizePage, paymentMethod)
    const dataSource = useMemo(() => {
      if (listLicenseRequest) {
        return listLicenseRequest?.records?.map((license: licenseCreateType, index: number) => ({
          ...license,
          key: license?.id,
          no: license?.id,
          price: license?.price?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          company: (
            <span onClick={() =>handleGoLicenseDetail(license?.id)} className='site-name line-1 cursor-pointer'>{license?.company}</span>
          ),
          start_time: (
            <span className='whitespace-nowrap'>
              {license?.start_time}
            </span>
          ),
          end_time: (
            <span className='whitespace-nowrap'>
              {license?.end_time}
            </span>
          ),
          payment_method: (
            <span className='whitespace-nowrap'>
              {
                paymentType.find((type: {value: number, label: string}) => type.value === license?.payment_method)?.label
              }
            </span>
          ),
          status: (
            <span className={`company-request-status whitespace-nowrap inline-block ${getClassStatusLicense(license?.status)}`}>
              {
                handleGetStatusLicense(license?.status)
              }
            </span>
          ),
          action: (
            <div className='flex justify-center' onClick={() =>handleGoLicenseDetail(license?.id)}>
              <img className='item-name-icon cursor-pointer mr-[10px]' src={constructionViewIcon} alt=""/>
            </div>
          ),
        }))
      }
      return []
    }, [listLicenseRequest])

    const handleChangeKeyword = (value:string) => {
      setKeyword(value)
    }

    const iconSearch = () => {
      return <div className='p-[5px] cursor-pointer' onClick={handleSearchConstruction}><img src={searchIcon} alt='' /></div>
    }

    const handleChangeSizePage = (value: number) => {
      setPage(1)
      setSizePage(value)
    }

    const handleChangePage = (page: number) => {
      setPage(page)
    }

    const handleGoLicenseDetail = (id: number | undefined) => {
      history.push(`/agency/license/${id}`)
    }

    const handleSearchConstruction = () => {
        setPage(1)
        setKeywordSearch(keyword)
    }

    const handleGoToLicenseCreatePage = () => {
        history.push('/agency/license/create')
    }

    const handleChangeStatus = (status: number) => {
      setPage(1)
      setStatus(status)
    }

    const handleChangePayment = (payment: number) => {
      setPage(1)
      setPaymentMethod(payment)
    }

    const renderSelectIcon = () => {
      return <img src={iconSelect} alt='' />
    }

    return (
        <div className='page-content company-page'>
            <div className='mb-[16px]'>
                <h2 className='page-content-title border-bottom-title'>ライセンス申請一覧 </h2>
            </div>
            <div className='page-content-table'>
              <div className='flex justify-between items-center p-[16px]'>
                    <div className='flex items-center'>
                      <Input 
                          onPressEnter={handleSearchConstruction} 
                          className='input-search mr-[8px]' 
                          placeholder='会社名を入力' 
                          suffix={iconSearch()} 
                          onChange={(e) => handleChangeKeyword(e.target.value)}
                      />
                      <Select 
                          options={licenseRequestStatus}
                          placeholder="状態"
                          className='company-select mr-[8px]'
                          allowClear={true}
                          onChange={(value: number) => handleChangeStatus(value)}
                          suffixIcon={renderSelectIcon()}
                      />
                      <Select 
                          options={paymentType}
                          placeholder="支払方法"
                          className='company-select'
                          allowClear={true}
                          onChange={(value: number) => handleChangePayment(value)}
                          suffixIcon={renderSelectIcon()}
                      />
                    </div>
                    <Button className='button-add' onClick={() => {handleGoToLicenseCreatePage()}}>
                        <img className='button-add-icon' src={addIcon} alt='' />
                        ライセンス申請作成
                    </Button>
              </div>
              <div className='constuction-list'>
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    loading={isLoading}
                    locale={{
                      emptyText: (
                        <TableNoteNoData />
                      )
                    }}
                  />
              </div>
              <div className='py-[20px] px-[12px] flex justify-between items-center'>
                <div className='pagination-infor'>
                  {listLicenseRequest?.limitFrom} - {listLicenseRequest?.limitTo} 件表示/全 {listLicenseRequest?.totalRow} 件中
                </div>
                <div className='flex'>
                  <Pagination 
                    current={page} 
                    total={listLicenseRequest?.totalRow} 
                    className='pagination-main mr-[20px]' 
                    showSizeChanger={false}
                    pageSize={sizePage}
                    onChange={(page) => handleChangePage(page)}
                  />
                  <Select
                      defaultValue={10}
                      style={{ width: 153 }}
                      className='pagination-options'
                      onChange={(e) => handleChangeSizePage(e)}
                      options={pageSizeList}
                  />
                </div>
              </div>
            </div>
        </div>
    )
}

export default LicenseListComponent