
import { api } from "./axios";

export const createLocationSite = (params: FormData) => api.post(
    `/api/admin/construction-zone`,
    params,
)

export const deleteFileLocationSite = (id: number) => api.delete(
    `/api/admin/construction-zone/ar-file/${id}`
)

export const addOrEditFileLocationSite = (params: FormData) => api.post(
    `/api/admin/construction-zone/ar-file`,
    params,
)

export const overloadManagement = (idConstruction: number,params: FormData) => api.post(
    `/api/admin/construction-zone/${idConstruction}/overload`,
    params,
)

export const uploadTonnageMixing = (idConstruction: number,params: FormData) => api.post(
    `/api/admin/construction-zone/${idConstruction}/tonnage-mixing`,
    params,
)

export const uploadPunchie = (idConstructionZone: number,params: FormData) => api.post(
    `/api/admin/construction-zone/${idConstructionZone}/punch`,
    params,
)

export const editPunchie = (idConstructionZone: number,idPunchie: number,params: any) => api.put(
    `/api/admin/construction-zone/${idConstructionZone}/punch/${idPunchie}`,
    params,
)

export const deletePunchie = (idConstructionZone: number,id: number | undefined) => api.delete(
    `/api/admin/construction-zone/${idConstructionZone}/punch/${id}`
)

export const duplicatePunchie = (id : number) => api.post(
    `/api/admin/duplicate/punch/${id}`
)

export const deleteLocationSite = (id?: number) => api.delete(
    `/api/admin/construction-zone/${id}`
)

export const editNameLocationSite  = (idLocationSite: number,params: {name: string}) => api.put(
    `/api/admin/construction-zone/${idLocationSite}`,
    params,
)
