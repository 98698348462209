import { accCompanyStatus, accCompanyStatusData, companyInAdminStatus, companyInAdminStatusData, companyRequestManagementStatus, licenseRequestStatus, licenseRequestStatusData, notiStatus, notiStatusData, staffAgencyStatus, staffAgencyStatusData } from 'src/constants';
export const findRepeatedObjects = (array: []) => {
    const repeatedObjects:any = [];
    array?.forEach((obj: any) => {
        if(!repeatedObjects?.find((item: any) => item.y === obj.y)) {
            repeatedObjects.push(obj)
        }
    }); 
  
    return repeatedObjects;
}
 
export const handleZoomCanvas = (canvas: any, updateMiniMapVP?: any) => {
  let isDragging: any
  let lastPosX: any
  let lastPosY: any
  let spaceKey: any

  document.onkeydown = function(e) {
    if (e.keyCode === 32 && e.target === document.body) {
      spaceKey = true
      canvas?.getObjects().forEach(function (object: any) {
        object.selectable = false; 
      });
      e.preventDefault()
    }
  } 

  document.onkeyup = function(e) {
    if (e.keyCode === 32 && e.target === document.body) {
      spaceKey = false
      canvas?.getObjects().forEach(function (object: any) {
        object.selectable = true; 
      });
      e.preventDefault()
    }
  }

  canvas?.on('mouse:down', function(opt: any) {
    var evt = opt.e;
    if (spaceKey === true) { 
      isDragging = true;
      lastPosX = evt.clientX;
      lastPosY = evt.clientY;
    }
  });

  canvas?.on('mouse:move', function(opt: any) {
    if (isDragging) {
      var e = opt.e;
      var vpt = canvas.viewportTransform;
      vpt[4] += e.clientX - lastPosX;
      vpt[5] += e.clientY - lastPosY;
      canvas.requestRenderAll();
      updateMiniMapVP()
      lastPosX = e.clientX;
      lastPosY = e.clientY;
    }
  });
  canvas?.on('mouse:up', function(opt: any) {
    canvas.setViewportTransform(canvas.viewportTransform);
    isDragging = false;
  });

  canvas?.on('mouse:wheel', function(opt: any) {
    if (opt.e.ctrlKey) {
      var delta = opt.e.deltaY;
      var zoom = canvas.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.01) zoom = 0.01;
      canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      updateMiniMapVP()
      opt.e.preventDefault();
      opt.e.stopPropagation();
    }

  });
}

export const handleGetStatusRequestCompany = (statusId: number | undefined) => {
    return accCompanyStatus?.find((status) => status.value === statusId)?.label
}

export const getClassStatusRequestCompany = (statusId: number | undefined) => {
  switch(statusId) {
    case accCompanyStatusData.REJECT:
      return 'reject-color'
    case accCompanyStatusData.APPROVED:
      return 'approved-color'
    default:
      return 'inprogress-color'
  }
}

export const handleGetStatusStaffAgency = (statusId: number | undefined) => {
  return staffAgencyStatus?.find((status) => status.value === statusId)?.label
}

export const getClassStatusStaffAgency = (statusId: number | undefined) => {
  switch(statusId) {
    case staffAgencyStatusData.ACTIVE:
      return 'approved-color'
    case staffAgencyStatusData.INACTIVE:
      return 'reject-color'
    default:
      return 'reject-color'
  }
}

export const handleGetStatusNoti = (statusId: number | undefined) => {
  return notiStatus?.find((status) => status.value === statusId)?.label
}

export const getClassStatusNoti = (statusId: number | undefined) => {
  switch(statusId) {
    case notiStatusData.ACTIVE:
      return 'approved-color'
    case notiStatusData.INACTIVE:
      return 'reject-color'
    default:
      return 'reject-color'
  }
}


export const handleGetStatusLicense = (statusId: number | undefined) => {
  return licenseRequestStatus?.find((status) => status.value === statusId)?.label
}

export const getClassStatusLicense = (statusId: number | undefined) => {
  switch(statusId) {
    case licenseRequestStatusData.REJECT:
      return 'reject-color'
    case licenseRequestStatusData.APPROVED:
      return 'approved-color'
    case licenseRequestStatusData.EXPIRED:
      return 'expired-color'
    default:
      return 'inprogress-color'
  }
}


export const handleGetStatusCompanyInAdmin = (statusId: number | undefined) => {
  return companyInAdminStatus?.find((status) => status.value === statusId)?.label
}

export const getClassStatusCompanyInAdmin = (statusId: number | undefined) => {
  switch(statusId) {
    case companyInAdminStatusData.REJECT:
      return 'reject-color'
    case companyInAdminStatusData.APPROVED:
      return 'approved-color'
    default:
      return 'approved-color'
  }
}


export const handleGetCompanyRequestManagementStatus = (statusId: number | undefined) => {
  return companyRequestManagementStatus?.find((status) => status.value === statusId)?.label
}

export const getClassCompanyRequestManagementStatus  = (statusId: number | undefined) => {
switch(statusId) {
  case accCompanyStatusData.REJECT:
    return 'reject-color'
  case accCompanyStatusData.APPROVED:
    return 'approved-color'
  case accCompanyStatusData.InActive:
    return 'approved-color'
  default:
    return 'inprogress-color'
}
}

export const trimmedValue = async(antForm: any) => {
  const allValueForm = antForm.getFieldsValue()
  Object.keys(allValueForm).forEach((key: any) => {
      if(typeof allValueForm[key] === typeof '') {
          antForm.setFieldsValue({ [key]: allValueForm[key]?.trim() })
      }
  })
}