
import inforIcon from 'src/assets/images/info-circle-white.png'
import zonesIcon from 'src/assets/images/house-icon.png'
import imgSlideLP1 from 'src/assets/images/landingPage/ar_img1.png'
import imgSlideLP2 from 'src/assets/images/landingPage/ar_img2.png'
import imgSlideLP3 from 'src/assets/images/landingPage/ar_img3.png'
export const DEFAULT_PONCHIE_WIDTH = 1200
export const PADDING_BOTTOM_CANVAS = 20
export const listMenus = [
    {
        id:1,
        name: '工事基本情報',
        icon: inforIcon,
        path: '/construction-site/detail'
    },
    {
        id:2,
        name: '工区一覧',
        icon: zonesIcon,
        path: '/location-site'
    },
]

export const listMenuDetails = [
    {
        id:1,
        name: '工区ファイル一覧',
        path: '/list-file'
    },
    {
        id:4,
        name: '過積載管理設定',
        path: '/overload-management'
    },
    {
        id:5,
        name: '合材トン数設定',
        path: '/mix-tonnage-setting'
    }
]

export const listTypeFileConstuctionZone = [
    {
        id: 1,
        type: '点群'
    },
    {
        id: 10,
        groupType:  {
            firstId: 2,
            firstType: '線形SIMA',
            secondId: 3,
            secondType: '縦横断SIMA'
        },
    },
    {
        id: 6,
        type: '一般SIMA'
    },
    {
        id: 4,
        type: '切削厚さ'
    },
    {
        id: 5,
        type: '舗設厚さ'
    },
]

export const typeFileSimaBaseConstuctionZone = [
    {
        id: 2,
        type: '線形SIMA'
    },
    {
        id: 3,
        type: '縦横断SIMA'
    },
    {
        id: 6,
        type: '舗設厚さ'
    },
]

export const listTypeNameFileConstuctionZone = {
    POINT_FILES: 1,
    SIM_LINEAR_FILES: 2,
    SIM_SLICE_FILES: 3,
    CUTTING_THICKNESS_FILES: 4,
    PAVING_THICKNESS_FILES: 5,
    SIM_COMMON: 6
}

export const direction = {
    LEFT: 1,
    RIGHT: 2,
}

export const punchieUploadType = {
    PUNCH_PICTURE: 1,
    PUNCH_CUTTING: 2,
    PUNCH_PAVEMENT: 3,
}

export const listTypePunchieConstuctionZone = [
    {
        id: 1,
        type: 'ポンチ絵'
    },
    {
        id: 2,
        type: '切削順序図'
    },
    {
        id: 3,
        type: '舗設順序図'
    }
]

export const role = {
    SYSTEM_ADMIN: 1,
    ADMIN: 2,
    AGENCY: 3,
    AGENCY_STAFF: 4,
    COMPANY: 5,
    COMPANY_STAFF: 6
}

export const menuAgency = [
    {
        id:1,
        name: '会社申請',
        path: '/agency/company'
    },
    {
        id:2,
        name: 'ライセンス申請',
        path: '/agency/license'
    },
    {
        id:3,
        name: '担当者一覧',
        path: '/agency/staff'
    }
]

export const menuAgencyStaff = [
    {
        id:1,
        name: '会社申請',
        path: '/agency/company'
    },
    {
        id:2,
        name: 'ライセンス申請',
        path: '/agency/license'
    },
]

export const accCompanyStatus = [
    {
        value:3,
        label: '承認済',
        title: ''
    },
    {
        value:1,
        label: '進行中',
        title: ''
    },
    {
        value:2,
        label: '拒否済',
        title: ''
    },
]

export const accCompanyStatusData = {
    INPROGRESS: 1,
    REJECT: 2,
    APPROVED: 3,
    InActive: 4
}


export const detailRequestCompany = [
    {
        label: '会社名',
        name: 'name',
        id: 1,
    },
    {
        label: '代表者',
        name: 'presentative',
        id: 2
    },
    {
        label: '住所',
        name: 'address',
        id: 4
    },
    {
        label: 'メールアドレス',
        name: 'email',
        id: 5
    },
    {
        label: '電話番号',
        name: 'phone',
        id: 6
    },
]

export const pageSizeList = [
    {
        value: 10,
        label: '10 件表示',
    },
    {
        value: 15,
        label: '15 件表示',
    },
    {
        value: 20,
        label: '20 件表示',
    },
    {
        value: 25,
        label: '25 件表示',
    },
]


export const staffAgencyStatus = [
    {
        value:3,
        label: '有効',
        title: ''
    },
    {
        value:4,
        label: '無効',
        title: ''
    },
]

export const staffAgencyStatusData = {
    ACTIVE: 3,
    INACTIVE: 4,
}

export const detailStaffAgency = [
    {
        label: '担当者コード',
        name: 'membership_code',
        id: 1,
    },
    {
        label: '担当者名',
        name: 'name',
        id: 2
    },
    {
        label: 'メールアドレス',
        name: 'email',
        id: 3
    },
    {
        label: '電話番号',
        name: 'phone',
        id: 4
    },
    {
        label: '有効',
        name: 'status',
        id: 5
    },
]

export const detailStaffCompany = [
    {
        label: '会員コード',
        name: 'membership_code',
        id: 1,
    },
    {
        label: '会員名',
        name: 'name',
        id: 2
    },
    {
        label: 'メールアドレス',
        name: 'email',
        id: 3
    },
    {
        label: '電話番号',
        name: 'phone',
        id: 4
    },
    {
        label: '有効',
        name: 'status',
        id: 5
    },
]

export const detailCompanyOfLicense = [
    {
        label: '会社名',
        name: 'name',
        id: 1,
    },
    {
        label: 'メールアドレス',
        name: 'email',
        id: 2
    },
    {
        label: '代表者',
        name: 'presentative',
        id: 3
    },
    {
        label: '電話番号',
        name: 'phone',
        id: 4
    },
]

export const menuSuperAdmin = [
    {
        id:1,
        name: '会社',
        path: '/cms/company'
    },
    {
        id:2,
        name: '担当者',
        path: '/cms/agency'
    },
    {
        id:3,
        name: '会社申請',
        path: '/cms/request/company'
    },
    {
        id:4,
        name: 'ライセンス申請',
        path: '/cms/request/license'
    },
    {
        id:5,
        name: '管理者',
        path: '/cms/staff'
    },
    {
        id:6,
        name: 'FAQ管理',
        path: '/cms/categories'
    },
    {
        id:7,
        name: 'お知らせ管理',
        path: '/cms/notifications'
    }
]

export const menuAdmin = [
    {
        id:1,
        name: '会社',
        path: '/cms/company'
    },
    {
        id:2,
        name: '担当者',
        path: '/cms/agency'
    },
    {
        id:3,
        name: '会社申請',
        path: '/cms/request/company'
    },
    {
        id:4,
        name: 'ライセンス申請',
        path: '/cms/request/license'
    },
]

export const menuCompany = [
    {
        id:4,
        name: '工事管理',
        path: '/construction-site'
    },
    {
        id:5,
        name: '会員',
        path: '/company/staff'
    },
    {
        id:6,
        name: 'ライセンス管理',
        path: '/company/license'
    }
]

export const licenseRequestStatusData = {
    INPROGRESS: 1,
    APPROVED: 2,
    REJECT: 3,
    EXPIRED: 4,
}

export const licenseRequestStatus = [
    {
        value:2,
        label: '承認済',
        title: ''
    },
    {
        value:1,
        label: '承認待ち',
        title: ''
    },
    {
        value:3,
        label: '拒否済',
        title: ''
    },
    {
        value:4,
        label: '無効',
        title: ''
    },
]


export const detailAgencyAdmin = [
    {
        label: '代理店名',
        name: 'name',
        id: 1,
    },
    {
        label: '電話番号',
        name: 'phone',
        id: 2
    },
    {
        label: '代表者',
        name: 'presentative_name',
        id: 3
    },
    {
        label: 'メールアドレス ',
        name: 'email',
        id: 4
    },
    {
        label: '住所',
        name: 'address',
        id: 7
    },
    {
        label: '有効',
        name: '',
        id: 6
    },
]

export const companyInAdminStatusData = {
    REJECT: 4,
    APPROVED: 3
}


export const companyInAdminStatus = [
    {
        value:3,
        label: '有効',
        title: ''
    },
    {
        value:4,
        label: '無効',
        title: ''
    },
]

export const detailCompanyAdmin = [
    {
        label: '会社名',
        name: 'name',
        id: 1,
    },
    {
        label: 'メールアドレス ',
        name: 'email',
        id: 2
    },
    {
        label: '代表者',
        name: 'presentative_name',
        id: 3
    },
    {
        label: '代理店名',
        name: 'agency',
        id: 6
    },
    {
        label: '住所',
        name: 'address',
        id: 7
    },
]

export const paymentType = [
    {
        value:1,
        label: 'クレジットカード',
    },
    {
        value:2,
        label: '銀行振込',
    },
]

export const paymentTypeData = {
    ACTIVE: 1,
    INACTIVE: 2,
}

export const companyRequestManagementStatus = [
    {
        value:3,
        label: '承認済',
        title: ''
    },
    {
        value:1,
        label: '承認待ち',
        title: ''
    },
    {
        value:2,
        label: '拒否済',
        title: ''
    },
    {
        value:4,
        label: '承認済',
        title: ''
    },
]

export const companyRequestStatus = [
    {
        value:5,
        label: '承認済',
        title: ''
    },
    {
        value:1,
        label: '承認待ち',
        title: ''
    },
    {
        value:2,
        label: '拒否済',
        title: ''
    },
]

export const redirectTypeData = {
    DETAIL: 1,
    LIST: 2,
}

export const UNIT_CANVAS = 10

export const imageSlideLandingPage = [imgSlideLP2, imgSlideLP3, imgSlideLP1];

export const notiStatus = [
    {
        value: 1,
        label: '公開',
        title: ''
    },
    {
        value: 0,
        label: '非公開',
        title: ''
    },
]

export const notiStatusData = {
    ACTIVE: 1,
    INACTIVE: 0,
}

export const staffCompanyAccountProfile = [
    {
        id: 1,
        title: '会社名',
        key: 'parent',
        subKey: 'name'
    },
    {
        id: 2,
        title: '会員コード',
        key: 'membership_code'
    },
    {
        id: 3,
        title: 'ユーザー名',
        key: 'name'
    },
    {
        id: 4,
        title: 'メールアドレス',
        key: 'email'
    },
    {
        id: 5,
        title: '電話番号',
        key: 'phone'
    }
]

export const staffAgencyProfile = [
    {
        id: 1,
        title: '代理店名',
        key: 'parent',
        subKey: 'name'
    },
    {
        id: 2,
        title: '会員コード',
        key: 'membership_code'
    },
    {
        id: 3,
        title: 'ユーザー名',
        key: 'name'
    },
    {
        id: 4,
        title: 'メールアドレス',
        key: 'email'
    },
    {
        id: 5,
        title: '電話番号',
        key: 'phone'
    }
]

export const staffAdminProfile = [
    {
        id: 1,
        title: '会社名',
        key: 'parent',
        subKey: 'name'
    },
    {
        id: 2,
        title: '管理者コード',
        key: 'membership_code'
    },
    {
        id: 3,
        title: '管理者名',
        key: 'name'
    },
    {
        id: 4,
        title: 'メールアドレス',
        key: 'email'
    },
    {
        id: 5,
        title: '電話番号',
        key: 'phone'
    }
]

export const superAdminAccountProfile = [
    {
        id: 3,
        title: '会社名',
        key: 'name'
    },
    {
        id: 4,
        title: 'メールアドレス',
        key: 'email'
    },
    {
        id: 5,
        title: '電話番号',
        key: 'phone'
    }
]

export const agencyAccountProfile = [
    {
        id: 3,
        title: '代理店名',
        key: 'name'
    },
    {
        id: 4,
        title: 'メールアドレス',
        key: 'email'
    },
    {
        id: 5,
        title: '電話番号',
        key: 'phone'
    }
]

export const companyAccountProfile = [
    {
        id: 3,
        title: '会社名',
        key: 'name'
    },
    {
        id: 4,
        title: 'メールアドレス',
        key: 'email'
    },
    {
        id: 5,
        title: '電話番号',
        key: 'phone'
    }
]


export const paddingPunch = 140