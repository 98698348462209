import { Form, InputNumber, Select } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { FileByType } from './type'

const CuttingDiagramSetting = ({form, overloadManagementData, listFileThickness}:any) => {
    const [quantityCars, setQuantityCars] = useState(1)

    const handleChangeQuantityCards = (quantity: any) => {
        if(quantity) {
            setQuantityCars(quantity?.toFixed(0));
        }else {
            setQuantityCars(1);
        }
    }

    const optionFileThickness = useMemo(() => {
        return listFileThickness?.map((file: FileByType) => (
            {
                value: file.id,
                label:file.file_name
            }
        ))
    },[listFileThickness])


    useEffect(() => {
        if(overloadManagementData?.vehicle_loads?.length > 0) {
            setQuantityCars(overloadManagementData?.vehicle_loads?.length)
        }else {
            setQuantityCars(1)
        }
    },[overloadManagementData?.vehicle_loads])

    const listVehicle: any = useMemo(() => {
        const convertListVehicle = overloadManagementData?.vehicle_loads?.reduce((a: any, v:number,i: number) => ({ ...a, [`vehicle_loads[${i}]`]: v}), {})  
        return {
            ...convertListVehicle,
        }
    },[overloadManagementData?.vehicle_loads])



    const validateNumberFields = (rule: any, value: number, callback: any, name: string, min: number) => {
        if (!value && value !== 0) {
            callback(`${name}を入力してください`);
        }else if(value <= min) {
            callback(`${name}は${min}より大きい値を入力してください `)
        }else {
            callback()
        }
    }

    const validateQuantityCars = (rule: any, value: number, callback: any) => {
        if(value > 100) {
            callback(`台数を1～100の範囲で入力してください`)
        }else {
            callback()
        }
    }

    useEffect(() => {
        if(optionFileThickness) {
            const isAgencyStaffExists = optionFileThickness?.find((file: any) => file.value === overloadManagementData?.cutting_thickness_file)
            if(isAgencyStaffExists) {
                form.setFieldValue('cutting_thickness_file', overloadManagementData?.cutting_thickness_file)
            }else {
                form.setFieldValue('cutting_thickness_file', null)
            }
        }
    },[form, optionFileThickness, overloadManagementData])

    const renderNotFoundContent = () => {
        return <p className='text-center p-[10px]'>データがありません</p>
    }

    useEffect(() => {
        form.setFieldValue('quantity_cars', quantityCars)
    },[form, quantityCars])

    return (
        <>
            <Form form={form}>
                <div className='setting_diagram'>
                    <div className='border-bottom-lighter'>
                        <h4 className='p-[12px] text-[16px] font-bold text-[#2E2E2E]'>混合物密度</h4>
                        <div className='amount-mixture'>
                            <span className='mr-[12px] mr-[26px] text-[16px] text-[#2E2E2E] whitespace-nowrap mt-[8px]'>混合物密度</span> 
                            <div className='relative'>
                                <Form.Item 
                                    initialValue={overloadManagementData?.amount_of_mixture} 
                                    name='amount_of_mixture' 
                                    rules={
                                        [                                    
                                            { validator: (rule, value, callback) => validateNumberFields(rule,value,callback, '混合物密度', 0)}
                                        ]
                                    }
                                >
                                    <InputNumber 
                                        min={0} 
                                        className='input__tonnage-total mr-[24px]' 
                                    />
                                </Form.Item>
                                <span className='text-[16px] text-[#2E2E2E] unit-input'>トン/m³</span>
                            </div>
                        </div>
                    </div>
                    <div className='border-bottom-lighter'>
                        <h4 className='p-[12px] text-[16px] font-bold text-[#2E2E2E]'>ダンプ１台毎のトン数設定</h4>
                        <div className='px-[12px] py-[8px] flex justify-'>
                            <span className='mr-[13px] text-[16px] text-[#2E2E2E] mt-[8px]'>台数</span> 
                                <Form.Item 
                                    name={'quantity_cars'} 
                                    initialValue={quantityCars}
                                    rules={
                                        [                                    
                                            { validator: (rule, value, callback) => validateQuantityCars(rule,value,callback)}
                                        ]
                                    }
                                >
                                    <InputNumber 
                                        onChange={(quantity) => handleChangeQuantityCards(quantity)}
                                        min={1} 
                                        value={quantityCars} 
                                        className='input__tonnage-total mr-[13px]' 
                                    />
                                </Form.Item>
                            <span className='text-[16px] text-[#2E2E2E] mt-[8px]'>台</span>
                        </div>
                        <div className='overload__tonnage text-[16px] text-[#091E42] p-[8px] pl-[60px]'>
                            <ul>
                                {
                                    quantityCars  && Number(quantityCars) <= 100 &&
                                    Array.from(Array(Number(quantityCars)), (e, i) => (
                                        <li className='text-[#2E2E2E] mb-[16px] flex relative' key={i}>
                                            <span className='mr-[24px] mt-[8px]'>{i + 1}台目</span> 
                                            <Form.Item 
                                                initialValue={listVehicle[`vehicle_loads[${i}]`]} 
                                                name={`vehicle_loads[${i}]`} 
                                                rules={
                                                    [                                    
                                                        { validator: (rule, value, callback) => validateNumberFields(rule,value,callback, 'ダンプ台のトン数', 0)}
                                                    ]
                                                }
                                            >
                                                <InputNumber min={0} className='input__tonnage' /> 
                                            </Form.Item>
                                            <span className='unit-input'>トン</span>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div>
                        <h4 className='p-[12px] text-[16px] font-bold text-[#2E2E2E]'>切削厚さ</h4>
                        <Form.Item 
                        name='cutting_thickness_file' 
                        initialValue={overloadManagementData?.cutting_thickness_file}
                        className='p-[12px]'
                        >
                            <Select
                                options={optionFileThickness}
                                notFoundContent={renderNotFoundContent()}
                            />
                        </Form.Item> 
                    </div>
                </div>
            </Form>
        </>
    )
}

export default CuttingDiagramSetting