import React from 'react'
import { useHistory } from 'react-router-dom'
import forbiddenImg from 'src/assets/images/forbidden-img.png'
import logo from 'src/assets/images/logo.png'
const ForbiddenPage = () => {
    const history = useHistory()
    const onGoToHomepage = () => {
        history.push('/')
    }
    return (
        <>
            <div id='header' className='flex justify-between header'>
                <div className='flex items-center cursor-pointer' onClick={onGoToHomepage}>
                    <img className='header__icon mr-[13px]' src={logo} alt='logo'/>
                    <h1 className='font-bold text-[24px] text-[#253858] whitespace-nowrap cursor-pointer'>助さん</h1>
                </div>
            </div>
            <div className='civil-content-page flex items-center justify-center flex-col'>
                <div>
                    <p className='text-center font-bold text-[28px] text-[#2E2E2E]'>現在、このアカウントにはライセンスが付与されていません。</p>
                    <p className='text-center font-bold text-[28px] text-[#2E2E2E] mb-[40px]'>引き続き使用するには代理店にお問い合わせください。</p>
                    <img src={forbiddenImg} alt='' />
                </div>
            </div>
        </>
    )
}

export default ForbiddenPage