import { api } from './axios';

export interface createContactUsType {
  company: string;
  full_name: string;
  email: string;
  phone_number: string;
  inquiry: string;
  lang?: string;
}

export const createContactUs = (params: createContactUsType) => api.post(`/api/contact-us`, params);
