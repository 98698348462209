const CmsIcon = ({ className }: any) => (
  <svg className={className} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.5 0.75C6.5 0.335786 6.83579 0 7.25 0H14.75C15.1642 0 15.5 0.335786 15.5 0.75V3.75C15.5 4.16421 15.1642 4.5 14.75 4.5H7.25C6.83579 4.5 6.5 4.16421 6.5 3.75V0.75ZM8 1.5V3H14V1.5H8Z"
      fill="black"
    ></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.5 3.75C0.5 3.33579 0.835786 3 1.25 3H20.75C21.1642 3 21.5 3.33579 21.5 3.75V17.25C21.5 17.6642 21.1642 18 20.75 18H1.25C0.835786 18 0.5 17.6642 0.5 17.25V3.75ZM2 4.5V16.5H20V4.5H2Z"
      fill="black"
    ></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.5 3.75C0.5 3.33579 0.835786 3 1.25 3H20.75C21.1642 3 21.5 3.33579 21.5 3.75V9C21.5 9.35751 21.2477 9.66532 20.8971 9.73544L13.3971 11.2354C12.9909 11.3167 12.5958 11.0533 12.5146 10.6471C12.4333 10.2409 12.6967 9.8458 13.1029 9.76457L20 8.38515V4.5H2V8.38515L8.89709 9.76457C9.30326 9.8458 9.56667 10.2409 9.48544 10.6471C9.4042 11.0533 9.00908 11.3167 8.60291 11.2354L1.10291 9.73544C0.752345 9.66532 0.5 9.35751 0.5 9V3.75Z"
      fill="black"
    ></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.75 9C8.75 8.58579 9.08579 8.25 9.5 8.25H12.5C12.9142 8.25 13.25 8.58579 13.25 9V12C13.25 12.4142 12.9142 12.75 12.5 12.75H9.5C9.08579 12.75 8.75 12.4142 8.75 12V9ZM10.25 9.75V11.25H11.75V9.75H10.25Z"
      fill="black"
    ></path>
  </svg>
);

export default CmsIcon;
