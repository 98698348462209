import React from 'react'
import './index.modules.scss'
import HeaderCompanyAdmin from '../common/layouts/headerCompanyAdmin'
import { role } from 'src/constants'
import Header from '../common/layouts/header'
type LayoutProps = {
    children: React.ReactNode
}
export const LayoutConstruction: React.FC<LayoutProps> = ({ children }) => {
    return (
        <>
            {
                Number(localStorage.getItem('role')) === role.COMPANY ? <HeaderCompanyAdmin /> : <Header/>
            }
            <div className='flex layout civil-content-page'>
                <div className='w-full'>
                    {children}
                </div>
            </div>
        </>
    )
}
