import { useState } from 'react';
import './faqItem.modules.scss';
import addIcon from 'src/assets/images/landingPage/add_icon.svg';
import removeIcon from 'src/assets/images/landingPage/remove_icon.svg';
import addIconBlack from 'src/assets/images/landingPage/addIconBlack.svg';
import removeIconBlack from 'src/assets/images/landingPage/removeIconBlack.svg';
import questionIcon from 'src/assets/images/landingPage/question.svg';

const FaqItem = ({ title, content, color }: { title: string; content: string; color: string }) => {
  const [show, setShow] = useState(false);

  return (
    <div className="faq_item">
      <div className="faq_click">
        <div className="text">
          <img className="question" src={questionIcon} alt="" onClick={() => setShow(!show)} />

          <div className="main">
            <p className="main_title" onClick={() => setShow(!show)} style={{ color: '#2B2B2B' }}>
              {title}
            </p>
          </div>
        </div>

        <div className="show_hide">
          {show ? (
            <div className="show_hide_item" onClick={() => setShow(false)}>
              <img src={removeIconBlack} alt="" />
            </div>
          ) : (
            <div className="show_hide_item" onClick={() => setShow(true)}>
              <img src={addIconBlack} alt="" />
            </div>
          )}
        </div>
      </div>

      {show && (
        <p
          className={`main_content ${color === 'white' ? 'main_content_scroll' : ''}`}
          id={color === 'white' ? `style_scroll` : ''}
          style={{ color: '#7C7C7C' }}
        >
          {content}
        </p>
      )}
    </div>
  );
};

export default FaqItem;
