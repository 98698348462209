import { useHistory } from 'react-router-dom'
import leftArrow from 'src/assets/images/back-icon.png'
import avatarIcon from 'src/assets/images/avatar_default.png';
import editIcon from 'src/assets/images/edit_avatar_icon.png';
import { Form, Spin, Upload, message } from 'antd';
import { useUserInFo } from 'src/api/data/userInfo/userInfo';
import { useMutation, useQueryClient } from 'react-query';
import { editAvatar } from 'src/api/userInfor';
import { QUERY_KEY } from 'src/api/data/query-key';
import { useMemo } from 'react';
import { 
    role, 
    superAdminAccountProfile, 
    staffAdminProfile, 
    agencyAccountProfile, 
    staffAgencyProfile, 
    companyAccountProfile, 
    staffCompanyAccountProfile
} from 'src/constants';

const ProfileComponent = () => {
    const queryClient = useQueryClient()
    const history = useHistory()
    const {data, isLoading} = useUserInFo()
    const handleBack = () => {
        history.goBack()
    }

    const {mutate: onEditAvatar , isLoading: isloadingEdit} = useMutation(
        (param: FormData) => editAvatar(param),
        {
          onSuccess: async() => {
            await queryClient.invalidateQueries(QUERY_KEY.USER_INFO)
          },
          onError: (error: any) => {
            if (error.response?.status === 400) {
                message.error(error.response.data.messages[0])
                return
            }
            message.error('システムエラー')
          },
        },
    )

    const handleEditAvatar = (file: any) => {
        const typeFile = file.file.type ? file.file.type : file?.file.name.slice(file?.file.name.lastIndexOf('.'))
        const fileSize = file?.file.size / 1024 / 1024
        const typeAccept = ['image/png', 'image/jpeg', 'image/gif', '.heic']
        if(!typeAccept?.find((type:string) => type === typeFile)) {
            return message.error('アップロードするファイルは「JPG, JPEG, PNG, GIF, JFIF, HEIC」のいずれかの形式にしてください')
        }
        if(fileSize >= 1) {
            return message.error('ファイルサイズが1Mを超えています')
        }
        const newFormData = new FormData()
        newFormData.append('avatar', file.file)
        onEditAvatar(newFormData)
    }

    const handleDecodeUrl = (url: string) => {
        return decodeURIComponent(url)
    }

    const accountInfo = useMemo(() => {
        switch(data?.role) {
            case role.SYSTEM_ADMIN:
                return superAdminAccountProfile
            case role.ADMIN:
                return staffAdminProfile
            case role.AGENCY:
                return agencyAccountProfile
            case role.AGENCY_STAFF:
                return staffAgencyProfile
            case role.COMPANY:
                return companyAccountProfile
            default:
                return staffCompanyAccountProfile
          }
    },[data?.role])
    return (
        <>
            {
                isLoading ? <Spin className='position-center'/> : 
                <div>
                    <div className='construction-create p-[16px]'>
                        <h2 className='page-content-title border-bottom-title mb-[30px]'>
                            <img src={leftArrow} alt='' className='inline-block mr-[17px] cursor-pointer' onClick={handleBack}/>
                            プロフィール
                        </h2>
                    </div>
                    <div className='profile_user'>
                        <h4 className='profile_user-title'>プロフィール情報</h4>
                        <div className='flex pt-[24px]'>
                            <div className='profile_user-avatar'>
                                <div className='frame'>
                                    <Spin spinning = {isloadingEdit}>
                                        <img src={data?.avatar ? handleDecodeUrl(data?.avatar) : avatarIcon} alt='' className='avatar'/>
                                    </Spin>
                                    <Upload 
                                        accept='image/*,.heic'
                                        showUploadList={false}
                                        beforeUpload={(file) => Promise.reject(file)} 
                                        multiple={false} 
                                        maxCount={1} 
                                        onChange={(e) => handleEditAvatar(e)}
                                    >
                                        <img src={editIcon} alt='' className='icon-edit'/>
                                    </Upload>
                                </div>
                            </div>
                            <Form className='profile_user-info'>
                                {
                                    accountInfo?.map((profile: any) => {
                                        return (
                                            <div className='mb-[25px]' key={profile.id}>
                                                <label className='labe-form'>{profile.title}</label>
                                                <div className='input-form break-words'>
                                                    {
                                                      profile?.subKey ? data?.[profile.key]?.[profile?.subKey] : data?.[profile.key]
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Form>
                        </div>
                    </div>
                </div>
            }
       </>
    )
}

export default ProfileComponent