import React from 'react'
import {
  BrowserRouter, Route, Switch, Redirect
} from 'react-router-dom'
import LoginPage from 'src/pages/login'
import ConstructionSitePage from 'src/pages/constructtionSite/constructionSitePage'
import CreateConstructionSite from 'src/pages/constructtionSite/createConstructionSite'
import LocationSitePage from 'src/pages/locationSite/locationSitePage'
import LocationSiteCreatePage from 'src/pages/locationSite/locationSiteCreatePage'
import ListFileLocationPage from 'src/pages/locationSiteDetail/listFileLocationPage'
import { UserRoute } from 'src/components/common/routes/PrivateRoute'
import OverloadManagementPage from 'src/pages/locationSiteDetail/overloadManagementPage'
import MixTonnageSettingPage from 'src/pages/locationSiteDetail/mixTonnageSettingPage'
import ConstructionSiteDetailPage from 'src/pages/constructtionSite/constructionSiteDetailPage'
import PonchiePicturePage from 'src/pages/locationSiteDetail/ponchiePicturePage'
import CuttingOrderDiagramPage from 'src/pages/locationSiteDetail/cuttingOrderDiagramPage'
import CompanyListPage from 'src/pages/agency/company/companyListPage'
import CompanyCreatePage from 'src/pages/agency/company/companyCreatePage'
import CompanyDetailPage from 'src/pages/agency/company/companyDetailPage'
import LicenseListPage from 'src/pages/agency/license/licenseListPage'
import LicenseCreatePage from 'src/pages/agency/license/licenseCreatePage'
import LicenseDetailPage from 'src/pages/agency/license/licenseDetailPage'
import StaffListPage from 'src/pages/agency/staff/staffListPage'
import StaffCreatePage from 'src/pages/agency/staff/staffCreatePage'
import StaffDetailPage from 'src/pages/agency/staff/staffDetailPage'
import CompanyRequestListPage from 'src/pages/superAdmin/companyRequestListPage'
import LicenseRequestListPage from 'src/pages/superAdmin/licenseRequestListPage'
import PaymentPage from 'src/pages/payment'
import AgencyListPage from 'src/pages/superAdmin/agencyListPage'
import AgencyDetailPage from 'src/pages/superAdmin/agencyDetailPage'
import AgencyCreatePage from 'src/pages/superAdmin/agencyCreatePage'
import ForbiddenPage from 'src/pages/forbiddenPage'
import CompanyListInAdminPage from 'src/pages/superAdmin/companyListPage'
import FaqListInAdminPage from 'src/pages/superAdmin/faqListPage'
import { AdminRoute } from 'src/components/common/routes/adminRoute'
import { AgencyRoute } from 'src/components/common/routes/agencyRoute'
import CompanyDetailInAdminPage from 'src/pages/superAdmin/companyDetailPage'
import CompanyCreateEditPage from 'src/pages/superAdmin/companyCreateEditPage'
import FaqCreateEditPage from 'src/pages/superAdmin/faqCreateEditPage'
import AdminStaffListPage from 'src/pages/superAdmin/adminStaffListPage'
import AdminStaffCreatePage from 'src/pages/superAdmin/adminStaffCreatePage'
import AdminStaffDetailPage from 'src/pages/superAdmin/adminStaffDetailPage'
import { CompanyAdminRoute } from 'src/components/common/routes/companyAdminRoute'
import CompanyStaffListPage from 'src/pages/companyManagement/companyStaffListPage'
import CompanyStaffCreatePage from 'src/pages/companyManagement/companyStaffCreatePage'
import CompanyStaffDetailPage from 'src/pages/companyManagement/companyStaffDetailPage'
import { role } from 'src/constants'
import LicenseManagementPage from 'src/pages/companyManagement/lincensePage'
import LandingPage from 'src/pages/landing-page'
import FaqPage from 'src/pages/faq'
import PolicyPage from 'src/pages/policy'
import CategoryListPage from 'src/pages/superAdmin/categoryListPage'
import LicenseInactivePage from 'src/pages/LicenseInactivePage'
import TermsOfServicePage from 'src/pages/payment/termsOfService'
import LicenseInvoicePage from 'src/pages/payment/licenseInvoice'
import NotifycationListPage from 'src/pages/superAdmin/notificationListPage'
import ChangePasswordPage from 'src/pages/changePasswordPage'
import ConfirmEmailPage from 'src/pages/fogotPassword/confirmEmailPage'
import ResetPasswordPage from 'src/pages/fogotPassword/resetPasswordPage'
import ResetPasswordExpriedPage from 'src/pages/fogotPassword/resetPasswordExpriedPage'
import { AuthRoute } from 'src/components/common/routes/authRoute'
import ProfilePage from 'src/pages/profile/profilePage'
import NotFoundPage from 'src/pages/notFoundPage'

const AllRoute = () => (
  <BrowserRouter>
    <Switch>
      {
        // landing-page
      }
      <Route exact path="/" component={LandingPage} />
      <Route exact path="/faq" component={FaqPage} />
      <Route exact path="/policy" component={PolicyPage} />

      <Route exact path="/login" component={LoginPage} />
      {
        // user
      }
      <UserRoute exact path="/construction-site" component={ConstructionSitePage} />
      <UserRoute exact path="/construction-site/create" component={CreateConstructionSite} />
      <UserRoute exact path="/construction-site/detail/:id" component={ConstructionSiteDetailPage} />
      <UserRoute exact path="/construction-site/edit/:id" component={CreateConstructionSite} />
      <UserRoute exact path="/construction-site/:id/location-site" component={LocationSitePage} />
      <UserRoute exact path="/construction-site/:id/location-site/create" component={LocationSiteCreatePage} />
      <UserRoute path="/construction-site/:id/location-site/:idConstructionZone/list-file" component={ListFileLocationPage} />
      <UserRoute path="/construction-site/:id/location-site/:idConstructionZone/overload-management" component={OverloadManagementPage} />
      <UserRoute path="/construction-site/:id/location-site/:idConstructionZone/mix-tonnage-setting" component={MixTonnageSettingPage} />
      <UserRoute exact path="/construction-site/:id/location-site/:idConstructionZone/ponchie-picture" component={PonchiePicturePage} />
      <UserRoute exact path="/construction-site/:id/location-site/:idConstructionZone/ponchie-picture/:punchieId" component={PonchiePicturePage} />
      <UserRoute exact path="/construction-site/:id/location-site/:idConstructionZone/cutting-order-diagram" component={CuttingOrderDiagramPage} />
      <UserRoute exact path="/construction-site/:id/location-site/:idConstructionZone/cutting-order-diagram/:diagramId" component={CuttingOrderDiagramPage} />
      <UserRoute exact path="/construction-site/:id/location-site/:idConstructionZone/pavement-order-diagram" component={CuttingOrderDiagramPage} />
      <UserRoute exact path="/construction-site/:id/location-site/:idConstructionZone/pavement-order-diagram/:diagramId" component={CuttingOrderDiagramPage} />
      {
        // agency
      }
      <AgencyRoute exact path="/agency/company" component={CompanyListPage} />
      <AgencyRoute exact path="/agency/company/create" component={CompanyCreatePage} />
      <AgencyRoute exact path="/agency/company/:id" component={CompanyDetailPage} />
      <AgencyRoute exact path="/agency/license" component={LicenseListPage} />
      <AgencyRoute exact path="/agency/license/create" component={LicenseCreatePage} />
      <AgencyRoute exact path="/agency/license/:id" component={LicenseDetailPage} />
      <AgencyRoute exact path="/agency/staff" component={StaffListPage} />
      <AgencyRoute exact path="/agency/staff/create" component={StaffCreatePage} />
      <AgencyRoute exact path="/agency/staff/edit/:id" component={StaffCreatePage} />
      <AgencyRoute exact path="/agency/staff/:id" component={StaffDetailPage} />
      {
        // super admin
      }

      <AdminRoute exact path="/cms/request/company" component={CompanyRequestListPage} />
      <AdminRoute exact path="/cms/request/license" component={LicenseRequestListPage} />
      <AdminRoute exact path="/cms/agency" component={AgencyListPage} />
      <AdminRoute exact path="/cms/agency/create" component={AgencyCreatePage} />
      <AdminRoute exact path="/cms/agency/:id" component={AgencyDetailPage} />
      <AdminRoute exact path="/cms/agency/edit/:id" component={AgencyCreatePage} />
      <AdminRoute exact path="/cms/company" component={CompanyListInAdminPage} />
      <AdminRoute exact path="/cms/company/create" component={CompanyCreateEditPage} />
      <AdminRoute exact path="/cms/company/:id" component={CompanyDetailInAdminPage} />
      <AdminRoute exact path="/cms/company/edit/:id" component={CompanyCreateEditPage} />
      <AdminRoute exact path="/cms/staff" component={AdminStaffListPage} />
      <AdminRoute exact path="/cms/staff/create" component={AdminStaffCreatePage} />
      <AdminRoute exact path="/cms/staff/edit/:id" component={AdminStaffCreatePage} />
      <AdminRoute exact path="/cms/staff/:id" component={AdminStaffDetailPage} />
      <AdminRoute exact path="/cms/categories" component={CategoryListPage} />
      <AdminRoute exact path="/cms/categories/:id" component={FaqListInAdminPage} />
      <AdminRoute exact path="/cms/categories/:id/faq/create" component={FaqCreateEditPage} />
      <AdminRoute exact path="/cms/categories/:id/faq/edit/:idFaq" component={FaqCreateEditPage} />
      <AdminRoute exact path="/cms/notifications" component={NotifycationListPage} />
      {/* <AdminRoute exact path="/cms/notifications/:id" component={FaqListInAdminPage} /> */}

      {
        // company management
      }
      <CompanyAdminRoute exact path="/company/staff" component={CompanyStaffListPage} />
      <CompanyAdminRoute exact path="/company/staff/create" component={CompanyStaffCreatePage} />
      <CompanyAdminRoute exact path="/company/staff/edit/:id" component={CompanyStaffCreatePage} />
      <CompanyAdminRoute exact path="/company/staff/:id" component={CompanyStaffDetailPage} />
      <CompanyAdminRoute exact path="/company/license" component={LicenseManagementPage} />
      {
        // payment
      }
      <Route exact path="/payment/:id" component={PaymentPage} />
      <Route exact path="/license/terms-of-service/:token" component={TermsOfServicePage} />
      <Route exact path="/license/invoice" component={LicenseInvoicePage} />
      {
        // forbidenPage
      }

      <Route exact path="/forbidden" component={ForbiddenPage} />
      <Route exact path="/license/inactive" component={LicenseInactivePage} />

      {
        // user infor
      }

      <AuthRoute exact path="/change-password" component={ChangePasswordPage} />
      <Route exact path="/profile" component={ProfilePage} />

      <Route exact path="/forgot-password" component={ConfirmEmailPage} />
      <Route exact path="/reset_password" component={ResetPasswordPage} />
      <Route exact path="/expried/reset_password" component={ResetPasswordExpriedPage} />
      <Route exact path="/404" component={NotFoundPage} />
      <Route exact path="*" component={NotFoundPage} />
    </Switch>
  </BrowserRouter>
)

export default AllRoute