import { Button, Form, InputNumber, Radio, Select, Spin, message } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useListFileByType } from 'src/api/data/locationSite/useListFileByType'
import selectIcon from 'src/assets/images/select-icon.png'
import { listTypeNameFileConstuctionZone, punchieUploadType } from 'src/constants'
import { FileByType } from './type'
import { useMutation, useQueryClient } from 'react-query'
import { uploadTonnageMixing } from 'src/api/locationSite'
import { useTonnageMixing } from 'src/api/data/locationSite/useTonnageMixing'
import { QUERY_KEY } from 'src/api/data/query-key'
import { useLocationSiteListFiles } from 'src/api/data/locationSite/useLocationSiteListFiles'
interface MixTonnageSettingComponentProp{
    handleCheckValueForm: (value: boolean) => void
}
const MixTonnageSettingComponent = ({handleCheckValueForm = () => {}}: MixTonnageSettingComponentProp) => {
    const SelectIcon = () => (
        <img src={selectIcon} alt='' />
    )
    const [antForm] = Form.useForm()
    const { idConstructionZone } = useParams<{ idConstructionZone: string }>()
    const { data: listFilePavingThickness } = useListFileByType(Number(idConstructionZone),listTypeNameFileConstuctionZone.PAVING_THICKNESS_FILES)
    const { data: tonnageMixingData, isLoading: isLoadingTonnage } = useTonnageMixing(Number(idConstructionZone))
    const { data: locationSiteListFiles } = useLocationSiteListFiles(Number(idConstructionZone))
    const [thicknessInputMethod, setThicknessInputMethod] = useState(1)
    const [isValueForm,setIsValueForm] = useState(false)
    const queryClient = useQueryClient()
    const handleSetNumberVehiclesValue = () => {
        const values = antForm.getFieldsValue()
        if(values.plant_loading_time && 
        values.outbound_travel_time &&
        values.return_travel_time &&
        values.paving_time_per_unit){
            const valueNumberVehicles = ((values.plant_loading_time + values.outbound_travel_time + values.return_travel_time)/values.paving_time_per_unit + 1).toFixed(2)
            antForm.setFieldsValue(
                {number_vehicles: valueNumberVehicles }
            )
        }else {
            antForm.setFieldsValue(
                {number_vehicles: 0}
            )
        }
    }

    const {isLoading: isloading, mutate: onUploadTonnageMixing } = useMutation(
        (param: any) => uploadTonnageMixing(Number(idConstructionZone),param),
        {
          onSuccess: async() => {
            await queryClient.invalidateQueries(QUERY_KEY.TONNAGEMIXING)
            message.success('データ更新成功')
          },
          onError: (error: any) => {
            if (error.response?.status === 400) {
                message.error(error.response.data.messages[0])
                return
            }
            message.error('システムエラー')
          },
        },
    )

    const handleUploadMixTonnageSetting = async() => {
        try {
            await antForm.validateFields()
        } catch { return }

        const values = antForm.getFieldsValue()

        onUploadTonnageMixing(values)
        setIsValueForm(false);
    }

    const handleChangeThicknessInputMethod = (value:number) => {
        setThicknessInputMethod(value)
    }

    useEffect(() => {
        if(tonnageMixingData?.cutting_thickness_input) {
            setThicknessInputMethod(2)
        }else{
            setThicknessInputMethod(1)
        }
    },[tonnageMixingData?.cutting_thickness_input])

    const pavementOrderDiagramOption = useMemo(() => {
        return locationSiteListFiles?.punches?.filter((e: any) => e.type === punchieUploadType.PUNCH_PAVEMENT)?.map((item: any) => (
            {
                value: item?.id,
                label: item?.name
            }
        ))
    },[locationSiteListFiles])

    const optionFilePavingThickness = useMemo(() => {
        return listFilePavingThickness?.map((file: FileByType) => (
            {
                value: file.id,
                label:file.file_name
            }
        ))
    },[listFilePavingThickness])

    const validateNumberFields = (rule: any, value: number, callback: any, name: string, min: number) => {
        if (!value && value !== 0) {
            callback(`${name}を入力してください`);
        }else if(value <= min) {
            callback(`${name}は${min}より大きい値を入力してください `)
        }else {
            callback()
        }
    }

    const validatePercentFields = (rule: any, value: number, callback: any, name: string, min: number, max: number) => {
        if (!value && value !== 0) {
            callback(`${name}を入力してください`);
        }else if(value <= min || value > max) {
            callback(`${name}は${min}より大きく${max}ちさい値を入力してください`)
        }else {
            callback()
        }
    }

    const validateQuatifyCarFields = (rule: any, value: number, callback: any, name: string, min: number, max: number) => {
        if (!value && value !== 0) {
            callback(`${name}を入力してください`);
        }else if(value < min || value > max) {
            callback(`${name}を${min}～${max}の範囲で入力してください`)
        }else {
            callback()
        }
    }

    useEffect(() => {
        if(pavementOrderDiagramOption) {
            const isAgencyStaffExists = pavementOrderDiagramOption?.find((file: any) => file.value === tonnageMixingData?.cutting_sequence_diagram_file)
            if(isAgencyStaffExists) {
                antForm.setFieldValue('cutting_sequence_diagram_file', tonnageMixingData?.cutting_sequence_diagram_file)
            }else {
                antForm.setFieldValue('cutting_sequence_diagram_file', null)
            }
        }
    },[antForm, pavementOrderDiagramOption, tonnageMixingData])


    useEffect(() => {
        if(optionFilePavingThickness && tonnageMixingData?.cutting_thickness_file) {
            const isAgencyStaffExists = optionFilePavingThickness?.find((file: any) => file.value === tonnageMixingData?.cutting_thickness_file)
            if(isAgencyStaffExists) {
                antForm.setFieldValue('cutting_thickness_file', tonnageMixingData?.cutting_thickness_file)
            }else {
                antForm.setFieldValue('cutting_thickness_file', null)
            }
        }
    },[antForm, optionFilePavingThickness, tonnageMixingData])

    const renderNotFoundContent = () => {
        return <p className='text-center p-[10px]'>データがありません</p>
    }

    const handleChangeValue = (allvalues: any) => {
        const allValuesExceptPaymentType = {...allvalues}
        const listValue = Object.keys(allValuesExceptPaymentType).map((key: any) => allvalues[key])
        const validateErrorValueList = []
        listValue?.forEach((value: any) => {
            if(value === undefined || value === '') {
                validateErrorValueList.push(value)
            }
        })
        listValue?.some((value:any) => {
            if(value !== undefined && value !== '' && value != null) {
                setIsValueForm(true)
                return true
            }
            setIsValueForm(false)
            return false
        })
    }

    useEffect(() => {
        handleCheckValueForm(isValueForm)
    },[isValueForm])
    return (
        isLoadingTonnage ? <Spin className='position-center' />
        : 
        <Form form={antForm} initialValues={{...tonnageMixingData, cutting_sequence_diagram_file: null, cutting_thickness_file : null}} onValuesChange={(value, allvalues) => handleChangeValue(allvalues)} className='mix__tonnage__setting page-content-detail'>
            <div className='mb-[16px]'>
                <div className='page-content-detail-title mb-[24px] flex justify-between items-center'>
                    合材トン数設定
                    <Button loading={isloading} className='button-add w-[128px]' onClick={handleUploadMixTonnageSetting}><span></span>保存</Button>
                </div>
                <div className='main-block'>
                    <h2 className='tonnage__setting'>
                        基本設定
                    </h2>
                    <div className='basic__settings '>
                        <div className='basic__settings__item'>
                            <p className='settings__item__lable w-2/12'>混合物密度</p>
                            <Form.Item 
                                name='density' 
                                rules={
                                    [                                    
                                        { validator: (rule, value, callback) => validateNumberFields(rule,value,callback, '混合物密度', 0)}
                                    ]
                                }
                            >
                                <InputNumber min={0} className='input__tonnage'/>
                            </Form.Item>
                        </div>
                        <div className='basic__settings__item'>
                            <p className='settings__item__lable w-2/12'>ロス率</p>
                            <div className='flex items-center relative'>
                                <Form.Item 
                                    name='loss_rate' 
                                    rules={
                                        [                                    
                                            { validator: (rule, value, callback) => validatePercentFields(rule,value,callback, 'ロス率', 0, 100)}
                                        ]
                                    }
                                >
                                    <InputNumber min={0} className='input__tonnage'/>
                                </Form.Item>
                                <span className='unit-input'>％</span>
                            </div>
                        </div>
                        <div className='basic__settings__item'>
                            <p className='settings__item__lable w-2/12'>ダンプ搭載重量</p>
                            <div className='flex items-center relative'>
                                <Form.Item 
                                    name='dump_weight' 
                                    rules={
                                        [                                    
                                            { validator: (rule, value, callback) => validateNumberFields(rule,value,callback, 'ダンプ搭載重量', 0)}
                                        ]
                                    }
                                >
                                    <InputNumber min={0} className='input__tonnage'/>
                                </Form.Item>
                                <span className='unit-input'>トン</span>
                            </div>
                        </div>
                        <div className='pavement-input-method my-[24px]'>
                            <p className='settings__item__lable mb-[16px]'>舗設厚さ入力方法</p>
                            <div className='ml-[15px]'>
                                <Radio.Group className='mb-[10px] flex flex-col' value={thicknessInputMethod} onChange={(e) => handleChangeThicknessInputMethod(e.target.value)}>
                                    <Radio className='text-[#2E2E2E] pavement-radio-lable' value={1}>ファイルから入力</Radio>
                                    {
                                        thicknessInputMethod === 1 &&
                                        <div className='ml-[15px]'>
                                            <div className='flex items-center py-[8px] px-[12px]'>
                                                <h4 className='text-[#2E2E2E] w-2/12 max-w-[227px]'>舗設厚さファイル</h4>
                                                <Form.Item name='cutting_thickness_file' rules={[{ required: true, message: '舗設厚さファイルを選択してください' }]}>
                                                    <Select
                                                        className='cutting-thickness-file'
                                                        style={{ width: 228 }}
                                                        options={optionFilePavingThickness}
                                                        suffixIcon={SelectIcon()}
                                                        placeholder=''
                                                        notFoundContent={renderNotFoundContent()}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    }
                                    <Radio className='mt-[20px] text-[#2E2E2E] pavement-radio-lable' value={2}>一括設定</Radio>
                                </Radio.Group>
                                {
                                    thicknessInputMethod === 2 && 
                                    <div className='flex items-center mt-[16px] ml-[26px]'>
                                        <p className='settings__item__lable w-2/12 max-w-[227px] text-[#2E2E2E] pavement-radio-lable'>厚さ</p>
                                        <div className='flex items-center relative'>
                                            <Form.Item 
                                                name='cutting_thickness_input' 
                                                rules={
                                                    [                                    
                                                        { validator: (rule, value, callback) => validateNumberFields(rule,value,callback, '厚さ', 0)}
                                                    ]
                                                }
                                            >
                                                <InputNumber min={0} className='input__tonnage ml-0px'/>
                                            </Form.Item>
                                            <span className='text-[#2E2E2E] pavement-radio-lable unit-input'>cm</span>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='main-block mb-[16px]'>
                <h2 className='tonnage__setting'>
                    入力データ
                </h2>
                <div className='flex items-center py-[8px] px-[12px]'>
                    <h4 className='text-[#2E2E2E] w-2/12 min-w-[200px]'>舗設順序図</h4>
                    <Form.Item name='cutting_sequence_diagram_file' rules={[{ required: true, message: '舗設順序図を選択してください' }]}>
                        <Select
                            className='cutting-thickness-file'
                            style={{ width: 228 }}
                            options={pavementOrderDiagramOption}
                            suffixIcon={SelectIcon()}
                            notFoundContent={renderNotFoundContent()}
                        />
                    </Form.Item>
                </div>
            </div>
            <div className='main-block mb-[35px]'>
                <h2 className='tonnage__setting'>
                    ダンプ台数計算設定
                </h2>
                <div className='basic__settings'>
                    <div className='basic__settings__item'>
                        <p className='settings__item__lable w-3/12'>プラント積み込み時間</p>
                        <div className='flex items-center relative'>
                            <Form.Item 
                                name='plant_loading_time' 
                                rules={
                                    [                                    
                                        { validator: (rule, value, callback) => validateNumberFields(rule,value,callback, 'プラント積み込み時間', 0)}
                                    ]
                                }
                            >
                                <InputNumber min={0} className='input__tonnage' onChange={() => handleSetNumberVehiclesValue()}/>
                            </Form.Item>
                            <span className='unit-input'>分</span>
                        </div>
                    </div>
                    <div className='basic__settings__item'>
                        <p className='settings__item__lable w-3/12'>往路走行時間</p>
                        <div className='flex items-center relative'>
                            <Form.Item 
                                name='outbound_travel_time' 
                                rules={
                                    [                                    
                                        { validator: (rule, value, callback) => validateNumberFields(rule,value,callback, '往路走行時間', 0)}
                                    ]
                                }
                            >
                                <InputNumber min={0} className='input__tonnage' onChange={() => handleSetNumberVehiclesValue()}/>
                            </Form.Item>
                            <span className='unit-input'>分</span>
                        </div>
                    </div>
                    <div className='basic__settings__item'>
                        <p className='settings__item__lable w-3/12'>復路走行時間</p>
                        <div className='flex items-center relative'>
                            <Form.Item 
                                name='return_travel_time' 
                                rules={
                                    [                                    
                                        { validator: (rule, value, callback) => validateNumberFields(rule,value,callback, '復路走行時間', 0)}
                                    ]
                                }
                            >
                                <InputNumber min={0} className='input__tonnage' onChange={() => handleSetNumberVehiclesValue()}/>
                            </Form.Item>
                            <span className='unit-input'>分</span>
                        </div>
                    </div>
                    <div className='basic__settings__item'>
                        <p className='settings__item__lable w-3/12'>一台当たりの舗設時間</p>
                        <div className='flex items-center relative'>
                            <Form.Item 
                                name='paving_time_per_unit' 
                                rules={
                                    [                                    
                                        { validator: (rule, value, callback) => validateNumberFields(rule,value,callback, '一台当たりの舗設時間', 0)}
                                    ]
                                }
                            >
                                <InputNumber min={0} className='input__tonnage' onChange={() => handleSetNumberVehiclesValue()}/>
                            </Form.Item>
                            <span className='unit-input'>分</span>
                        </div>
                    </div>
                    <div className='basic__settings__item'>
                        <p className='settings__item__lable w-3/12'>チャーター台数 (自動計算)</p>
                        <div className='flex items-center relative'>
                            <Form.Item name='number_vehicles'>
                                <InputNumber disabled className='input__tonnage'/>
                            </Form.Item>
                            <span className='unit-input'>台</span>
                        </div>
                    </div>
                    <div className='basic__settings__item'>
                        <p className='settings__item__lable w-3/12'>チャーター台数</p>
                        <div className='flex items-center relative'>
                            <Form.Item 
                                name='actual_rent_vehicles' 
                                rules={
                                    [                                    
                                        { validator: (rule, value, callback) => validateQuatifyCarFields(rule,value,callback, 'チャーター台数', 1, 100)}
                                    ]
                                }
                            >
                                <InputNumber min={0} className='input__tonnage' formatter={value => value ? Number(value)?.toFixed(0) : ''}/>
                            </Form.Item>
                            <span className='unit-input'>台</span>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    )
}

export default MixTonnageSettingComponent