import React from 'react'
import './header.modules.scss'
import logo from 'src/assets/images/logo.png'
import { useHistory, useLocation } from 'react-router-dom'
import UserInfor from './userInfor'
import { role } from 'src/constants'

const Header = () => {
    const history = useHistory()
    const location = useLocation()
    const handleGoHomePage = () => {
        const userRole = Number(localStorage.getItem('role'))
        if(location?.pathname === '/login') {
            return
        }
        if(userRole === role.ADMIN || userRole === role.SYSTEM_ADMIN) {
            history.push('/cms/company')
        }else if(userRole === role.AGENCY || userRole === role.AGENCY_STAFF) {
            history.push('/agency/company')
        }else if(userRole === role.COMPANY || userRole === role.COMPANY_STAFF) {
            history.push('/construction-site')
        }
    }
    return (
        <>
            <div id='header' className='flex justify-between header'>
                <div className='flex items-center cursor-pointer' onClick={handleGoHomePage}>
                    <img className='header__icon mr-[13px]' src={logo} alt='logo'/>
                    <h1 className='font-bold text-[24px] text-[#253858] whitespace-nowrap cursor-pointer'>助さん</h1>
                </div>
                <UserInfor />
            </div>
        </>
    )
}

export default Header