import { useQuery } from 'react-query'
import { api } from 'src/api/axios'
import { QUERY_KEY } from '../query-key'
export const useListCompanyStaffOfAdmin = (id:number, keyWord: string, status:number | undefined, page: number, size:number) => useQuery(
    [QUERY_KEY.LIST_COMPANY_STAFF_OF_ADMIN, keyWord,status, page, size],
    async () => {
      const res = await api.get(`/api/cms/company/admin/${id}/staff`, {
        params: {
          keyword: keyWord,
          page:page, 
          size: size,
          status: status,
        },
      })
      return res.data.data
    },
    {
      retry: 0,
    }
  )
  