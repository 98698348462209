import { api } from "./axios";
export const licensePayment = (params: any, idLicense: number) => api.post(
    `/api/license/payment/${idLicense}`,
    params,
)

export const licensePaymentExpired = (idLicense: number) => api.get(
    `/api/license/payment/${idLicense}`
)

export const confirmLicense = (token: string) => api.post(
    `/api/license/estimation-confirm?token=${token}`
)