import { useQuery } from 'react-query'
import { api } from 'src/api/axios'
import { QUERY_KEY } from '../query-key'


export const usePunchieDetail = (constructionId: number, punchieId: number) => useQuery(
  [QUERY_KEY.PUNCHIE_DETAIL,constructionId, punchieId],
  async () => {
    const res = await api.get(`/api/admin/construction-zone/${constructionId}/punch/${punchieId}`)
    return res.data.data
  },
  {
    enabled: Boolean(punchieId),
    retry: 0
  }
)
