import React from 'react'
import CompanyStaffCreateComponent from 'src/components/features/companyManagement/companyStaffCreateComponent'
import { LayoutCompanyAdmin } from 'src/components/layouts/layoutCompanyAdmin'

const CompanyStaffCreatePage = () => (
    <LayoutCompanyAdmin>
       <CompanyStaffCreateComponent />
    </LayoutCompanyAdmin>
)

export default CompanyStaffCreatePage