import { Spin } from 'antd'
import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import { licensePaymentExpired } from 'src/api/payment'
import PaymentComponent from 'src/components/features/payment/paymentComponent'
import PaymentExpired from './paymentExpired'

const PaymentPage = () => {
    const { id } = useParams<{ id: string }>()
    const [isExpired, setIsExpired] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const handleCheckExpiredPayment = async() => {
        setIsLoading(true)
        await licensePaymentExpired(Number(id)).catch(function (error) {
            if(error?.response?.status === 410) {
                setIsExpired(true)
            }
        })
        setIsLoading(false)
    }
    useEffect(() => {
        handleCheckExpiredPayment()
    },[id])

    const handleRenderPaymentComponent = () => {
        if(isExpired) {
            return (
                <PaymentExpired />
            )
        }
        return  <PaymentComponent />
    }
    return (
        isLoading ? <Spin className='position-center'/> :
        handleRenderPaymentComponent()
    )
}

export default PaymentPage