import React from 'react'
import logo from 'src/assets/images/logo.png'
import './payment.modules.scss'
import './payment.css'
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import PaymentForm from './paymentForm';
const PUBLICKEY = process.env.REACT_APP_PUBLIC_KEY
const stripePromise = loadStripe(PUBLICKEY!);

const PaymentComponent = () => {
    return (
        <div className='payment payment-bgr'>
            <div id='header' className='flex justify-between header'>
                <div className='flex items-center cursor-pointer'>
                    <img className='header__icon mr-[13px]' src={logo} alt='logo'/>
                    <h1 className='font-bold text-[24px] text-[#253858] whitespace-nowrap cursor-pointer'>助さん</h1>
                </div>
            </div>
            <div className='payment__form'>
                <div className='payment__form-content'>
                    <h1 className='form-title mb-[40px]'>支払い</h1>
                    <Elements stripe={stripePromise}>
                        <PaymentForm/>
                    </Elements>
                </div>
            </div>
        </div>
    )
}

export default PaymentComponent