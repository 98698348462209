
import React, { useContext } from 'react'
import './constuction.modules.scss'
import { Button, Form } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { ConstructionDetailContext, useConstructionDetailContext } from 'src/context/ConstructionDetailContext'
const ConstructionDetailComponent = () => {
    const { id } = useParams<{ id: string }>()
    const {constructionDetail} = useConstructionDetailContext()
    const history = useHistory()
    const handleEditConstruction = () => {
        history.push(`/construction-site/edit/${id}?redirectType=1`)
    }
    return (
        <>
            <div className='page-content-detail construction-detail'>
                <div className='mb-[30px] construction-detail-title flex justify-between items-center'>
                    <h2 className='page-content-detail-title'>
                        工事基本情報
                    </h2>
                    <Button className='button-antd-custom btn-edit' onClick={() => handleEditConstruction()}>
                        <span></span>変更
                    </Button>
                </div>
                <Form className='construction-detail-infor'>
                    <div className='mb-[25px]'>
                        <label className='labe-form'>工事名</label>
                        <div className='input-form break-words'>
                            {constructionDetail?.name}
                        </div>
                    </div>
                    <div className='mb-[25px]'>
                        <label className='labe-form'>発注者名</label>
                        <div className='input-form'>
                            {constructionDetail?.orderer_name}
                        </div>
                    </div>
                    <div className='mb-[25px]'>
                        <label className='labe-form'>工事所在地</label>
                        <div className='input-form'>
                            {constructionDetail?.location}
                        </div>
                    </div>
                    <div>
                        <label className='labe-form'>会社名</label>
                        <div className='input-form'>
                            {constructionDetail?.company_name}
                        </div>
                    </div>
                </Form>
            </div>
       </>
    )
}

export default ConstructionDetailComponent