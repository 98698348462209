import { companyCreateType, licenseCreateType, staffCreateType } from "src/components/features/agency/type";
import { api } from "./axios";

export const agencyCreateCompany = (params: companyCreateType) => api.post(
    `/api/cms/company`,
    params,
)

export const agencyCreateLicense = (params: licenseCreateType) => api.post(
    `/api/cms/license`,
    params,
)

export const agencyCreateStaff = (params: staffCreateType) => api.post(
    `/api/cms/staff`,
    params,
)

export const agencyEditStaff = (params: staffCreateType, id: number) => api.put(
    `/api/cms/staff/${id}`,
    params,
)

export const deleteStaffAgency = (id: number | undefined) => api.delete(
    `/api/cms/staff/${id}`,
)
  