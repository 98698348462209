import React from 'react'
import { useHistory } from 'react-router-dom';
import logo from 'src/assets/images/logo.png'
type LayoutProps = {
    children: React.ReactNode,
    className?: string;
}
export const LayoutForgotPassword: React.FC<LayoutProps> = ({ children, className = '' }) => {
    const history = useHistory()
    const handleGoHomePage = () => {
        history.push('/')
    }
    return (
        <>
            <div id='header' className='flex justify-between header'>
                <div className='flex items-center cursor-pointer' onClick={handleGoHomePage}>
                    <img className='header__icon mr-[13px]' src={logo} alt='logo'/>
                    <h1 className='font-bold text-[24px] text-[#253858] whitespace-nowrap cursor-pointer'>助さん</h1>
                </div>
            </div>
        <div className={`civil-content-page ${className}`}>
            {children}
        </div>
        </>
    )
}
