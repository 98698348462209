import React from 'react'
import './index.modules.scss'
import HeaderSuperAdmin from '../common/layouts/headerSuperAdmin'
type LayoutProps = {
    children: React.ReactNode
}
export const LayoutSuperAdmin: React.FC<LayoutProps> = ({ children }) => {
    return (
        <>
            <HeaderSuperAdmin />
            <div className='flex layout civil-content-page'>
            <div className='w-full'>
                {children}
            </div>
            </div>
        </>
    )
}
