import React, {useCallback, useState } from 'react'
import { useMutation } from 'react-query'
import emailIcon from 'src/assets/images/icon-email.png'
import { Input, Button, Form, message, Modal  } from 'antd'
import { useHistory } from 'react-router-dom'
import closeIcon from 'src/assets/images/close-circle.png'
import success from 'src/assets/images/tick-circle.png'

import { forgotPassword } from 'src/api/userInfor'

const ConfirmEmail = () => {
    const history = useHistory()
    const [antForm] = Form.useForm()
    const [messError, setMessError] = useState('')
    const [isOpenModalSuccess, setIsOpenModalSuccess] = useState<boolean>(false)
    const [emailAccount, setEmailAccount] = useState('')
    const {isLoading: isloading, mutate: onForgotPassword } = useMutation(
        (param: {email: string}) => forgotPassword(param),
        {
          onSuccess: (res) => {
            setIsOpenModalSuccess(true)
          },
          onError: (error: any) => {
            if (error.response?.status === 400) {
                setMessError(error.response?.data[0])
                return
            }
            message.error('システムエラー')
          },
        },
    )

    

    const handleSendEmailForgotPassword = useCallback(async() => {
        try {
            await antForm.validateFields()
        } catch { return }
        const values = antForm.getFieldsValue()
        return onForgotPassword(values)
    },[antForm, onForgotPassword])
    const EmailIconReactNode = () => (
        <img src={emailIcon} alt="" />
    )
    const handleChangeEmail = (value: string) => {
        setEmailAccount(value)
        setMessError('')
    }
    const handleEnterSubmit = (e: any) => {
        if (e.key === "Enter") {
            handleSendEmailForgotPassword()
        }
    }

    const validateEmail = (rule: any, value: string, callback: any) => {
        const emailRegex = /^[a-zA-Z0-9._+-]+@([\w-]+\.)+([a-zA-Z]+)$/;
        if (!value?.trim()) {
            callback('メールアドレスを入力してください');
        } else if (!emailRegex.test(value.trim())) {
            callback('メールアドレスの形式が正しくありません');
        } else {
            callback();
        }
    };

    const handleCloseModalSuccess = () => {
        setIsOpenModalSuccess(false)
        history.push('/login')
    }

    return (
        <div className='login login-bgr'>
            <div className='login__form'>
                <div className='login__form-content'>
                    <h1 className='form-title mb-[8px] px-[40px]'>パスワードをお忘れの方は</h1>
                    <p className='text-[16px] font-bold text-[#ffffff] text-center px-[40px]'>パスワード変更情報がメールに送信されます。</p>
                    <p className='mb-[40px] text-[16px] font-bold text-[#ffffff] text-center px-[40px]'>メールアドレスをご記入ください。</p>
                    <Form form={antForm} onKeyPress={(e) => handleEnterSubmit(e)}>

                        <div className='mb-[16px]'>
                            <Form.Item 
                                name='email' 
                                rules={
                                    [
                                        { validator: (rule, value, callback) => validateEmail(rule,value,callback)}
                                    ]
                                }
                            >
                                <Input onChange={(value) => handleChangeEmail(value.target.value)} className='login__form__input' size="large" placeholder="メールアドレス" prefix={<EmailIconReactNode/>} />
                            </Form.Item>
                            <p className='invalid-feedback whitespace-pre-line'>{messError}</p>
                        </div>
                        <Button  onClick={() => handleSendEmailForgotPassword()} loading={isloading} className='login__form__button mx-auto block my-[32px]'>
                            送信
                        </Button>
                    </Form>
                </div>
            </div>
            <Modal 
                title="" 
                open={isOpenModalSuccess} 
                width={451}
                className="modal-success"
                footer={false}
                closable={false}
                centered={true}
                afterClose={handleCloseModalSuccess}
            >
                <img src={closeIcon} alt="" className="close-modal cursor-pointer" onClick={handleCloseModalSuccess}/>
                <img src={success} alt='' className='mb-[16px] mx-auto'/>
                <div className='text-[#272122] text-[20px] font-medium text-center mb-[8px]'>パスワード再設定</div>
                <p className='text-[#272122] text-[14px] text-center'>パスワード再設定用のURLを{emailAccount}へお送りいたしました。</p>
                <p className='text-[#272122] text-[14px] text-center mb-[44px]'>メールをご確認の上、お進めてください。</p>
                <Button className='button-add w-full' onClick={() => handleCloseModalSuccess()}>
                    OK
                </Button>
            </Modal>
        </div>
    )
}

export default ConfirmEmail