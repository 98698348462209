import React from 'react'
import LocationSiteCreateComponent from 'src/components/features/locationSite/locationSiteCreate'
import { LayoutMoreNote } from 'src/components/layouts/layoutMoreNote'
import { ConstructionDetailProvider } from 'src/context/ConstructionDetailContext'

const LocationSiteCreatePage = () => (
    <ConstructionDetailProvider>
        <LayoutMoreNote>
            <LocationSiteCreateComponent />
        </LayoutMoreNote>
    </ConstructionDetailProvider>
)

export default LocationSiteCreatePage