import React from 'react'
import StaffDetailComponent from 'src/components/features/agency/staffDetailComponent'
import { LayoutAgency } from 'src/components/layouts/layoutAgency'

const StaffDetailPage = () => (
    <LayoutAgency>
       <StaffDetailComponent />
    </LayoutAgency>
)

export default StaffDetailPage