import { Button, Checkbox, Spin } from 'antd'
import React, {useEffect, useState} from 'react'
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import { useLicenseInvoice } from 'src/api/data/companyManagement/useLicenseInvoice'
import ConfirmLicenseExpired from 'src/components/features/payment/confirmLicenseExpired'
import logo from 'src/assets/images/logo.png'
import { useQueryClient } from 'react-query';
const TermsOfServiceComponent = () => {
    const history = useHistory()
    const { token } = useParams<{ token: string }>()
    const {data, isLoading} = useLicenseInvoice(token!.replace('token=', ''))
    const [isCheckedCheckbox, setIsCheckedCheckbox] = useState<boolean>(false)
    const queryParameters = new URLSearchParams(window.location.search);
    const queryClient = useQueryClient()
    const handleCheckedTermsService = (value: CheckboxChangeEvent) => {
        queryParameters.set('isChecked',value.target.checked?.toString())
        setIsCheckedCheckbox(value.target.checked)
    }
    const onGoLicenseInvoice = () => {
        queryClient.clear()
        history.push(`/license/invoice?${token}`)
    }

    useEffect(() => {
        setIsCheckedCheckbox(queryParameters.get('isChecked') === 'true' ? true : false)
    },[])
    return (
        <>
            {
                isLoading ? <Spin className='position-center' /> :
                data ? 
                <>
                    <div id='header' className='flex justify-between header'>
                        <div className='flex items-center cursor-pointer'>
                            <img className='header__icon mr-[13px]' src={logo} alt='logo'/>
                            <h1 className='font-bold text-[24px] text-[#253858] whitespace-nowrap cursor-pointer'>助さん</h1>
                        </div>
                    </div>
                    <div className='mt-[40px] pb-[148px]'>
                        <div className='terms-service'>
                            <h3 className='title mb-[12px] px-[40px]'>利用規約</h3>
                            <hr className='border-[#EAEAEA]'/>
                            <div className='px-[40px]'>
                                <div className='mb-[20px]'>
                                    <h6 className='content-title'>Lorem Ipsum is simply dummy text</h6>
                                    <p className='content-text'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                </div>
                                <div className='mb-[20px]'>
                                    <h6 className='content-title'>Lorem Ipsum is simply dummy text</h6>
                                    <p className='content-text'>
                                        It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                    </p>
                                </div>
                                <div className='mb-[20px]'>
                                    <h6 className='content-title'>Lorem Ipsum is simply dummy text</h6>
                                    <p className='content-text'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                </div>
                                <Checkbox checked={isCheckedCheckbox} onChange={handleCheckedTermsService} className='terms-checkbox text-[16px] text-[#272122]'>利用規約に同意する</Checkbox>
                            </div>
                        </div>
                        <div className='terms-service-btn text-center'>
                            <Button disabled={!isCheckedCheckbox} className='button-form button-form-Submit' onClick={onGoLicenseInvoice}>次へ</Button>
                        </div>
                    </div>
                </>
                : <ConfirmLicenseExpired />
            }
        </>
    )
}

export default TermsOfServiceComponent