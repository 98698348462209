import React from 'react'
import { useHistory } from 'react-router-dom'
import LoginComponent from 'src/components/features/login/loginComponent'
import logo from 'src/assets/images/logo.png'
const LoginPage = () => {
    const history = useHistory()
    const onGoToHomepage = () => {
        history.push('/login')
    }
    return (
        <>
            <div id='header' className='flex justify-between header'>
                <div className='flex items-center cursor-pointer' onClick={onGoToHomepage}>
                    <img className='header__icon mr-[13px]' src={logo} alt='logo'/>
                    <h1 className='font-bold text-[24px] text-[#253858] whitespace-nowrap cursor-pointer'>助さん</h1>
                </div>
            </div>
            <div className={`civil-content-page`}>
                <LoginComponent />
            </div>
        </>
    )
}

export default LoginPage