import { useQuery } from 'react-query'
import { api } from 'src/api/axios'
import { QUERY_KEY } from '../query-key'


export const useDetailStaff = (id: number) => useQuery(
  [QUERY_KEY.DETAIL_STAFF, id],
  async () => {
    if(id) {
      const res = await api.get(`/api/cms/staff/${id}`)
      return res.data.data
    }
    return
  },
  {
    enabled: Boolean(id),
  }
)
