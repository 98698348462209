import React from 'react'
import CreatePonchiePicture from 'src/components/features/locationSiteDetail/createPonchiePicture'
import { LayoutConstruction } from 'src/components/layouts/layoutConstruction'

const PonchiePicturePage = () => (
    <LayoutConstruction>
        <CreatePonchiePicture />
    </LayoutConstruction>
)

export default PonchiePicturePage