
import { agencyCreateType } from "src/components/features/superAdmin/type";
import { api } from "./axios";
import { categoryCreateType, companyCreateType, notificationCreateType } from "src/components/features/agency/type";

export const ratifyRequest = (params: any) => api.put(
    `/api/cms/company/change_status/request`,
    params,
)

export const ratifyLicenseRequest = (params: any) => api.put(
    `/api/cms/license/change_status/request`,
    params,
)

export const changeAgencyStatus = (id: number, params: {status: number}) => api.put(
    `/api/cms/agency/admin/change_status/${id}`,
    params
)

export const agencyCreate= (params: agencyCreateType) => api.post(
    `/api/cms/agency`,
    params,
)

export const agencyEdit= (params: agencyCreateType, id: number) => api.put(
    `/api/cms/agency/${id}`,
    params,
)

export const createCompany = (params: companyCreateType) => api.post(
    `/api/cms/company`,
    params,
)

export const editCompany = (params: companyCreateType, id: number) => api.put(
    `/api/cms/company/${id}`,
    params,
)


export const changeStatusCompanyInAdmin = (id: number, params: {status: number}) => api.put(
    `/api/cms/company/admin/change_status/${id}`,
    params
)

export const createCategory = (params: categoryCreateType) => api.post(
    `/api/cms/category`,
    params,
)

export const editCategory = (params: categoryCreateType, id: number) => api.put(
    `/api/cms/category/${id}`,
    params,
)

export const deleteCategory = (id: number | undefined) => api.delete(
    `/api/cms/category/${id}`,
)

export const createFaq = (params: companyCreateType) => api.post(
    `/api/cms/faq`,
    params,
)

export const editFaq = (params: companyCreateType, id: number) => api.put(
    `/api/cms/faq/${id}`,
    params,
)

export const deleteFaq = (id: number | undefined) => api.delete(
    `/api/cms/faq/${id}`,
)

export const createNotification = (params: notificationCreateType) => api.post(
    `/api/cms/notify`,
    params,
)

export const editNotification = (params: notificationCreateType, id: number) => api.put(
    `/api/cms/notify/${id}`,
    params,
)

export const deleteNotification = (id: number | undefined) => api.delete(
    `/api/cms/notify/${id}`,
)
