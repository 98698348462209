import { useQuery } from 'react-query'
import { api } from 'src/api/axios'
import { QUERY_KEY } from '../query-key'


export const useLicenseListCompany = () => useQuery(
  [QUERY_KEY.LICENSE_LIST_COMPANY],
  async () => {
    const res = await api.get(`/api/cms/license/list_company`)
      return res.data.data
  },
)
