import React from 'react'
import LicenseCreateComponent from 'src/components/features/agency/licenseCreateComponent'
import { LayoutAgency } from 'src/components/layouts/layoutAgency'

const LicenseCreatePage = () => (
    <LayoutAgency>
       <LicenseCreateComponent />
    </LayoutAgency>
)

export default LicenseCreatePage