import React from 'react'
import ProfileComponent from 'src/components/features/userInfor/profile'
import { LayoutProfile } from 'src/components/layouts/layoutProfile'
const ProfilePage = () => {
    return (
        <LayoutProfile>
            <ProfileComponent />
        </LayoutProfile>
    )
}

export default ProfilePage