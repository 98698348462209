import { Button, Form, Select, Spin, message, DatePicker, Radio, InputNumber } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import leftArrow from 'src/assets/images/back-icon.png'
import { licenseCreateType } from 'src/components/features/agency/type'
import { agencyCreateLicense } from 'src/api/agency'
import ModalConfirm from 'src/components/common/modal/modalConfirm'
import { useLicenseListCompany } from 'src/api/data/agency/useLicenseListCompany'
import { detailRequestCompany } from 'src/constants'
import iconDatePicker from 'src/assets/images/icon-calendar.png'
import 'moment/locale/ja';
import locale from 'antd/es/date-picker/locale/ja_JP';
import moment, { Moment } from 'moment'
import { validateRangeNumber } from 'src/utils/validateFields'
const { RangePicker } = DatePicker;
const LicenseCreateComponent = () => {
    const location = useLocation()
    const [antForm] = Form.useForm()
    const history = useHistory()
    const { id } = useParams<{ id: string }>()
    const { data: licenseListCompany, isLoading } = useLicenseListCompany() 
    const [isDisableSubmit,setIsDisableSubmit] = useState(true)
    const [isValueForm,setIsValueForm] = useState(false)
    const [isVisibleModelConfirmBack,setIsVisibleModelConfirmBack] = useState(false)
    const [detailCompany,setDetailCompany] = useState<any>()

    const {isLoading: isloading, mutate: onCreateLicense } = useMutation(
        (param: licenseCreateType) => agencyCreateLicense(param),
        {
          onSuccess: (res) => {
            message.success('ライセンス申請登録に成功しました')
            history.push('/agency/license')
            antForm.resetFields()
          },
          onError: (error: any) => {
            if (error.response?.status === 400) {
                message.error(error.response.data.messages[0])
                return
            }
            message.error('システムエラー')
          },
        },
    )
    

    const handleCreateLicense = async() => {
        try {
            await antForm.validateFields()
        } catch { return }
        const value = antForm.getFieldsValue()
        const startDate = moment(value?.time?.[0])?.format('YYYY/MM/DD')
        const endDate = moment(value?.time?.[1])?.format('YYYY/MM/DD')
        delete value.time;
        const params = {
            ...value,
            start_time: startDate,
            end_time: endDate
        }
        onCreateLicense(params)
    }

    const handleChangeValue = (allvalues: any) => {
        const allValuesExceptPaymentType = {...allvalues}
        delete allValuesExceptPaymentType['payment_type']
        const listValue = Object.keys(allValuesExceptPaymentType).map((key: any) => allvalues[key])
        const validateErrorValueList = []
        listValue?.forEach((value: any) => {
            if(value === undefined || value === '' || value == null) {
                validateErrorValueList.push(value)
            }
        })
        if(validateErrorValueList?.length > 0) {
            setIsDisableSubmit(true)
        }else{
            setIsDisableSubmit(false)
        }
        
        listValue?.some((value:any) => {
            if(value !== undefined && value !== '' && value != null) {
                setIsValueForm(true)
                return true
            }
            setIsValueForm(false)
            return false
        })
    }

    useEffect(() => {
        if(id) {
            setIsDisableSubmit(false)
        }
    },[id])

    const handleGoBackLicenseList = () => {
        history.push('/agency/license')
    }

    const handleBackLicenseList = () => {
        if(isValueForm) {
            setIsVisibleModelConfirmBack(true)
        }else{
            history.push('/agency/license')
        }
    }

    const handleCancel = () => {
        setIsVisibleModelConfirmBack(false)
    }

    const optionLicenseCompany = useMemo(() => {
        return licenseListCompany?.map((item: any) => (
            {
                value: item?.id,
                label: `${item?.membership_code ? item?.membership_code + '-': ''}${item?.name}`,
                detail: item
            }
        ))
    },[licenseListCompany])

    const handleGetDetailCompany = (value: any) => {
        setDetailCompany(value?.detail)
    }

    const renderIconDatePicker = () => {
        return <img src={iconDatePicker} alt='' />
    }

    const renderSeparator = () => {
        return <span>~</span>
    }

    const renderNotFoundContent = () => {
        return <p className='text-center p-[10px]'>データがありません</p>
    }

    const handleChangePrice = () => {
        const valueQuantity = antForm.getFieldValue('quantity')
        const valueUnitPrice = antForm.getFieldValue('unit_price')
        if(valueQuantity && valueUnitPrice) {
            const subtotalPrice = valueQuantity * valueUnitPrice
            const tax = subtotalPrice * 0.1
            const totalPrice = subtotalPrice + tax
            antForm.setFieldValue('subtotal', subtotalPrice)
            antForm.setFieldValue('tax', tax)
            antForm.setFieldValue('price', totalPrice)
        }else {
            antForm.setFieldValue('subtotal', null)
            antForm.setFieldValue('tax', null)
            antForm.setFieldValue('price', null)
        }
    }

    const disabledDate = (current : Moment) => {
        let customDate = moment().format("YYYY-MM-DD");
        return current && current < moment(customDate, "YYYY-MM-DD");
      };
    return (
       <div className='license-create p-[16px]'>
            <h2 className='page-content-title border-bottom-title mb-[30px] flex items-center'>
                <img src={leftArrow} alt='' className='inline-block mr-[17px] cursor-pointer' onClick={handleBackLicenseList}/>
                <span onClick={handleBackLicenseList} className='cursor-pointer'>ライセンス申請作成</span>
            </h2>
            {
                isLoading ? <Spin /> :
                <Form form={antForm} onValuesChange={(value, allvalues) => handleChangeValue(allvalues)} className='form-create'>
                    <div className='px-[20px] py-[12px]'>
                        <div className='mb-[16px]'>
                            <label className='labe-form'>会社名 <span>*</span></label>
                            <Form.Item name='company' rules={[{ required: true, message: '会社名を入力してください' }]}>
                                <Select 
                                    className='company-input-select h-[51px]'
                                    showSearch={true}
                                    allowClear={true}
                                    options={optionLicenseCompany!}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '')?.toString().toLowerCase().includes(input.trim()?.toLowerCase())
                                    }
                                    onChange={(value, event) =>handleGetDetailCompany(event)}
                                    notFoundContent={renderNotFoundContent()}
                                />
                            </Form.Item>
                            {
                                detailCompany && 
                                <div className='license-company-detail mt-[8px]'>
                                    {
                                        detailRequestCompany.map((item: any) => (
                                            <div className='mb-[8px] flex' key={item.id}>
                                                <label className='labe-form w-2/6'>{item.label}</label>
                                                <div className='w-4/6 text-[16px] text-[#000000] line-1 break-all'>
                                                    {
                                                        detailCompany?.[item.name]
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    } 
                                    <div className='mb-[8px] flex'>
                                        <label className='labe-form w-2/6'>担当者名</label>
                                        <div className='w-4/6 text-[16px] text-[#000000] line-1 break-all'>
                                            {
                                                detailCompany?.agency_staff ? 
                                                detailCompany?.agency_staff?.membership_code + '-' +  detailCompany?.agency_staff?.name 
                                                : '-'
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='mb-[16px]'>
                            <label className='labe-form'>数量<span>*</span></label>
                            <Form.Item name='quantity' 
                                rules={[
                                    { validator: (rule, value, callback) => validateRangeNumber(value, callback, '数量') }]}>
                                <InputNumber onChange={handleChangePrice} min={1} className='input-form w-full' formatter={value => `${value}`.replace('.', '')}/>
                            </Form.Item>
                        </div>
                        <div className='mb-[16px]'>
                            <label className='labe-form'>単価<span>*</span></label>
                            <Form.Item className='input-price' name='unit_price' rules={[{ required: true, message: '単価を入力してください' }]}>
                                <InputNumber onChange={handleChangePrice} min={0} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} className='input-form w-full'/>
                            </Form.Item>
                        </div>
                        <div className='mb-[16px]'>
                            <label className='labe-form'>小計</label>
                            <Form.Item className='input-price' name='subtotal'>
                                <InputNumber disabled min={0} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} className='input-form w-full'/>
                            </Form.Item>
                        </div>
                        <div className='mb-[16px]'>
                            <label className='labe-form'>消費税10%</label>
                            <Form.Item className='input-price' name='tax'>
                                <InputNumber disabled min={0} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} className='input-form w-full'/>
                            </Form.Item>
                        </div>
                        <div className='mb-[16px]'>
                            <label className='labe-form'>合計価格</label>
                            <Form.Item className='input-price' name='price'>
                                <InputNumber disabled min={0} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} className='input-form w-full'/>
                            </Form.Item>
                        </div>
                        <div className='mb-[16px]'>
                            <label className='labe-form'>有効期間<span>*</span></label>
                            <Form.Item name='time' rules={[{ required: true, message: '有効期間を入力してください' }]}>
                                <RangePicker 
                                    className='w-full'
                                    suffixIcon={renderIconDatePicker()}
                                    placeholder={['yyyy-mm-dd', 'yyyy-mm-dd']}
                                    separator={renderSeparator()}
                                    locale={locale}
                                    format={['YYYY-MM-DD', 'YYYY-MM-DD']}
                                    disabledDate = {disabledDate}
                                />
                            </Form.Item>
                        </div>
                        <div className='mb-[16px]'>
                            <label className='labe-form'>支払方法</label>
                            <Form.Item initialValue={1} name='payment_type' rules={[{ required: true, message: '支払方法を入力してください' }]}>
                                <Radio.Group className='flex'>
                                    <Radio value={1} className='radio-label w-3/6'>クレジットカード</Radio>
                                    <Radio value={2} className='radio-label w-3/6'>銀行振込</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                    </div>
                    <div className='footer-form flex'>
                        <Button className='button-form mr-[16px]' onClick={handleBackLicenseList}>戻る</Button>
                        <Button 
                            disabled={isDisableSubmit} 
                            className='button-form button-form-Submit' 
                            loading={isloading}
                            onClick={handleCreateLicense}
                        >
                            <span></span>{location.pathname?.includes('edit') ? '保存' :  '作成'}
                        </Button>
                    </div>
                </Form>
            }
            <ModalConfirm 
                isShowModal={isVisibleModelConfirmBack}
                onCancel={handleCancel}
                onOk={handleGoBackLicenseList}
                content='このページを離れますか？'
                subContent='ページから移動すると、保存されていない変更は失われます。'
            />
       </div>
    )
}

export default LicenseCreateComponent