import React from 'react'
import StaffCreateComponent from 'src/components/features/agency/staffCreateComponent'
import { LayoutAgency } from 'src/components/layouts/layoutAgency'

const StaffCreatePage = () => (
    <LayoutAgency>
       <StaffCreateComponent />
    </LayoutAgency>
)

export default StaffCreatePage