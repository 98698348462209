import Cookies from 'js-cookie'

export const setLocalStorage = (key: string, value: string) => {
  window.localStorage.setItem(key, value)
}

export const getLocalStorage = (key: string) => {
  const value = window.localStorage.getItem(key)
  return value === null ? '' : value
}

export const getCookie = (key: string) => {
  const value = Cookies.get(key)
  return value
}

export const setCookie = (key: string, value: string) => {
  let now = new Date();
  const time = now.getTime();
  const expireTime = time + 1000*86400;
  now.setTime(expireTime);
  Cookies.set(key, value, { path: '/',  expires:1})
}

export const clearToken = () => {
  Cookies.set('token', '')
}