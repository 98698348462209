import { useQuery } from 'react-query'
import { api } from 'src/api/axios'
import { QUERY_KEY } from '../query-key'
export const useListRequestLicense = (keyWord: string, status:number | undefined, page: number, size:number, paymentMethod?: number | undefined) => useQuery(
    [QUERY_KEY.LIST_REQUEST_LICENSE, keyWord,status, page, size, paymentMethod],
    async () => {
      const res = await api.get('/api/cms/license', {
        params: {
          keyword: keyWord,
          page:page, 
          size: size,
          status: status,
          payment_method: paymentMethod
        },
      })
      return res.data.data
    },
  )
  