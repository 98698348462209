import { Input, Pagination, Select, Table } from 'antd'
import{useMemo, useState } from 'react'
import searchIcon from 'src/assets/images/search-icon.png'
import type { ColumnsType } from 'antd/es/table';
import {pageSizeList } from 'src/constants'
import { staffCreateType } from 'src/components/features/agency/type';
import TableNoteNoData from 'src/components/common/tableNoteNoData'
import iconSelect from 'src/assets/images/arrow-down-2.png'
import { useCompanyLicenseList } from 'src/api/data/companyManagement/useListLicense';
interface DataType {
    key: string;
    name: string;
    location: string;
    updated_at: string;
}

const columns: ColumnsType<DataType> = [
    {
      title: '請求書番号',
      key: 'invoice_code',
      dataIndex: 'invoice_code',
      width: 150
    },
    {
      title: '数量',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 200
    },
    {
      title: '合計金額(円)',
      dataIndex: 'price',
      key: 'price',
      width: 200
    },
    {
      title: '開始日',
      key: 'start_time',
      dataIndex:'start_time',
      width:200
    },
    {
        title: '終了日',
        key: 'end_time',
        dataIndex:'end_time',
        width:200
    },
    {
        title: '代理店',
        key: 'agency',
        dataIndex:'agency',
        width:300
    },
    {
      title: '状態',
      key: 'status',
      dataIndex:'status',
      width:200
    },
]

const licenseStatus = [
    {
        value:2,
        label: '有効',
        title: ''
    },
    {
        value:4,
        label: '無効',
        title: ''
    },
]

const LicenseManagement = () => {
    const [keyword, setKeyword] = useState<string>('')
    const [keywordSearch, setKeywordSearch] = useState<string>('')
    const [status, setStatus] = useState<number | undefined>()
    const [page, setPage] = useState<number>(1)
    const [sizePage, setSizePage] = useState<number>(10)
    const { data: listLicense, isLoading } = useCompanyLicenseList(keywordSearch,status, page, sizePage)
    const dataSource = useMemo(() => {
      if (listLicense) {
        return listLicense?.records?.map((license: staffCreateType, index: number) => ({
          ...license,
          key: license?.id,
          status: (
            <span className={`company-request-status inline-block text-center ${license?.status === 2 ? 'approved-color' : 'reject-color'}`}>
              {
                license?.status === 2 ? '有効' : '無効'
              }
            </span>
          ),
        }))
      }
      return []
    }, [listLicense])

    const handleChangeKeyword = (value:string) => {
      setKeyword(value)
    }

    const iconSearch = () => {
      return <div className='p-[5px] cursor-pointer' onClick={handleSearchConstruction}><img src={searchIcon} alt='' /></div>
    }

    const handleChangeSizePage = (value: number) => {
      setPage(1)
      setSizePage(value)
    }

    const handleChangePage = (page: number) => {
      setPage(page)
    }

    const handleSearchConstruction = () => {
        setPage(1)
        setKeywordSearch(keyword)
    }

    const handleChangeStatus = (status: number) => {
      setStatus(status)
    }

    const renderSelectIcon = () => {
      return <img src={iconSelect} alt='' />
    }
    
    return (
            <div className='page-content-table'>
              <div className='flex justify-between items-center p-[16px]'>
                    <div className='flex items-center'>
                      <Input 
                          onPressEnter={handleSearchConstruction} 
                          className='input-search input-search-staff mr-[8px]' 
                          placeholder='請求書番号を入力' 
                          suffix={iconSearch()} 
                          onChange={(e) => handleChangeKeyword(e.target.value)}
                      />
                      <Select 
                          options={licenseStatus}
                          placeholder="状態"
                          className='company-select'
                          allowClear={true}
                          onChange={(value: number) => handleChangeStatus(value)}
                          suffixIcon={renderSelectIcon()}
                      />
                    </div>
              </div>
              <div className='constuction-list'>
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    loading={isLoading}
                    locale={{
                      emptyText: (
                        <TableNoteNoData />
                      )
                    }}
                  />
              </div>
              <div className='py-[20px] px-[12px] flex justify-between items-center'>
                <div className='pagination-infor'>
                  {listLicense?.limitFrom} - {listLicense?.limitTo} 件表示/全 {listLicense?.totalRow} 件中
                </div>
                <div className='flex'>
                  <Pagination 
                    current={page} 
                    total={listLicense?.totalRow} 
                    className='pagination-main mr-[20px]' 
                    showSizeChanger={false}
                    pageSize={sizePage}
                    onChange={(page) => handleChangePage(page)}
                  />
                  <Select
                      defaultValue={10}
                      style={{ width: 153 }}
                      className='pagination-options'
                      onChange={(e) => handleChangeSizePage(e)}
                      options={pageSizeList}
                  />
                </div>
              </div>
            </div>
    )
}

export default LicenseManagement