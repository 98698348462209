import React from 'react'
import CompanyRequestListComponent from 'src/components/features/superAdmin/companyRequestListComponent'
import { LayoutSuperAdmin } from 'src/components/layouts/layoutSuperAdmin'

const CompanyRequestListPage = () => (
    <LayoutSuperAdmin>
       <CompanyRequestListComponent />
    </LayoutSuperAdmin>
)

export default CompanyRequestListPage