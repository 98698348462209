import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import ModalConfirm from 'src/components/common/modal/modalConfirm'
import MixTonnageSettingComponent from 'src/components/features/locationSiteDetail/mixTonnageSetting'
import { LayoutDetail } from 'src/components/layouts/layoutDetail'

const MixTonnageSettingPage = () => {
    const [isValue,setIsValue] = useState(false)
    const [isVisibleModelConfirmBack,setIsVisibleModelConfirmBack] = useState(false)
    const history = useHistory()
    const { id } = useParams<{ id: string }>()
    const handleGoBack = (id: number) => {
        if(isValue) {
            setIsVisibleModelConfirmBack(true)
        }else {
            onGoBack()
        }
    }
    const handleCheckValueForm = (value: boolean) => {
        setIsValue(value)
    }

    const handleCancel = () => {
        setIsVisibleModelConfirmBack(false)
    }

    const onGoBack = () => {
        history.push(`/construction-site/${id}/location-site`)
    }
    return (
        <LayoutDetail handleGoBack={handleGoBack}>
            <MixTonnageSettingComponent handleCheckValueForm={handleCheckValueForm}/>
            <ModalConfirm 
                isShowModal={isVisibleModelConfirmBack}
                onCancel={handleCancel}
                onOk={onGoBack}
                content='このページを離れますか？'
                subContent='ページから移動すると、保存されていない変更は失われます。'
            />
        </LayoutDetail>
    )
}

export default MixTonnageSettingPage