import React from 'react'
import StaffListComponent from 'src/components/features/agency/staffListComponent'
import { LayoutAgency } from 'src/components/layouts/layoutAgency'

const StaffListPage = () => (
    <LayoutAgency>
       <StaffListComponent isAdmin={false}/>
    </LayoutAgency>
)

export default StaffListPage