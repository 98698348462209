import React from 'react'
import ChangePasswordComponent from 'src/components/features/userInfor/changePassword'
import { LayoutOnlyHeader } from 'src/components/layouts/layoutOnlyHeader'
const ChangePasswordPage = () => {
    return (
        <LayoutOnlyHeader>
            <ChangePasswordComponent />
        </LayoutOnlyHeader>
    )
}

export default ChangePasswordPage