/* eslint-disable react-hooks/exhaustive-deps */
import 'moment/locale/ja';
import { Button, Form, Input, Modal, Pagination, Select, Table, message } from 'antd';
import { useMemo, useState } from 'react';
import searchIcon from 'src/assets/images/search-icon.png';
import addIcon from 'src/assets/images/add-icon.png';
import constructionViewIcon from 'src/assets/images/view-icon.png';
import { useHistory } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import { useListCategory } from 'src/api/data/agency/useListCategory';
import { categoryCreateType } from 'src/components/features/agency/type';
import TableNoteNoData from 'src/components/common/tableNoteNoData';
import editIcon from 'src/assets/images/edit-icon.png';
import deleteIcon from 'src/assets/images/delete-icon.png';
import { useMutation, useQueryClient } from 'react-query';
import { createCategory, deleteCategory, editCategory } from 'src/api/admin';
import { QUERY_KEY } from 'src/api/data/query-key';
import ModalConfirm from 'src/components/common/modal/modalConfirm';
import { pageSizeList } from 'src/constants';

interface DataType {
  key: string;
  name: string;
  location: string;
  updated_at: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'ID',
    key: 'no',
    dataIndex: 'no',
    width: 60,
  },
  {
    title: 'カテゴリー名',
    dataIndex: 'name',
    key: 'name',
    width: 1088,
  },
  {
    title: () => {
      return <p className="text-center text-[16px] text-[#2E2E2E]">アクション</p>;
    },
    key: 'action',
    dataIndex: 'action',
    width: 100,
  },
];

const CategoryListComponent = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const [antForm] = Form.useForm();
  const [keyword, setKeyword] = useState<string>('');
  const [keywordSearch, setKeywordSearch] = useState<string>('');
  const [isModalCreateEdit, setIsModalCreateEdit] = useState(false);
  const [isDisableSubmit, setIsDisableSubmit] = useState(true);
  const [idCategory, setIdCategory] = useState<any>();
  const [isVisibleModalConfirm, setIsVisibleModalConfirm] = useState(false);
  const [categoryDelete, setCategoryDelete] = useState<any>();
  const [page, setPage] = useState<number>(1);
  const [sizePage, setSizePage] = useState<number>(10);

  const handleCancel = () => {
    setIsVisibleModalConfirm(false);
  };

  const iconSearch = () => {
    return (
      <div className="p-[5px] cursor-pointer" onClick={handleSearch}>
        <img src={searchIcon} alt="" />
      </div>
    );
  };

  const { data: listCategory, isLoading, refetch } = useListCategory(keywordSearch, page, sizePage);

  const { isLoading: isLoadingDeleta, mutate: onDelete } = useMutation((id: number | undefined) => deleteCategory(id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEY.LIST_CATEGORY);
      message.success('データ更新成功');
      setIsVisibleModalConfirm(false);
    },
    onError: (error: any) => {
      if (error.response?.status === 400) {
        message.error(error.response.data.messages[0]);
        return;
      }
      message.error('システムエラー');
    },
  });

  const dataSource = useMemo(() => {
    if (listCategory) {
      return listCategory?.records?.map((category: categoryCreateType) => ({
        ...category,
        key: category?.id,
        no: category?.id,
        name: (
          <span onClick={() => handleGoCategoryDetail(category?.id)} className="site-name line-1 cursor-pointer">
            {category?.ja_name}
          </span>
        ),
        action: (
          <div className="flex justify-center">
            <img
              className="item-name-icon cursor-pointer mr-[10px]"
              src={constructionViewIcon}
              alt=""
              onClick={() => handleGoCategoryDetail(category?.id)}
            />
            <img
              className="item-name-icon cursor-pointer mr-[10px]"
              src={editIcon}
              onClick={() => handleGoEditCategory(category)}
              alt=""
            />
            <img
              className="item-name-icon cursor-pointer mr-[10px]"
              src={deleteIcon}
              alt=""
              onClick={() => handleOpenModalDelete(category)}
            />
          </div>
        ),
      }));
    }
    return [];
  }, [listCategory]);

  const handleConfirmDelete = () => {
    onDelete(categoryDelete?.id);
  };

  const handleOpenModalDelete = (categoryDelete: any) => {
    setIsVisibleModalConfirm(true);
    setCategoryDelete(categoryDelete);
  };

  const handleChangeKeyword = (value: string) => {
    setKeyword(value);
  };

  const handleGoCategoryDetail = (id: number | undefined) => {
    history.push(`/cms/categories/${id}`);
  };

  const handleChangeSizePage = (value: number) => {
    setPage(1);
    setSizePage(value);
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleSearch = () => {
    setPage(1)
    setKeywordSearch(keyword?.trim());
    setKeyword(keyword?.trim());
  };

  const handleGoToCategoryCreate = () => {
    antForm.resetFields();
    setIdCategory(undefined);
    setIsDisableSubmit(true);
    setIsModalCreateEdit(true);
  };

  const handleGoEditCategory = (category: categoryCreateType) => {
    antForm.setFields([
      {
        name: 'ja_name',
        value: category?.ja_name,
        errors: [],
      },
      {
        name: 'en_name',
        value: category?.en_name,
        errors: [],
      },
    ]);

    setIdCategory(category.id);
    setIsModalCreateEdit(true);
  };

  const handleCloseModal = () => {
    setIsModalCreateEdit(false);
  };

  const handleChangeValue = (allvalues: []) => {
    const listValue = Object.keys(allvalues)?.map((key: any) => allvalues[key]);
    const validateErrorValueList: any[] = [];
    listValue?.forEach((value: any) => {
      if (value === undefined || value === '') {
        validateErrorValueList.push(value);
      }
    });
    if (validateErrorValueList.length > 0) {
      setIsDisableSubmit(true);
    } else {
      setIsDisableSubmit(false);
    }
  };

  const handleSubmitForm = async (values: any) => {
    await trimmedValue();
    try {
      await antForm.validateFields();
    } catch {
      return;
    }
    const params = {
      ...values,
      id: idCategory,
    };
    if (idCategory) {
      onEditCategory(params);
    } else {
      onCreateCategory({
        ...values,
      });
    }
  };

  const { isLoading: isloadingCreate, mutate: onCreateCategory } = useMutation(
    (param: categoryCreateType) => createCategory(param),
    {
      onSuccess: (res) => {
        message.success('データ更新成功');
        setIsModalCreateEdit(false);
        refetch();
      },
      onError: (error: any) => {
        if (error.response?.status === 400) {
          message.error(error.response.data.messages[0]);
          return;
        }
        message.error('システムエラー');
      },
    }
  );

  const { isLoading: isloadingEdit, mutate: onEditCategory } = useMutation(
    (param: categoryCreateType) => editCategory(param, idCategory),
    {
      onSuccess: (res) => {
        message.success('カテゴリが正常に編集されました');
        setIsModalCreateEdit(false);
        refetch();
      },
      onError: (error: any) => {
        if (error.response?.status === 400) {
          message.error(error.response.data.messages[0]);
          return;
        }
        message.error('システムエラー');
      },
    }
  );

  const trimmedValue = async () => {
    const allValueForm = antForm.getFieldsValue();
    Object.keys(allValueForm).forEach((key: any) => {
      if (typeof allValueForm[key] === typeof '') {
        antForm.setFieldsValue({ [key]: allValueForm[key]?.trim() });
      }
    });
  };

  return (
    <div className="page-content company-page">
      <div className="mb-[16px]">
        <h2 className="page-content-title border-bottom-title">FAQ管理</h2>
      </div>

      <div className="page-content-table">
        <div className="flex justify-between items-center p-[16px]">
          <div className="flex items-center">
            <Input
              onPressEnter={handleSearch}
              className="input-search mr-[8px]"
              placeholder="カテゴリー名"
              suffix={iconSearch()}
              onChange={(e) => handleChangeKeyword(e.target.value)}
              value={keyword}
            />
          </div>

          <Button
            className="button-add"
            onClick={() => {
              handleGoToCategoryCreate();
            }}
          >
            <img className="button-add-icon" src={addIcon} alt="" />
            カテゴリー追加
          </Button>
        </div>

        <div className="constuction-list">
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            loading={isLoading}
            locale={{
              emptyText: <TableNoteNoData />,
            }}
          />
        </div>
        <div className="py-[20px] px-[12px] flex justify-between items-center">
          <div className="pagination-infor">
            {listCategory?.limitFrom} - {listCategory?.limitTo} 件表示/全 {listCategory?.totalRow} 件中
          </div>
          <div className="flex">
            <Pagination
              current={page}
              total={listCategory?.totalRow}
              className="pagination-main mr-[20px]"
              showSizeChanger={false}
              pageSize={sizePage}
              onChange={(page) => handleChangePage(page)}
            />
            <Select
              defaultValue={10}
              style={{ width: 153 }}
              className="pagination-options"
              onChange={(e) => handleChangeSizePage(e)}
              options={pageSizeList}
            />
          </div>
        </div>
      </div>

      <Modal
        centered
        className="modal-category"
        title={idCategory ? 'カテゴリの編集' : 'カテゴリの追加'}
        width={832}
        open={isModalCreateEdit}
        onCancel={handleCloseModal}
        footer={false}
        maskClosable={false}
      >
        <Form
          form={antForm}
          className="form"
          onFinish={(values) => handleSubmitForm(values)}
          onValuesChange={(value, allvalues) => handleChangeValue(allvalues)}
        >
          <div className="form_item">
            <div className="language text-base font-bold leading-8 tracking-wider mb-2">日本語</div>

            <label className="label_form text-base font-bold leading-6 tracking-wide inline-block mb-1">
              カテゴリ名 <span>*</span>
            </label>
            <Form.Item
              name="ja_name"
              rules={[
                { required: true, message: 'カテゴリ名を入力してください' },
                { max: 255, message: 'カテゴリ名は255文字以内で入力してください' },
              ]}
            >
              <Input className="input_form" />
            </Form.Item>
          </div>

          <div className="form_item">
            <div className="language text-base font-bold leading-8 tracking-wider mb-2">英語</div>
            <label className="label_form text-base font-bold leading-6 tracking-wide inline-block mb-1">
              カテゴリ名（英語） <span>*</span>
            </label>
            <Form.Item
              name="en_name"
              rules={[
                { required: true, message: 'カテゴリ名（英語）を入力してください' },
                { max: 255, message: 'カテゴリ名（英語）は255文字以内で入力してください' },
              ]}
            >
              <Input className="input_form" />
            </Form.Item>
          </div>

          <div className="footer-form flex">
            <Button className="button-form mr-[16px]" onClick={handleCloseModal}>
              戻る
            </Button>
            <Button
              loading={isloadingCreate || isloadingEdit}
              disabled={isDisableSubmit}
              className="button-form button-form-Submit"
              htmlType="submit"
            >
              <span></span>
              {idCategory ? '保存' : '追加'}
            </Button>
          </div>
        </Form>
      </Modal>

      <ModalConfirm
        isShowModal={isVisibleModalConfirm}
        onCancel={handleCancel}
        onOk={handleConfirmDelete}
        content={'カテゴリ削除確認'}
        subContent={`選択した「${
          categoryDelete?.ja_name.length > 10
            ? `${(categoryDelete?.ja_name).substring(0, 10)}...`
            : categoryDelete?.ja_name
        }」を削除してもよろしいですか？`}
        loadingButtonOk={isLoadingDeleta}
        isIcon={false}
      />
    </div>
  );
};

export default CategoryListComponent;
