import React from 'react'
import LocationSiteComponent from 'src/components/features/locationSite/locationSiteComponent'
import { LayoutMoreNote } from 'src/components/layouts/layoutMoreNote'
import { ConstructionDetailProvider } from 'src/context/ConstructionDetailContext'

const LocationSitePage = () => (
    <ConstructionDetailProvider>
        <LayoutMoreNote>
            <LocationSiteComponent />
        </LayoutMoreNote>
    </ConstructionDetailProvider>
    
)

export default LocationSitePage