import { Button, Form, Input, Spin, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useCallback } from 'react'
import { useMutation } from 'react-query'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { createConstruction, editConstruction } from 'src/api/construction'
import { useConstructionDetail } from 'src/api/data/construction/useConstructionDetail'
import { constructionType } from './type'
import ModalConfirm from 'src/components/common/modal/modalConfirm'
import { trimmedValue } from 'src/utils'
import leftArrow from 'src/assets/images/back-icon.png'
import { redirectTypeData } from 'src/constants'

const CreateConstructionSiteComponent = () => {
    const location = useLocation()
    const [antForm] = Form.useForm()
    const history = useHistory()
    const { id } = useParams<{ id: string }>()
    const { data: constructionDetail, isLoading } = useConstructionDetail(Number(id)) 
    const [isDisableSubmit,setIsDisableSubmit] = useState(true)
    const [isVisibleModelConfirmBack,setIsVisibleModelConfirmBack] = useState(false)
    const [isValueForm,setIsValueForm] = useState(false)
    const urlSearchParams = new URLSearchParams(location.search).get("redirectType")
    const {isLoading: isloading, mutate: onCreateConstruction } = useMutation(
        (param: constructionType) => createConstruction(param),
        {
          onSuccess: (res) => {
            message.success('データ更新成功')
            history.push('/construction-site')
            antForm.resetFields()
          },
          onError: (error: any) => {
            if (error.response?.status === 400) {
                message.error(error.response.data.messages[0])
                return
            }
            message.error('システムエラー')
          },
        },
    )

    const {isLoading: isloadingEdit, mutate: onEditConstruction } = useMutation(
        (param: constructionType) => editConstruction(Number(id),param),
        {
          onSuccess: (res) => {
            message.success('データ更新成功')
            history.push('/construction-site')
            antForm.resetFields()
          },
          onError: (error: any) => {
            if (error.response?.status === 400) {
                message.error(error.response.data.messages[0])
                return
            }
            message.error('システムエラー')
          },
        },
    )

    const handleCreateEditConstruction = useCallback(async() => {
        await trimmedValue(antForm)
        try {
            await antForm.validateFields()
        } catch { return }
        const values = antForm.getFieldsValue()
        const paramLogin = {
            name: values.name,
            orderer_name: values.orderer_name,
            location: values.location,
            company_name: values.company_name
        }
        if(id) {
            return onEditConstruction(paramLogin)
        }else {
            return onCreateConstruction(paramLogin)
        }
    },[antForm, id, onCreateConstruction, onEditConstruction])

    useEffect(() => {
        antForm.setFieldsValue(constructionDetail)
    }, [antForm, constructionDetail])

    const handleChangeValue = (allvalues: []) => {
        const listValue = Object.keys(allvalues).map((key: any) => allvalues[key])
        const validateErrorValueList = []
        listValue?.forEach((value: any) => {
            if(value === undefined || value === '') {
                validateErrorValueList.push(value)
            }
        })
        if(validateErrorValueList.length > 0) {
            setIsDisableSubmit(true)
        }else{
            setIsDisableSubmit(false)
        }

        listValue?.some((value:any) => {
            if(value !== undefined && value !== '' && value != null) {
                setIsValueForm(true)
                return true
            }
            setIsValueForm(false)
            return false
        })
    }

    const handleCancel = () => {
        setIsVisibleModelConfirmBack(false)
    }

    const handleGoBack = () => {
        if(Number(urlSearchParams) === redirectTypeData.DETAIL) {
            history.push(`/construction-site/detail/${id}`)
        }else {
            history.push('/construction-site')
        }
    }

    const handleBack = () => {
        if(isValueForm) {
            setIsVisibleModelConfirmBack(true)
        }else{
            handleGoBack()
        }
    }

    useEffect(() => {
        if(id) {
            setIsDisableSubmit(false)
        }
    },[id])

    const validateFields = (rule: any, value: string, callback: any, name: string) => {
        if (!value?.trim()) {
            callback(`${name}を入力してください`);
        }else if(value?.trim()?.length > 255) {
            callback(`${name}は255文字以内で入力してください`)
        }else {
            callback()
        }
    }

    

    
    return (
       <div className='construction-create p-[16px]'>
            <h2 className='page-content-title border-bottom-title mb-[30px]'>
                <img src={leftArrow} alt='' className='inline-block mr-[17px] cursor-pointer' onClick={handleBack}/>
                {location.pathname?.includes('edit') ? '工事変更' :  '新規工事作成'}
            </h2>
            {
                isLoading ? <Spin /> :
                <Form form={antForm} onValuesChange={(value, allvalues) => handleChangeValue(allvalues)} initialValues={constructionDetail} className='form-create'>
                    <div className='px-[20px] py-[12px]'>
                        <div className='mb-[16px]'>
                            <label className='labe-form'>工事名 <span>*</span></label>
                            <Form.Item name='name' rules={
                                [                                    
                                    { validator: (rule, value, callback) => validateFields(rule,value,callback, '工事名')}
                                ]
                            }>
                                <Input className='input-form'/>
                            </Form.Item>
                        </div>
                        <div className='mb-[16px]'>
                            <label className='labe-form'>発注者名 <span>*</span></label>
                            <Form.Item 
                                name='orderer_name' 
                                rules={
                                    [                                    
                                        { validator: (rule, value, callback) => validateFields(rule,value,callback, '発注者名')}
                                    ]
                                }
                            >
                                <Input className='input-form'/>
                            </Form.Item>
                        </div>
                        <div className='mb-[16px]'>
                            <label className='labe-form'>工事所在地 <span>*</span></label>
                            <Form.Item 
                                name='location' 
                                rules={
                                    [                                    
                                        { validator: (rule, value, callback) => validateFields(rule,value,callback, '工事所在地')}
                                    ]
                                }
                            >
                                <Input className='input-form'/>
                            </Form.Item>
                        </div>
                        <div className='mb-[16px]'>
                            <label className='labe-form'>会社名 <span>*</span></label>
                            <Form.Item 
                                name='company_name' 
                                rules={
                                    [                                    
                                        { validator: (rule, value, callback) => validateFields(rule,value,callback, '会社名')}
                                    ]
                                }
                            >
                                <Input className='input-form'/>
                            </Form.Item>
                        </div>
                    </div>
                    <div className='footer-form flex'>
                        <Button className='button-form mr-[16px]' onClick={() =>handleBack()}>戻る</Button>
                        <Button disabled={isDisableSubmit} className='button-form button-form-Submit' loading={isloading || isloadingEdit} onClick={() =>handleCreateEditConstruction()}>
                            <span></span>{location.pathname?.includes('edit') ? '保存' :  '作成'}
                        </Button>
                    </div>
                </Form>
            }
            <ModalConfirm 
                isShowModal={isVisibleModelConfirmBack}
                onCancel={handleCancel}
                onOk={handleGoBack}
                content='このページを離れますか？'
                subContent='ページから移動すると、保存されていない変更は失われます。'
            />
       </div>
    )
}

export default CreateConstructionSiteComponent