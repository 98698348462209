/* eslint-disable react-hooks/exhaustive-deps */
import './agency.modules.scss'
import { Button, Input, Pagination, Select, Table, message } from 'antd'
import React, { useMemo, useState } from 'react'
import searchIcon from 'src/assets/images/search-icon.png'
import addIcon from 'src/assets/images/add-icon.png'
import viewIcon from 'src/assets/images/view-icon.png'
import { useHistory } from 'react-router-dom'
import type { ColumnsType } from 'antd/es/table';
import {staffAgencyStatus, pageSizeList } from 'src/constants'
import { getClassStatusStaffAgency, handleGetStatusStaffAgency } from 'src/utils';
import { staffCreateType } from 'src/components/features/agency/type';
import deleteIcon from 'src/assets/images/delete-icon.png'
import editIcon from 'src/assets/images/edit-icon.png'
import { useListStaff } from 'src/api/data/agency/useListStaff'
import ModalDelete from 'src/components/common/modalDelete'
import { QUERY_KEY } from 'src/api/data/query-key'
import { useMutation, useQueryClient } from 'react-query'
import { deleteStaffAgency } from 'src/api/agency'
import TableNoteNoData from 'src/components/common/tableNoteNoData'
import iconSelect from 'src/assets/images/arrow-down-2.png'
interface DataType {
    key: string;
    name: string;
    location: string;
    updated_at: string;
}
interface StaffListComponentProp {
  isAdmin?: boolean
}
const StaffListComponent:React.FC<StaffListComponentProp> = (props) => {
    const columns: ColumnsType<DataType> = [
      {
        title: props?.isAdmin ? '管理者コード' : '担当者コード',
        key: 'membership_code',
        dataIndex: 'membership_code',
        width: 115
      },
      {
        title: props?.isAdmin ? '管理者名' : '担当者名',
        dataIndex: 'name',
        key: 'name',
        width: 430
      },
      {
        title: 'メールアドレス',
        dataIndex: 'email',
        key: 'email',
        width: 430
      },
      {
        title: '電話番号',
        key: 'phone',
        dataIndex:'phone',
        width:160
      },
      {
        title: '状態',
        key: 'status',
        dataIndex:'status',
        width:120
      },
      {
        title: () => {
          return <p className='text-center text-[16px] text-[#2E2E2E]'>アクション</p>
        },
        key: 'action',
        dataIndex:'action',
        width:140
      }
    ]
    const history = useHistory()
    const queryClient = useQueryClient()
    const [keyword, setKeyword] = useState<string>('')
    const [keywordSearch, setKeywordSearch] = useState<string>('')
    const [status, setStatus] = useState<number | undefined>()
    const [page, setPage] = useState<number>(1)
    const [sizePage, setSizePage] = useState<number>(10)
    const { data: listStaff, isLoading } = useListStaff(keywordSearch,status, page, sizePage)
    const [isShowModalConfirmDelete, setIsShowModalConfirmDelete] = useState(false)
    const [idDelete, setIdDelete] = useState<number | undefined>()
    const dataSource = useMemo(() => {
      if (listStaff) {
        return listStaff?.records?.map((staff: staffCreateType, index: number) => ({
          ...staff,
          key: staff?.id,
          name: (
            <span onClick={() =>handleGoToDetailStaff(staff?.id)} className='site-name line-1 cursor-pointer'>{staff?.name}</span>
          ),
          status: (
            <span className={`company-request-status inline-block text-center ${getClassStatusStaffAgency(staff?.status)}`}>
              {
                 handleGetStatusStaffAgency(staff?.status)
              }
            </span>
          ),
          email: (
            <span className='line-1 break-all'>{staff?.email}</span>
          ),
          action: (
            <div className='flex justify-center'>
              <img 
                className='item-name-icon cursor-pointer mr-[10px]' 
                src={viewIcon} 
                alt=""
                onClick={() => handleGoToDetailStaff(staff?.id)}
              />
              <img 
                className='item-name-icon cursor-pointer mr-[10px]' 
                src={editIcon} 
                alt=""
                onClick={() =>handleGoEditStaff(staff?.id)}
              />
              <img 
                className='item-name-icon cursor-pointer mr-[10px]' 
                src={deleteIcon} 
                alt=""
                onClick={() =>handleConfirmDelete(staff?.id)}
              />
            </div>
          ),
        }))
      }
      return []
    }, [listStaff])

    const handleChangeKeyword = (value:string) => {
      setKeyword(value)
    }

    const iconSearch = () => {
      return <div className='p-[5px] cursor-pointer' onClick={handleSearchConstruction}><img src={searchIcon} alt='' /></div>
    }

    const handleChangeSizePage = (value: number) => {
      setPage(1)
      setSizePage(value)
    }

    const handleChangePage = (page: number) => {
      setPage(page)
    }

    const handleGoEditStaff = (id: number | undefined) => {
      if(props?.isAdmin) {
        history.push(`/cms/staff/edit/${id}`)
      }else {
        history.push(`/agency/staff/edit/${id}`)
      }
    }

    const handleSearchConstruction = () => {
        setPage(1)
        setKeywordSearch(keyword)
    }

    const handleGoToStaffCreatePage = () => {
      if(props?.isAdmin) {
        history.push('/cms/staff/create')
      }else {
        history.push('/agency/staff/create')
      }
    }

    const handleGoToDetailStaff = (id: number | undefined) => {
      if(props?.isAdmin) {
        history.push(`/cms/staff/${id}`)
      }else {
        history.push(`/agency/staff/${id}`)
      }
  }

    const handleChangeStatus = (status: number) => {
      setStatus(status)
    }

    const handleCancelDelete = () => {
      setIsShowModalConfirmDelete(false)
    }

    const handleConfirmDelete = (id: number | undefined) => {
      setIdDelete(id)
      setIsShowModalConfirmDelete(true)
    }

    const {mutate: onDelete, isLoading: isloadingDelete } = useMutation(
      (id: number | undefined) => deleteStaffAgency(id),
      {
        onSuccess: async() => {
          await queryClient.invalidateQueries(QUERY_KEY.LIST_STAFF)
          message.success(props?.isAdmin ? '管理者を削除しました ' : '担当者を削除しました')
          setIsShowModalConfirmDelete(false)
        },
        onError: (error: any) => {
          if (error.response?.status === 400) {
              message.error(error.response.data.messages[0])
              return
          }
          message.error('システムエラー')
        },
      },
    )

    const handleDeleteStaffAgency = () => {
      onDelete(idDelete)
    }

    const renderSelectIcon = () => {
      return <img src={iconSelect} alt='' />
    }

    
    return (
        <div className='page-content company-page'>
            <div className='mb-[16px]'>
                <h2 className='page-content-title border-bottom-title'>{props?.isAdmin ? '管理者一覧' : '担当者一覧'}</h2>
            </div>
            <div className='page-content-table'>
              <div className='flex justify-between items-center p-[16px]'>
                    <div className='flex items-center'>
                      <Input 
                          onPressEnter={handleSearchConstruction} 
                          className='input-search input-search-staff mr-[8px]' 
                          placeholder={ props?.isAdmin ? '管理者コードもしくは管理者名を入力' : '担当者コードもしくは担当者名を入力'} 
                          suffix={iconSearch()} 
                          onChange={(e) => handleChangeKeyword(e.target.value)}
                      />
                      <Select 
                          options={staffAgencyStatus}
                          placeholder="状態"
                          className='company-select'
                          allowClear={true}
                          onChange={(value: number) => handleChangeStatus(value)}
                          suffixIcon={renderSelectIcon()}
                      />
                    </div>
                    <Button className='button-add' onClick={() => {handleGoToStaffCreatePage()}}>
                        <img className='button-add-icon' src={addIcon} alt='' />
                        { props?.isAdmin ? '管理者作成' : '担当者作成'} 
                    </Button>
              </div>
              <div className='constuction-list'>
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    loading={isLoading}
                    locale={{
                      emptyText: (
                        <TableNoteNoData />
                      )
                    }}
                  />
              </div>
              <div className='py-[20px] px-[12px] flex justify-between items-center'>
                <div className='pagination-infor'>
                  {listStaff?.limitFrom} - {listStaff?.limitTo} 件表示/全 {listStaff?.totalRow} 件中
                </div>
                <div className='flex'>
                  <Pagination 
                    current={page} 
                    total={listStaff?.totalRow} 
                    className='pagination-main mr-[20px]' 
                    showSizeChanger={false}
                    pageSize={sizePage}
                    onChange={(page) => handleChangePage(page)}
                  />
                  <Select
                      defaultValue={10}
                      style={{ width: 153 }}
                      className='pagination-options'
                      onChange={(e) => handleChangeSizePage(e)}
                      options={pageSizeList}
                  />
                </div>
              </div>
            </div>
            <ModalDelete 
              loadingButtonOk={isloadingDelete}
              isShowModal={isShowModalConfirmDelete}
              onCancel={handleCancelDelete}
              onDelete={handleDeleteStaffAgency}
              content={props?.isAdmin ? 'この管理者を削除します。よろしいですか？' : 'この担当者を削除します。よろしいですか？'}
            />
        </div>
    )
}

export default StaffListComponent