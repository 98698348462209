import React from 'react'
import ListFileLocationComponent from 'src/components/features/locationSiteDetail/listFileLocationComponent'
import { LayoutDetail } from 'src/components/layouts/layoutDetail'

const ListFileLocationPage = () => (
    <LayoutDetail>
       <ListFileLocationComponent />
    </LayoutDetail>
)

export default ListFileLocationPage