import { useQuery } from 'react-query'
import { api } from 'src/api/axios'
import { QUERY_KEY } from '../query-key'


export const useDetailAgencyAdmin = (id: number) => useQuery(
  [QUERY_KEY.DETAIL_AGENCY_ADMIN, id],
  async () => {
    if(id) {
      const res = await api.get(`/api/cms/agency/admin/${id}`)
      return res.data.data
    }
    return
  },
  {
    retry: 0,
  }
)
