import React from 'react'
import CompanyDetailComponent from 'src/components/features/superAdmin/companyDetailComponent'
import { LayoutSuperAdmin } from 'src/components/layouts/layoutSuperAdmin'

const CompanyDetailPage = () => (
    <LayoutSuperAdmin>
       <CompanyDetailComponent />
    </LayoutSuperAdmin>
)

export default CompanyDetailPage