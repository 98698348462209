import React from 'react'
import LicenseManagementComponent from 'src/components/features/companyManagement/licenseComponent'
import { LayoutCompanyAdmin } from 'src/components/layouts/layoutCompanyAdmin'

const LicenseManagementPage = () => (
    <LayoutCompanyAdmin>
       <LicenseManagementComponent />
    </LayoutCompanyAdmin>
)

export default LicenseManagementPage