import React from 'react'
import LicenseDetailComponent from 'src/components/features/agency/licenseDetailComponent'
import { LayoutAgency } from 'src/components/layouts/layoutAgency'

const LicenseDetailPage = () => (
    <LayoutAgency>
       <LicenseDetailComponent />
    </LayoutAgency>
)

export default LicenseDetailPage