import React from 'react'
import './index.modules.scss'
import HeaderCompanyAdmin from '../common/layouts/headerCompanyAdmin'
type LayoutProps = {
    children: React.ReactNode
}
export const LayoutCompanyAdmin: React.FC<LayoutProps> = ({ children }) => {
    return (
        <>
            <HeaderCompanyAdmin />
            <div className='flex layout civil-content-page'>
                <div className='w-full'>
                    {children}
                </div>
            </div>
        </>
    )
}
