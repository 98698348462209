import './header.modules.scss'
// import questionIcon from 'src/assets/images/question-circle.png'
// import settingIcon from 'src/assets/images/settings.png'
import avatarIcon from 'src/assets/images/avatar.svg'
import { Dropdown, Space, MenuProps, message } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import ProfileIcon from 'src/assets/icon/profileIcon'
import LockIcon from 'src/assets/icon/lockIcon'
import LogoutIcon from 'src/assets/icon/logoutIcon'
import { useMutation } from 'react-query'
import { AxiosError } from 'axios'
import { logout } from 'src/api/auth'
import { clearToken } from 'src/utils/cookie'
import { useQueryClient } from 'react-query';
import HomeIcon from 'src/assets/icon/homeIcon'
import { useUserInFo } from 'src/api/data/userInfo/userInfo'

const UserInfor = () => {
    const history = useHistory()
    const location = useLocation()
    const queryClient = useQueryClient()
    const {data, isLoading} = useUserInFo()
    const items: MenuProps['items'] = [ 
        {
            label:<a className='user-infor-item' href='/'>
                    <HomeIcon className={'mr-[12px] menu-icon-spec'}/>
                    <span>ホームページ</span>
                </a>,
            key: 1,
        },
        {
            label:<div className='user-infor-item' onClick={() => handleGoToProfile()}>
                    <ProfileIcon className={'mr-[12px] menu-icon'}/>
                    <span>プロフィール</span>
                </div>,
            key: 2,
        },
        {
            label:<div className='user-infor-item' onClick={() =>handleGoToChangePassword()}>
                    <LockIcon className={'mr-[12px] menu-icon'}/>
                    <span>パスワード変更</span>
                </div>,
            key: 3,
        },
        {
            label:<div className='user-infor-item' onClick={() => handleLogout()}>
                    <LogoutIcon className={'mr-[12px] menu-icon'}/>
                    <span>ログアウト</span>
                </div>,
            key: 4,
        },
    ];

    const { mutateAsync: onLogout } = useMutation(
        () => logout(),
        {
          onSuccess: async () => {
            history.push('/login')
            clearToken()
            localStorage.clear()
            queryClient.clear()
          },
          onError: (err: AxiosError) => {
            if (err.response?.status === 400) {
                message.error(err.response.data.messages[0])
                return
            }
            message.error('システムエラー')
          },
        },
    )
    const handleLogout = () => {
        onLogout()
    }

    const handleGoToChangePassword = () => {
        history.push('/change-password')
    }

    const handleGetDropdownContainer = () => {
        return document.getElementById('header')!
    }

    const handleGoToProfile = () => {
        history.push('/profile')
    }

    const handleDecodeUrl = (url: string) => {
        return decodeURIComponent(url)
    }
    return (
        <>
            <div className='flex items-center'>
                {/* <img className='header__icon' src={questionIcon} alt=''/>
                <img className='header__icon ml-[18px] mr-[26px]' src={settingIcon} alt=''/> */}
                {
                    location?.pathname !== '/login' && location?.pathname !== '/forgot-password' && location?.pathname !== '/reset_password' &&
                    <Dropdown overlayClassName="user-infor" menu={{ items }} trigger={['click']} getPopupContainer={handleGetDropdownContainer}>
                        <a href='#' onClick={e => e.preventDefault()}>
                            <Space>
                               {!isLoading && <img className='header__icon-avatar' src={data?.avatar ? handleDecodeUrl(data?.avatar) : avatarIcon} alt=''/>} 
                            </Space>
                        </a>
                    </Dropdown>
                }
            </div>
        </>
    )
}

export default UserInfor