import {Spin} from 'antd'
import { useLicenseTermInfor } from 'src/api/data/companyManagement/useLicenseTermInfor';
import LicenseTimeLineChart from './licenseTimeLineChart';
import LicenseManagement from './licenseManagement';

const LicenseManagementComponent = () => {
    const { data: licenseTermInfor, isLoading:licenseTermLoading } = useLicenseTermInfor()

    return (
        <div className='page-content company-page'>
            <div className='mb-[16px]'>
                <h2 className='page-content-title border-bottom-title'>ライセンス管理  </h2>
            </div>
            <div className='license-chart relative'>
              {
                licenseTermLoading ? <Spin className='position-center' /> :
                <LicenseTimeLineChart licenseTermInfor={licenseTermInfor}/>
              }
            </div>
            <LicenseManagement />
        </div>
    )
}

export default LicenseManagementComponent