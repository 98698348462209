import { useQuery } from 'react-query'
import { api } from 'src/api/axios'
import { QUERY_KEY } from '../query-key'


export const useListAgencyDrop = () => useQuery(
  [QUERY_KEY.LIST_AGENCY_DROP],
  async () => {
    const res = await api.get(`/api/cms/list_agency`)
      return res.data.data
  },
)
