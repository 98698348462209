import { Spin } from "antd";
import { useEffect, useRef, useState } from "react";
import { api } from "src/api/axios";
import Module from "src/libraryC";

const baseURL = process.env.REACT_APP_API
interface PreviewFileSimaProps {
  pathLineSima: string,
  pathSlineSima: string,
  visible: boolean
}
const PreviewFileSima = ({pathLineSima, pathSlineSima, visible}: PreviewFileSimaProps) => {
  const isFirstLoad = useRef(true) 
  const [listPoint3DSline,setListPoint3DSline] = useState<any>([])
  const [listNamePoint3DSline,setListNamePoint3DSline] = useState<any>()
  const [listPoint3Dline,setListPoint3Dline] = useState<any>([])
  const [isloading,setIsloading] = useState<boolean>(false)
  useEffect(() => {
    handleClearCache()
    if(pathLineSima && pathSlineSima && visible) {
      setIsloading(true)
      Module().then(async(module: any) => {
        async function readContentFile(url: string) {
          const response = await api.get(`${baseURL}/api/get-file?path=${url}`)
          return response.data;
        }
        const content1 = await readContentFile(pathLineSima);
        const content2 = await readContentFile(pathSlineSima);
        const sima3DSline = module.getSima3DSline(content1, content2);
        const sima3Dline = module.getSima3Dline(content1, content2);
        if(isFirstLoad.current) {
          for (let i = 0; i < sima3DSline.size(); i++) {
            const {name, pointList3D} = sima3DSline.get(i);
           
            
            if(i === 0) {
              setListNamePoint3DSline((prevState: {}) => ({...prevState, nameStart: name}))
            }
            if(i === sima3DSline.size() - 1) {
              setListNamePoint3DSline((prevState: {}) => ({...prevState, nameEnd: name}))
            }
            const listLine3DSline: any = []
            for (let z = 0; z < pointList3D.size(); z++) { 
              const {x, y} = pointList3D.get(z)
              console.log(x, y);
              if(typeof x === typeof 1 && typeof y === typeof 1) {
                listLine3DSline.push([x ,y])
              }
            }
            setListPoint3DSline((prevState: []) => [...prevState, listLine3DSline])
          }
          
          for (let i = 0; i < sima3Dline.size(); i++) {
            const { pointList3D} = sima3Dline.get(i);
            for (let z = 0; z < pointList3D.size(); z++) { 
              const {x, y} = pointList3D.get(z)
              if(typeof x === typeof 1 && typeof y === typeof 1) {
                setListPoint3Dline((prevState: []) => [...prevState, [x , y]])
              }
            }
          }
        }
        isFirstLoad.current = false
        sima3DSline.delete();
        sima3Dline.delete();
        setIsloading(false)
      });
    }
  }, [pathLineSima, pathSlineSima, visible]);

  useEffect(() => {
    console.log(listPoint3DSline?.length, listPoint3Dline?.length);
    
    if(listPoint3DSline?.length > 0 && listPoint3Dline?.length > 0) {
      let canvas: any = document.getElementById(`myCanvas`);
      const canvasContext = canvas.getContext("2d");
      canvas.width = 800;
      canvas.height = 800;
      const padding = 100;
      const graphWidth = canvas.width - padding * 2;
      const graphHeight = canvas.height - padding * 2;
      //start: draw center line

      const dataSliceConvert = [].concat(...listPoint3DSline)
      const data: any = listPoint3Dline.concat(dataSliceConvert)

      const maxX = Math.max(...data.map(([x, y]: [number,number]) => x));
      const minX = Math.min(...data.map(([x, y]: [number,number]) => x));
      const maxY = Math.max(...data.map(([x, y]: [number,number]) => y));
      const minY = Math.min(...data.map(([x, y]: [number,number]) => y))
      
      const xUnit = graphWidth / (maxX - minX);
      const yUnit = graphHeight / (maxY - minY);
      let unit: any
      if(Math.abs(xUnit) > Math.abs(yUnit)) {
        unit = yUnit
      }else {
        unit = xUnit
      }

      canvasContext.beginPath();

      const splitPoint3dlineList = listPoint3Dline.reduce((acc:any, curr:any, index:any) => {
        const MaximumLengthArray = 200
        if (index % MaximumLengthArray === 0) {
          acc.push([curr]);
        } else {
          acc[acc.length - 1].push(curr);
        }
        return acc;
      }, []);

      splitPoint3dlineList?.forEach((slines:any, index: number) => {
        for (let z = 0; z < slines.length - 1; z++) { 
          canvasContext.beginPath();
          const xPosMove = padding + (slines[z][0] - minX) * unit;
          const yPosMove = canvas.height - padding - (slines[z][1]- minY) * unit;
          const xPosTo = padding + (slines[z+ 1][0] - minX) * unit;
          const yPosTo = canvas.height - padding - (slines[z+ 1][1] - minY) * unit;
          canvasContext.moveTo(xPosMove,canvas.height - yPosMove);
          canvasContext.lineTo(xPosTo, canvas.height - yPosTo);
          canvasContext.strokeStyle = '#ff0000'
          canvasContext.stroke();
          if(index === 0 && z === 0) {
            canvasContext.textBaseline = "top"; 
            canvasContext.fillText(listNamePoint3DSline?.nameStart, xPosMove, canvas.height - yPosMove); 
          }
          if(index === splitPoint3dlineList?.length - 1 && z === slines.length - 2) {
            canvasContext.textBaseline = "bottom"; 
            canvasContext.fillText(listNamePoint3DSline?.nameEnd, xPosTo, canvas.height - yPosTo); 
          }
        }
      });

      //end: draw center line

      listPoint3DSline?.forEach((slines:any) => {
        for (let z = 0; z < slines.length - 1; z++) { 
          canvasContext.beginPath();
          const xPosMove = padding + (slines[z][0] - minX) * unit;
          const yPosMove = canvas.height - padding - (slines[z][1]- minY) * unit;
          const xPosTo = padding + (slines[z+ 1][0] - minX) * unit;
          const yPosTo = canvas.height - padding - (slines[z+ 1][1] - minY) * unit;
          canvasContext.moveTo(xPosMove,canvas.height - yPosMove);
          canvasContext.lineTo(xPosTo,canvas.height - yPosTo);
          canvasContext.strokeStyle = '#1d89f9'
          canvasContext.stroke();
        }
      });

      isFirstLoad.current = true

      //end: draw sline
    }
  },[listPoint3DSline, listPoint3Dline, listNamePoint3DSline])

  const handleClearCache = () => {
    setListPoint3DSline([])
    setListPoint3Dline([])
  }
  return (
    <div className="min-h-[800px]">
      {
        isloading ? <Spin className="position-center"/> : 
        <canvas id={`myCanvas`} className="mx-auto"/>
      }
    </div>
  );
};

export default PreviewFileSima;