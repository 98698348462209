
import { Button, Checkbox, Form, Input, InputNumber, Select, Spin, message } from 'antd'
import React, { useState, useMemo, useEffect, useRef, useCallback} from 'react'
import backIcon from 'src/assets/images/back-icon.png'
import arrowDownIcon from 'src/assets/images/arrow-down.png'
import deleteIcon from 'src/assets/images/delete-icon.png'
import addIcon from 'src/assets/images/add-icon-2.png'
import { useHistory, useParams, useLocation } from 'react-router'
import InputColor from 'react-input-color';
import { fabric } from 'fabric';
import { useLocationSiteListFiles } from 'src/api/data/locationSite/useLocationSiteListFiles'
import { usePunchieDetail } from 'src/api/data/locationSite/usePunchieDetail'
import { useQueryClient } from 'react-query'
import { editPunchie, overloadManagement, uploadPunchie, uploadTonnageMixing } from 'src/api/locationSite'
import { PADDING_BOTTOM_CANVAS, punchieUploadType, listTypeNameFileConstuctionZone, DEFAULT_PONCHIE_WIDTH, paddingPunch, UNIT_CANVAS } from 'src/constants'
import { handleZoomCanvas } from 'src/utils'
import downIcon from 'src/assets/images/arrow-down-3.png'
import upIcon from 'src/assets/images/arrow-up-3.png'
import ModalConfirm from 'src/components/common/modal/modalConfirm'
import copyIcon from 'src/assets/images/copy-icon.png'
import { useListFileByType } from 'src/api/data/locationSite/useListFileByType'
import { useOverloadManagement } from "src/api/data/locationSite/useOverloadManagement";
import { FileByType } from './type'
import { api } from "src/api/axios";
import Module from "src/libraryC";
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { usePunchieDetailAtReachableline } from 'src/api/data/locationSite/usePunchieDetailAtReachableline'
import { QUERY_KEY } from 'src/api/data/query-key'
import { useTonnageMixing } from 'src/api/data/locationSite/useTonnageMixing'
import debounce from 'lodash/debounce';
import PavementDiagramSettingComponent from './pavementDiagramSetting'
import CuttingDiagramSetting from './cuttingDiagramSetting'

let canvas: any;
let minimap: any;
const unit = 10
var edgedetection = 5
const baseURL = process.env.REACT_APP_API
interface pitDataType {
  left: number,
  top: number,
  width: number,
  height: number,
  type: string,
  color: string,
  id: number,
  intersectIds: any,
  orderNumber: string,
  direction: number
}

interface measuringPointType {
  x: number,
  y: number,
  name: string
}

interface typePit {
  orderNumber: string,
  direction: number,
  top: number,
  left: number,
  width: number,
  height: number,
}

interface simaSliceType {
  sliceList: number[];
  name: string;
}

const directionOptions = [
  {
    value: 1,
    label: '起点から',
  },
  {
    value: -1,
    label: '終点から',
  }
]
let pitDatas: any = []
let pitDataForApp: any = []

const strokeWidthLine = 2
const CuttingOrderDiagram = () => {
 
  const canvasRef = useRef(null);
  const minimapRef = useRef(null);
  const history = useHistory()
  const location = useLocation()
  const { idConstructionZone } = useParams<{ idConstructionZone: string }>()
  const { id } = useParams<{ id: string }>()
  const { diagramId } = useParams<{ diagramId: string }>()
  const [ponchiePitList, setPonchiePitList] = useState<any>([0])
  const [idPunchie, setIdPunchie] = useState<number | undefined>()
  const [isLoadFileFirstTime, setIsLoadFileFirstTime] = useState(true)
  const { data: locationSiteListFiles } = useLocationSiteListFiles(Number(idConstructionZone))
  const { data: punchieDetailData} = usePunchieDetailAtReachableline(Number(idConstructionZone), Number(idPunchie))
  const { data: diagramDetailData, isLoading:isloadingPunchie} = usePunchieDetail(Number(idConstructionZone), Number(diagramId))
  const { data: listFileThickness, isLoading: isLoadingFile } = useListFileByType(
    Number(idConstructionZone), 
    location.pathname.includes('cutting-order-diagram') ? 
    listTypeNameFileConstuctionZone.CUTTING_THICKNESS_FILES : 
    listTypeNameFileConstuctionZone.PAVING_THICKNESS_FILES
  )
  const [isVisibleModelConfirmBack,setIsVisibleModelConfirmBack] = useState(false)
  const [isValueForm,setIsValueForm] = useState(false)
  const [idHidenPits, setIdHidenPits] = useState<any>([])
  const [antForm] = Form.useForm()
  const [antFormCuttingSetting] = Form.useForm()
  const [antFormPavementSetting] = Form.useForm()
  const queryClient = useQueryClient()
  const [idPointCopy, setIdPointCopy] = useState<number>()
  const [idPointPate, setIdPointPate] = useState<number>()
  const [coppyPitList, setCoppyPitList] = useState<any>([])
  const { data: overloadManagementData, isLoading: isLoadingOverloadData } = useOverloadManagement(Number(idConstructionZone), location.pathname.includes('cutting-order-diagram') ? true : false)
  const { data: tonnageMixingData, isLoading: isLoadingMixingData } = useTonnageMixing(Number(idConstructionZone), location.pathname.includes('pavement-order-diagram') ? true : false)
  // const [idFileThickness, setIdFileThickness] = useState<number>()
  const [isFormChange, setIsFormChange] = useState<boolean>(false)
  // const [contentFileThinkness, setContentFileThinkness] = useState<any>()
  const [thicknessInputMethod, setThicknessInputMethod] = useState(1)
  const [simaCenterLine, setSimaCenterLine] = useState<number[]>([])
  const [simaSlice, setSimaSlice] = useState<simaSliceType[]>([])
  const [minYSlice, setMinYSlice] = useState<number>()
  const [isShowSetting, setIsShowSetting] = useState<boolean>(true)
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false)
  const handleAddPoint = (value: number, index:number) => {
    setIsLoadFileFirstTime(false)
    let items = [...ponchiePitList]
    items.splice(index, 0, value);
    setPonchiePitList(items)
    
  }

  const handleDeletePoint = (value: number) => {
    setIsLoadFileFirstTime(false)
    const listArrayRemovePoint = ponchiePitList?.filter((point: number) => point !== value)
    setPonchiePitList((prevState: []) => [...listArrayRemovePoint])
  }

  const [color, setColor] = useState<any>([]);

  const handleChangeColor = (value: any) => {
    setColor((prevState: any) => [...prevState, value])
    antForm.setFieldsValue({
      [`pit-color[${value.name}]`]: value.value?.hex
    })
  }

  const handleCopyPoint = (point: number,index: number) => {
    const valuesSetting = antForm.getFieldsValue()
    const idNewPoint = Date.now()
    handleAddPoint(idNewPoint, index)
    setIdPointPate(idNewPoint)
    setIdPointCopy(point)
    setCoppyPitList((prevState: any) => [...prevState, {
      pitId: idNewPoint,
      pitColor: valuesSetting[`pit-color[${point}]`]
    }])
  }

  useEffect(() => {
    const valuesSetting = antForm.getFieldsValue()
    antForm.setFieldsValue(
        {
            [`pit-width[${idPointPate}]`]: valuesSetting[`pit-width[${idPointCopy}]`],
            [`pit-height[${idPointPate}]`]: valuesSetting[`pit-height[${idPointCopy}]`],
            [`pit-x[${idPointPate}]`]: valuesSetting[`pit-x[${idPointCopy}]`],
            [`pit-y[${idPointPate}]`]: valuesSetting[`pit-y[${idPointCopy}]`],
            [`pit-color[${idPointPate}]`]: valuesSetting[`pit-color[${idPointCopy}]`],
            [`pit-direction[${idPointPate}]`]: valuesSetting[`pit-direction[${idPointCopy}]`],
        }
    )
    },[idPointCopy, idPointPate])

  const handleGoBack = () => {
    history.push(`/construction-site/${id}/location-site/${idConstructionZone}/list-file`)
  }

  const handleGetColor = (element: any) => {
      const objectColor = color?.filter((item: any) => item.name === element)
      return objectColor[objectColor?.length - 1]?.value?.hex
  }

  const ponchieOption = useMemo(() => {
      return locationSiteListFiles?.punches?.filter((e: any) => e.type === punchieUploadType.PUNCH_PICTURE)?.map((item: any) => (
          {
              value: item?.id,
              label: item?.name
          }
      ))
  },[locationSiteListFiles])

  useEffect(() => {
      const canvasWidth = DEFAULT_PONCHIE_WIDTH
      const canvasHeight = document.getElementById('punchie-picture')?.offsetHeight
      canvas  = new fabric.Canvas(canvasRef.current, {
        preserveObjectStacking: true,
      });
      canvas.setDimensions({width:canvasWidth, height:canvasHeight});
      canvas.selection = false
      handleZoomCanvas(canvas, updateMiniMapVP)
      const centerLineX = canvas.width / 2 - 1.5;
      const centerLineY = canvas.height;
      
      const centerLine = new fabric.Line([centerLineX, -10, centerLineX, centerLineY], {
          fill: '#C2C2C2',
          stroke: '#C2C2C2',
          strokeWidth: 3,
          strokeDashArray: [5, 5],
          originX: 'left',
          originY: 'top',
          hasControls: false,
          lockMovementY:true,
          lockMovementX: true,
          type: 'center-line',
      });
      canvas.add(centerLine);
      canvas.bringToFront(centerLine)
      canvas.renderAll();
      return () => {
        canvas.dispose();
      };
    }, [canvasRef.current]);


  //start load sima
  async function readContentFileSima(url: string) {
    const response = await api.get(`${baseURL}/api/get-file?path=${url}`)
    return response.data;
  }
  
  const handleGetDataDrawSima = useCallback(() => {
    const pathLineSima = locationSiteListFiles?.files?.find((file: {file_type: number}) => file.file_type === listTypeNameFileConstuctionZone.SIM_LINEAR_FILES)?.file_path
    const pathSlineSima = locationSiteListFiles?.files?.find((file: {file_type: number}) => file.file_type === listTypeNameFileConstuctionZone.SIM_SLICE_FILES)?.file_path
    if(pathLineSima && pathSlineSima) {
      Module().then(async(module: any) => {
        const content1 = await readContentFileSima(pathLineSima);
        const content2 = await readContentFileSima(pathSlineSima);
        const sliceSIMA = module.get2DSliceSIMA(content1, content2);
        const centerLineSimaX = canvas.width / 2 - 0.5 
        const startDistance = sliceSIMA.get(0).distance
        setMinYSlice(startDistance)
        const yEndSimaCenterLine = sliceSIMA.get(sliceSIMA.size() - 1).distance
        const yEndSimaCenterLineWithCoordinateOrigin0 = yEndSimaCenterLine - startDistance 
        canvas.setDimensions({height:yEndSimaCenterLineWithCoordinateOrigin0 * UNIT_CANVAS + 100});
        const yStartSliceChangeCoordinateOrigin = canvas.height - yEndSimaCenterLine * UNIT_CANVAS  + startDistance * UNIT_CANVAS - PADDING_BOTTOM_CANVAS - 0.5
        const yEndSliceChangeCoordinateOrigin = canvas.height - startDistance * UNIT_CANVAS  + startDistance * UNIT_CANVAS - PADDING_BOTTOM_CANVAS - 0.5
        setSimaCenterLine((prevState: number[]) => [...prevState, centerLineSimaX, yStartSliceChangeCoordinateOrigin, centerLineSimaX, yEndSliceChangeCoordinateOrigin])
        for (let i = 0; i < sliceSIMA.size(); i++) {
            const {distance,right, left, name} = sliceSIMA.get(i);
            const xStartPointSlice = Number((centerLineSimaX - right * UNIT_CANVAS).toFixed(2))
            const yPointSliceChangeCoordinateOrigin = canvas.height - distance * UNIT_CANVAS  + startDistance * UNIT_CANVAS - PADDING_BOTTOM_CANVAS - 0.5
            const xEndPointSlice = centerLineSimaX - left * UNIT_CANVAS
            setSimaSlice((prevState: any) => [...prevState, {
                sliceList: [xStartPointSlice,yPointSliceChangeCoordinateOrigin, xEndPointSlice, yPointSliceChangeCoordinateOrigin],
                name: name
              }
            ])
        }
        sliceSIMA.delete();
      });
    }
  },[locationSiteListFiles?.files])

  useEffect(() => {
    if(locationSiteListFiles && canvas) {
      handleGetDataDrawSima()
    }
  },[locationSiteListFiles, handleGetDataDrawSima])


  const handleDrawSima = useCallback((isLoadSima?:boolean) => {
    const simaCenterLineCanvas = new fabric.Line(simaCenterLine, {
        fill: '#ff0000',
        stroke: '#ff0000',
        strokeWidth: 1,
        originX: 'left',
        originY: 'top',
        hasControls: false,
        lockMovementY:true,
        lockMovementX: true,
        type: 'sima',
        name: 'sima_slice'
    });
    canvas.add(simaCenterLineCanvas);

    simaSlice?.forEach((slice) => {
        const simaSliceCanvas = new fabric.Line(slice?.sliceList, {
            fill: '#1d89f9',
            stroke: '#1d89f9',
            strokeWidth: 1,
            originX: 'left',
            originY: 'top',
            hasControls: false,
            lockMovementY:true,
            lockMovementX: true,
            type: 'sima',
            name: 'sima_slice'
        });
        canvas.add(simaSliceCanvas);
        const xStartMeasuring = paddingPunch
        const xEndMeasuring = canvas.getWidth() - paddingPunch
        const yMeasuring = slice?.sliceList[1] - 1.5
        const simaMeasuringCanvas = new fabric.Line([xStartMeasuring,yMeasuring, xEndMeasuring, yMeasuring], {
            fill: '#C2C2C2',
            stroke: '#C2C2C2',
            strokeWidth: 3,
            strokeDashArray: [5, 5],
            originX: 'left',
            originY: 'top',
        });
        let text = new fabric.IText(slice?.name, {
          fontSize: 14,
          fill: '#000000',
          top:slice?.sliceList[1] - 8,
          originX: 'left',
          originY: 'top',
        })
        text.left = 0  

        const group = new fabric.Group([simaMeasuringCanvas, text],{
            hasControls: false,
            lockMovementY:true,
            lockMovementX: true,
            hasBorders: false,
            type: 'sima',
            globalCompositeOperation: 'destination-over',
        });
        canvas.add(group);
    })
    if(isLoadSima) {
        initMinimap()
    }else {
        updateMiniMap()
    }
    canvas.renderAll();
  },[simaCenterLine, simaSlice])

  const handleHideSima = () => {
      canvas.getObjects()?.forEach((object: any) => {
          if(object?.type === 'sima') {
            canvas.remove(object);
          }
        })
      canvas.renderAll();
  }

  const handleToggleSima = (value:  CheckboxChangeEvent) => {
    if(value.target.checked) {
      canvas.getObjects()?.forEach((object: any) => {
        if(object?.type === 'measuring') {
          object.visible = false
        }
      })
      canvas.renderAll();
      handleDrawSima()
    }else {
      canvas.getObjects()?.forEach((object: any) => {
        if(object?.type === 'measuring') {
          object.visible = true
        }
      })
      canvas.renderAll();
      handleHideSima()
    }
}

  useEffect(() => {
    if(simaCenterLine?.length > 0 && simaSlice?.length > 0) {
      const isLoadSima = true
      handleDrawSima(isLoadSima)
    }
  },[handleDrawSima, simaCenterLine, simaSlice])

  //end load sima

  const handleLoadPunchie = async() => {
    const data = await JSON.parse(punchieDetailData?.draw_datas?.web)
      canvas.setDimensions({width:data?.canvasWidth});
      // canvas.setDimensions({height:data?.canvasHeight});
      canvas.setViewportTransform([1,0,0,1,0,0])
      canvas.calcOffset()
      canvas._objects = canvas._objects?.filter((object: any) => object?.type === 'sima')
      const minY = minYSlice! * 10
      const currentCanvasHeight = canvas.getHeight()
      data?.measuringPoints?.forEach((point: measuringPointType, index: number) => {
          const line = new fabric.Line([paddingPunch,currentCanvasHeight - point.y + minY - PADDING_BOTTOM_CANVAS - 1.5, canvas.getWidth() - paddingPunch,currentCanvasHeight - point.y + minY - PADDING_BOTTOM_CANVAS - 1.5], {
              fill: '#C2C2C2',
              stroke: '#C2C2C2',
              strokeWidth: 3,
              strokeDashArray: [5, 5],
              originX: 'left',
              originY: 'top',
          })
          let text = new fabric.IText(point?.name, {
              fontSize: 14,
              fill: '#000000',
              top:currentCanvasHeight - point.y + minY - PADDING_BOTTOM_CANVAS - 8,
              originX: 'left',
              originY: 'top',
          })
          
          const group = new fabric.Group([line, text],{
              hasControls: false,
              lockMovementY:true,
              lockMovementX: true,
              type: 'measuring',
              hasBorders: false,
              visible: false,
              globalCompositeOperation: 'destination-over',
          });
          canvas.add(group)
      });
      await handleUpdateCenterLine()

      data?.ponchiePoints?.forEach((data: []) => {
        canvas.add(new fabric.Polygon(data.map((item: any) => ({...item, y:currentCanvasHeight - item.y + minY - PADDING_BOTTOM_CANVAS})), {
            originX: 'left',
            originY: 'top',
            fill : '#ffffff',
            hasControls: false,
            lockMovementY:true,
            lockMovementX: true,
            strokeWidth: 3,
            stroke: '#000000',
            type: 'punch',
            hasBorders: false,
        }))
    });
      canvas.renderAll();
      canvas._objects?.forEach(async(object: any) => {
        if(object?.name === 'sima_slice') {
            object.bringToFront()
           canvas.renderAll();
        }else {
            object.sendToBack()
           canvas.renderAll();
        }
        
    })
    updateMiniMap();
  }

  const handleGetPonchie = (value: number) => {
    queryClient.removeQueries(QUERY_KEY.PUNCHIE_DETAIL_AT_REACHABLELINE)
    setIdPunchie(value)
  }

  const handleHidenPit = (id: number) => {
      const isHiden = idHidenPits?.find((item: number) => item === id)
      if(isHiden !== undefined) {
          const removeIdHiden = idHidenPits.filter((item: number) => item !== id)
          setIdHidenPits(removeIdHiden)
      }else {
          setIdHidenPits((prevState: []) => [...prevState, id])
      }
  }
  const handleOpenBlockPoint = (id: number) => {
    const isHiden = idHidenPits?.find((item: number) => item === id);
    if(isHiden >= 0){
      setIdHidenPits((prev: any) => prev.filter((item: number) => item !== id));
    }
  }

  const handleDrawPit = async () => {
      try {
          await antForm.validateFields()
      } catch { return }
      pitDatas = []
      pitDataForApp = []
      canvas._objects = canvas._objects?.filter((object: any) => object?.type !== 'pit')
      const valuesSetting = antForm.getFieldsValue()
      const listPitX: any = []
      const listPitY: any = []
      ponchiePitList?.forEach((pitId: number) => {
        pitDatas.push({
            left: (valuesSetting[`pit-x[${pitId}]`] * unit) + (canvas?.width / 2),
            top: valuesSetting[`pit-y[${pitId}]`] * unit,
            width: valuesSetting[`pit-width[${pitId}]`] * unit,
            height: valuesSetting[`pit-height[${pitId}]`] * unit,
            type: 'pit',
            color: valuesSetting[`pit-color[${pitId}]`],
            id: pitId,
            orderNumber: valuesSetting[`pit-number[${pitId}]`]?.toString(),
            direction: valuesSetting[`pit-direction[${pitId}]`],
            objectCaching: false
        })

        pitDataForApp.push({
          left: (valuesSetting[`pit-x[${pitId}]`]) + (canvas?.width / 2)/unit,
          top: valuesSetting[`pit-y[${pitId}]`],
          width: valuesSetting[`pit-width[${pitId}]`],
          height: valuesSetting[`pit-height[${pitId}]`],
          type: 'pit',
          color: valuesSetting[`pit-color[${pitId}]`],
          id: pitId,
          orderNumber: valuesSetting[`pit-number[${pitId}]`]?.toString(),
          direction: valuesSetting[`pit-direction[${pitId}]`],
          objectCaching: false
        })

        listPitX.push(Math.abs(valuesSetting[`pit-x[${pitId}]`] * unit))
        listPitY.push( Math.abs(valuesSetting[`pit-y[${pitId}]`] * unit))
      })

      const minYPunchie = minYSlice! * 10
      
      if(minYPunchie || minYPunchie === 0) {
        const currentCanvasHeight = canvas.getHeight()
        pitDatas?.forEach((data: pitDataType) => {
          const pitData: any = {
            top:currentCanvasHeight - data?.top - data?.height + minYPunchie - PADDING_BOTTOM_CANVAS,
            left: data?.left,
            width: data?.width,
            height: data?.height,
            fill: data?.color,
            cornerSize: 15,
            perPixelTargetFind: true,
            lockUniScaling: true,
            lockScalingY: true,
            minScaleLimit: 1,
            strokeWidth: 0
          }
          const pitDataInCanvas = new fabric.Rect(pitData)
          const OrderNumber = new fabric.Text(data?.orderNumber, {
            fontSize: 14,
            top: currentCanvasHeight - data?.top - data?.height + minYPunchie + ((data?.height / 2) - 7) - PADDING_BOTTOM_CANVAS,
            left: data?.left + ((data?.width / 2) - 4),
          })
  
          const settingGroup = {
            type: 'pit',
            intersectIds: [],
            id: data?.id,
            strokeWidth: 0,
            objectCaching: false
          }
          const group = new fabric.Group([pitDataInCanvas, OrderNumber],
            {
              ...settingGroup,
              lockRotation: true,
              hasRotatingPoint: false,
              globalCompositeOperation: 'source-over',
              originX: 'left',
              originY: 'top',
              lockScalingFlip: true,
            }
          )
          group.setControlsVisibility({ mtr: false })
          canvas.add(group)
          if(data?.direction === 1 ) {
            fabric.Image.fromURL(upIcon, function(Img: any) {
              Img.left = -8;
              Img.top = -22;
              Img.direction = data?.direction
              group.add(Img);
              canvas.renderAll();
            })
          }else {
            fabric.Image.fromURL(downIcon, function(Img: any) {
              Img.left = -8;
              Img.top = 8;
              Img.direction = data?.direction
              group.add(Img);
              canvas.renderAll();
            })
          }
          group.setCoords()
        })
      }
      canvas.discardActiveObject().renderAll();
      updateMiniMap();
  }

  const isHiden = (id: number) => {
      return idHidenPits?.find((item: number) => item === id)
  }

  const handleUpdateCenterLine = async() => {
    const centerLineX = canvas.width / 2 - 1.5;
    const centerLineY = canvas.height;
      
    const centerLine = new fabric.Line([centerLineX, 30, centerLineX, centerLineY], {
        fill: '#C2C2C2',
        stroke: '#C2C2C2',
        strokeWidth: 3,
        strokeDashArray: [5, 5],
        originX: 'left',
        originY: 'top',
        hasControls: false,
        lockMovementY:true,
        lockMovementX: true,
    });

    const centerLineName = new fabric.IText('CL', {
      fontSize: 14,
      fill: '#000000',
      top: 0,
      left: centerLineX - 5,
      originX: 'left',
      originY: 'top',
    })
    const groupCenterLine = new fabric.Group([centerLine, centerLineName],{
        hasControls: false,
        lockMovementY:true,
        lockMovementX: true,
        type: 'center-line',
        hasBorders: false,
        globalCompositeOperation: 'destination-over',
    });

    await canvas.add(groupCenterLine)
  }


  const handleSnapBlocks = async() => {
    const minYPunchie = minYSlice! * 10
    canvas?.getObjects().forEach(function (targ: any) {
        var activeObject = canvas?.getActiveObject();
        activeObject?.setCoords()
        if (targ === activeObject) {
          const currentCanvasHeight = canvas.getHeight()
          const activeObjectHeight = activeObject.getScaledHeight()
          let pitY = (currentCanvasHeight - activeObjectHeight + minYPunchie - PADDING_BOTTOM_CANVAS - activeObject.top)/ unit
          antForm.setFieldsValue(
            {
                [`pit-x[${activeObject.id}]`]: (activeObject.left - (canvas?.width/2)) / unit ,
                [`pit-y[${activeObject.id}]`]: (pitY > 0 ? pitY.toFixed(1) : 0),
            }
          )

          const valuesSetting = antForm.getFieldsValue()
          if(punchieDetailData?.draw_datas?.web && idPunchie === valuesSetting['ponchie']) {
            if(pitY <= 0) {
              activeObject.top = currentCanvasHeight - activeObjectHeight + minYPunchie - PADDING_BOTTOM_CANVAS
            }
          }
          return false;
        }

        if(targ.type === 'measuring' || targ.type === 'center-line' || targ?.type === 'reachable-line' || targ?.type === 'sima') {
          return false
        }

        if(activeObject && targ) {
          const targRight = targ.left + targ.getScaledWidth()
          const activeObjectRight = activeObject.left + activeObject.getScaledWidth()
          const activeObjectBot = activeObject.top + activeObject.getScaledHeight()
          const targBot = targ.top + targ.getScaledHeight()
          if(
            (activeObject?.left > targ?.left && activeObject?.left < targRight) || 
            (activeObjectRight > targ?.left && activeObjectRight < targRight) ||
            (activeObject?.left < targ?.left && activeObjectRight > targRight) || 
            (activeObject.left === targ.left && activeObjectRight === targRight)
          ) {
            if(targ.type === 'punch') {
              //snap pit and punchie
              for (let i = 0; i < targ?.points?.length - 1; i++) {
                // snap up
                if(Math.abs(activeObject?.top - (targ?.points[i].y) - targ.strokeWidth) < edgedetection && targ?.points[i].y === targ?.points[i + 1].y ) {
                  activeObject.top = targ?.points[i].y
                }
                // snap down
                if(Math.abs(activeObjectBot - (targ?.points[i].y)) < edgedetection && targ?.points[i].y === targ?.points[i + 1].y) {
                  activeObject.top = targ?.points[i].y - activeObject?.getScaledHeight()
                }
              }
            }else {
              //snap pit and pit
              handleSnapRectangleY(activeObject, targ)
            }
          }
          if(
            (activeObject?.top > targ.top && activeObject?.top < targBot) || 
            (activeObjectBot > targ.top && activeObjectBot < targBot) ||
            (activeObject?.top < targ.top && activeObjectBot > targBot) || 
            (activeObject?.top === targ.top && activeObjectBot === targBot)
          ){ 
            if(targ.type === 'punch') {
              //snap pit and punchie
              for (let i = 0; i < targ?.points?.length - 1; i++) {
                //snap left
                if((Math.abs((activeObject?.left - (targ?.points[i].x) - targ.strokeWidth)) < edgedetection) && targ?.points[i].x === targ?.points[i + 1].x) {
                  
                  activeObject.left = targ?.points[i].x 
                }
                //snap right
                if(Math.abs(activeObjectRight - (targ?.points[i].x)) < edgedetection && targ?.points[i].x === targ?.points[i + 1].x) {
                  activeObject.left = targ?.points[i].x - activeObject?.getScaledWidth()
                }
              }
            }else {
              //snap pit and pit
              handleSnapRectangleX(activeObject, targ)
            }
          }
        }

       
    });
  }

  const handleSnapRectangleY = (activeObject: any, targ: any) => {
    // snap up
    const targRight = targ.left + targ.getScaledWidth()
    const activeObjectRight = activeObject.left + activeObject.getScaledWidth()
    const activeObjectBot = activeObject.top + activeObject.getScaledHeight()
    const targBot = targ.top + targ.getScaledHeight()
    if(Math.abs(activeObject?.top - targBot - targ.strokeWidth) < edgedetection) {
      activeObject.top = targ.top + targ.getScaledHeight() + targ.strokeWidth
      if(Math.abs(activeObject?.left - targ?.left) < edgedetection) {
        activeObject.left = targ.left
      }
      if( Math.abs(activeObjectRight - targRight) < edgedetection) {
        activeObject.left = targ.left + (targ.getScaledWidth() - activeObject?.getScaledWidth())
      }
    }
    if(Math.abs(activeObject?.top - targ?.top - targ.strokeWidth) < edgedetection) {
      activeObject.top = targ.top + targ.strokeWidth
      if(Math.abs(activeObject?.left - targ?.left) < edgedetection) {
        activeObject.left = targ.left + targ.strokeWidth
      }
      if( Math.abs(activeObjectRight - targRight) < edgedetection) {
        activeObject.left = targ.left + (targ.getScaledWidth() - activeObject?.getScaledWidth())
      }
    }
  
    // snap down
    if(Math.abs(activeObjectBot - targ?.top) < edgedetection) {
      activeObject.top = targ.top - activeObject?.getScaledHeight()
    }
    if(Math.abs(activeObjectBot - targBot) < edgedetection) {
      activeObject.top = targ.top + targ.getScaledHeight() - activeObject?.getScaledHeight()
    }
    if(Math.abs(activeObjectBot - targ?.top) < edgedetection || Math.abs(activeObjectBot - targBot) < edgedetection) {
      if(Math.abs(activeObject?.left - targ?.left) < edgedetection) {
        activeObject.left = targ.left
      }
      if(Math.abs(activeObjectRight - targRight) < edgedetection){
        activeObject.left = targ.left + (targ.getScaledWidth() - activeObject?.getScaledWidth())
      }
    }
  }
      
  const handleSnapRectangleX = (activeObject: any, targ: any) => {
    //snap left
    const targRight = targ.left + targ.getScaledWidth()
    const activeObjectRight = activeObject.left + activeObject.getScaledWidth()
    const activeObjectBot = activeObject.top + activeObject.getScaledHeight()
    const targBot = targ.top + targ.getScaledHeight()
    if(Math.abs(activeObject.left - targRight) < edgedetection) {           
      activeObject.left =targ.left + targ.getScaledWidth() + targ.strokeWidth
      if(Math.abs(activeObject?.top - targ?.top) < edgedetection) {
        activeObject.top = targ.top + targ.strokeWidth
      }
      if(Math.abs(activeObjectBot - targBot) < edgedetection){
        activeObject.top = targ.top + (targ.getScaledHeight() - activeObject?.getScaledHeight())
  
      }
    }
    if(Math.abs(activeObject.left - targ?.left) < edgedetection) {              
      activeObject.left =targ.left + targ.strokeWidth
      if(Math.abs(activeObject?.top - targ?.top) < edgedetection) {
        activeObject.top = targ.top + targ.strokeWidth
      }
      if(Math.abs(activeObjectBot - targBot) < edgedetection){
        activeObject.top = targ.top + (targ.getScaledHeight() - activeObject?.getScaledHeight())
      }
    }
    //snap right
    if(Math.abs(activeObjectRight - targ?.left) < edgedetection) {             
      activeObject.left =targ.left - activeObject?.getScaledWidth() 
      if(Math.abs(activeObject?.top - targ?.top) < edgedetection) {
        activeObject.top = targ.top
      }
      if(Math.abs(activeObjectBot - targBot) < edgedetection){
        activeObject.top = targ.top + (targ.getScaledHeight() - activeObject?.getScaledHeight()) + targ.strokeWidth
      }
    }
    if(Math.abs(activeObjectRight - targRight) < edgedetection) {                
      activeObject.left =targ.left + targ.getScaledWidth() - activeObject?.getScaledWidth()
      if(Math.abs(activeObject?.top - targ?.top) < edgedetection) {
        activeObject.top = targ.top + targ.strokeWidth
      }
      if(Math.abs(activeObjectBot - targBot) < edgedetection){
        activeObject.top = targ.top + (targ.getScaledHeight() - activeObject?.getScaledHeight())
      }
    }
    // return ''
  }
    

  canvas?.on('object:moving', function (e: any) {
      var obj = e.target;
      obj.setCoords();
      setIsFormChange(true)
      handleSnapBlocks()
  });

  const onObjectScaled = async(e: any) =>
  {
    setIsFormChange(true)
    var scaledObject = e.target;
    // Reset the scaling for the rest of the objects in the group
    scaledObject._objects[1].scaleX = 1 / scaledObject.scaleX;
    scaledObject._objects[1].scaleY = 1 / scaledObject.scaleY;
    scaledObject._objects[1].zoomX = 1 / scaledObject.zoomX
    scaledObject._objects[1].zoomY = 1 / scaledObject.zoomY
    scaledObject._objects[1].left = -2 / scaledObject.scaleX;
    scaledObject._objects[1].top = -7 / scaledObject.scaleY;
    
    scaledObject._objects[2].scaleX = 1 / scaledObject.scaleX;
    scaledObject._objects[2].scaleY = 1 / scaledObject.scaleY;
    scaledObject._objects[2].zoomX = 1 / scaledObject.zoomX
    scaledObject._objects[2].zoomY = 1 / scaledObject.zoomY

    if(scaledObject._objects[2].direction === -1) {
      scaledObject._objects[2].left = -6 / scaledObject.scaleX;
      scaledObject._objects[2].top = -22 / scaledObject.scaleY;
    }else {
      scaledObject._objects[2].left = -6 / scaledObject.scaleX;
      scaledObject._objects[2].top = 8 / scaledObject.scaleY;
    }
    scaledObject.setCoords()
    canvas.discardActiveObject().renderAll();
    const minYPunchie = await handleGetMinYPunchie()
    const currentCanvasHeight = canvas.getHeight()
    const activeObjectHeight = scaledObject.getScaledHeight()
    const yPitInForm = (currentCanvasHeight - activeObjectHeight + minYPunchie - PADDING_BOTTOM_CANVAS - scaledObject.top) / unit
    antForm.setFieldsValue(
      {
          [`pit-width[${scaledObject.id}]`]: scaledObject.getScaledWidth() / unit ,
          [`pit-height[${scaledObject.id}]`]: scaledObject.getScaledHeight() / unit,
          [`pit-x[${scaledObject.id}]`]: (scaledObject.left - (canvas?.width/2)) / unit ,
          [`pit-y[${scaledObject.id}]`]: yPitInForm.toFixed(),
      }
    )
  }

  // const {isLoading: isloading, mutate: onUploadCuttingOrderDiagram } = useMutation(
  //     (param: any) => uploadPunchie(Number(idConstructionZone),param),
  //     {
  //       onSuccess: async() => {
  //         // await queryClient.invalidateQueries(QUERY_KEY.TONNAGEMIXING)
  //         message.success('データ更新成功')
  //         history.push(`/construction-site/${id}/location-site/${idConstructionZone}/list-file`)
  //       },
  //       onError: (error: any) => {
  //         if (error.response?.status === 400) {
  //             message.error(error.response.data.messages[0])
  //             return
  //         }
  //         message.error('システムエラー')
  //       },
  //     },
  // )

//   const {isLoading: isloadingEdit, mutate: onEditCuttingOrderDiagram } = useMutation(
//     (param: any) => editPunchie(Number(idConstructionZone),Number(diagramId),param),
//     {
//       onSuccess: async() => {
//         message.success('データ更新成功')
//         history.push(`/construction-site/${id}/location-site/${idConstructionZone}/list-file`)
//         // queryClient.clear()
//       },
//       onError: (error: any) => {
//         if (error.response?.status === 400) {
//             message.error(error.response.data.messages[0])
//             return
//         }
//         message.error('システムエラー')
//       },
//     },
// )

  canvas?.on('object:scaling', onObjectScaled);


  const handleGetDataUpload = async() => {
    await handleDrawPit()
    if(punchieDetailData) {
      const dataWeb = await JSON.parse(punchieDetailData?.draw_datas?.web)
      const dataApp = await JSON.parse(punchieDetailData?.draw_datas?.app)
      const valuesSetting = antForm.getFieldsValue()
      const pointDatas = {
        pitList: ponchiePitList,
        pitValue: valuesSetting,
        punchieId: valuesSetting?.ponchie
      }
  
      const drawData = {
        ponchiePoints: dataWeb?.ponchiePoints,
        measuringPoints: dataWeb?.measuringPoints,
        centerLine: dataWeb?.centerLine,
        pitDatas: pitDatas,
        canvasWidth: dataWeb?.canvasWidth,
        canvasHeight: dataWeb?.canvasHeight,
      }
  
      const drawDataApp = {
        ponchiePoints: dataApp?.ponchiePoints,
        measuringPoints: dataApp?.measuringPoints,
        centerLine: dataApp?.centerLine,
        pitDatas: pitDataForApp,
        canvasWidth: dataApp?.canvasWidth,
        canvasHeight: dataApp?.canvasHeight
      }
  
      const typeUpload = () => {
        if(location.pathname.includes('cutting-order-diagram')) {
          return punchieUploadType.PUNCH_CUTTING
        }
        if(location.pathname.includes('pavement-order-diagram')) {
          return punchieUploadType.PUNCH_PAVEMENT
        }
        return 
      }
  
      const params = {
        name: valuesSetting.name,
        type:  typeUpload(),
        point_datas: JSON.stringify(pointDatas),
        draw_datas: {
          web: JSON.stringify(drawData),
          app: JSON.stringify(drawDataApp)
        },
        punch_id: idPunchie,
        file_thickness_id: valuesSetting?.cutting_thickness_file,
        cutting_thickness_input: valuesSetting?.cutting_thickness_input
      }
      return params
    }
  }
  
  // const {isLoading: isloadingSetting, mutate: onUpdateOverloadManagement } = useMutation(
  //   (param: any) => overloadManagement(Number(idConstructionZone),param),
  //   {
  //     onSuccess: async() => {
  //       // await queryClient.invalidateQueries(QUERY_KEY.OVERLOAD_MANAGEMENT)
  //       message.success('データ更新成功')
  //     },
  //     onError: (error: any) => {
  //       console.log(error);
        
  //       if (error.response?.status === 400) {
  //           message.error(error.response.data.messages[0])
  //           return
  //       }
  //       message.error('システムエラー')
  //     },
  //   },
  // )

  
  const onUploadCuttingOrderDiagram = async (params: any) => {
    await uploadPunchie(Number(idConstructionZone),params)
  }

  const onEditCuttingOrderDiagram = async (params: any) => {
    await editPunchie(Number(idConstructionZone),Number(diagramId),params)
  }

  const onUpdateOverloadManagement = async (params: any) => {
    await overloadManagement(Number(idConstructionZone),params)
  }

  const onUploadTonnageMixing = async (params: any) => {
    await uploadTonnageMixing(Number(idConstructionZone),params)
  }

  const handleUploadCuttingOrderDiagram =  async () => {
    try {
      await antForm.validateFields()
    } catch { return }
    if(location.pathname.includes('cutting-order-diagram')) {
      try {
        await antFormCuttingSetting.validateFields()
      } catch { return }
    }else {
      try {
        await antFormPavementSetting.validateFields()
      } catch { return }
    }
    setIsLoadingUpload(true)
    const params = await handleGetDataUpload()

    await handleCheckIntersect()
    const canvasObjectPit = canvas.getObjects()?.filter((object: any) => object?.type === 'pit')
    
    const isIntersect = canvasObjectPit?.find((pit: any) => pit?.intersectIds?.length > 0)
    if(isIntersect) {
      return message.error(
        location.pathname.includes('cutting-order-diagram') ? 
        'オーバーラッピング切削順序図があります。保存できません。再度ご確認してください。' : 
        'オーバーラッピング舗設順序図があります。保存できません。再度ご確認してください。'
      )
    }

    if(location.pathname.includes('cutting-order-diagram')) {
      const valuesSetting = antFormCuttingSetting.getFieldsValue()

      const listTonnage: any = []

      Array.from(Array(Number(valuesSetting?.['quantity_cars'])), (e, i) => (
          listTonnage.push(valuesSetting[`vehicle_loads[${i}]`])
      ))

      const paramUpdate = {
          vehicle_loads: listTonnage,
          cutting_thickness_file: valuesSetting.cutting_thickness_file,
          cutting_order_file: overloadManagementData.cutting_order_file,
          amount_of_mixture: valuesSetting.amount_of_mixture
      }
      try {
        if(diagramId) {
          await onEditCuttingOrderDiagram(params)
        }else {
          await onUploadCuttingOrderDiagram(params)
        }
        await onUpdateOverloadManagement(paramUpdate)
        setIsLoadingUpload(false)
        message.success('データ更新成功')
        history.push(`/construction-site/${id}/location-site/${idConstructionZone}/list-file`)
        queryClient.clear()
      } catch(error: any) { 
        setIsLoadingUpload(false)
        if (error.response?.status === 400) {
          message.error(error.response.data.messages[0])
          return
        }
        message.error('システムエラー')
        return 
      }   
    }else {
      const values = antFormPavementSetting.getFieldsValue()
      const paramMixing = {...values, cutting_sequence_diagram_file: tonnageMixingData?.cutting_sequence_diagram_file}
      try {
        if(diagramId) {
          await onEditCuttingOrderDiagram(params)
        }else {
          await onUploadCuttingOrderDiagram(params)
        }
        await onUploadTonnageMixing(paramMixing)
        setIsLoadingUpload(false)
        message.success('データ更新成功')
        history.push(`/construction-site/${id}/location-site/${idConstructionZone}/list-file`)
        queryClient.clear()
      } catch(error: any) { 
        setIsLoadingUpload(false)
        if (error.response?.status === 400) {
          message.error(error.response.data.messages[0])
          return
        }
        message.error('システムエラー')
        return 
      }   
    }
  }


  const handleCheckIntersect = async() => {
    const canvasObjectPit = canvas.getObjects()?.filter((object: any) => object?.type === 'pit')
    canvasObjectPit?.forEach((scaledObject: any) => {
      canvasObjectPit?.forEach((targ: any) => {
        if(scaledObject.id !== targ.id) {
          const targWidth = targ._objects[0].width * targ.scaleX
          const scaledObjectWidth = scaledObject._objects[0].width * scaledObject.scaleX
          const targHeight = targ._objects[0].height * targ.scaleY
          const scaledObjectHeight = scaledObject._objects[0].height * scaledObject.scaleY
          if(
            (scaledObject.left > targ.left && scaledObject.left < targ.left + targWidth) || 
            (scaledObject.left + scaledObjectWidth > targ.left && scaledObject.left + scaledObjectWidth < targ.left + targWidth) ||
            (scaledObject.left < targ.left && scaledObject.left + scaledObjectWidth > targ.left + targWidth)||
            (scaledObject.left === targ.left && scaledObject.left + scaledObjectWidth === targ.left + targWidth)
          ) {
            if((scaledObject.top > targ.top && scaledObject.top < targ.top + targHeight) || 
              (scaledObject.top + scaledObjectHeight > targ.top && scaledObject.top + scaledObjectHeight < targ.top + targHeight) ||
              (scaledObject.top < targ.top && scaledObject.top + scaledObjectHeight > targ.top + targHeight)
              ) {
              const intersectIds: any = new Set([...scaledObject.intersectIds, targ.id])
              scaledObject.intersectIds = [...intersectIds]
            }else {
              const isIntersect = scaledObject.intersectIds?.find((item: number) => item === targ.id )
              const isIntersectActive = targ.intersectIds?.find((item: number) => item === scaledObject.id)
              if(isIntersect) {
                const removeIntersectId = scaledObject.intersectIds?.filter((item: number) => item !== targ.id)
                scaledObject.intersectIds = removeIntersectId
              }
              if(isIntersectActive) {
                const removeIntersectIdTarg = scaledObject.intersectIds?.filter((item: number) => item !== targ.id)
                targ.intersectIds = removeIntersectIdTarg
              }
            }
          }
    
          if(
            (scaledObject.top > targ.top && scaledObject.top < targ.top + targHeight) || 
            (scaledObject.top + scaledObjectHeight > targ.top && scaledObject.top + scaledObjectHeight < targ.top + targHeight) ||
            (scaledObject.top < targ.top && scaledObject.top + scaledObjectHeight > targ.top + targHeight)|| 
            (scaledObject.top === targ.top && scaledObject.top + scaledObjectHeight === targ.top + targHeight)
          ){
            if((scaledObject.left > targ.left && scaledObject.left < targ.left + targWidth) || 
              (scaledObject.left + scaledObjectWidth > targ.left && scaledObject.left + scaledObjectWidth < targ.left + targWidth) ||
              (scaledObject.left < targ.left && scaledObject.left + scaledObjectWidth > targ.left + targWidth) || 
              (scaledObject.left === targ.left && scaledObject.left + scaledObjectWidth === targ.left + targWidth)
              ) {
                const intersectIds: any = new Set([...scaledObject.intersectIds, targ.id])
                scaledObject.intersectIds = [...intersectIds]
            }else{
              const isIntersectTarg = scaledObject.intersectIds?.find((item: number) => item === targ.id )
              const isIntersectActive = targ.intersectIds?.find((item: number) => item === scaledObject.id)
              if(isIntersectTarg) {
                const removeIntersectId = scaledObject.intersectIds?.filter((item: number) => item !== targ.id)
                scaledObject.intersectIds = removeIntersectId
              }
              if(isIntersectActive) {
                const removeIntersectIdTarg = targ.intersectIds?.filter((item: number) => item !== scaledObject.id)
                targ.intersectIds = removeIntersectIdTarg
              }
            }
          }
        }
      })
    });
  }
  

  useEffect(() => {
    window.scrollTo(0, 0)
  },[])


  useEffect(() => {
    const valuesSetting = antForm.getFieldsValue()
    if(diagramDetailData && !valuesSetting['visible-reachableline']) {
      setIdPunchie(diagramDetailData?.punch_id)
      const dataEdit = JSON.parse(diagramDetailData?.point_datas) 
      setPonchiePitList(dataEdit?.pitList)
    }
  },[diagramDetailData])

  useEffect(() => {
    if(punchieDetailData && idPunchie && (minYSlice || minYSlice === 0)) {
      handleLoadPunchie() 
      const valuesSetting = antForm.getFieldsValue()
      if(valuesSetting['visible-reachableline']) {
        handleDrawReachableLine()
      }else if(pitDatas?.length > 0) {
        handleDrawPit()
      }
    }
  },[punchieDetailData,idPunchie, minYSlice])

  
  // useEffect(() => {
  //   if(idFileThickness) {
  //     readContentFile()
  //   }
  // },[idFileThickness])

  // useEffect(() => {
  //   const valuesSetting = antForm.getFieldsValue()
  //   if(valuesSetting['visible-reachableline'] && contentFileThinkness) {
  //     handleDrawReachableLine()
  //   }
  // },[antForm, contentFileThinkness])
  const blockListValue = useMemo(() => {
    if(diagramDetailData?.point_datas) {
      return JSON.parse(diagramDetailData?.point_datas)?.pitValue
    }
  },[diagramDetailData])


  useEffect(() => {
    if(isLoadFileFirstTime && !isFormChange) {
      if(ponchiePitList && blockListValue && punchieDetailData && (minYSlice || minYSlice === 0)) {
        handleDrawPit()
      }
    }
  },[ponchiePitList, blockListValue, punchieDetailData, isLoadFileFirstTime, isFormChange, minYSlice])


  // const validatePitNumber = (rule: any, value: string, callback: any, pitId: number) => {
  //   const valueForm = antForm.getFieldsValue()
  //   const isDuplicate = ponchiePitList?.find((pit: any) => (valueForm[`pit-number[${pit}]`] === value && pit !== pitId))
  //   if (!value) {
  //     openValidateField(pitId);
  //     callback(' 並び順を入力してください');
  //   } else if (isDuplicate || isDuplicate === 0) {
  //     openValidateField(pitId);
  //     callback('指定の並び順は既に使用されています');
  //   } else {
  //     callback();
  //   }
  // };

  const handleChangeValue = (allvalues: any) => {
    const allValuesExceptPaymentType = {...allvalues}
    if(Object.keys(allValuesExceptPaymentType)?.find((key: string ) => key === 'visible-reachableline')) {
      return
    }
    const listValue = Object.keys(allValuesExceptPaymentType)?.filter((key) => !key?.includes('pit-color'))?.map((key: any) => allvalues[key])
    listValue?.some((value:any) => {
        if(value !== undefined && value !== '' && value != null) {
            setIsValueForm(true)
            return true
        }
        setIsValueForm(false)
        return false
    })
  }

  const handleBack = () => {
    if(isValueForm) {
        setIsVisibleModelConfirmBack(true)
    }else{
        handleGoBack()
    }
  }

  const handleCancel = () => {
    setIsVisibleModelConfirmBack(false)
  }
  const openValidateField = (position: number) => {
      handleOpenBlockPoint(position);
  }


  const validateFields = (rule: any, value: string, callback: any, name: string, length?: number) => {
    if (!value?.trim()) {
        callback(`${name}を入力してください`);
    }else if( length && value?.trim()?.length > length) {
        callback(`${name}は${length}文字以内で入力してください`)
    }else {
        callback()
    }
  }

  const validateNumberFields = (rule: any, value: number, callback: any, name: string, position: number, min?: number) => {
    if (!value && value !== 0) {
          openValidateField(position)
        callback(`${name}を入力してください`);
    }else if(min && value <= min) {
          openValidateField(position)
        callback(`${name}は${min}より大きい値を入力してください `)
    }else {
        callback()
    }
  }

  const handleRenderColorDefault = (pitId: number) => {
    const pitCoppy = coppyPitList?.find((pit: {pitId: number, pitColor: string}) => pit.pitId === pitId )
    if(pitCoppy) {
      return pitCoppy.pitColor
    }
    return '#7dc7bc85'
  }

  const optionFileThickness = useMemo(() => {
    return listFileThickness?.map((file: FileByType) => (
        {
            value: file.id,
            label:file.file_name
        }
    ))
  },[listFileThickness])

  // const handleChangeFileThickness = async (value: number) => {
  //   setIdFileThickness(value)
  // }

  // useEffect(() => {
  //   const isCuttingThicknessFileExists = optionFileThickness?.find((file: any) => file.value === diagramDetailData?.file_thickness_id)
  //   if(diagramDetailData && isCuttingThicknessFileExists) {
  //     setIdFileThickness(diagramDetailData?.file_thickness_id)
  //   }
  // },[antForm, diagramDetailData, diagramDetailData?.file_thickness_id, optionFileThickness])

  useEffect(() => {
    if(optionFileThickness) {
        const isCuttingThicknessFileExists = optionFileThickness?.find((file: any) => file.value === diagramDetailData?.file_thickness_id)
        if(isCuttingThicknessFileExists) {
            antForm.setFieldValue('cutting_thickness_file', diagramDetailData?.file_thickness_id)
        }else {
            antForm.setFieldValue('cutting_thickness_file', null)
        }
    }
},[antForm, optionFileThickness, diagramDetailData?.file_thickness_id])


const handleGetMinYPunchie = async() => {
  if(punchieDetailData?.draw_datas?.web) {
    const dataDrawPonch = await JSON.parse(punchieDetailData?.draw_datas?.web)
    const flatArray = [].concat(...dataDrawPonch.ponchiePoints);
    const minYPunchie = Math.min(...flatArray.map((obj: {x: number, y: number}) => obj.y))
    return minYPunchie
  }
  return 0
}

async function readContentFile(id:number) {
  if(id) {
    const response = await api.get(`${baseURL}/api/admin/file-preview/excel?id=${id}`)
    return response.data.data
  }
}

const handleDrawReachableLine = useCallback(async() => {
  await handleDrawPit()
  handleResetReachableLine()
  try {
    await antFormCuttingSetting.validateFields()
} catch { return }

  const valuesForm = antForm.getFieldsValue()

  const valuesSetting = antFormCuttingSetting.getFieldsValue()

  if(idPunchie !== valuesForm['ponchie']) {
    return
  }
  // if(idFileThickness !== valuesSetting['cutting_thickness_file'] && thicknessInputMethod === 1) {
  //   return
  // }

 
  
  if(pitDatas && canvas && punchieDetailData && (minYSlice || minYSlice === 0)) {
    const contentFileThinkness = await readContentFile(valuesSetting['cutting_thickness_file']);
    Module().then(async(module: any, res: any, next: any) => {
      const vecPointC = new module.PointCList();
      const dataDrawPonch = await JSON.parse(punchieDetailData?.draw_datas?.web)
      const centerLineX = dataDrawPonch?.centerLine?.points[0].x
      dataDrawPonch?.ponchiePoints[0].forEach((point: {x: number, y: number}) => {
        const pointConvert = [...[Number(((point.x - centerLineX) / 10).toFixed(2)), Number((point.y / 10).toFixed(2))]]
        vecPointC.push_back(pointConvert)
      })
      const ponchFigureList = new module.PonchFigureList();
      const minYPunchie = minYSlice * 10
      const listPitData = pitDatas?.map((pit: typePit) => {
        return {
          order: Number(pit.orderNumber),
          direction: pit.direction,
          render2D: [
            {
              distance: Number((pit.top / 10).toFixed(2)),
              left:Number(((pit.left - centerLineX) / 10).toFixed(2)),
              right: Number(((pit.left + pit.width - centerLineX) / 10).toFixed(2))
            },
            {
              distance: Number(((pit.top + pit.height) / 10).toFixed(2)),
              left: Number(((pit.left - centerLineX) / 10).toFixed(2)),
              right: Number(((pit.left + pit.width - centerLineX) / 10).toFixed(2))
            }
          ]
        }
      })

      for (let i = 0 ; i < listPitData.length ; i++ ) {
        const ponchFigure = new module.PonchFigure();
        ponchFigure.order = listPitData[i].order;
        ponchFigure.direction = listPitData[i].direction;
        const center2DList = new module.PonchLineList();
        for (let z = 0; z < listPitData[i].render2D.length ; z++) {
          const center2D = new module.PonchLine()
          center2D.distance = listPitData[i].render2D[z].distance;
          center2D.left = listPitData[i].render2D[z].left;
          center2D.right = listPitData[i].render2D[z].right;
          center2DList.push_back(center2D)
        }
        ponchFigure.render2D = center2DList
        ponchFigureList.push_back(ponchFigure);
      }

      const subPonch = await module.getSubPonch(vecPointC, ponchFigureList)
      const canvasHeight = canvas.getHeight()
      const subPonchData = []
      for (let i = 0; i < subPonch.size(); i++) {
        
        const {lineSegmentList2D} = subPonch.get(i)
      
        const subPonchList: any = []
        for (let y = 0; y < lineSegmentList2D.size(); y++) { 
          const {p0, p1} = lineSegmentList2D.get(y)
          const list1 = p0.map((e: number, index: number) => {
            if(index === 0) {
              return e * UNIT_CANVAS + centerLineX
            } else {
              return canvasHeight - e * UNIT_CANVAS - PADDING_BOTTOM_CANVAS + minYPunchie
            }
          })
          if(y === 0) {
            const list3 = p1.map((e: number, index: number) => {
              if(index === 0) {
                return e * UNIT_CANVAS + centerLineX + strokeWidthLine
              } else {
                return canvasHeight - e * UNIT_CANVAS - PADDING_BOTTOM_CANVAS + minYPunchie
              }
            })
            const pointListLineSegment = list1.concat(list3)
            subPonchList.push(pointListLineSegment)
          }else {
            const list2 = p1.map((e: number, index: number) => {
              if(index === 0) {
                return e * UNIT_CANVAS + centerLineX
              } else {
                return canvasHeight - e * UNIT_CANVAS - PADDING_BOTTOM_CANVAS + minYPunchie
              }
            })
            const pointListLineSegment = list1.concat(list2)
            subPonchList.push(pointListLineSegment)
          }
        }
        subPonchData.push(subPonchList)
      }
      const dataSubponchie: any = []

      dataSubponchie.concat(...subPonchData)?.forEach((point: [number]) => {
        const line = new fabric.Line(point, {
          fill: '#10b0f4',
          stroke: '#10b0f4',
          strokeWidth: strokeWidthLine,
          hasControls: false,
          lockMovementY:true,
          lockMovementX: true,
          type: 'reachable-line',
          objectCaching: false
        })
        canvas.add(line)
      });
      const oneDumpWeight = new module.doubleList()

      if(location.pathname.includes('cutting-order-diagram')) {
        Array.from(Array(Number(valuesSetting?.['quantity_cars'])), (e, i) => (
          oneDumpWeight.push_back(valuesSetting[`vehicle_loads[${i}]`])
        ))
      }else {
        for (var i = 0; i < tonnageMixingData?.number_vehicles; i++) {
          oneDumpWeight.push_back(tonnageMixingData?.dump_weight)
        } 
      }
      const density = location.pathname.includes('cutting-order-diagram') ? valuesSetting[`amount_of_mixture`] : tonnageMixingData?.density
      const initialDumpIdx = 0
      const dataThicknessList = new module.ThicknessListOnOneLineList();
      if(location.pathname.includes('pavement-order-diagram') && thicknessInputMethod === 2) {
        const seriesThicknessData = [
          {
            thicknessList: [
              {
                x:0,
                t: valuesSetting['cutting_thickness_input']/100
              }
            ],
            height: 0
          }
        ]

        for (let i = 0 ; i < seriesThicknessData.length ; i++ ) {
          const dataThickness = new module.ThicknessListOnOneLine();
          dataThickness.height = seriesThicknessData[i].height;
          const ThicknessList = new module.ThicknessList()
          for (let z = 0; z < seriesThicknessData[i].thicknessList.length ; z++) {
            const Thickness = new module.Thickness()
            Thickness.x = seriesThicknessData[i].thicknessList[z].x;
            Thickness.t = seriesThicknessData[i].thicknessList[z].t;
            ThicknessList.push_back(Thickness)
          }
          dataThickness.thicknessList = ThicknessList
          dataThicknessList.push_back(dataThickness)
        }
      }else {
        for (let i = 0 ; i < contentFileThinkness.length ; i++ ) {
          const dataThickness = new module.ThicknessListOnOneLine();
          dataThickness.height = contentFileThinkness[i].coordinates.y;
          const ThicknessList = new module.ThicknessList()
          for (let z = 0; z < contentFileThinkness[i].data.length ; z++) {
            const Thickness = new module.Thickness()
            Thickness.x = contentFileThinkness[i].data[z].coordinates.x;
            Thickness.t = contentFileThinkness[i].data[z].thickness;
            ThicknessList.push_back(Thickness)
          }
          dataThickness.thicknessList = ThicknessList
          dataThicknessList.push_back(dataThickness)
        }
      }
      
      const reachableLineListOnOneSubPonch= await module.getReachableLine(vecPointC, ponchFigureList, dataThicknessList, initialDumpIdx, oneDumpWeight, density)

      vecPointC.delete()
      ponchFigureList.delete()
      oneDumpWeight.delete()
      dataThicknessList.delete()
      const dataReachables = []
      for (let i = 0; i < reachableLineListOnOneSubPonch.size(); i++) {
        const dataReachable: {order?: number, direction?: number, render2D?: []} = {}
        const {order, direction, render2D} = reachableLineListOnOneSubPonch.get(i)
        dataReachable.order = order
        dataReachable.direction = direction
        const listPointPit: any = []
        for (let z = 0; z < render2D.size(); z++) { 
          const pointPit = []
        
          const {distance, left, right} = render2D.get(z)
          const yPitConvertToCanvas =canvasHeight - distance * UNIT_CANVAS - PADDING_BOTTOM_CANVAS + minYPunchie
          pointPit.push(left * UNIT_CANVAS + centerLineX, yPitConvertToCanvas , right * UNIT_CANVAS + centerLineX, yPitConvertToCanvas)
          listPointPit.push(pointPit)
        }
        dataReachable.render2D = listPointPit
        dataReachables.push(dataReachable)

      }
      // canvas.discardActiveGroup();
    
      dataReachables?.forEach((data: {order?: number, direction?: number, render2D?: []}) => {
        data.render2D?.forEach((points: []) => {
          const line = new fabric.Line(points, {
            fill: '#ff0000 ',
            stroke: '#ff0000 ',
            strokeWidth: 2,
            hasControls: false,
            lockMovementY:true,
            lockMovementX: true,
            type: 'reachable-line',
            objectCaching: false
          })
          canvas.add(line)
        })
      })
      canvas.discardActiveObject().renderAll();
    })
  }
},[antForm, idPunchie, overloadManagementData, punchieDetailData, tonnageMixingData, ponchiePitList, thicknessInputMethod, minYSlice])


  const handleToggleReachableLine = async (value:  CheckboxChangeEvent) => {
    const valuesSetting = antForm.getFieldsValue()
    if(valuesSetting['visible-reachableline']) {
      try {
        await antFormCuttingSetting.validateFields()
        await antFormPavementSetting.validateFields()
      } catch { 
        antForm.setFieldsValue({[`visible-reachableline`]: false})
        return 
      }
      // if(location.pathname.includes('cutting-order-diagram')) {
      //   // if(!valuesSetting['cutting_thickness_file']) {
      //   //   antForm.setFieldsValue({[`visible-reachableline`]: false})
      //   //   return message.error('切削厚さファイルを選択してください')
      //   // }
      //   // if(!overloadManagementData) {
      //   //   antForm.setFieldsValue({[`visible-reachableline`]: false})
      //   //   return message.error('過積載管理を設定してください')
      //   // }
      // }else {
      //   if(!valuesSetting['cutting_thickness_file'] && thicknessInputMethod === 1) {
      //     antForm.setFieldsValue({[`visible-reachableline`]: false})
      //     return message.error('舗設厚さファイルを選択してください')
      //   }
      //   if(valuesSetting['cutting_thickness_input'] === 0 && thicknessInputMethod === 2) {
      //     antForm.setFieldsValue({[`visible-reachableline`]: false})
      //     return message.error('厚さは0より大きい値を入力してください')
      //   }
      //   if(!valuesSetting['cutting_thickness_input'] && thicknessInputMethod === 2) {
      //     antForm.setFieldsValue({[`visible-reachableline`]: false})
      //     return message.error('厚さを入力してください')
      //   }
      //   if(!tonnageMixingData) {
      //     antForm.setFieldsValue({[`visible-reachableline`]: false})
      //     return message.error('合材トン数を設定してください')
      //   }
      // }
  
    }
    try {
        await antForm.validateFields()
    } catch { 
      antForm.setFieldsValue({[`visible-reachableline`]: false})
      return 
    }

    if(value.target.checked) {
      await handleDrawReachableLine()
    }else {
      handleResetReachableLine()
    }
  }

  const pitObjectModifiedHandler = (event: any) => {
    var obj = event.target;
    obj.setCoords()
    const valuesSetting = antForm.getFieldsValue()
    if(valuesSetting['visible-reachableline']) {
      handleDrawReachableLine()
    }
    canvas.discardActiveObject().renderAll()
    updateMiniMap();
  };

  const debouncedObjectModifiedHandler = debounce(pitObjectModifiedHandler, 200);

  useEffect(() => {
    canvas.on('object:modified', debouncedObjectModifiedHandler);
    return () => {
      canvas.off('object:modified', debouncedObjectModifiedHandler);
    };
  }, [debouncedObjectModifiedHandler]);

  

  const handlePreviewDiagram = async() => {
    const valuesSetting = antForm.getFieldsValue()
    try {
      await antForm.validateFields()
    } catch { 
      return 
    }
    if(valuesSetting['visible-reachableline']) {
      await handleDrawReachableLine()
    }else {
      await handleDrawPit()
    }
  }

  useEffect(() => {
    ponchiePitList?.forEach((pit: any, index: number) => {
      antForm.setFieldsValue({[`pit-number[${pit}]`]: index + 1})
    })
  },[antForm, ponchiePitList, ponchiePitList.length])

  const renderNotFoundContent = () => {
    return <p className='text-center p-[10px]'>データがありません</p>
  }

  useEffect(() => {
    const valuesSetting = antForm.getFieldsValue()
    if(valuesSetting['visible-reachableline']) {
      if(thicknessInputMethod === 1 && !valuesSetting['cutting_thickness_file']) {
        handleResetReachableLine()
        return message.error('舗設厚さファイルを選択してください')
      }
      if(thicknessInputMethod === 2 && !valuesSetting['cutting_thickness_input']) {
        handleResetReachableLine()
        return message.error('厚さを入力してください')
      }
      handleDrawReachableLine()
    }
  },[thicknessInputMethod])

  useEffect(() => {
    if(location.pathname.includes('pavement-order-diagram') && diagramDetailData) {
      if(diagramDetailData?.file_thickness_id) {
        setThicknessInputMethod(1)
      }else {
        setThicknessInputMethod(1)
      }
    }
  },[diagramDetailData, location.pathname])

  const handleResetReachableLine = () => {
    canvas.getObjects()?.forEach((object: any) => {
      if(object?.type === 'reachable-line') {
        canvas.remove(object);
      }
    })
    canvas.renderAll();
  }

  const handleToggleSetting = () => {
    setIsShowSetting(!isShowSetting)
  }
  useEffect(() => {
    minimap  = new fabric.Canvas(minimapRef.current, {
      preserveObjectStacking: true,
      selection: false
    })
    return () => {
      minimap.dispose();
    };
  }, [minimapRef.current]);

  const createCanvasEl = () => {
    const designSize = { width: canvas?.width, height: canvas?.height };
    const originalVPT = canvas?.viewportTransform;
    const fabricUtil: any = fabric.util
    const designRatio = fabricUtil.findScaleToFit(designSize, canvas);
    const minimapRatio: any = fabricUtil.findScaleToFit(canvas, minimap);

    const scaling = minimap.getRetinaScaling();

    const finalWidth =  designSize.width * designRatio;
    const finalHeight =  designSize.height * designRatio;

    canvas.viewportTransform = [
      designRatio, 0, 0, designRatio,
      (canvas?.getWidth() - finalWidth) / 2,
      (canvas?.getHeight() - finalHeight) / 2
    ];
    const canvasElement = canvas?.toCanvasElement(minimapRatio * scaling);
    canvas.viewportTransform = originalVPT;
    return canvasElement;
  }

  function initMinimap() {
    const canvasEl = createCanvasEl();
    const backgroundImage = new fabric.Image(canvasEl);
    backgroundImage.scaleX = 1 / canvas.getRetinaScaling();
    backgroundImage.scaleY = 1 / canvas.getRetinaScaling();
    minimap.centerObject(backgroundImage);
    minimap.backgroundColor = 'white';
    minimap.backgroundImage = backgroundImage;
    minimap.requestRenderAll();
    minimap.getObjects()?.forEach((object: any) => {
      if(object?.type === 'minimap') {
        minimap.remove(object);
      }
    })
    const frameHeight = document.getElementById('punchie-picture')?.offsetHeight
    const ratioHeightCanvas = frameHeight! / canvas.height
    const frameWidth = document.getElementById('punchie-picture')?.offsetWidth
    const ratioWidthCanvas = frameWidth! / canvas.width
    const minimapView = new fabric.Rect({
      top: backgroundImage.top,
      left: backgroundImage.left,
      width: backgroundImage.width! * ratioWidthCanvas / canvas.getRetinaScaling(),
      height: backgroundImage.height! * ratioHeightCanvas / canvas.getRetinaScaling(),
      fill: 'rgba(0, 0, 255, 0.3)',
      strokeWidth: 0,
      lockScalingY: true,
      lockScalingX: true,
      lockRotation: true,
      hasBorders: false,
      type:'minimap'
    });
    minimapView.setControlsVisibility({
      mt: false,
      mb: false,
      ml: false,
      mr: false,
      tl:false,
      bl: false,
      tr: false,
      br: false,
      mtr: false,
    });
    minimap.add(minimapView);
  }

  function updateMiniMap() {
    var canvasEl = createCanvasEl();
    minimap.backgroundImage._element = canvasEl;
    minimap.requestRenderAll();
  }

  function updateMiniMapVP() {
    const designSize = { width: canvas.width, height: canvas.height };
    const rect = minimap.getObjects()[0];
    const fabricUtil: any = fabric.util
    const designRatio = fabricUtil.findScaleToFit(designSize, canvas);
    const totalRatio = fabricUtil.findScaleToFit(designSize, minimap);
    const finalRatio = designRatio / canvas.getZoom();
    if(rect) {
      rect.scaleX = finalRatio;
      rect.scaleY = finalRatio;
      rect.top = minimap.backgroundImage.top - canvas.viewportTransform[5] * totalRatio / canvas.getZoom();
      rect.left = minimap.backgroundImage.left - canvas.viewportTransform[4] * totalRatio / canvas.getZoom();
    }
    minimap.requestRenderAll();
  }
 
  useEffect(()=> {
    minimap?.on('object:moving', function (e: any) {
      const obj = e.target;
      if(obj?.type !== 'minimap') {
        return
      }
      obj.setCoords();
      const designSize = { width: canvas.width, height: canvas.height };
      const fabricUtil: any = fabric.util
      const totalRatio = fabricUtil.findScaleToFit(designSize, minimap);
      canvas.viewportTransform[5] = ((minimap.backgroundImage.top - obj.top) * canvas.getZoom()) / totalRatio
      canvas.viewportTransform[4] = ((minimap.backgroundImage.left - obj.left) * canvas.getZoom()) / totalRatio
      canvas.requestRenderAll();
    });

  
  },[minimap, canvas])


  return (
    <>
      <div className='page-only-header-title mb-[16px] justify-between'>
        <div className='flex items-center'>
            <img src={backIcon} alt='' className='mr-[16px] cursor-pointer w-[24px] h-[24px]' onClick={handleBack}/>
            <h2 className='page-detail-note cursor-pointer' onClick={() =>handleBack()}>工区ファイル一覧 </h2>
            <span className='text-blue page-detail-note whitespace-nowrap'>/  
                { location.pathname.includes('cutting-order-diagram') ? '切削順序図' : '舗設順序図'}
            </span>
        </div>
        <Button loading={isLoadingUpload} className='button-add min-w-[128px]' onClick={handleUploadCuttingOrderDiagram}><span></span>保存</Button>
      </div>   
      <div className='ponchie-picture'>
         
          {
            (isloadingPunchie || isLoadingFile || isLoadingOverloadData || isLoadingMixingData) ? <Spin/> : 

              <div className='flex'>
                <div className='w-4/12 mt-[76px]'>
                  <div className='form-diagram'>
                    <Form form={antForm} onValuesChange={(value, allvalues) => handleChangeValue(allvalues)}>
                      <div className='mb-[16px] ponchie-setting'>
                          <h6 className='ponchie-setting-header'>ポンチ絵</h6>
                          <div className='p-[16px]'>
                              <Form.Item name='ponchie' initialValue={diagramDetailData?.punch_id} rules={[{ required: true, message: 'ポンチ絵を選択してください' }]}>
                                  <Select
                                      onChange={(e) => handleGetPonchie(e)}
                                      style={{ width: '100%' }}
                                      options={ponchieOption}
                                      notFoundContent={renderNotFoundContent()}
                                  />
                              </Form.Item>
                          </div>
                      </div>
                    </Form>  
                    <div className='mb-[16px] ponchie-setting'>
                        <div className='flex justify-between ponchie-setting-header'>
                          <h6 className='text-[#1C48E7] text-[16px] font-bold'>{location.pathname.includes('cutting-order-diagram') ? '過積載管理設定' : '合材トン数設定'}</h6>
                          <img src={arrowDownIcon} alt='' className={`cursor-pointer drop-arrow ${!isShowSetting ? 'rotate-180' : 'rotate-0'}`} onClick={handleToggleSetting}/>
                        </div>
                        <div className={`h-full  ${!isShowSetting ? 'hidden': 'block'}`}>
                          {
                            location.pathname.includes('cutting-order-diagram') ? 
                              <CuttingDiagramSetting form={antFormCuttingSetting} overloadManagementData={overloadManagementData} listFileThickness={listFileThickness}/>
                            : 
                              <PavementDiagramSettingComponent form={antFormPavementSetting} tonnageMixingData={tonnageMixingData} listFileThickness={listFileThickness}/>
                          }
                        </div>
                    </div>
                    <Form form={antForm} onValuesChange={(value, allvalues) => handleChangeValue(allvalues)}>
                      <div className='ponchie-setting'>
                          <h6 className='ponchie-setting-header'>{ location.pathname.includes('cutting-order-diagram') ? '切削順序図' : '舗設順序図'}</h6>
                          <div className='ponchie-setting-name'>
                              <label className='mt-[8px]'>{ location.pathname.includes('cutting-order-diagram') ? '切削順序図名' : '舗設順序図名'}</label>
                              <Form.Item 
                                initialValue={diagramDetailData?.name} 
                                className='w-full' 
                                name='name' 
                                rules={
                                  [                                    
                                      { validator: (rule, value, callback) => 
                                        validateFields(rule,
                                          value,
                                          callback, 
                                          location.pathname.includes('cutting-order-diagram') ? '切削順序図名' : '舗設順序図名', 
                                          255
                                        )
                                    }
                                  ]
                                }
                              >
                                <Input className='input-form'/>
                              </Form.Item>
                          </div>
                          <div className='ponchie-setting-content cutting-order-diagram-content'>
                              {
                                  ponchiePitList?.map((pitId: number, index: number) => (
                                      <div className='ponchie-setting-content-item' key={pitId}>
                                          <div className='flex items-center justify-between text-[16px] font-bold text-[#000000]'>
                                              { location.pathname.includes('cutting-order-diagram') ? '切削ブロック' : '舗設ブロック'} {index + 1}
                                              <div className='flex items-center'>
                                                  <img className='button-add-icon cursor-pointer' src={addIcon} alt='' onClick={() => handleAddPoint(Date.now(), index + 1)}/>
                                                  <img src={copyIcon} alt='' className='cursor-pointer' onClick={() => handleCopyPoint(pitId, index+1)}/>
                                                  {
                                                    ponchiePitList?.length === 1 ? '' :
                                                    <img src={deleteIcon} alt='' className='cursor-pointer mx-[10px]' onClick={() =>handleDeletePoint(pitId)}/>
                                                  }
                                                  <img src={arrowDownIcon} alt='' className={`cursor-pointer drop-arrow ${isHiden(pitId) !== undefined ? 'rotate-180' : 'rotate-0'}`} onClick={() => handleHidenPit(pitId)}/>
                                              </div>
                                          </div>
                                          <div className={`data-pits mt-[16px] ${isHiden(pitId) !== undefined ? 'hiden-block' : ''}`}>
                                              <div className='flex mb-[12px]'>
                                                  <div className='text-[#2E2E2E] text-[16px] whitespace-nowrap min-w-[96px] mt-[8px]'>幅</div>
                                                  <Form.Item 
                                                    initialValue={blockListValue?.[`pit-width[${pitId}]`]} 
                                                    name={`pit-width[${pitId}]`} 
                                                    className='w-full' 
                                                    rules={
                                                      [                                    
                                                          { validator: (rule, value, callback) => validateNumberFields(rule,value,callback, '幅', pitId, 0)}
                                                      ]
                                                    }
                                                  >
                                                      <InputNumber min={0} className='input-form w-full'/>
                                                  </Form.Item>
                                                  <span className='ml-[4px] mt-[8px]'>m</span>
                                                  <div className='text-[#2E2E2E] text-[16px] whitespace-nowrap mx-[28px] mt-[8px]'>高さ</div>
                                                  <Form.Item 
                                                    initialValue={blockListValue?.[`pit-height[${pitId}]`]} 
                                                    name={`pit-height[${pitId}]`} 
                                                    className='w-full' 
                                                    rules={
                                                      [                                    
                                                          { validator: (rule, value, callback) => validateNumberFields(rule,value,callback, '高さ', pitId, 0)}
                                                      ]
                                                    }
                                                  >
                                                      <InputNumber min={0} className='input-form w-full'/>
                                                  </Form.Item>
                                                  <span className='ml-[4px] mt-[8px]'>m</span>
                                              </div>
                                              <div className='flex mb-[12px]'>
                                                  <div className='text-[#2E2E2E] text-[16px] whitespace-nowrap min-w-[96px] mt-[8px]'>座標</div>
                                                  <Form.Item 
                                                    initialValue={blockListValue?.[`pit-x[${pitId}]`]} 
                                                    name={`pit-x[${pitId}]`} 
                                                    className='w-full margin-right-5px' 
                                                    rules={[{ validator: (rule, value, callback) => validateNumberFields(rule,value,callback, '座標', pitId)}]}
                                                  >
                                                      <InputNumber className='input-form w-full'/>
                                                  </Form.Item>
                                                  <Form.Item 
                                                    initialValue={blockListValue?.[`pit-y[${pitId}]`]} 
                                                    name={`pit-y[${pitId}]`} 
                                                    className='w-full' 
                                                    rules={[{ validator: (rule, value, callback) => validateNumberFields(rule,value,callback, '座標', pitId, 0)}]}
                                                  >
                                                      <InputNumber min={0} className='input-form w-full'/>
                                                  </Form.Item>
                                              </div>
                                              <div className='flex mb-[12px]'>
                                                  <div className='text-[#2E2E2E] text-[16px] whitespace-nowrap min-w-[96px] mt-[8px]'>{ location.pathname.includes('cutting-order-diagram') ? '切削順序' : '舗設順序'}</div>
                                                  <Form.Item 
                                                    name={`pit-number[${pitId}]`} 
                                                    className='w-full' 
                                                  >
                                                      <InputNumber disabled min={1} className='input-form w-full'/>
                                                  </Form.Item>
                                              </div>                                           
                                              <div className='flex items-center mb-[12px]'>
                                                  <div className='text-[#2E2E2E] text-[16px] whitespace-nowrap min-w-[96px]'>カラー</div>
                                                  <div className='input-color-group'>
                                                      <InputColor
                                                          initialValue={blockListValue?.[`pit-color[${pitId}]`] ? blockListValue?.[`pit-color[${pitId}]`] : handleRenderColorDefault(pitId)}
                                                          onChange={(value: any) => handleChangeColor({
                                                              name: pitId,
                                                              value: value
                                                          })}
                                                          placement="right"
                                                      />
                                                      <Form.Item initialValue={blockListValue?.[`pit-color[${pitId}]`] ? blockListValue?.[`pit-color[${pitId}]`]  : handleGetColor(pitId) || '#7dc7bc85'} name={`pit-color[${pitId}]`} className='hidden'>
                                                        <Input className='input-form w-full'/>
                                                      </Form.Item>
                                                      <div className='ml-[9px] text-[16px] text-[#212529]'>{handleGetColor(pitId)}</div>
                                                  </div>
                                              </div>
                                              <div className='flex'>
                                                <div className='text-[#2E2E2E] text-[16px] whitespace-nowrap min-w-[96px] mt-[8px]'>方向</div>
                                                <Form.Item name={`pit-direction[${pitId}]`} initialValue={blockListValue?.[`pit-direction[${pitId}]`] || 1}  className='w-full'>
                                                    <Select
                                                        style={{ width: '100%' }}
                                                        options={directionOptions}
                                                    />
                                                </Form.Item>
                                              </div>
                                          </div>
                                      </div>
                                  ))
                              }
                          </div>
                      </div>
                    </Form>
                  </div>
                  <div className='ponchie-setting-footer '>
                      <Button className='button-add w-full' onClick={handlePreviewDiagram}><span></span>更新</Button>
                  </div>
                </div>
                <div className='w-8/12 pl-[13px] mt-[76px]'>
                  <Form form={antForm} onValuesChange={(value, allvalues) => handleChangeValue(allvalues)}>
                    <div className='flex items-center justify-end'>
                      <Form.Item initialValue={true} name='visible-sima' valuePropName="checked">
                        <Checkbox onChange={handleToggleSima} className='text-[#272122] text-[16px]'>基本SIMAを見る</Checkbox>
                      </Form.Item>
                      <Form.Item name='visible-reachableline' valuePropName="checked">
                        <Checkbox onChange={handleToggleReachableLine} className='text-[#272122] text-[16px]'>ダンプ到達ライン</Checkbox>
                      </Form.Item>
                    </div>
                  </Form>
                  <div id='punchie-picture' className='ponchie-canvas form-diagram-content overflow-hidden scroll-punchie relative'>
                    <canvas ref={canvasRef}/>
                    <div className='minimap'>
                      <canvas id="minimap" ref={minimapRef} width="200" height="200"></canvas>
                    </div>
                  </div>
                </div>
              </div>
        
          }
          <ModalConfirm 
                isShowModal={isVisibleModelConfirmBack}
                onCancel={handleCancel}
                onOk={handleGoBack}
                content='このページを離れますか？'
                subContent='ページから移動すると、保存されていない変更は失われます。'
            />
      </div>
    </>
  )
}

export default CuttingOrderDiagram
