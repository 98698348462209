import React from 'react'
import CompanyStaffDetailComponent from 'src/components/features/companyManagement/companyStaffDetailComponent'
import { LayoutCompanyAdmin } from 'src/components/layouts/layoutCompanyAdmin'

const CompanyStaffDetailPage = () => (
    <LayoutCompanyAdmin>
       <CompanyStaffDetailComponent />
    </LayoutCompanyAdmin>
)

export default CompanyStaffDetailPage