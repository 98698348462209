import React, { useEffect } from 'react'

import {
  Route, RouteProps, useLocation,
} from 'react-router-dom'
import { api } from 'src/api/axios'
import { getCookie } from 'src/utils/cookie'

export const AuthRoute: React.FC<RouteProps> = (props) => {
  const location = useLocation()
  const handleCheckInfoUser = () => {
    api.get('/api/info-user').then((response) => {
        return response.data
    });
  }
  useEffect(() => {
    if (!getCookie('token')) {
      window.location.href = '/login'
    }else {
      handleCheckInfoUser()
    }
  }, [location.pathname])

  return <Route {...props} />
}