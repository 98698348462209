import { constructionType } from "src/components/features/constructionSite/type";
import { api } from "./axios";

export const createConstruction = (params: constructionType) => api.post(
    `/api/admin/construction`,
    params,
)

export const deleteConstruction = (id: number) => api.delete(
  `/api/admin/construction/${id}`,
)

export const editConstruction = (id: number, params: constructionType) => api.put(
  `/api/admin/construction/${id}`,
  params
)

export const duplicateConstruction = (id: number) => api.post(
  `/api/admin/duplicate/construction-zone/${id}`
)