import React from 'react'
import forbiddenImg from 'src/assets/images/not_found.png'
const NotFoundPage = () => {
    return (
        <>
            <div className='not-found-page flex items-center justify-center flex-col'>
                <img src={forbiddenImg} alt='' />
                <p className='mt-[43px] text-[28px] text-[#2E2E2E]'>Page not found</p>
            </div>
        </>
    )
}

export default NotFoundPage