import {CardCvcElement, CardExpiryElement, CardNumberElement, useStripe, useElements} from '@stripe/react-stripe-js';
import { Button, Input } from 'antd';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { licensePayment } from 'src/api/payment';
import ModalPayment from 'src/components/common/modal/modalPayment';
import tickCircle from 'src/assets/images/tick-circle.png'
import errorCircle from 'src/assets/images/confirm-circle.png'
const baseCssCardElement = {
    fontSize: '16px',
    color: '#272122',
    '::placeholder': {
      color: '#818181',
    },
}

const cardElementOptions = {
    style: {
      base: {...baseCssCardElement},
    },
    placeholder: '1234 5678 9012 3456'
};

const cardCvcElementOptions = {
    style: {
        base: {...baseCssCardElement},
    },
    placeholder: '右参照 '
}

const cardExpiryElementOptions = {
    style: {
        base: {...baseCssCardElement},
    },
    placeholder: '00/00'
}
const PaymentForm = () => {
    const { id } = useParams<{ id: string }>()
    const history = useHistory()
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false)
    const [errorCardNumber, setErrorCardNumber] = useState('')
    const [errorCardExpiry, setErrorCardExpiry] = useState('')
    const [errorCardCvc, setErrorCardCvc] = useState('')
    const [isShowModalPaymentSuccess, setIsShowModalPaymentSuccess] = useState(false)
    const [isShowModalPaymentError, setIsShowModalPaymentError] = useState(false)
    const [errorPayment, setErrorPayment] = useState('')
    const {isLoading: isloading, mutate: onLicensePayment } = useMutation(
        (param: any) => licensePayment(param, Number(id)),
        {
          onSuccess: (res) => {
            setIsShowModalPaymentSuccess(true)
          },
          onError: (error: any) => {
            setIsShowModalPaymentError(true)
            if (error.response?.status === 400) {
                setErrorPayment(error.response.data.messages[0])
                return
            }
            setErrorPayment('システムエラー')
          },
        },
    )

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        const cardNumberElement: any = elements.getElement(CardNumberElement);
        setLoading(true)
        const { error, token } = await stripe.createToken(cardNumberElement!);
        if (error) {
            console.error('Error creating token:', error);
            if(error.code === "incomplete_number") {
                setErrorCardNumber('クレジットカード番号の形式が正しくありません')
            }else if(error.code === "invalid_number") {
                setErrorCardNumber('クレジットカード番号が無効です')
            }else {
                setErrorCardNumber('')
            }

            if(error.code === "incomplete_expiry") {
                setErrorCardExpiry('有効期限の形式が正しくありません')
            }else if(error.code === "invalid_expiry_month_past" || error.code === "invalid_expiry_year_past") {
                setErrorCardExpiry('有効期限が無効です')
            }else {
                setErrorCardExpiry('')
            }

            if(error.code === "incomplete_cvc") {
                setErrorCardCvc('セキュリティーコードの形式が正しくありません')
            }else {
                setErrorCardCvc('')
            }
            setLoading(false)
        } else {
            onLicensePayment({
                token: token?.id
            })
            setLoading(false)
        }
    };

    const handleCloseModalSucces= () => {
        setIsShowModalPaymentSuccess(false)
        history.push('/login')
    }

    const handleCloseModalError= () => {
        setIsShowModalPaymentError(false)
    }
  
    return (
        <>
            <form onSubmit={handleSubmit}>
                <span className='label'>カード番号</span>
                <CardNumberElement className='card-input' options={cardElementOptions}/>
                <p className='mb-[16px] text-[#ff4d4f] text-[14px]'>{errorCardNumber}</p>
                <span className='label'>カード名義</span>
                <Input placeholder='KAZUO ISONO' className='input-form mb-[16px]'/>
                <span className='label'>有効期限</span>
                <CardExpiryElement className='card-input' options={cardExpiryElementOptions}/>
                <p className='mb-[16px] text-[#ff4d4f] text-[14px]'>{errorCardExpiry}</p>
                <span className='label'>セキュリティーコード</span>
                <CardCvcElement className='card-input' options={cardCvcElementOptions} />
                <p className='mb-[32px] text-[#ff4d4f] text-[14px]'>{errorCardCvc}</p>
                <Button loading={loading || isloading} htmlType="submit" className='button-add w-full h-[48px]'>支払い</Button>
            </form>
            <ModalPayment 
                isShowModal={isShowModalPaymentSuccess}
                content="支払い成功"
                subContent={'お支払い手続きが完了いたしました。\n ご登録いただいたライセンス数でシステムにアクセスできます。'}
                onCancel={() => handleCloseModalSucces()}
                onOk={() => handleCloseModalSucces()}
                iconModal={tickCircle}
            />
             <ModalPayment 
                isShowModal={isShowModalPaymentError}
                content="支払いに失敗しました"
                subContent={errorPayment}
                onCancel={() => handleCloseModalError()}
                onOk={() => handleCloseModalError()}
                iconModal={errorCircle}
            />
        </>
    );
  };

  export default PaymentForm