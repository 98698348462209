import React from 'react'
import StaffDetailComponent from 'src/components/features/agency/staffDetailComponent'
import { LayoutSuperAdmin } from 'src/components/layouts/layoutSuperAdmin'

const AdminStaffDetailPage = () => (
    <LayoutSuperAdmin>
       <StaffDetailComponent />
    </LayoutSuperAdmin>
)

export default AdminStaffDetailPage