import {utils} from 'xlsx';
export const stox = (wb: any) => {
    var out:any = [];
    wb.SheetNames.forEach(function (name: any) {
      var o:any = { name: name, rows: {} };
      var ws = wb.Sheets[name];
      if(!ws || !ws["!ref"]) return;
      var range = utils.decode_range(ws['!ref']);
      // sheet_to_json will lost empty row and col at begin as default
      range.s = { r: 0, c: 0 };
      var aoa = utils.sheet_to_json(ws, {
        raw: false,
        header: 1,
        range: range
      });
  
      aoa.forEach(function (r: any, i) {
        var cells: any = {};
        r.forEach(function (c: any, j: any) {
          cells[j] = { text: c };
  
          var cellRef = utils.encode_cell({ r: i, c: j });
  
          if ( ws[cellRef] != null && ws[cellRef].f != null) {
            cells[j].text = "=" + ws[cellRef].f;
          }
        });
        o.rows[i] = { cells: cells };
      });
  
      o.merges = [];
      (ws["!merges"]||[]).forEach(function (merge:any, i:any) {
        //Needed to support merged cells with empty content
        if (o.rows[merge.s.r] == null) {
          o.rows[merge.s.r] = { cells: {} };
        }
        if (o.rows[merge.s.r].cells[merge.s.c] == null) {
          o.rows[merge.s.r].cells[merge.s.c] = {};
        }
  
        o.rows[merge.s.r].cells[merge.s.c].merge = [
          merge.e.r - merge.s.r,
          merge.e.c - merge.s.c
        ];
  
        o.merges[i] = utils.encode_range(merge);
      });
  
      out.push(o);
    });
  
    return out;
  }