import React from 'react'
import LicenseListComponent from 'src/components/features/agency/licenseListComponent'
import { LayoutAgency } from 'src/components/layouts/layoutAgency'

const LicenseListPage = () => (
    <LayoutAgency>
       <LicenseListComponent />
    </LayoutAgency>
)

export default LicenseListPage