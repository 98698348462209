import { useQuery } from 'react-query';
import { api } from 'src/api/axios';

export const useDetailCategory = (id: number) =>
  useQuery(['detail-category', id], async () => {
    if (id) {
      const res = await api.get(`/api/cms/category/${id}`);
      return res.data.data;
    }
    return;
  });
