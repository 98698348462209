import { useQuery } from 'react-query'
import { api } from 'src/api/axios'
import { QUERY_KEY } from '../query-key'
export const useLicenseInvoice = (token: string) => useQuery(
    [QUERY_KEY.LICENSE_INVOICE, token],
    async () => {
      const res = await api.get('/api/license/estimation', {
        params: {
          token: token,
        },
      })
      return res.data
    },
  )
  