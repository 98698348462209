import { useQuery } from 'react-query'
import { api } from 'src/api/axios'
import { QUERY_KEY } from '../query-key'


export const useLocationSiteListFiles = (constructionZoneId: number) => useQuery(
  [QUERY_KEY.LOCATION_SITE_LIST_FILES],
  async () => {
    const res = await api.get(`/api/admin/construction-zone/${constructionZoneId}`)
    return res.data.data
  },
  {
    retry: 0,
  }
)
