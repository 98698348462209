/* eslint-disable react-hooks/exhaustive-deps */
import 'moment/locale/ja';
import { Button, Input, Pagination, Select, Spin, Tabs, message } from 'antd';
import { useState } from 'react';
import searchIcon from 'src/assets/images/search-icon.png';
import addIcon from 'src/assets/images/add-icon.png';
import { useHistory, useParams } from 'react-router-dom';
import leftArrow from 'src/assets/images/back-icon.png';
import { pageSizeList } from 'src/constants';
import editIcon from 'src/assets/images/edit-icon.png';
import deleteIcon from 'src/assets/images/delete-icon.png';
import { useMutation, useQueryClient } from 'react-query';
import { deleteFaq } from 'src/api/admin';
import { QUERY_KEY } from 'src/api/data/query-key';
import questionIcon from 'src/assets/images/landingPage/question.svg';
import { useDetailCategory } from 'src/api/data/admin/useDetailCategory';
import { useListFaq } from 'src/api/data/agency/useListFaq';
import ModalConfirm from 'src/components/common/modal/modalConfirm';
import TableNoteNoData from 'src/components/common/tableNoteNoData';

const FaqListComponent = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const query: any = useParams();

  const [keyword, setKeyword] = useState<string>('');
  const [keywordSearch, setKeywordSearch] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [sizePage, setSizePage] = useState<number>(10);

  const [isVisibleModalConfirm, setIsVisibleModalConfirm] = useState(false);
  const [faqDelete, setFaqDelete] = useState<any>();

  const handleCancel = () => {
    setIsVisibleModalConfirm(false);
  };

  const iconSearch = () => {
    return (
      <div className="p-[5px] cursor-pointer" onClick={handleSearch}>
        <img src={searchIcon} alt="" />
      </div>
    );
  };

  const { data: listFaq, isLoading } = useListFaq(keywordSearch, [query.id], page, sizePage);
  const { data: detailCategory } = useDetailCategory(query.id);

  const { isLoading: isLoadingDeleta, mutate: onDelete } = useMutation((id: number | undefined) => deleteFaq(id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEY.LIST_FAQ);
      message.success('データ更新成功');
      setIsVisibleModalConfirm(false);
    },
    onError: (error: any) => {
      if (error.response?.status === 400) {
        message.error(error.response.data.messages[0]);
        return;
      }
      message.error('システムエラー');
    },
  });

  const handleConfirmDelete = () => {
    onDelete(faqDelete?.id);
  };

  const handleOpenModalDelete = (faqDelete: any) => {
    setIsVisibleModalConfirm(true);
    setFaqDelete(faqDelete);
  };

  const handleChangeKeyword = (value: string) => {
    setKeyword(value);
  };

  const handleChangeSizePage = (value: number) => {
    setPage(1);
    setSizePage(value);
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleSearch = () => {
    setPage(1);
    setKeywordSearch(keyword?.trim());
    setKeyword(keyword?.trim());
  };

  const handleGoToFaqCreatePage = () => {
    history.push(`/cms/categories/${query.id}/faq/create`);
  };

  const handleGoEditCategory = (id: number | undefined) => {
    history.push(`/cms/categories/${query.id}/faq/edit/${id}`);
  };

  const handleGoBackCategoryList = () => {
    history.push('/cms/categories');
  };

  return (
    <div className="page-content company-page">
      <div className="mb-[16px]">
        <h2 className="page-content-title border-bottom-title">
          <img
            src={leftArrow}
            alt=""
            className="inline-block mr-[17px] cursor-pointer"
            onClick={handleGoBackCategoryList}
          />

          <span onClick={handleGoBackCategoryList} className="cursor-pointer">
            {detailCategory?.ja_name}
          </span>
        </h2>
      </div>

      <div className="page-content-table">
        <div className="flex justify-between items-center p-[16px]">
          <div className="flex items-center">
            <Input
              onPressEnter={handleSearch}
              className="input-search mr-[8px]"
              placeholder="検索"
              suffix={iconSearch()}
              onChange={(e) => handleChangeKeyword(e.target.value)}
              value={keyword}
            />
          </div>

          <Button
            className="button-add"
            onClick={() => {
              handleGoToFaqCreatePage();
            }}
          >
            <img className="button-add-icon" src={addIcon} alt="" />
            FAQ追加
          </Button>
        </div>

        <div className="faq-list">
          {isLoading ? (
            <div className="flex justify-center">
              <Spin />
            </div>
          ) : (
            <>
              {listFaq &&
                listFaq?.records.length > 0 &&
                listFaq?.records.map((faq: any, index: number) => {
                  return (
                    <div
                      className="faq-list-item p-6 items-start grid gap-4 border-b-2 border-gray-300"
                      style={{ gridTemplateColumns: '0.5fr 15fr 1fr' }}
                    >
                      <img className="question" src={questionIcon} alt="" />

                      <div className="main">
                        <p className="main-title">{faq?.ja_title}</p>

                        <p className="main-content">{faq?.ja_content}</p>
                      </div>

                      <div className="flex justify-center">
                        <img
                          className="item-name-icon cursor-pointer mr-[10px]"
                          src={editIcon}
                          onClick={() => handleGoEditCategory(faq?.id)}
                          alt=""
                        />
                        <img
                          className="item-name-icon cursor-pointer mr-[10px]"
                          src={deleteIcon}
                          alt=""
                          onClick={() => handleOpenModalDelete(faq)}
                        />
                      </div>
                    </div>
                  );
                })}
            </>
          )}
        </div>

        {listFaq && listFaq?.records.length > 0 ? (
          <div className="py-[20px] px-[12px] flex justify-between items-center">
            <div className="pagination-infor">
              {listFaq?.limitFrom} - {listFaq?.limitTo} 件表示/全 {listFaq?.totalRow} 件中
            </div>
            <div className="flex">
              <Pagination
                current={page}
                total={listFaq?.totalRow}
                className="pagination-main mr-[20px]"
                showSizeChanger={false}
                pageSize={sizePage}
                onChange={(page) => handleChangePage(page)}
              />
              <Select
                defaultValue={10}
                style={{ width: 153 }}
                className="pagination-options"
                onChange={(e) => handleChangeSizePage(e)}
                options={pageSizeList}
              />
            </div>
          </div>
        ) : (
          <div className="py-[20px] px-[12px] flex justify-center items-center">
            <TableNoteNoData />
          </div>
        )}
      </div>

      <ModalConfirm
        isShowModal={isVisibleModalConfirm}
        onCancel={handleCancel}
        onOk={handleConfirmDelete}
        content={'FAQ削除確認'}
        subContent={`選択した「${
          faqDelete?.ja_title.length > 10 ? `${(faqDelete?.ja_title).substring(0, 10)}...` : faqDelete?.ja_title
        }」を削除してもよろしいですか？`}
        loadingButtonOk={isLoadingDeleta}
        isIcon={false}
      />
    </div>
  );
};

export default FaqListComponent;
