import { Button, Form, InputNumber, Select, Spin, message } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useListFileByType } from 'src/api/data/locationSite/useListFileByType'
import selectIcon from 'src/assets/images/select-icon.png'
import { listTypeNameFileConstuctionZone, punchieUploadType } from 'src/constants'
import { FileByType } from './type'
import { useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { overloadManagement } from 'src/api/locationSite'
import { useOverloadManagement } from 'src/api/data/locationSite/useOverloadManagement'
import { QUERY_KEY } from 'src/api/data/query-key'
import { useLocationSiteListFiles } from 'src/api/data/locationSite/useLocationSiteListFiles'
interface OverloadManagementProp{
    handleCheckValueForm: (value: boolean) => void
}
const OverloadManagement = ({handleCheckValueForm = () => {}}: OverloadManagementProp) => {
    const [antForm] = Form.useForm()
    const [quantityCars, setQuantityCars] = useState(1)
    const { idConstructionZone } = useParams<{ idConstructionZone: string }>()
    const { data: listFileThickness, isLoading } = useListFileByType(Number(idConstructionZone),listTypeNameFileConstuctionZone.CUTTING_THICKNESS_FILES)
    const { data: overloadManagementData, isLoading: isLoadingOverload } = useOverloadManagement(Number(idConstructionZone))
    const { data: locationSiteListFiles } = useLocationSiteListFiles(Number(idConstructionZone))
    const queryClient = useQueryClient()
    const [isValueForm,setIsValueForm] = useState(false)
    const [errorQuantityCars, setErrorQuantityCars] = useState('')
    const SelectIcon = () => (
        <img src={selectIcon} alt='' />
    )

    const handleChangeQuantityCards = (quantity: any) => {
        if(quantity) {
            setQuantityCars(quantity?.toFixed(0));
            if(quantity > 100 || quantity < 1) {
                setErrorQuantityCars('台数を1～100の範囲で入力してください')
            }else {
                setErrorQuantityCars('')
            }
        }else {
            setQuantityCars(1);
        }
    }

    const optionFileThickness = useMemo(() => {
        return listFileThickness?.map((file: FileByType) => (
            {
                value: file.id,
                label:file.file_name
            }
        ))
    },[listFileThickness])

    const {isLoading: isloading, mutate: onUpdateOverloadManagement } = useMutation(
        (param: any) => overloadManagement(Number(idConstructionZone),param),
        {
          onSuccess: async() => {
            // antForm.resetFields()
            await queryClient.invalidateQueries(QUERY_KEY.OVERLOAD_MANAGEMENT)
            message.success('データ更新成功')
          },
          onError: (error: any) => {
            if (error.response?.status === 400) {
                message.error(error.response.data.messages[0])
                return
            }
            message.error('システムエラー')
          },
        },
    )

    const handleUpdateOverloadManagement = async() => {
        try {
            await antForm.validateFields()
        } catch { return }

        if(errorQuantityCars?.length > 0){
            return
        }
        
        const values = antForm.getFieldsValue()

        const listTonnage: any = []

        Array.from(Array(Number(quantityCars)), (e, i) => (
            listTonnage.push(values[`vehicle_loads[${i}]`])
        ))

        const paramUpdate = {
            vehicle_loads: listTonnage,
            cutting_thickness_file: values.cutting_thickness_file,
            cutting_order_file: values.cutting_order_file,
            amount_of_mixture: values.amount_of_mixture
        }

        onUpdateOverloadManagement(paramUpdate)
        setIsValueForm(false);
    }
    useEffect(() => {
        if(overloadManagementData?.vehicle_loads?.length > 0) {
            setQuantityCars(overloadManagementData?.vehicle_loads?.length)
        }else {
            setQuantityCars(1)
        }
    },[overloadManagementData?.vehicle_loads])

    const listVehicle: any = useMemo(() => {
        const convertListVehicle = overloadManagementData?.vehicle_loads?.reduce((a: any, v:number,i: number) => ({ ...a, [`vehicle_loads[${i}]`]: v}), {})  
        return {
            ...convertListVehicle,
        }
    },[overloadManagementData?.vehicle_loads])

    const cuttingOrderDiagramOption = useMemo(() => {
        return locationSiteListFiles?.punches?.filter((e: any) => e.type === punchieUploadType.PUNCH_CUTTING)?.map((item: any) => (
            {
                value: item?.id,
                label: item?.name
            }
        ))
    },[locationSiteListFiles])


    const validateNumberFields = (rule: any, value: number, callback: any, name: string, min: number) => {
        if (!value && value !== 0) {
            callback(`${name}を入力してください`);
        }else if(value <= min) {
            callback(`${name}は${min}より大きい値を入力してください `)
        }else {
            callback()
        }
      }

    useEffect(() => {
        if(cuttingOrderDiagramOption) {
            const isAgencyStaffExists = cuttingOrderDiagramOption?.find((file: any) => file.value === overloadManagementData?.cutting_order_file)
            if(isAgencyStaffExists) {
                antForm.setFieldValue('cutting_order_file', overloadManagementData?.cutting_order_file)
            }else {
                antForm.setFieldValue('cutting_order_file', null)
            }
        }
    },[antForm, cuttingOrderDiagramOption, overloadManagementData])

    useEffect(() => {
        if(optionFileThickness) {
            const isAgencyStaffExists = optionFileThickness?.find((file: any) => file.value === overloadManagementData?.cutting_thickness_file)
            if(isAgencyStaffExists) {
                antForm.setFieldValue('cutting_thickness_file', overloadManagementData?.cutting_thickness_file)
            }else {
                antForm.setFieldValue('cutting_thickness_file', null)
            }
        }
    },[antForm, optionFileThickness, overloadManagementData])

    const renderNotFoundContent = () => {
        return <p className='text-center p-[10px]'>データがありません</p>
    }

    const handleChangeValue = (allvalues: any) => {
        const allValuesExceptPaymentType = {...allvalues}
        const listValue = Object.keys(allValuesExceptPaymentType).map((key: any) => allvalues[key])
        const validateErrorValueList = []
        listValue?.forEach((value: any) => {
            if(value === undefined || value === '') {
                validateErrorValueList.push(value)
            }
        })
        listValue?.some((value:any) => {
            if(value !== undefined && value !== '' && value != null) {
                setIsValueForm(true)
                return true
            }
            setIsValueForm(false)
            return false
        })
    }

    useEffect(() => {
        handleCheckValueForm(isValueForm)
    },[isValueForm])
    return (
        <div className='overload__management page-content-detail'>
            {
                isLoadingOverload ? <Spin className='position-center'/> :
                <Form form={antForm} onValuesChange={(value, allvalues) => handleChangeValue(allvalues)}>
                    <div className='page-content-detail-title mb-[24px] flex justify-between items-center'>
                        過積載管理設定
                        <Button loading={isloading} className='button-add w-[128px]' onClick={() => handleUpdateOverloadManagement()}><span></span>保存</Button>
                    </div>
                    <div className='main-block mb-[16px]'>
                        <h4 className='tonnage__setting'>混合物密度</h4>
                        <div className='amount-mixture'>
                            <span className='mr-[12px] mr-[134px] text-[16px] text-[#2E2E2E]'>混合物密度</span> 
                            <div className='relative'>
                                <Form.Item 
                                    initialValue={overloadManagementData?.amount_of_mixture} 
                                    name='amount_of_mixture' 
                                    rules={
                                        [                                    
                                            { validator: (rule, value, callback) => validateNumberFields(rule,value,callback, '混合物密度', 0)}
                                        ]
                                    }
                                >
                                    <InputNumber 
                                        min={0} 
                                        className='input__tonnage-total mr-[24px]' 
                                    />
                                </Form.Item>
                                <span className='text-[16px] text-[#2E2E2E] unit-input'>トン/m³</span>
                            </div>
                        </div>
                    </div>
                    <div className='main-block mb-[16px]'>
                        <h4 className='tonnage__setting'>ダンプ１台毎のトン数設定</h4>
                        <div className='tonnage-total'>
                            <span className='mr-[13px] text-[16px] text-[#2E2E2E]'>台数</span> 
                            <InputNumber 
                                onChange={(quantity) => handleChangeQuantityCards(quantity)}
                                min={1} 
                                value={quantityCars} 
                                className='input__tonnage-total mr-[13px]' 
                            />
                            <span className='text-[16px] text-[#2E2E2E]'>台</span>
                        <p className='ml-[42px] text-[#ff4d4f]'>{errorQuantityCars}</p>
                        </div>
                        <div className='list__tonnages overload__tonnage'>
                            <ul>
                                {
                                    quantityCars  && Number(quantityCars) <= 100 &&
                                    Array.from(Array(Number(quantityCars)), (e, i) => (
                                        <li className='tonnage flex relative' key={i}>
                                            <span className='mr-[24px] mt-[8px]'>{i + 1}台目</span> 
                                            <Form.Item 
                                                initialValue={listVehicle[`vehicle_loads[${i}]`]} 
                                                name={`vehicle_loads[${i}]`} 
                                                rules={
                                                    [                                    
                                                        { validator: (rule, value, callback) => validateNumberFields(rule,value,callback, 'ダンプ台のトン数', 0)}
                                                    ]
                                                }
                                            >
                                                <InputNumber min={0} className='input__tonnage' /> 
                                            </Form.Item>
                                            <span className='unit-input'>トン</span>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div className='main-block'>
                        <h2 className='tonnage__setting'>
                            入力データ
                        </h2>
                        <div className='flex items-center border-bottom-gray py-[8px] px-[12px]'>
                            <h4 className='text-[#2E2E2E] w-1/12 min-w-[200px]'>切削厚さファイル</h4>
                            <Form.Item 
                                name='cutting_thickness_file' 
                                initialValue={overloadManagementData?.cutting_thickness_file} 
                                rules={[{ required: true, message: '切削厚さファイルを選択してください' }]}
                            >
                                <Select
                                    className='cutting-thickness-file'
                                    loading={isLoading}
                                    options={optionFileThickness}
                                    suffixIcon={SelectIcon()}
                                    notFoundContent={renderNotFoundContent()}
                                />
                            </Form.Item>
                        </div>
                        <div className='flex items-center border-bottom-gray py-[8px] px-[12px]'>
                            <h4 className='text-[#2E2E2E] w-1/12 min-w-[200px]'>切削順序図</h4>
                            <Form.Item 
                                initialValue={overloadManagementData?.cutting_order_file} 
                                name='cutting_order_file' 
                                rules={[{ required: true, message: '切削順序図を選択してください' }]}
                            >
                                <Select
                                    className='cutting-thickness-file'
                                    options={cuttingOrderDiagramOption}
                                    suffixIcon={SelectIcon()}
                                    notFoundContent={renderNotFoundContent()}
                                />
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            }
        </div>
    )
}

export default OverloadManagement