import React, { useCallback, useMemo, useState, useEffect } from 'react'
import './locationDetail.modules.scss'
import addIconBlue from 'src/assets/images/add-icon-blue.png'
import viewIcon from 'src/assets/images/view-icon.png'
import deleteIcon from 'src/assets/images/delete-icon.png'
import deleteIconWhite from 'src/assets/images/delete-icon-white.png'
import editIcon from 'src/assets/images/edit-icon.png'
import copyIcon from 'src/assets/images/copy-icon.png'
import { Button, Form, Input, Modal, Spin, Upload, UploadFile, message } from 'antd'
import { listTypeFileConstuctionZone, listTypeNameFileConstuctionZone, listTypePunchieConstuctionZone, punchieUploadType } from 'src/constants'
import { useMutation, useQueryClient } from 'react-query'
import { addOrEditFileLocationSite, deleteFileLocationSite, deletePunchie, duplicatePunchie, editNameLocationSite } from 'src/api/locationSite'
import { useHistory, useParams } from 'react-router-dom'
import ModalDelete from 'src/components/common/modalDelete'
import { useLocationSiteListFiles } from 'src/api/data/locationSite/useLocationSiteListFiles'
import { FileConstructionZoneType } from './type'
import { QUERY_KEY } from 'src/api/data/query-key'
// import PreviewLinearSima from './previewLinearSima'
// import PreviewVerticalSima from './previewVerticalSima'
// import { useLoadFileSima } from 'src/api/data/locationSite/useLoadFileSima'
import uploadIcon from 'src/assets/images/upload-icon.png'
import SheetJSXSpread from './previewExcel'
import { usePunchieDetail } from 'src/api/data/locationSite/usePunchieDetail'
import PreviewPunchie from './previewPunchie'
import PreviewFileSima from 'src/pages/viewSima'
import { validateFieldString } from 'src/utils/validateFields'
import downloadIcon from 'src/assets/images/download_icon.png'
import { apiResBlob } from 'src/api/axios'
interface FileType {
    file_name: string,
    file_path: string,
    file_type:number,
    id:number
}

const ListFileLocationComponent = () => {
    const { id } = useParams<{ id: string }>()
    const { idConstructionZone } = useParams<{ idConstructionZone: string }>()
    const [isShowModalDeleteFile, setIsShowModalDeleteFile] = useState(false)
    const [isShowModalPreviewFile, setIsShowModalPreviewFile] = useState(false)
    // const [isShowModalPreviewFileSima, setIsShowModalPreviewFileSima] = useState(false)
    // const [isShowModalPreviewVerticalSima, setIsShowModalPreviewVerticalSima] = useState(false)
    const [idFileDelete, setIdFileDelete] = useState(0)
    const [urlFileExcelPrivew,setUrlFileExcelPrivew] = useState('')
    const [antForm] = Form.useForm()
    const [antFormSimaBase] = Form.useForm()
    const queryClient = useQueryClient()
    // const [simaFileId, setSimaFileId] = useState(0)
    const history = useHistory()
    const [idPunchie, setIdPunchie] = useState<number | undefined>()
    const [isShowModalPreviewPunchie, setIsShowModalPreviewPunchie] = useState(false)
    const [isShowModalDeletePunchie, setIsShowModalDeletePunchie] = useState(false)
    const [isShowPreviewSimaBase, setIsShowPreviewSimaBase] = useState(false)
    const [idPunchieDelete, setIdPunchieDelete] = useState<number | undefined>()
    const [typePunchieDelete, setTypePunchieDelete] = useState(0)
    const [typePunchieView, setTypePunchieView] = useState(0)
    const [pathLineSima, setPathLineSima] = useState('')
    const [pathSlineSima, setPathSlineSima] = useState('')
    const [isShowModalChangeFileSimaBase, setisShowModalChangeFileSimaBase] = useState(false)
    const [isHidenUploadSima, setIsHidenUploadSima] = useState({lineSima: false, slineSima: false})
    const [isLoadingUpdateSima, setIsLoadingUpdateSima] = useState(false)
    const [lineSimaFileData, setLineSimaFileData] = useState<{file_name: string}>()
    const [sliceSimaFileData, setSliceSimaFileData] = useState<{file_name: string}>()
    const [idDuplicate, setIdDuplicate] = useState<number | null>(null)
    const [isShowModalEditName, setIsShowModalEditName] = useState(false)
    const [antFormNameLocationsite] = Form.useForm()
    const { data: locationSiteListFiles, isLoading } = useLocationSiteListFiles(Number(idConstructionZone))

    // const { data: simaFileData, isLoading:isloadingFile} = useLoadFileSima(simaFileId)

    const { data: punchieDetailData, isLoading:isloadingPunchie} = usePunchieDetail(Number(idConstructionZone), Number(idPunchie))


    const handleOpenModalDeleteFile = (idDelete: number) => {
        setIdFileDelete(idDelete)
        setIsShowModalDeleteFile(true)
    }

    const handleCancelDeleteFile = () => {
        setIsShowModalDeleteFile(false)
    }

    const {mutate: onDeleteFile, isLoading: isloadingDelete } = useMutation(
        (id: number) => deleteFileLocationSite(id),
        {
          onSuccess: async() => {
            await queryClient.invalidateQueries(QUERY_KEY.LOCATION_SITE_LIST_FILES)
            setIsShowModalDeleteFile(false)
            message.success('データ更新成功')
          },
          onError: (error: any) => {
            if (error.response?.status === 400) {
                message.error(error.response.data.messages[0])
                return
            }
            message.error('システムエラー')
          },
        },
    )

    const handleDeleteFile = () => {
        onDeleteFile(idFileDelete)
    }

    const {mutate: onAddEditFile , isLoading: isloadingAddOrEdit} = useMutation(
        (param: FormData) => addOrEditFileLocationSite(param),
        {
          onSuccess: async() => {
            await queryClient.invalidateQueries(QUERY_KEY.LOCATION_SITE_LIST_FILES)
            antForm.resetFields()
            message.success('データ更新成功')
          },
          onError: (error: any) => {
            if (error.response?.status === 400) {
                message.error(error.response.data.messages[0])
                return
            }
            message.error('システムエラー')
          },
        },
    )

    const handleAddEditFile = useCallback(async(file: any, typeFileNew: number) => {
        if(handleValidateFile(file?.file, typeFileNew)) {
            return
        }
        const newFormData = new FormData()
        
        newFormData.append('file', file.fileList[0].originFileObj)
        newFormData.append('type', typeFileNew.toString())
        newFormData.append('construction_zone_id', idConstructionZone)
        onAddEditFile(newFormData);
    },[idConstructionZone, onAddEditFile])

    const handleValidateFile = (file: UploadFile, typeFileNew: number) => {
        const fileSize = file?.size! / 1024 / 1024
        if(fileSize <= 25) {
            return false
        }else {
            message.error('ファイルサイズが25Mを超えています')
            return true
        }
    }

    const handleOpenModalPreviewFile = (file: FileType) => {
        
        if(file?.file_type === listTypeNameFileConstuctionZone.CUTTING_THICKNESS_FILES || file?.file_type === listTypeNameFileConstuctionZone.PAVING_THICKNESS_FILES) {
            handleOpenModalPreviewFileExcel(file?.file_path)
            return
        }
        // if(file.file_type === listTypeNameFileConstuctionZone.SIM_LINEAR_FILES) {
        //     setSimaFileId(file?.id)
        //     handleOpenModalPreviewFileSima()
        //     return
        // }
        // setSimaFileId(file?.id)
        // handleOpenModalPreviewVerticalSima()
    }


    const handleCloseModalPreview = () => {
        setIsShowModalPreviewFile(false)
    }

    const handleOpenModalPreviewFileExcel = (url: string) => {
        setIsShowModalPreviewFile(true)
        setUrlFileExcelPrivew(url)
    }

    // const handleOpenModalPreviewFileSima = () => {
    //     setIsShowModalPreviewFileSima(true)
    // }

    // const handleCloseModalPreviewFileSima = () => {
    //     setIsShowModalPreviewFileSima(false)
    // }

    // const handleOpenModalPreviewVerticalSima = () => {

    //     setIsShowModalPreviewVerticalSima(true)
    // }

    // const handleCloseModalPreviewVerticalSima = () => {
    //     setIsShowModalPreviewVerticalSima(false)
    // }

    const handleGoCreatePonchiePicture = (type: number) => {
        if(type === punchieUploadType.PUNCH_PICTURE) {
            history.push(`/construction-site/${id}/location-site/${idConstructionZone}/ponchie-picture`)
            return
        }
        if(type === punchieUploadType.PUNCH_CUTTING) {
            history.push(`/construction-site/${id}/location-site/${idConstructionZone}/cutting-order-diagram`)
            return
        }
        if(type === punchieUploadType.PUNCH_PAVEMENT) {
            history.push(`/construction-site/${id}/location-site/${idConstructionZone}/pavement-order-diagram`)
            return
        }
    }

    const handleOpenModalViewPunchie = (id: number, type: number) => {
        setIdPunchie(id)
        setTypePunchieView(type)
        setIsShowModalPreviewPunchie(true)
    }

    const handleCloseModalViewPunchie = () => {
        setIsShowModalPreviewPunchie(false)
    }

    const {mutate: onDeletePunchie, isLoading: isloadingDeletePunchie } = useMutation(
        (id: number | undefined) => deletePunchie(Number(idConstructionZone),id),
        {
          onSuccess: async() => {
            await queryClient.invalidateQueries(QUERY_KEY.LOCATION_SITE_LIST_FILES)
            setIsShowModalDeletePunchie(false)
            message.success('データ更新成功')
          },
          onError: (error: any) => {
            if (error.response?.status === 400) {
                message.error(error.response.data.messages[0])
                return
            }
            message.error('システムエラー')
          },
        },
    )
    const {mutate: onDuplicate, isLoading: isLoadingDuplicate} = useMutation(
        (id: number) => duplicatePunchie(id),
        {
            onSuccess: async() => {
              await queryClient.invalidateQueries(QUERY_KEY.LOCATION_SITE_LIST_FILES)
              setIsShowModalDeletePunchie(false)
              message.success('データ更新成功')
            },
            onError: (error: any) => {
              if (error.response?.status === 400) {
                  message.error(error.response.data.messages[0])
                  return
              }
              message.error('システムエラー')
            },
          },
    )

    const handleOpenModalDeletePunchie = (id: number, type: number) => {
        setIdPunchieDelete(id)
        setTypePunchieDelete(type)
        setIsShowModalDeletePunchie(true)
    }

    const handleDeletePunchie = () => {
        onDeletePunchie(idPunchieDelete)
    }
    
    const handleCancelDeletePunchie = () => {
        setIsShowModalDeletePunchie(false)
    }

    const handleGetDeleteMessage = useMemo(() => {
        if(typePunchieDelete === punchieUploadType.PUNCH_PICTURE) {
            return 'このポンチ絵を削除します。よろしいですか？ \n 注意：ポンチ絵を削除すると、このポンチ絵によって作成された切削順序図と舗設順序図も削除されることになります。'
        }
        if(typePunchieDelete === punchieUploadType.PUNCH_CUTTING) {
            return 'この切削順序図を削除します。よろしいですか？'
        }
        return 'この舗設順序図を削除します。よろしいですか？'
    },[typePunchieDelete])

    const handleGoToEdit = (punchieId: number, punchieType: number) => {
        queryClient.removeQueries(QUERY_KEY.LIST_FILE_BY_TYPE)
        if(punchieType === punchieUploadType.PUNCH_PICTURE) {
            return  history.push(`/construction-site/${id}/location-site/${idConstructionZone}/ponchie-picture/${punchieId}`)
        }
        if(punchieType === punchieUploadType.PUNCH_CUTTING) {
            return history.push(`/construction-site/${id}/location-site/${idConstructionZone}/cutting-order-diagram/${punchieId}`)
        }
        return history.push(`/construction-site/${id}/location-site/${idConstructionZone}/pavement-order-diagram/${punchieId}`)
    }

    const handlePreviewFileSimaBase = () => {
        const listFile = locationSiteListFiles?.files
        const fileLineSima = listFile.find((file: any) => file.file_type === listTypeNameFileConstuctionZone.SIM_LINEAR_FILES)
        const fileSlineSima = listFile.find((file: any) => file.file_type === listTypeNameFileConstuctionZone.SIM_SLICE_FILES)
        setPathLineSima(fileLineSima?.file_path)
        setPathSlineSima(fileSlineSima?.file_path)
        setIsShowPreviewSimaBase(true)
    }

    const handleCloseModalViewSimaBase = () => {
        setIsShowPreviewSimaBase(false)
        setPathLineSima('')
        setPathSlineSima('')
    }

    const handleOpenModalChangeFileSimaBase = () => {
        setisShowModalChangeFileSimaBase(true)
    }

    const handleCloseModalChangeFileSimaBase = () => {
        setisShowModalChangeFileSimaBase(false)
        setIsHidenUploadSima((prevState) => ({
            ...prevState,
            slineSima: false,
            lineSima: false
        }))
    }

    const renderUploadIcon = () => (
        <img className='inline-block mr-[8px]' src={uploadIcon} alt=''/>
    )

    const renderUploadList = {
        removeIcon: () => (
          <img src={deleteIconWhite} alt='' />
        ),
    };

    const handleChangeFileSima = (value: any, type: number) => {
        if(value.fileList.length > 0) {
            if(type === 1) {
                setIsHidenUploadSima((prevState) => ({
                    ...prevState,
                    lineSima: true
                }))
            }else {
                setIsHidenUploadSima((prevState) => ({
                    ...prevState,
                    slineSima: true
                }))
            }
        }else {
            if(type === 1) {
                setIsHidenUploadSima((prevState) => ({
                    ...prevState,
                    lineSima: false
                }))
            }else {
                setIsHidenUploadSima((prevState) => ({
                    ...prevState,
                    slineSima: false
                }))
            }
        }

    }

    const handleUploadFileSima = async (params: any) => {
        
        await addOrEditFileLocationSite(params)
    }

    const handleChangeFileSimaBase = useCallback(async () => {
        try {
            await antFormSimaBase.validateFields()
        } catch { return }
        setIsLoadingUpdateSima(true)
        const values = antFormSimaBase.getFieldsValue()
        const newFormDataLineSima = new FormData()
        
        values.sim_linear_files?.fileList?.forEach((simLinearFile: any) => {
            newFormDataLineSima.append('file',simLinearFile?.originFileObj)
        });
        const fileLineSimaInConstructionZone = locationSiteListFiles?.files?.find(
            (file: {file_type: number}) => file.file_type === listTypeNameFileConstuctionZone.SIM_LINEAR_FILES
        )
        newFormDataLineSima.append('id', fileLineSimaInConstructionZone?.id)
        newFormDataLineSima.append('type', listTypeNameFileConstuctionZone.SIM_LINEAR_FILES.toString())
        newFormDataLineSima.append('construction_zone_id', idConstructionZone)
        const newFormDataSlineSima = new FormData()
        
        values.sim_slice_files?.fileList?.forEach((simSliceFile: any) => {
            newFormDataSlineSima.append('file',simSliceFile?.originFileObj)
        });
        const fileSlineSimaInConstructionZone = locationSiteListFiles?.files?.find(
            (file: {file_type: number}) => file.file_type === listTypeNameFileConstuctionZone.SIM_SLICE_FILES
        )
        newFormDataSlineSima.append('id', fileSlineSimaInConstructionZone?.id)
        newFormDataSlineSima.append('type', listTypeNameFileConstuctionZone.SIM_SLICE_FILES.toString())
        newFormDataSlineSima.append('construction_zone_id', idConstructionZone)
        
        try {
            if(values.sim_linear_files?.fileList.length > 0) {
                await handleUploadFileSima(newFormDataLineSima);
            }
            if(values.sim_slice_files?.fileList.length > 0) {
                await handleUploadFileSima(newFormDataSlineSima);
            }
            await queryClient.invalidateQueries(QUERY_KEY.LOCATION_SITE_LIST_FILES)
            message.success('データ更新成功')
            antFormSimaBase.resetFields()
            handleCloseModalChangeFileSimaBase()
            setIsLoadingUpdateSima(false)
        } catch { 
            message.error('システムエラー')
            setIsLoadingUpdateSima(false)
            return 
        }    
        
    },[antFormSimaBase, idConstructionZone, locationSiteListFiles?.files, queryClient])

    const validateFileExtension = (_: any, file: any, callback: any) => {
        const acceptedExtensions = ['.sim']
        const fileExtension = file?.file.name.slice(file?.file.name.lastIndexOf('.'));
        const fileSize = file?.file.size / 1024 / 1024
        const isSizeNotAccepted = fileSize > 25
        if(file?.fileList?.length === 0 || file === undefined) {
            callback('ファイルを選択してください')
        }else if (!acceptedExtensions.includes(fileExtension?.toLowerCase())) {
            callback('アップロードするファイルは「siｍ」のいずれかの形式にしてください')
        }else if(isSizeNotAccepted) {
            callback(`ファイルサイズが25Mを超えています`)
        }
        else {
            callback()
        }
    };

    useEffect(() => {
        if(locationSiteListFiles?.files?.length > 0 && isShowModalChangeFileSimaBase) {
            const fileLineSimaInConstructionZone = locationSiteListFiles?.files?.find(
                (file: {file_type: number}) => file.file_type === listTypeNameFileConstuctionZone.SIM_LINEAR_FILES
            )
            setLineSimaFileData(fileLineSimaInConstructionZone)
            const fileSliceSimaInConstructionZone = locationSiteListFiles?.files?.find(
                (file: {file_type: number}) => file.file_type === listTypeNameFileConstuctionZone.SIM_SLICE_FILES
            )
            setSliceSimaFileData(fileSliceSimaInConstructionZone)
            setIsHidenUploadSima((prevState) => ({
                ...prevState,
                lineSima: true,
                slineSima: true
            }))
        }
    },[locationSiteListFiles?.files, isShowModalChangeFileSimaBase])

    const handleDeleteCurrentFileLine = () => {
        setIsHidenUploadSima((prevState) => ({
            ...prevState,
            lineSima: false,
        }))
        setLineSimaFileData({file_name:''})
    }

    const handleDeleteCurrentFileSlice = () => {
        setIsHidenUploadSima((prevState) => ({
            ...prevState,
            slineSima: false,
        }))
        setSliceSimaFileData({file_name:''})
    }

    const resetFormUpdateSimaBase = () => {
        antFormNameLocationsite.resetFields()
    }
    const handleDuplicatePunchie = (id : number) => {
        onDuplicate(id)
        setIdDuplicate(id)
    }

    const acceptTypeFile = (type: number) => {
        if(type === listTypeNameFileConstuctionZone.SIM_COMMON) {
            return '.sim'
        }
        if(type === listTypeNameFileConstuctionZone.POINT_FILES) {
            return '.las'
        }
        return 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }

    const handleOpenModaleEditName = (value: string) => {
        antFormNameLocationsite.setFieldValue('name', value)
        setIsShowModalEditName(true)
    }

    const handleCloseModalEditName = () => {
        setIsShowModalEditName(false)
    }

    const {mutate: onEditNameLocationSite , isLoading: isloadingEditName} = useMutation(
        (param: {name: string}) => editNameLocationSite(Number(idConstructionZone),param),
        {
          onSuccess: async() => {
            await queryClient.invalidateQueries(QUERY_KEY.LOCATION_SITE_LIST_FILES)
            setIsShowModalEditName(false)
            antFormNameLocationsite.resetFields()
            message.success('データ更新成功')
          },
          onError: (error: any) => {
            if (error.response?.status === 400) {
                message.error(error.response.data.messages[0])
                return
            }
            message.error('システムエラー')
          },
        },
    )

    const handleEditNameLocationSite = async() => {
        try {
            await antFormNameLocationsite.validateFields()
        } catch { return }
        const values = antFormNameLocationsite.getFieldsValue()
        onEditNameLocationSite(values)
    }

    const handleDownloadFile = (id: number, name: string) => {
        apiResBlob.get(`/api/admin/download-file/${id}`).then((data: any) => {
            var url = window.URL.createObjectURL(data.data);
            var a = document.createElement('a');
            a.href = url;
            a.download = name;
            document.body.appendChild(a);
            a.click();
            a.remove();
        })
        .catch(error => {
            console.error(error);
         });
    }
    return (
        <>
            {
                isLoading ? <Spin className='position-center'/> : 
                <div className='location__list__file page-content-detail mb-[60px]'>
                    <h2 className='page-content-detail-title mb-[24px] flex'>
                        <span className='mr-[8px]'>{locationSiteListFiles?.name}</span>
                        <img className='cursor-pointer h-[24px]' onClick={() => handleOpenModaleEditName(locationSiteListFiles?.name)} src={editIcon} alt=''/>
                    </h2>
                    {
                        <Spin className='m-auto z-1000' spinning={isloadingAddOrEdit}>
                            <Form form={antForm}>
                                {
                                    listTypeFileConstuctionZone?.map((typeFile) => {
                                        if(typeFile?.groupType) {
                                            return (
                                                <div className='block-file-location' key={typeFile.id}>
                                                    <div className='lable-file'>
                                                        <span className='mr-[16px]'>基本SIMA</span>
                                                        <img className='cursor-pointer mr-[16px]' onClick={handlePreviewFileSimaBase} src={viewIcon} alt=''/>
                                                        <img className='cursor-pointer' onClick={handleOpenModalChangeFileSimaBase} src={editIcon} alt=''/>
                                                    </div>
                                                    <div className='main-block p-[16px]'>
                                                        <div className='group-sima-base '>
                                                            <span className='p-[16px] lable-file'>{typeFile.groupType.firstType}</span>
                                                            <ul className='list-file'>
                                                                {
                                                                    locationSiteListFiles?.files?.map((item: FileConstructionZoneType) => {
                                                                        if(item?.file_type === typeFile.groupType.firstId) {
                                                                            return (                                 
                                                                                <li className='p-[12px] flex justify-between bg-[#EAEAEA] file-icon' key={item?.id}>
                                                                                    <p className='file-name break-all break-words'>{item?.file_name}</p>
                                                                                    <div className='flex min-w-[140px] justify-end'>
                                                                                        <img className='cursor-pointer' src={downloadIcon} alt='' onClick={() => handleDownloadFile(item?.id, item?.file_name)}/>
                                                                                    </div>
                                                                                </li>  
                                                                            )
                                                                        }
                                                                        return ''
                                                                    })
                                                                }
                                                            </ul>
                                                            <span className='p-[16px] lable-file'>{typeFile.groupType.secondType}</span>
                                                            <ul className='list-file'>
                                                                {
                                                                    locationSiteListFiles?.files?.map((item: FileConstructionZoneType) => {
                                                                        if(item?.file_type === typeFile.groupType.secondId) {
                                                                            return (                                 
                                                                                <li className='list-file-item justify-between file-icon' key={item?.id}>
                                                                                    <p className='file-name break-all break-words'>{item?.file_name}</p>
                                                                                    <div className=' flex min-w-[140px] justify-end'>
                                                                                        <img className='cursor-pointer' src={downloadIcon} alt='' onClick={() => handleDownloadFile(item?.id, item?.file_name)}/>
                                                                                    </div>
                                                                                </li>  
                                                                            )
                                                                        }
                                                                        return ''
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }else {
                                            return (
                                                <div className='block-file-location' key={typeFile.id}>
                                                    <div className='lable-file'>
                                                        <span className='mr-[16px]'>{typeFile.type}</span>
                                                        <Upload 
                                                            accept={acceptTypeFile(typeFile.id)}
                                                            showUploadList={false}
                                                            beforeUpload={(file) => Promise.reject(file)} 
                                                            multiple={false} 
                                                            maxCount={1} 
                                                            onChange={(e) => handleAddEditFile(e, typeFile.id)}
                                                            className='flex'
                                                        >
                                                            <img className='add-icon' src={addIconBlue} alt='' />
                                                        </Upload>
                                                    </div>
                                                    <ul className='list-file'>
                                                        {
                                                            locationSiteListFiles?.files?.map((item: FileConstructionZoneType) => {
                                                                if(item?.file_type === typeFile.id) {
                                                                    return (                                 
                                                                        <li className='list-file-item justify-between' key={item?.id}>
                                                                            <p className='file-name break-words break-all'>{item?.file_name}</p>
                                                                            <div className='ml-[5px] file-icon min-w-[140px] justify-end'>
                                                                                {
                                                                                    item.file_type !== listTypeNameFileConstuctionZone.POINT_FILES 
                                                                                    && item.file_type !== listTypeNameFileConstuctionZone.SIM_COMMON ? 
                                                                                    <>
                                                                                        <img src={viewIcon} alt='' onClick={() => handleOpenModalPreviewFile(item)}/>
                                                                                    </> 
                                                                                    : ''
                                                                                }
                                                                                {
                                                                                     item.file_type !== listTypeNameFileConstuctionZone.POINT_FILES ?
                                                                                     <img className='cursor-pointer' src={downloadIcon} alt='' onClick={() => handleDownloadFile(item?.id, item?.file_name)}/>
                                                                                     :''
                                                                                }
                                                                                <img src={deleteIcon} alt='' onClick={() => handleOpenModalDeleteFile(item?.id)}/>
                                                                            </div>
                                                                        </li>  
                                                                    )
                                                                }
                                                                return ''
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            )
                                        }
                                        
                                    })
                                }
                                {
                                    listTypePunchieConstuctionZone.map((typePunchie) => (
                                        <div className='block-file-location' key={typePunchie.id}>
                                            <div className='lable-file'>
                                                <span className='mr-[16px]'>{typePunchie.type}</span>
                                                <Button 
                                                onClick={() => handleGoCreatePonchiePicture(typePunchie.id)} 
                                                className='button-add button-add-file'
                                                >
                                                    <span></span>作成
                                                </Button>
                                            </div>
                                            <ul className='list-file'>
                                                {
                                                    locationSiteListFiles?.punches?.map((punchie: any) => {
                                                        if(punchie?.type === typePunchie.id) {
                                                            return (       
                                                                <li className='list-file-item' key={punchie?.id}>
                                                                    <Spin spinning = {isLoadingDuplicate && Number(idDuplicate) === Number(punchie.id) }>
                                                                        <p className='file-name break-words'>{punchie?.name}</p>
                                                                        <div className='ml-[5px] file-icon min-w-[140px] justify-end'>
                                                                            <img src={viewIcon} alt='' onClick={() => handleOpenModalViewPunchie(punchie.id, punchie?.type)}/>
                                                                            {
                                                                                <img src={editIcon} alt='' onClick={() => {
                                                                                    handleGoToEdit(punchie.id, punchie?.type)
                                                                                }}/>
                                                                            }
                                                                            <img src={copyIcon} alt='' onClick={() => handleDuplicatePunchie(Number(punchie.id))}/>
                                                                            <img src={deleteIcon} alt='' onClick={() => handleOpenModalDeletePunchie(punchie.id, punchie.type)}/>
                                                                        </div>
                                                                    </Spin>
                                                                </li>
                                                            )
                                                        }
                                                        return ''
                                                    })
                                                }
                                            </ul> 
                                        </div>
                                    ))
                                }
                            </Form>
                        </Spin>
                    }
                    

                    <Modal 
                        className='modal-preview-punchie' 
                        width={1000} 
                        open={isShowPreviewSimaBase} 
                        onCancel={handleCloseModalViewSimaBase} 
                        footer={false}
                        centered
                        style={{ top: 20 }}
                    >
                        {
                           <PreviewFileSima pathLineSima={pathLineSima} pathSlineSima={pathSlineSima} visible={isShowPreviewSimaBase}/>  
                        }
                    </Modal>

                    <Modal 
                        centered 
                        className='modal-upload-sima' 
                        width={832} 
                        open={isShowModalChangeFileSimaBase} 
                        onCancel={handleCloseModalChangeFileSimaBase} 
                        footer={false}
                        afterClose={resetFormUpdateSimaBase}
                    >
                        <Form form={antFormSimaBase} className='upload-sima-base'> 
                            <div className='mb-[16px] mt-[8px]'>
                                <label className='labe-upload mb-[8px]'>線形SIM <span>*</span></label>
                                {
                                    lineSimaFileData?.file_name && 
                                    <div className='file-name-edit'>
                                        <p className='w-full line-1'>
                                            {lineSimaFileData?.file_name}
                                        </p>
                                        <img src={deleteIconWhite} alt="" className='cursor-pointer' onClick={handleDeleteCurrentFileLine}/>
                                    </div>
                                }
                                {
                                    !lineSimaFileData?.file_name &&
                                    <Form.Item 
                                        name="sim_linear_files" 
                                        rules={[
                                            {
                                                validator: validateFileExtension,
                                            },
                                        ]}
                                    >
                                        <Upload 
                                            showUploadList={renderUploadList} 
                                            beforeUpload={(file) => Promise.reject(file)} 
                                            maxCount={1} 
                                            className={`upload-file-form ${isHidenUploadSima.lineSima ? 'upload-sima' : ''}`}
                                            onChange={(value) => handleChangeFileSima(value, 1)}
                                            accept=".sim"
                                        >
                                            <Button icon={renderUploadIcon()}>ファイルを選択して下さい</Button>
                                        </Upload>
                                    </Form.Item>
                                }
                            </div>
                            <div className='mb-[16px]'>
                                <label className='labe-upload mb-[8px]'>縦横断SIM <span>*</span></label>
                                {
                                    sliceSimaFileData?.file_name && 
                                    <div className='file-name-edit'>
                                        <p className='w-full line-1'>{sliceSimaFileData?.file_name}</p>  
                                        <img src={deleteIconWhite} alt="" className='cursor-pointer' onClick={handleDeleteCurrentFileSlice}/>
                                    </div>
                                }
                                {
                                    !sliceSimaFileData?.file_name &&
                                    <Form.Item 
                                        name="sim_slice_files" 
                                        rules={[
                                            {
                                                validator: validateFileExtension,
                                            },
                                        ]}
                                    >
                                        <Upload 
                                            showUploadList={renderUploadList} 
                                            beforeUpload={(file) => Promise.reject(file)} 
                                            maxCount={1}
                                            className={`upload-file-form ${isHidenUploadSima.slineSima ? 'upload-sima' : ''}`}
                                            onChange={(value) => handleChangeFileSima(value, 2)}
                                            accept=".sim"
                                        >
                                            <Button icon={renderUploadIcon()}>ファイルを選択して下さい</Button>
                                        </Upload>
                                    </Form.Item>
                                }
                            </div>
                            <div className='footer-form mt-[40px] flex'>
                                <Button className='button-form mr-[16px]' onClick={handleCloseModalChangeFileSimaBase}>キャンセル</Button>
                                <Button loading={isLoadingUpdateSima} className='button-form button-form-Submit' onClick={handleChangeFileSimaBase}>
                                    OK
                                </Button>
                            </div>
                        </Form>
                    </Modal>

                    {isShowModalPreviewFile && <Modal className='modal-preview-punchie modal-preview-custom' width={1257} open={isShowModalPreviewFile} onCancel={handleCloseModalPreview} footer={false}>
                        <SheetJSXSpread urlFileExcelPrivew={urlFileExcelPrivew}/>
                    </Modal>}

                    {/* <Modal className='modal-preview-punchie modal-preview-custom' width={1295} open={isShowModalPreviewFileSima} onCancel={handleCloseModalPreviewFileSima} footer={false}>
                        {
                            isloadingFile ? <Spin className='position-center' /> : <PreviewLinearSima simaFileData={simaFileData}/>
                        }
                    </Modal> */}

                    {/* <Modal className='modal-preview-punchie modal-preview-custom' width={1295} open={isShowModalPreviewVerticalSima} onCancel={handleCloseModalPreviewVerticalSima} footer={false}>
                        {
                            isloadingFile ? <Spin className='position-center' /> : <PreviewVerticalSima simaFileData={simaFileData}/>
                        }
                    </Modal> */}

                    {isShowModalPreviewPunchie && <Modal className='modal-preview-punchie' centered width={1295} open={isShowModalPreviewPunchie} onCancel={handleCloseModalViewPunchie} footer={false}>
                        {
                           isloadingPunchie ? <Spin className='position-center' /> : <PreviewPunchie punchieData={punchieDetailData} typePunchieView={typePunchieView}/>  
                        }
                    </Modal>}

                    <ModalDelete 
                        isShowModal={isShowModalDeleteFile}
                        onCancel={handleCancelDeleteFile}
                        onDelete={handleDeleteFile}
                        content='このファイルを削除します。よろしいですか？'
                        loadingButtonOk={isloadingDelete}
                    />

                    <ModalDelete 
                        isShowModal={isShowModalDeletePunchie}
                        onCancel={handleCancelDeletePunchie}
                        onDelete={handleDeletePunchie}
                        content={handleGetDeleteMessage}
                        loadingButtonOk={isloadingDeletePunchie}
                    />

                    <Modal 
                        centered 
                        className='modal-upload-sima' 
                        width={832} 
                        open={isShowModalEditName} 
                        onCancel={handleCloseModalEditName} 
                        footer={false}
                        afterClose={resetFormUpdateSimaBase}
                    >
                        <Form form={antFormNameLocationsite} className='pt-[90px]'>
                            <label className='labe-form'>工区名 <span>*</span></label>
                            <Form.Item 
                                name="name" 
                                initialValue={locationSiteListFiles?.name}
                                rules={[                                    
                                    { validator: (rule, value, callback) => validateFieldString(rule,value,callback, '工区名', 255)}
                                ]}
                            >
                                <Input className='input-form'/>
                            </Form.Item>
                            <div className='footer-form mt-[40px] flex'>
                                <Button className='button-form mr-[16px]' onClick={handleCloseModalEditName}>キャンセル</Button>
                                <Button loading={isloadingEditName} className='button-form button-form-Submit' onClick={handleEditNameLocationSite}>
                                    OK
                                </Button>
                            </div>
                        </Form>
                    </Modal>
                </div>
            }
        </>
    )
}
export default ListFileLocationComponent