import React from 'react'
import AgencyListComponent from 'src/components/features/superAdmin/agencyListComponent'
import { LayoutSuperAdmin } from 'src/components/layouts/layoutSuperAdmin'

const AgencyListPage = () => (
    <LayoutSuperAdmin>
       <AgencyListComponent />
    </LayoutSuperAdmin>
)

export default AgencyListPage