import React from 'react'
import HeaderProfile from '../common/layouts/headerProfile';

type LayoutProps = {
    children: React.ReactNode,
    className?: string;
}
export const LayoutProfile: React.FC<LayoutProps> = ({ children, className = '' }) => {
  return (
    <>
      <HeaderProfile />
      <div className={`civil-content-page ${className}`}>
        {children}
      </div>
    </>
  )
}
