import React from 'react'
import StaffCreateComponent from 'src/components/features/agency/staffCreateComponent'
import { LayoutSuperAdmin } from 'src/components/layouts/layoutSuperAdmin'

const AdminStaffCreatePage = () => (
    <LayoutSuperAdmin>
       <StaffCreateComponent />
    </LayoutSuperAdmin>
)

export default AdminStaffCreatePage