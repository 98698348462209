import React, {useCallback, useState } from 'react'
import './login.modules.scss'
import './login.css'
import { useMutation } from 'react-query'
import emailIcon from 'src/assets/images/email-icon.png'
import passWordIcon from 'src/assets/images/password-icon.png'
import { Input, Button, Form, message  } from 'antd'
import { login } from 'src/api/auth'
import { useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import { role } from 'src/constants'
import ModalConfirm from 'src/components/common/modal/modalConfirm'
interface dataError {
    has_license: boolean,
    has_license_active: boolean
    is_logined: boolean
}
const LoginComponent = () => {
    const history = useHistory()
    const [antForm] = Form.useForm()
    const [messError, setMessError] = useState('')
    const [visibleModalConfirmLogin, setVisibleModalConfirmLogin] = useState<boolean>(false)
    const {isLoading: isloading, mutate: onLogin } = useMutation(
        (param: any) => login(param),
        {
          onSuccess: (res) => {
            let now = new Date();
            const time = now.getTime();
            const expireTime = time + 1000*86400;
            now.setTime(expireTime);
            Cookies.set('token', res?.data?.token, { path: '/',  expires:1})
            localStorage.setItem("role", res?.data?.role);
            localStorage.setItem("user_id", res?.data?.id);
            switch(res?.data?.role) {
                case role.SYSTEM_ADMIN:
                    history.push('/cms/company')
                  break;
                case role.ADMIN:
                    history.push('/cms/company')
                    break;
                case role.AGENCY:
                    history.push('/agency/company')
                  break;
                case role.AGENCY_STAFF:
                    history.push('/agency/company')
                    break;
                default:
                    history.push('/construction-site')
            }
          },
          onError: (error: any) => {
            if(error.response?.status === 410) {
                handleCheckErrorLogin(error.response.data)
                return
            }
            if (error.response?.status === 400) {
                setMessError(error.response.data.messages[0])
                return
            }
            message.error('システムエラー')
          },
        },
    )

    const handleCheckErrorLogin = (data: dataError) => {
        if(data?.is_logined) {
            setVisibleModalConfirmLogin(true)
            return
        }
        if(!data?.has_license) {
            history.push('/forbidden')
        }else if(!data?.has_license_active) {
            history.push('/license/inactive')
        }
    }
    

    const handleLogin = useCallback(async() => {
        try {
            await antForm.validateFields()
        } catch { return }
        const values = antForm.getFieldsValue()
        const paramLogin = {
            email: values.email,
            password: values.password
        }
        return onLogin(paramLogin)
    },[antForm, onLogin])
    const EmailIconReactNode = () => (
        <img src={emailIcon} alt="" />
    )
    const PassIconReactNode = () => (
        <img src={passWordIcon} alt="" />
    )
    const handleResetMessError = () => {
        setMessError('')
    }
    const handleEnterSubmit = (e: any) => {
        if (e.key === "Enter") {
            handleLogin()
        }
    }

    const handleCancel = () => {
        setVisibleModalConfirmLogin(false)
    }

    const handleAcceptLogin = async() => {
        try {
            await antForm.validateFields()
        } catch { return }
        const values = antForm.getFieldsValue()
        const paramLogin = {
            email: values.email,
            password: values.password,
            isLogoutConfirmed: true
        }
        return onLogin(paramLogin)
    }

    return (
        <div className='login login-bgr'>
            <div className='login__form'>
                <div className='login__form-content'>
                    <h1 className='form-title mb-[40px]'>ログイン</h1>
                    <Form form={antForm} onKeyPress={(e) => handleEnterSubmit(e)}>
                        <div className='mb-[16px]'>
                            <Form.Item name='email' rules={[{ required: true, message: 'メールアドレスを入力してください' }]}>
                                <Input onChange={() => handleResetMessError()} className='login__form__input' size="large" placeholder="メールアドレス" prefix={<EmailIconReactNode/>} />
                            </Form.Item>
                        </div>
                        <Form.Item name='password' rules={[{ required: true, message: 'パスワードを入力してください' }]}>
                            <Input.Password 
                            className='login__form__input' 
                            prefix={<PassIconReactNode/>} 
                            onChange={() => handleResetMessError()}
                            placeholder="パスワード" />
                        </Form.Item>
                        <p className='invalid-feedback whitespace-pre-line'>{messError}</p>
                        <Button  onClick={() => handleLogin()} loading={isloading} className='login__form__button mx-auto block my-[32px]'>
                                ログイン
                        </Button>
                        <div className='form-footer'>
                            <div className='text-center login__form__guide'>パスワードをお忘れの方は <a href='/forgot-password'>こちら</a></div>
                        </div>
                    </Form>
                </div>
            </div>
            <ModalConfirm 
                isShowModal={visibleModalConfirmLogin}
                onCancel={handleCancel}
                onOk={handleAcceptLogin}
                content={`他のデバイスからログインしようとしています。\n このデバイスでログアウトしますか？`}
                subContent=''
                width={500}
            />
        </div>
    )
}

export default LoginComponent