
import 'moment/locale/ja';
import locale from 'antd/es/date-picker/locale/ja_JP';
import { Button, Input, Pagination, Select, Table, DatePicker, Checkbox, message } from 'antd'
import React, { useMemo, useState } from 'react'
import searchIcon from 'src/assets/images/search-icon.png'
import acceptIcon from 'src/assets/images/accept-icon.png'
import rejectIcon from 'src/assets/images/reject-icon.png'
import type { ColumnsType } from 'antd/es/table';
import { accCompanyStatusData, companyRequestStatus, pageSizeList } from 'src/constants'
import iconDatePicker from 'src/assets/images/icon-calendar.png'
import { useListRequestCompany } from 'src/api/data/agency/useListRequestCompany';
import { companyCreateType } from 'src/components/features/agency/type';
import iconSelect from 'src/assets/images/arrow-down-2.png'
import moment from 'moment';
import TableNoteNoData from 'src/components/common/tableNoteNoData';
import './superAdmin.modules.scss'
import ModalConfirm from 'src/components/common/modal/modalConfirm';
import { useMutation, useQueryClient } from 'react-query';
import { ratifyRequest } from 'src/api/admin';
import { QUERY_KEY } from 'src/api/data/query-key';
interface DataType {
    key: string;
    name: string;
    location: string;
    updated_at: string;
}

const { RangePicker } = DatePicker;

const ratifyTypeData = {
  accept: 1,
  reject: 2,
  acceptMultipleRequests: 3,
  rejectMultipleRequests: 4
}
const CompanyRequestListComponent = () => {
    const queryClient = useQueryClient()
    const [keyword, setKeyword] = useState<string>('')
    const [keywordSearch, setKeywordSearch] = useState<string>('')
    const [status, setStatus] = useState<number | undefined>()
    const [date, setDate] = useState<string>('')
    const [page, setPage] = useState<number>(1)
    const [dateValue, setDateValue] = useState<any>('')
    const [sizePage, setSizePage] = useState<number>(10)
    const [listIdRequest,setListIdRequest] = useState<any>([])
    const [isVisibleModalConfirm, setIsVisibleModalConfirm] = useState(false)
    const [idRatify, setIdRatify] = useState<number | undefined>()
    const [ratifyType, setRatifyType] = useState<number | undefined>()
    const { data: listCompanyRequest, isLoading } = useListRequestCompany(keywordSearch,status,date, page, sizePage, )
    const columns: ColumnsType<DataType> = useMemo(() => {
      return [
        {
            title: () => <Checkbox 
              checked={
                (listIdRequest?.length === listCompanyRequest?.records?.filter(
                (request: any) => request.status === accCompanyStatusData.INPROGRESS)?.length 
                && listIdRequest?.length > 0) ? true : false} 
              onChange={(e) => handleCheckAll(e)} 
              className='company-request-checkbox' 
            />,
            key: 'no',
            dataIndex: 'no',
            width: 60
        },
        {
            title: '会社名',
            dataIndex: 'name',
            key: 'name',
            width: 388
        },
        {
            title: 'メールアドレス',
            dataIndex: 'email',
            key: 'email',
            width: 388
        },
        {
            title: '電話番号',
            key: 'phone',
            dataIndex:'phone',
            width:160
        },
        {
            title: '代理店',
            key: 'agency',
            dataIndex:'agency',
            width:200
        },
        {
            title: '最終更新日時',
            key: 'updated_at',
            dataIndex:'updated_at',
            width:160
        },
        {
            title: '',
            key: 'action',
            dataIndex:'action',
            width:100
        }
      ]
    }, [listIdRequest, listCompanyRequest])
    const handleRenderAction = (status: number | undefined, idRequest: number | undefined) => {
        if(status === accCompanyStatusData.APPROVED || status === accCompanyStatusData.InActive) {
            return <div className='status-request status-accept mx-auto'>承認済</div>
        }
        if(status === accCompanyStatusData.REJECT) {
            return <div className='status-request status-reject mx-auto'>拒否済 </div>
        }
        return (
            <div className='flex justify-center'>
                <Button 
                  onClick={() => handleOpenModalRatify( ratifyTypeData.accept, idRequest)} 
                  className='accept-icon mr-[20px]'>
                    <img src={acceptIcon} alt='' />
                </Button>
                <Button 
                  onClick={() => handleOpenModalRatify(ratifyTypeData.reject, idRequest)} 
                  className='reject-icon'>
                  <img src={rejectIcon} alt='' />
                </Button>
            </div>
        )  
    }

    const handleChangeCheckBox = (event: any) => {
        if(event.target.checked) {
          setListIdRequest((idRequest: any) => [...idRequest,event.target.value])
        }else {
          setListIdRequest((idRequest: any) => idRequest.filter((id: any) => id !==  event.target.value))
        }
    }

    const handleCheckAll = (event: any) => {
      if(event.target.checked) {
        setListIdRequest(listCompanyRequest?.records?.filter(
          (request: any) => request.status === accCompanyStatusData.INPROGRESS
        )?.map((newRequest: any) => newRequest?.id))
      }else {
        setListIdRequest([])
      }
    }

    const dataSource = useMemo(() => {
      if (listCompanyRequest) {
        return listCompanyRequest?.records?.map((company: companyCreateType, index: number) => {
          return ({
            ...company,
            key: company?.id,
            no: <Checkbox 
              disabled={company?.status !== accCompanyStatusData.INPROGRESS} 
              checked={listIdRequest?.find((id: number) => id === company?.id) ? true : false} 
              onChange={(e) => handleChangeCheckBox(e)} value={company?.id} 
              className='company-request-checkbox' 
            />,
            name: (
                <span className='site-name line-1'>{company?.name}</span>
            ),
            email: (
                <span className='line-1 break-all'>{company?.email}</span>
            ),
            agency: (
                <span className='line-1'>{company?.agency?.name}</span>
            ),
            action: (
                handleRenderAction(company?.status, company?.id)
            ),
          })
        })
      }
      return []
    }, [listCompanyRequest, listIdRequest])

    const handleChangeKeyword = (value:string) => {
      setKeyword(value)
    }

    const iconSearch = () => {
      return <div className='p-[5px] cursor-pointer' onClick={handleSearch}><img src={searchIcon} alt='' /></div>
    }

    const handleChangeSizePage = (value: number) => {
      setPage(1)
      setSizePage(value)
      setListIdRequest([])
    }

    const handleChangePage = (page: number) => {
      setPage(page)
      setListIdRequest([])
    }

    const handleSearch = () => {
        setPage(1)
        setKeywordSearch(keyword?.trim())
        setKeyword(keyword?.trim())
        setListIdRequest([])
    }

    const renderIconDatePicker = () => {
        return <img src={iconDatePicker} alt='' />
    }

    const renderSeparator = () => {
        return <span>~</span>
    }

    const renderSelectIcon = () => {
      return <img src={iconSelect} alt='' />
    }

    const handleChangeStatus = (status: number) => {
      setStatus(status)
      setListIdRequest([])
      setPage(1)
    }

    const handleCalendarChange = (date: any) => {
      setDateValue(date)
      const startDate = date?.[0] == null ? '1970/01/01' : moment(date?.[0])?.format('YYYY-MM-DD')
      const endDate = date?.[1] == null ? moment(date?.[0])?.add(1200,'M').format('YYYY-MM-DD') : moment(date?.[1])?.format('YYYY-MM-DD')
      const dateParam = startDate + ' ~ ' + endDate
      setPage(1)
      if(date) {
        setDate(dateParam)
      }else {
        setDate('')
      }
      
    }  

    const handleCancel = () => {
        setIsVisibleModalConfirm(false)
    }

    const {isLoading: isloadingRatify, mutate: onRatifyRequest } = useMutation(
      (param: any) => ratifyRequest(param),
      {
        onSuccess: async() => {
          message.success((ratifyType === ratifyTypeData.accept || ratifyType === ratifyTypeData.acceptMultipleRequests) ?
          '承認されました' 
          : '拒否されました')
          setIsVisibleModalConfirm(false)
          setPage(1)
          setListIdRequest([])
          await queryClient.invalidateQueries(QUERY_KEY.LIST_REQUEST_COMPANY)
        },
        onError: (error: any) => {
          if (error.response?.status === 400) {
              message.error(error.response.data.messages[0])
              return
          }
          message.error('システムエラー')
        },
      },
    )


    const handleOpenModalRatify = (ratifyType: number | undefined, id?: number | undefined) => {
      setIsVisibleModalConfirm(true)
      setIdRatify(id)
      setRatifyType(ratifyType)
    }

    const handleRatifyRequest = () => {
      let params
      switch(ratifyType) {
        case ratifyTypeData.accept:
          params = {
            status: accCompanyStatusData.APPROVED,
            ids: [...[],idRatify]
          }
          break;
        case ratifyTypeData.reject:
          params = {
            status: accCompanyStatusData.REJECT,
            ids: [...[],idRatify]
          }
          break;
        case ratifyTypeData.acceptMultipleRequests:
          params = {
            status: accCompanyStatusData.APPROVED,
            ids: listIdRequest
          }
          break;
        case ratifyTypeData.rejectMultipleRequests:
          params = {
            status: accCompanyStatusData.REJECT,
            ids: listIdRequest
          }
          break;
        default:
      }
      onRatifyRequest(params)
    }
    
    return (
        <div className='page-content company-page'>
            <div className='mb-[16px]'>
                <h2 className='page-content-title border-bottom-title'>会社アカウント申請一覧</h2>
            </div>
            <div className='page-content-table'>
              <div className='flex justify-between items-center p-[16px]'>
                    <div className='flex items-center'>
                            <Input 
                                onPressEnter={handleSearch} 
                                className='input-search mr-[8px]' 
                                placeholder='会社名を入力' 
                                suffix={iconSearch()} 
                                onChange={(e) => handleChangeKeyword(e.target.value)}
                                value={keyword}
                            />
                            <Select 
                                options={companyRequestStatus}
                                placeholder="状態"
                                className='company-select'
                                allowClear={true}
                                onChange={(value: number) => handleChangeStatus(value)}
                                suffixIcon={renderSelectIcon()}
                            />
                            <RangePicker 
                                className={`ml-[8px] range-picker-search`}
                                suffixIcon={renderIconDatePicker()}
                                placeholder={['yyyy-mm-dd', 'yyyy-mm-dd']}
                                separator={renderSeparator()}
                                locale={locale}
                                onCalendarChange={handleCalendarChange}
                                format={['YYYY-MM-DD', 'YYYY-MM-DD']}
                                value={dateValue}
                                inputReadOnly={true}
                            />
                    </div>
                    <div className='flex'>
                        <Button onClick={() => handleOpenModalRatify(ratifyTypeData.rejectMultipleRequests)} className='reject-button mr-[16px]' disabled={listIdRequest?.length > 0 ? false : true}>
                            拒否済 
                        </Button>
                        <Button onClick={() => handleOpenModalRatify(ratifyTypeData.acceptMultipleRequests)} className='button-add accept-button' disabled={listIdRequest?.length > 0 ? false : true}>
                            承認済
                        </Button>
                    </div>
              </div>
              <div className='constuction-list'>
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    loading={isLoading}
                    locale={{
                      emptyText: (
                        <TableNoteNoData />
                      )
                    }}
                  />
              </div>
              <div className='py-[20px] px-[12px] flex justify-between items-center'>
                <div className='pagination-infor'>
                  {listCompanyRequest?.limitFrom} - {listCompanyRequest?.limitTo} 件表示/全 {listCompanyRequest?.totalRow} 件中
                </div>
                <div className='flex'>
                  <Pagination 
                    current={page} 
                    total={listCompanyRequest?.totalRow} 
                    className='pagination-main mr-[20px]' 
                    showSizeChanger={false}
                    pageSize={sizePage}
                    onChange={(page) => handleChangePage(page)}
                  />
                  <Select
                      defaultValue={10}
                      style={{ width: 153 }}
                      className='pagination-options'
                      onChange={(e) => handleChangeSizePage(e)}
                      options={pageSizeList}
                  />
                </div>
              </div>
            </div>
          <ModalConfirm 
            isShowModal={isVisibleModalConfirm}
            onCancel={handleCancel}
            onOk={handleRatifyRequest}
            content={(ratifyType === ratifyTypeData.accept || ratifyType === ratifyTypeData.acceptMultipleRequests) ? 'この申請を承認します。よろしいですか？' : 'この申請を拒否します。よろしいですか？'}
            loadingButtonOk={isloadingRatify}
            isIcon={false}
            className={isloadingRatify ? "loading-modal" : ""}
          />
        </div>
    )
}

export default CompanyRequestListComponent