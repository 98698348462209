import React from 'react'
import AgencyCreateComponent from 'src/components/features/superAdmin/agencyCreateComponent'
import { LayoutSuperAdmin } from 'src/components/layouts/layoutSuperAdmin'

const AgencyCreatePage = () => (
    <LayoutSuperAdmin>
       <AgencyCreateComponent />
    </LayoutSuperAdmin>
)

export default AgencyCreatePage