import { Container } from 'styled-bootstrap-grid';
import './faq.modules.scss';
import arrowLeftIcon from 'src/assets/images/landingPage/arrow_left.svg';
import FaqItem from 'src/components/common/faqItem/faqItem';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FooterLP from 'src/components/common/footerLP/footerLP';
import { useCategoryAll } from 'src/api/data/admin/useCategoryAll';

const FaqComponent = () => {
  const history = useHistory();
  const queryParameters = new URLSearchParams(window.location.search);
  const lang = queryParameters.get('lang');
  const { i18n, t } = useTranslation();
  const { data: categoryAll } = useCategoryAll();
  const [active, setActive] = useState<string>();

  useEffect(() => {
    if (lang) {
      if (lang !== i18n.language) {
        i18n.changeLanguage(lang);
        document.title = lang === 'en' ? 'AR Civil' : '助さん';
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  useEffect(() => {
    if (categoryAll && categoryAll.length > 0) {
      setActive(categoryAll[0]?.id);
    }
  }, [categoryAll]);

  const scrollToElement = (id: any) => {
    if (id) {
      const container = window.document.getElementById(id);
      container?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (window !== undefined) {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <div className="wrap_faq">
      <div className="faq_top">
        <p
          onClick={() => {
            history.push({ pathname: '/', search: lang ? `lang=${lang}` : '' });
          }}
        >
          <img src={arrowLeftIcon} alt="" />
          {t('detail_faq')}
        </p>
      </div>

      <div className="faq_content">
        <Container>
          <div className="control">
            <p className="control_title">{t('category')}</p>

            <ul className="control_content">
              {categoryAll &&
                categoryAll.map((cate: any, index: number) => {
                  return (
                    <li
                      key={cate?.id + index}
                      className={`control_content_item ${active === cate?.id && active !== '' ? 'active' : ''}`}
                      onClick={() => {
                        scrollToElement(cate?.id);
                        setActive(cate?.id);
                      }}
                    >
                      {i18n.language === 'jp' ? cate?.ja_name : cate?.en_name}
                    </li>
                  );
                })}
            </ul>
          </div>

          <div className="content">
            {categoryAll &&
              categoryAll.map((cate: any) => {
                return (
                  <div className="content_item" key={cate?.id} id={cate?.id}>
                    <p className="title">{i18n.language === 'jp' ? cate?.ja_name : cate?.en_name}</p>
                    {cate?.faqs.map((item: any, index: number) => {
                      return (
                        <FaqItem
                          title={i18n.language === 'jp' ? item?.ja_title : item?.en_title}
                          content={i18n.language === 'jp' ? item?.ja_content : item?.en_content}
                          color="black"
                          key={item?.id + index}
                        />
                      );
                    })}
                  </div>
                );
              })}
          </div>
        </Container>
      </div>

      <FooterLP />
    </div>
  );
};

export default FaqComponent;
