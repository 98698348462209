import React, { useEffect, useState } from 'react'
import './header.modules.scss'
import logo from 'src/assets/images/logo.png'
import { useHistory, useLocation } from 'react-router-dom'
import { menuAgency, menuAgencyStaff, role } from 'src/constants'
import UserInfor from './userInfor'

const HeaderAgency = () => {
    const history = useHistory()
    const location = useLocation()
    const [menu,setMenu] = useState<any>()
   
    const handleGoHomePage = () => {
        history.push('/agency/company')
    }

    const handleClickMenu = (path: string) => {
        history.push(path)
    }

    useEffect(() => {
        switch(Number(localStorage.getItem('role'))) {
            case role.AGENCY:
                setMenu(menuAgency)
              break;
            case role.AGENCY_STAFF:
                setMenu(menuAgencyStaff)
              break;
            default:
                setMenu(menuAgency)
        }
    },[])
    return (
        <>
            <div id='header' className='flex justify-between header'>
                <div className='flex items-center'>
                    <div className='flex items-center cursor-pointer mr-[80px]' onClick={handleGoHomePage}>
                        <img className='header__icon mr-[13px]' src={logo} alt='logo'/>
                        <h1 className='font-bold text-[24px] text-[#253858] whitespace-nowrap cursor-pointer'>助さん</h1>
                    </div>
                    <ul className='flex items-center'>
                        {
                            menu?.map((item:any) => (
                                <li 
                                    className={`cursor-pointer menu-item ${location.pathname?.includes(item.path) ? 'menu-item-active' : ''}`} 
                                    key={item.id} 
                                    onClick={() =>handleClickMenu(item.path)}
                                >
                                    {item.name}
                                </li>
                            ))
                        }
                    </ul>
                </div>
                <UserInfor />
            </div>
        </>
    )
}

export default HeaderAgency