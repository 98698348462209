import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import 'src/assets/css/main.scss';
import AllRoute from './routes';
import { QueryClient, QueryClientProvider } from 'react-query';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BaseCSS, GridThemeProvider } from 'styled-bootstrap-grid';

import ChannelService from 'src/utils/ChannelService';
const pluginKey = process.env.REACT_APP_PLUGIN_KEY;

const gridTheme = {
  gridColumns: 12,
  breakpoints: {
    xxl: 1440,
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 575,
  },
  row: {
    padding: 15,
  },
  col: {
    padding: 15, // default 15
  },
  container: {
    padding: 0,
    maxWidth: {
      xxl: 1241,
      xl: 1240,
      lg: 960,
      md: 720,
      sm: 540,
      xs: 540,
    },
  },
};

const App = () => {
  const { i18n } = useTranslation();
  const url = window.location.pathname;

  useEffect(() => {
    ChannelService.boot(
      {
        pluginKey: pluginKey ? pluginKey : '',
        language: url === '/' ? (i18n.language === 'jp' ? 'ja' : 'en') : 'ja',
      },
      () => {
        ChannelService.updateUser({
          language: url === '/' ? (i18n.language === 'jp' ? 'ja' : 'en') : 'ja',
        });
      }
    );
  }, [i18n.language, url]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
      },
    },
  });
  return (
    <GridThemeProvider gridTheme={gridTheme}>
      <QueryClientProvider client={queryClient}>
        <AllRoute />

        <BaseCSS />
      </QueryClientProvider>
    </GridThemeProvider>
  );
};

export default App;
