import { Button, Form, Input, Modal, Spin, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { useConstructionDetail } from 'src/api/data/construction/useConstructionDetail'
import { trimmedValue } from 'src/utils'
import leftArrow from 'src/assets/images/back-icon.png'
import { changePassword } from 'src/api/userInfor'
import success from 'src/assets/images/tick-circle.png'
import closeIcon from 'src/assets/images/close-circle.png'
import { logout } from 'src/api/auth'
import { clearToken } from 'src/utils/cookie'
import { useQueryClient } from 'react-query';
interface TypeFormChangePass {
    current_password: string,
    new_password: string,
    confirm_password: string,
}
const ChangePasswordComponent = () => {
    const [antForm] = Form.useForm()
    const history = useHistory()
    const { id } = useParams<{ id: string }>()
    const { data: constructionDetail, isLoading } = useConstructionDetail(Number(id)) 
    const [isDisableSubmit,setIsDisableSubmit] = useState(true)
    const [isOpenModalSuccess, setIsOpenModalSuccess] = useState<boolean>(false)
    const queryClient = useQueryClient()
    const {isLoading: isloading, mutate: onChangePassword } = useMutation(
        (param:TypeFormChangePass) => changePassword(param),
        {
          onSuccess: (res) => {
            setIsOpenModalSuccess(true)
            clearToken()
            localStorage.clear()
            queryClient.clear()
            antForm.resetFields()
          },
          onError: (error: any) => {
            if (error.response?.status === 400) {
                message.error(error.response.data.messages[0])
                return
            }
            message.error('システムエラー')
          },
        },
    )

    const handleChangePassword = async() => {
        await trimmedValue(antForm)
        try {
            await antForm.validateFields()
        } catch { return }
        const values = antForm.getFieldsValue()
        onChangePassword(values)
    }

    useEffect(() => {
        antForm.setFieldsValue(constructionDetail)
    }, [antForm, constructionDetail])

    const handleChangeValue = (allvalues: []) => {
        const listValue = Object.keys(allvalues).map((key: any) => allvalues[key])
        const validateErrorValueList = []
        listValue?.forEach((value: any) => {
            if(value === undefined || value === '') {
                validateErrorValueList.push(value)
            }
        })
        if(validateErrorValueList.length > 0) {
            setIsDisableSubmit(true)
        }else{
            setIsDisableSubmit(false)
        }
    }

    const handleGoBack = () => {
        history.goBack()
    }

    useEffect(() => {
        if(id) {
            setIsDisableSubmit(false)
        }
    },[id])

    const validateFields = (rule: any, value: string, callback: any, name: string) => {
        if (!value?.trim()) {
            callback(`${name}を入力してください`);
        }else {
            callback()
        }
    }

    const validateNewPassword = (rule: any, value: string, callback: any) => {
        const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[_~?&!@#%|,.<>=:;\\/\-\$\^\[\]\{\}\+\'\"\*\(\)])[A-Za-z0-9_~?&!@#%|,.<>=:;\\/\-\$\^\[\]\{\}\+\'\"\*\(\)]+$/
        if (!value?.trim()) {
            callback(`新しいパスワードを入力してください`);
        }else if(value?.trim()?.length < 8 || value?.trim()?.length > 25) {
            callback(`パスワードは半角英数字8文字以上25文字以内で入力してください`);
        }else if(!passwordRegex.test(value?.trim())) {
            callback(`パスワードは英大文字・英小文字・数字・記号それぞれを最低1文字ずつ含む必要があります`);
        }else {
            callback()
        }
    }

    const validateConfirmPassword = (rule: any, value: string, callback: any) => {
        const valueForm = antForm.getFieldsValue()
        if (!value?.trim()) {
            callback(`スワード確認を入力してください`);
        }else if(valueForm['new_password'] !== value) {
            callback(`パスワードとパスワード確認用が一致しません`);
        }else {
            callback()
        }
    }

    const handleCloseModalSuccess = async() => {
        history.push('/login')
    }
    const onClickButtonOk = async() => {
        history.push('/login')
    }

    return (
       <div className='construction-create p-[16px]'>
            <h2 className='page-content-title border-bottom-title mb-[30px]'>
                <img src={leftArrow} alt='' className='inline-block mr-[17px] cursor-pointer' onClick={handleGoBack}/>
                パスワード変更
            </h2>
            {
                isLoading ? <Spin /> :
                <Form form={antForm} onValuesChange={(value, allvalues) => handleChangeValue(allvalues)} initialValues={constructionDetail} className='form-create'>
                    <div className='px-[20px] py-[12px]'>
                        <div className='mb-[16px]'>
                            <label className='labe-form'>現在のパスワード <span>*</span></label>
                            <Form.Item name='current_password' rules={
                                [                                    
                                    { validator: (rule, value, callback) => validateFields(rule,value,callback, '現在のパスワード')}
                                ]
                            }>
                                <Input.Password className='input-form login__form__input'/>
                            </Form.Item>
                        </div>
                        <div className='mb-[16px]'>
                            <label className='labe-form'>新しいパスワード <span>*</span></label>
                            <Form.Item 
                                name='new_password' 
                                rules={
                                    [                                    
                                        { validator: (rule, value, callback) => validateNewPassword(rule,value,callback)}
                                    ]
                                }
                            >
                                <Input.Password className='input-form login__form__input'/>
                            </Form.Item>
                        </div>
                        <div className='mb-[16px]'>
                            <label className='labe-form'>新しいパスワード（確認） <span>*</span></label>
                            <Form.Item 
                                name='confirm_password' 
                                dependencies={['new_password']}
                                rules={
                                    [                                    
                                        { validator: (rule, value, callback) => validateConfirmPassword(rule,value,callback)}
                                    ]
                                }
                            >
                                <Input.Password className='input-form login__form__input'/>
                            </Form.Item>
                        </div>
                    </div>
                    <div className='footer-form flex'>
                        <Button className='button-form mr-[16px]' onClick={() =>handleGoBack()}>戻る</Button>
                        <Button disabled={isDisableSubmit} loading={isloading} className='button-form button-form-Submit' onClick={() => handleChangePassword()}>
                            <span></span>変更
                        </Button>
                    </div>
                </Form>
            }
             <Modal 
                title="" 
                open={isOpenModalSuccess} 
                width={451}
                className="modal-success"
                footer={false}
                closable={false}
                centered={true}
            >
                <img src={closeIcon} alt="" className="close-modal cursor-pointer" onClick={handleCloseModalSuccess}/>
                <img src={success} alt='' className='mb-[16px] mx-auto'/>
                <div className='text-[#272122] text-[20px] font-medium text-center mb-[8px]'>パスワードが変更されました</div>
                <p className='text-[#272122] text-[14px] text-center mb-[44px]'>新しいパスワードで再ログインをお願い致します。</p>
                <Button className='button-add w-full' onClick={() => onClickButtonOk()}>
                    OK
                </Button>
            </Modal>
       </div>
    )
}

export default ChangePasswordComponent