import React, { useEffect } from 'react'

import {
  Route, RouteProps, useHistory, useLocation,
} from 'react-router-dom'
import { role } from 'src/constants'
import { getCookie } from 'src/utils/cookie'


export const UserRoute: React.FC<RouteProps> = (props) => {
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if (!getCookie('token')) {
      window.location.href = '/login'
    }else if(Number(localStorage.getItem('role')) !== role.COMPANY_STAFF && Number(localStorage.getItem('role')) !== role.COMPANY) {
      window.location.href = '/forbidden'
    }
  }, [history, location.pathname])

  return <Route {...props} />
}