import { Button, Form, Spin } from 'antd'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import leftArrow from 'src/assets/images/back-icon.png'
import { detailStaffCompany } from 'src/constants'
import { handleGetStatusStaffAgency } from 'src/utils'
import { useDetailCompanyStaff } from 'src/api/data/companyManagement/useDetailCompanyStaff'

const CompanyStaffDetailComponent = () => {
    const history = useHistory()
    const { id } = useParams<{ id: string }>()
    const { data: detailStaff, isLoading } = useDetailCompanyStaff(Number(id)) 
    const handleGoBackStaffList = () => {
        history.push('/company/staff')
    }

    const handleGoEditStaff = (id: number | undefined) => {
        history.push(`/company/staff/edit/${id}`)
    }
  
    
    return (
       <div className='construction-create p-[16px]'>
            <div className='page-content-title border-bottom-title mb-[30px] flex items-center justify-between'>
                <div>
                    <img src={leftArrow} alt='' className='inline-block mr-[17px] cursor-pointer' onClick={handleGoBackStaffList}/>
                    <span onClick={handleGoBackStaffList} className='cursor-pointer'>会員詳細</span>
                </div>
                <Button 
                    className='button-add min-w-[173px]' 
                    onClick={() =>handleGoEditStaff(Number(id))}
                >
                    <span></span>変更
                </Button>
            </div>
            {
                isLoading ? <Spin /> :
                <Form className='construction-detail-infor'>
                    {
                        detailStaffCompany.map((item: any) => (
                            <div className='mb-[25px]' key={item.id}>
                                <label className='labe-form'>{item.label}</label>
                                <div className='input-form break-all'>
                                    {
                                        item.id === 5 ? handleGetStatusStaffAgency(detailStaff?.[item.name]) : detailStaff?.[item.name]
                                    }
                                </div>
                            </div>
                        ))
                    } 
                </Form>
            }
       </div>
    )
}

export default CompanyStaffDetailComponent