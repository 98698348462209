import React, {useEffect, useRef} from 'react';
import { fabric } from 'fabric';
import downIcon from 'src/assets/images/arrow-down-3.png'
import upIcon from 'src/assets/images/arrow-up-3.png'
import { DEFAULT_PONCHIE_WIDTH, PADDING_BOTTOM_CANVAS, paddingPunch, punchieUploadType } from 'src/constants';
import { handleZoomCanvas } from 'src/utils';
import { useParams } from 'react-router-dom';
import { api } from 'src/api/axios';
let canvas: any;
let minimap: any;
interface pitDataType {
    left: number,
    top: number,
    width: number,
    height: number,
    type: string,
    color: string,
    id: number,
    intersectIds: any,
    orderNumber: string,
    direction: number
  }
const  PreviewPunchie = ({punchieData, typePunchieView}:any) => {
    const canvasRef = useRef(null);
    const minimapRef = useRef(null);
    const { idConstructionZone } = useParams<{ idConstructionZone: string }>()
    useEffect(() => {
      if(canvasRef.current != null) {
        canvas = new fabric.Canvas(canvasRef.current);
        canvas.selection = false
        canvas.renderAll();
        handleZoomCanvas(canvas, updateMiniMapVP)
  
      }
      return () => {
        canvas?.dispose();
      };
    },[canvasRef.current])

    const handleGetDetailPunchie = async(idPunchie: number, type: number) => {
      if(type === punchieUploadType.PUNCH_PICTURE) {
        return
      }
      const res = await api.get(`/api/admin/construction-zone/${idConstructionZone}/punch/${idPunchie}`)
      return res?.data?.data
    }
    useEffect(() => {
        if(punchieData && typePunchieView) {
          handlePreviewPunchie()
        }
    },[punchieData, canvasRef.current, typePunchieView])

    const handlePreviewPunchie = async() => {
      const dataPunchie = JSON.parse(punchieData?.draw_datas?.web)
      const canvasWidth = DEFAULT_PONCHIE_WIDTH
      const canvasHeight = document.getElementById('punchie-picture')?.offsetHeight!
            canvas.setDimensions({width:dataPunchie?.canvasWidth < canvasWidth ? canvasWidth : dataPunchie?.canvasWidth});
            canvas.setDimensions({height:dataPunchie?.canvasHeight < canvasHeight ? canvasHeight : dataPunchie?.canvasHeight});
            const centerLineX = canvas.width / 2 - 1.5;
            const centerLineY = canvas.height;
            
            const centerLine = new fabric.Line([centerLineX, 30, centerLineX, centerLineY], {
                fill: '#C2C2C2',
                stroke: '#C2C2C2',
                strokeWidth: 3,
                strokeDashArray: [5, 5],
                originX: 'left',
                originY: 'top',
                hasControls: false,
                lockMovementY:true,
                lockMovementX: true,
            });

            const centerLineName = new fabric.IText('CL', {
              fontSize: 14,
              fill: '#000000',
              top: 0,
              left: centerLineX - 5,
              originX: 'left',
              originY: 'top',
            })
            const groupCenterLine = new fabric.Group([centerLine, centerLineName],{
                hasControls: false,
                lockMovementY:true,
                lockMovementX: true,
                hasBorders: false,
                globalCompositeOperation: 'destination-over'
            });
            canvas.add(groupCenterLine);
            const dataDrawPunchieAtDiagram = await handleGetDetailPunchie(punchieData?.punch_id, typePunchieView)
           
            
            const data = JSON.parse(punchieData?.draw_datas?.web)
            const dataDetailPunchie = typePunchieView === punchieUploadType.PUNCH_PICTURE ?  JSON.parse(punchieData?.draw_datas?.web) : JSON.parse(dataDrawPunchieAtDiagram?.draw_datas?.web)
            const flatArray = [].concat(...dataDetailPunchie.ponchiePoints);
            const minYPunchie = Math.min(...flatArray.map((obj: {x: number, y: number}) => obj.y))
            const currentCanvasHeight = canvas.getHeight()
            dataDetailPunchie?.ponchiePoints?.forEach((data: any) => {
                canvas.add(new fabric.Polygon(data.map((item: any) => ({...item, y:currentCanvasHeight - item.y - PADDING_BOTTOM_CANVAS + minYPunchie})), {
                    originX: 'left',
                    originY: 'bottom',
                    fill : '#ffffff',
                    hasControls: false,
                    lockMovementY:true,
                    lockMovementX: true,
                    strokeWidth: 3,
                    stroke: '#000000',
                }))
            });

            dataDetailPunchie?.measuringPoints?.forEach((point: any, index: number) => {
                const line = new fabric.Line([paddingPunch,currentCanvasHeight - point.y + minYPunchie - PADDING_BOTTOM_CANVAS - 1.5, canvas.getWidth() - paddingPunch, currentCanvasHeight - point.y + minYPunchie - PADDING_BOTTOM_CANVAS - 1.5], {
                    fill: '#C2C2C2',
                    stroke: '#C2C2C2',
                    strokeWidth: 3,
                    strokeDashArray: [5, 5],
                    originX: 'left',
                    originY: 'top',
                })
                let text = new fabric.IText(point?.name, {
                    fontSize: 14,
                    fill: '#000000',
                    top:currentCanvasHeight - point.y + minYPunchie - PADDING_BOTTOM_CANVAS - 5,
                    originX: 'left',
                    originY: 'top',
                })

                text.left = 40
                
                const group = new fabric.Group([line, text],{
                    hasControls: false,
                    lockMovementY:true,
                    lockMovementX: true,
                    hasBorders: false,
                    globalCompositeOperation: 'destination-over'
                });
                canvas.add(group)
            });

            

            data?.pitDatas?.forEach((data: pitDataType) => {
                const pitData: any = {
                  top: currentCanvasHeight - data?.top - data?.height + minYPunchie - PADDING_BOTTOM_CANVAS,
                  left: data?.left,
                  width: data?.width,
                  height: data?.height,
                  fill: data?.color,
                  lockRotation: true,
                  cornerSize: 15,
                  hasRotatingPoint: false,
                  perPixelTargetFind: true,
                  lockUniScaling: true,
                  lockScalingY: true,
                  minScaleLimit: 1,
                }
                const pitDataInCanvas = new fabric.Rect(pitData)
                const OrderNumber = new fabric.Text(data?.orderNumber, {
                  fontSize: 14,
                  top: currentCanvasHeight - data?.top - data?.height + minYPunchie + ((data?.height / 2) - 7) - PADDING_BOTTOM_CANVAS,
                  left: data?.left + ((data?.width / 2) - 2),
                  originX: 'left',
                  originY: 'top'
                })
                const settingGroup = {
                  type: 'pit',
                  intersectIds: [],
                  id: data?.id
                }

                const group = new fabric.Group([pitDataInCanvas, OrderNumber],
                  {
                    ...settingGroup,
                    globalCompositeOperation: 'source-over',
                    lockMovementY:true,
                    lockMovementX: true,
                    hasControls: false,
                    hasBorders: false
                  }
                )

                canvas.add(group)
                if(data?.direction === 1 ) {
                  fabric.Image.fromURL(upIcon, function(Img: any) {
                    Img.left = -6;
                    Img.top = -22;
                    Img.direction = data?.direction
                    group.add(Img);
                    group.addWithUpdate()
                    group.setCoords()
                    canvas.renderAll();
                  })
                }else {
                  fabric.Image.fromURL(downIcon, function(Img: any) {
                    Img.left = -6;
                    Img.top = 8;
                    Img.direction = data?.direction
                    group.add(Img);
                    group.addWithUpdate()
                    group.setCoords()
                    canvas.renderAll();
                  })
                }
              })
            canvas.renderAll();
            initMinimap()
    }

    useEffect(() => {
      minimap  = new fabric.Canvas(minimapRef.current, {
        preserveObjectStacking: true,
        selection: false
      })
      return () => {
        minimap.dispose();
      };
    }, [minimapRef.current]);
  
    const createCanvasEl = () => {
      const designSize = { width: canvas?.width, height: canvas?.height };
      const originalVPT = canvas?.viewportTransform;
      const fabricUtil: any = fabric.util
      const designRatio = fabricUtil.findScaleToFit(designSize, canvas);
      const minimapRatio: any = fabricUtil.findScaleToFit(canvas, minimap);
  
      const scaling = minimap.getRetinaScaling();
  
      const finalWidth =  designSize.width * designRatio;
      const finalHeight =  designSize.height * designRatio;
  
      canvas.viewportTransform = [
        designRatio, 0, 0, designRatio,
        (canvas?.getWidth() - finalWidth) / 2,
        (canvas?.getHeight() - finalHeight) / 2
      ];
      const canvasElement = canvas?.toCanvasElement(minimapRatio * scaling);
      canvas.viewportTransform = originalVPT;
      return canvasElement;
    }
  
    function initMinimap() {
      const canvasEl = createCanvasEl();
      const backgroundImage = new fabric.Image(canvasEl);
      backgroundImage.scaleX = 1 / canvas.getRetinaScaling();
      backgroundImage.scaleY = 1 / canvas.getRetinaScaling();
      minimap.centerObject(backgroundImage);
      minimap.backgroundColor = 'white';
      minimap.backgroundImage = backgroundImage;
      minimap.requestRenderAll();
      minimap.getObjects()?.forEach((object: any) => {
        if(object?.type === 'minimap') {
          minimap.remove(object);
        }
      })
      const frameHeight = document.getElementById('view-ponchie')?.offsetHeight
      const ratioHeightCanvas = frameHeight! / canvas.height
      const frameWidth = document.getElementById('view-ponchie')?.offsetWidth
      const ratioWidthCanvas = frameWidth! / canvas.width
      const minimapView = new fabric.Rect({
        top: backgroundImage.top,
        left: backgroundImage.left,
        width: backgroundImage.width! * ratioWidthCanvas / canvas.getRetinaScaling(),
        height: backgroundImage.height! * ratioHeightCanvas / canvas.getRetinaScaling(),
        fill: 'rgba(0, 0, 255, 0.3)',
        strokeWidth: 0,
        lockScalingY: true,
        lockScalingX: true,
        lockRotation: true,
        hasBorders: false,
        type:'minimap'
      });
      minimapView.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false,
        tl:false,
        bl: false,
        tr: false,
        br: false,
        mtr: false,
      });
      minimap.add(minimapView);
    }
  
    // function updateMiniMap() {
    //   var canvasEl = createCanvasEl();
    //   minimap.backgroundImage._element = canvasEl;
    //   minimap.requestRenderAll();
    // }
  
    function updateMiniMapVP() {
      const designSize = { width: canvas.width, height: canvas.height };
      const rect = minimap.getObjects()[0];
      const fabricUtil: any = fabric.util
      const designRatio = fabricUtil.findScaleToFit(designSize, canvas);
      const totalRatio = fabricUtil.findScaleToFit(designSize, minimap);
      const finalRatio = designRatio / canvas.getZoom();
      if(rect) {
        rect.scaleX = finalRatio;
        rect.scaleY = finalRatio;
        rect.top = minimap.backgroundImage.top - canvas.viewportTransform[5] * totalRatio / canvas.getZoom();
        rect.left = minimap.backgroundImage.left - canvas.viewportTransform[4] * totalRatio / canvas.getZoom();
      }
      minimap.requestRenderAll();
    }
   
    useEffect(()=> {
      minimap?.on('object:moving', function (e: any) {
        const obj = e.target;
        if(obj?.type !== 'minimap') {
          return
        }
        obj.setCoords();
        const designSize = { width: canvas.width, height: canvas.height };
        const fabricUtil: any = fabric.util
        const totalRatio = fabricUtil.findScaleToFit(designSize, minimap);
        canvas.viewportTransform[5] = ((minimap.backgroundImage.top - obj.top) * canvas.getZoom()) / totalRatio
        canvas.viewportTransform[4] = ((minimap.backgroundImage.left - obj.left) * canvas.getZoom()) / totalRatio
        canvas.requestRenderAll();
      });
  
    
    },[minimap])
    
    return (
        <div className='h-[85vh] scroll-punchie flex justify-center overflow-hidden' id={'view-ponchie'}>
            <canvas ref={canvasRef}/>  
            <div className='minimap_preview'>
                <canvas id="minimap" ref={minimapRef} width="200" height="200"></canvas>
            </div>
        </div>
    );
}

export default PreviewPunchie;
