import { Form, InputNumber, Radio, Select } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import selectIcon from 'src/assets/images/select-icon.png'
import { FileByType } from './type'
const PavementDiagramSettingComponent = ({form, tonnageMixingData, listFileThickness}: any) => {
    const SelectIcon = () => (
        <img src={selectIcon} alt='' />
    )
    const [thicknessInputMethod, setThicknessInputMethod] = useState(1)
    const handleSetNumberVehiclesValue = () => {
        const values = form.getFieldsValue()
        if(values.plant_loading_time && 
        values.outbound_travel_time &&
        values.return_travel_time &&
        values.paving_time_per_unit){
            const valueNumberVehicles = ((values.plant_loading_time + values.outbound_travel_time + values.return_travel_time)/values.paving_time_per_unit + 1).toFixed(2)
            form.setFieldsValue(
                {number_vehicles: valueNumberVehicles }
            )
        }else {
            form.setFieldsValue(
                {number_vehicles: 0}
            )
        }
    }

    const handleChangeThicknessInputMethod = (value:number) => {
        setThicknessInputMethod(value)
    }

    const optionFilePavingThickness = useMemo(() => {
        return listFileThickness?.map((file: FileByType) => (
            {
                value: file.id,
                label:file.file_name
            }
        ))
    },[listFileThickness])

    const validateNumberFields = (rule: any, value: number, callback: any, name: string, min: number) => {
        if (!value && value !== 0) {
            callback(`${name}を入力してください`);
        }else if(value <= min) {
            callback(`${name}は${min}より大きい値を入力してください `)
        }else {
            callback()
        }
    }

    const validatePercentFields = (rule: any, value: number, callback: any, name: string, min: number, max: number) => {
        if (!value && value !== 0) {
            callback(`${name}を入力してください`);
        }else if(value <= min || value > max) {
            callback(`${name}は${min}より大きく${max}ちさい値を入力してください`)
        }else {
            callback()
        }
    }

    const validateQuatifyCarFields = (rule: any, value: number, callback: any, name: string, min: number, max: number) => {
        if (!value && value !== 0) {
            callback(`${name}を入力してください`);
        }else if(value < min || value > max) {
            callback(`${name}を${min}～${max}の範囲で入力してください`)
        }else {
            callback()
        }
    }

    const renderNotFoundContent = () => {
        return <p className='text-center p-[10px]'>データがありません</p>
    }

    useEffect(() => {
        if(tonnageMixingData?.cutting_thickness_input) {
            setThicknessInputMethod(2)
        }else{
            setThicknessInputMethod(1)
        }
    },[tonnageMixingData?.cutting_thickness_input])

    
    useEffect(() => {
        if(optionFilePavingThickness && tonnageMixingData?.cutting_thickness_file) {
            const isAgencyStaffExists = optionFilePavingThickness?.find((file: any) => file.value === tonnageMixingData?.cutting_thickness_file)
            if(isAgencyStaffExists) {
                form.setFieldValue('cutting_thickness_file', tonnageMixingData?.cutting_thickness_file)
            }else {
                form.setFieldValue('cutting_thickness_file', null)
            }
        }
    },[form, optionFilePavingThickness, tonnageMixingData])


    return (
        <Form form={form} initialValues={{...tonnageMixingData}} >
            <div className='setting_diagram'>
                <div className='mb-[16px]'>
                    <div className=''>
                        <div className='basic__settings'>
                            <div className='border-bottom-lighter px-[12px]'>
                                <h2 className='py-[16px] text-[16px] font-bold text-[#2E2E2E]'>
                                    基本設定
                                </h2>
                                <div className='basic__settings__item'>
                                    <p className='settings__item__lable text-[16px] text-[#2E2E2E] w-6/12'>混合物密度</p>
                                    <div className='flex items-center relative w-6/12'>
                                        <Form.Item 
                                            name='density' 
                                            rules={
                                                [                                    
                                                    { validator: (rule, value, callback) => validateNumberFields(rule,value,callback, '混合物密度', 0)}
                                                ]
                                            }
                                        >
                                            <InputNumber min={0} className='input__tonnage'/>
                                        </Form.Item>
                                        <span className='unit-input'>トン/m³</span>
                                    </div>
                                </div>
                                <div className='basic__settings__item'>
                                    <p className='settings__item__lable w-6/12'>ロス率</p>
                                    <div className='flex items-center relative w-6/12'>
                                        <Form.Item 
                                            name='loss_rate' 
                                            rules={
                                                [                                    
                                                    { validator: (rule, value, callback) => validatePercentFields(rule,value,callback, 'ロス率', 0, 100)}
                                                ]
                                            }
                                        >
                                            <InputNumber min={0} className='input__tonnage'/>
                                        </Form.Item>
                                        <span className='unit-input'>％</span>
                                    </div>
                                </div>
                                <div className='basic__settings__item'>
                                    <p className='settings__item__lable w-6/12'>ダンプ搭載重量</p>
                                    <div className='flex items-center relative w-6/12'>
                                        <Form.Item 
                                            name='dump_weight' 
                                            rules={
                                                [                                    
                                                    { validator: (rule, value, callback) => validateNumberFields(rule,value,callback, 'ダンプ搭載重量', 0)}
                                                ]
                                            }
                                        >
                                            <InputNumber min={0} className='input__tonnage'/>
                                        </Form.Item>
                                        <span className='unit-input'>トン</span>
                                    </div>
                                </div>
                            </div>
                            <div className='pavement-input-method border-bottom-lighter px-[12px] pb-[12px]'>
                                <h2 className='py-[16px] text-[16px] font-bold text-[#2E2E2E]'>
                                    舗設厚さ入力方法
                                </h2>
                                <div className=''>
                                    <Radio.Group className='mb-[10px] flex flex-col' value={thicknessInputMethod} onChange={(e) => handleChangeThicknessInputMethod(e.target.value)}>
                                        <Radio className='text-[#2E2E2E] pavement-radio-lable' value={1}>ファイルから入力</Radio>
                                        {
                                            thicknessInputMethod === 1 &&
                                            <div className=''>
                                                <div className='flex items-center py-[8px]'>
                                                    <h4 className='text-[#2E2E2E] w-6/12 '>舗設厚さファイル</h4>
                                                    <Form.Item name='cutting_thickness_file' className='w-6/12' rules={[{ required: true, message: '舗設厚さファイルを選択してください' }]}>
                                                        <Select
                                                            className='cutting-thickness-file'
                                                            options={optionFilePavingThickness}
                                                            suffixIcon={SelectIcon()}
                                                            placeholder=''
                                                            notFoundContent={renderNotFoundContent()}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        }
                                        <Radio className='mt-[20px] text-[#2E2E2E] pavement-radio-lable' value={2}>一括設定</Radio>
                                    </Radio.Group>
                                    {
                                        thicknessInputMethod === 2 && 
                                        <div className='flex items-center mt-[8px]'>
                                            <p className='settings__item__lable w-6/12 text-[#2E2E2E] pavement-radio-lable'>厚さ</p>
                                            <div className='flex items-center relative w-6/12'>
                                                <Form.Item 
                                                    name='cutting_thickness_input' 
                                                    rules={
                                                        [                                    
                                                            { validator: (rule, value, callback) => validateNumberFields(rule,value,callback, '厚さ', 0)}
                                                        ]
                                                    }
                                                >
                                                    <InputNumber min={0} className='input__tonnage ml-0px'/>
                                                </Form.Item>
                                                <span className='text-[#2E2E2E] pavement-radio-lable unit-input'>cm</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mb-[35px] px-[12px] pb-[12px]'>
                    <h2 className='py-[16px] text-[16px] font-bold text-[#2E2E2E]'>
                        ダンプ台数計算設定
                    </h2>
                    <div className='basic__settings'>
                        <div className='basic__settings__item'>
                            <p className='settings__item__lable w-6/12'>プラント積み込み時間</p>
                            <div className='flex items-center relative w-6/12'>
                                <Form.Item 
                                    name='plant_loading_time' 
                                    rules={
                                        [                                    
                                            { validator: (rule, value, callback) => validateNumberFields(rule,value,callback, 'プラント積み込み時間', 0)}
                                        ]
                                    }
                                >
                                    <InputNumber min={0} className='input__tonnage' onChange={() => handleSetNumberVehiclesValue()}/>
                                </Form.Item>
                                <span className='unit-input'>分</span>
                            </div>
                        </div>
                        <div className='basic__settings__item'>
                            <p className='settings__item__lable w-6/12'>往路走行時間</p>
                            <div className='flex items-center relative w-6/12'>
                                <Form.Item 
                                    name='outbound_travel_time' 
                                    rules={
                                        [                                    
                                            { validator: (rule, value, callback) => validateNumberFields(rule,value,callback, '往路走行時間', 0)}
                                        ]
                                    }
                                >
                                    <InputNumber min={0} className='input__tonnage' onChange={() => handleSetNumberVehiclesValue()}/>
                                </Form.Item>
                                <span className='unit-input'>分</span>
                            </div>
                        </div>
                        <div className='basic__settings__item'>
                            <p className='settings__item__lable w-6/12'>復路走行時間</p>
                            <div className='flex items-center relative w-6/12'>
                                <Form.Item 
                                    name='return_travel_time' 
                                    rules={
                                        [                                    
                                            { validator: (rule, value, callback) => validateNumberFields(rule,value,callback, '復路走行時間', 0)}
                                        ]
                                    }
                                >
                                    <InputNumber min={0} className='input__tonnage' onChange={() => handleSetNumberVehiclesValue()}/>
                                </Form.Item>
                                <span className='unit-input'>分</span>
                            </div>
                        </div>
                        <div className='basic__settings__item'>
                            <p className='settings__item__lable w-6/12'>一台当たりの舗設時間</p>
                            <div className='flex items-center relative w-6/12'>
                                <Form.Item 
                                    name='paving_time_per_unit' 
                                    rules={
                                        [                                    
                                            { validator: (rule, value, callback) => validateNumberFields(rule,value,callback, '一台当たりの舗設時間', 0)}
                                        ]
                                    }
                                >
                                    <InputNumber min={0} className='input__tonnage' onChange={() => handleSetNumberVehiclesValue()}/>
                                </Form.Item>
                                <span className='unit-input'>分</span>
                            </div>
                        </div>
                        <div className='basic__settings__item'>
                            <p className='settings__item__lable w-6/12'>チャーター台数 (自動計算)</p>
                            <div className='flex items-center relative w-6/12'>
                                <Form.Item name='number_vehicles'>
                                    <InputNumber disabled className='input__tonnage'/>
                                </Form.Item>
                                <span className='unit-input'>台</span>
                            </div>
                        </div>
                        <div className='basic__settings__item'>
                            <p className='settings__item__lable w-6/12'>チャーター台数</p>
                            <div className='flex items-center relative w-6/12'>
                                <Form.Item 
                                    name='actual_rent_vehicles' 
                                    rules={
                                        [                                    
                                            { validator: (rule, value, callback) => validateQuatifyCarFields(rule,value,callback, 'チャーター台数', 1, 100)}
                                        ]
                                    }
                                >
                                    <InputNumber min={0} className='input__tonnage' formatter={value => value ? Number(value)?.toFixed(0) : ''}/>
                                </Form.Item>
                                <span className='unit-input'>台</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    )
}

export default PavementDiagramSettingComponent