import { useQuery } from 'react-query'
import { api } from 'src/api/axios'
import { QUERY_KEY } from '../query-key'


export const useOverloadManagement = (constructionId: number, enabled=true) => useQuery(
  [QUERY_KEY.OVERLOAD_MANAGEMENT, constructionId],
  async () => {
    const res = await api.get(`/api/admin/construction-zone/${constructionId}/overload`)
    return res.data.data
  },
  {
    enabled: Boolean(enabled),
    retry: 0
  }
)
