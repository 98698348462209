import React from 'react'
import CompanyCreateEditComponent from 'src/components/features/superAdmin/companyCreateEditComponent'
import { LayoutSuperAdmin } from 'src/components/layouts/layoutSuperAdmin'

const CompanyCreateEditPage = () => (
    <LayoutSuperAdmin>
       <CompanyCreateEditComponent />
    </LayoutSuperAdmin>
)

export default CompanyCreateEditPage