import React from 'react'
import Header from '../common/layouts/header'
import './index.modules.scss'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { listMenuDetails, role } from 'src/constants'
import { useConstructionDetail } from 'src/api/data/construction/useConstructionDetail'
import constructionIcon from 'src/assets/images/back-icon.png'
import { useLocationSiteListFiles } from 'src/api/data/locationSite/useLocationSiteListFiles'
import HeaderCompanyAdmin from '../common/layouts/headerCompanyAdmin'
import { Spin } from 'antd'
type LayoutProps = {
    children: React.ReactNode,
    handleGoBack?: (id: number) => void 
}
export const LayoutDetail: React.FC<LayoutProps> = ({ children, handleGoBack }) => {
    const location = useLocation()
    const history = useHistory()
    const { id } = useParams<{ id: string }>()
    const { idConstructionZone } = useParams<{ idConstructionZone: string }>()
    const handleActiveSideBar = (path: string) => {
        history.push(`/construction-site/${id}/location-site/${idConstructionZone}${path}`)
    }
    const { data: constructionDetail } = useConstructionDetail(Number(id))
    const { data: locationSite, isLoading } = useLocationSiteListFiles(Number(idConstructionZone))
    const handleBackConstructionZone = () => {
        if(handleGoBack) {
            handleGoBack(Number(id))
        }else {
            history.push(`/construction-site/${id}/location-site`)
        }
    }
    return (
        <>
            {
                Number(localStorage.getItem('role')) && Number(localStorage.getItem('role')) === role.COMPANY ?  <HeaderCompanyAdmin /> :  <Header />
            }
            {
                isLoading ? <Spin className='position-center'/> : 
                <div className='civil-content-page'>
                    <div className='page-note'>
                        <div className='detail-title'>
                            <img src={constructionIcon} alt='' className='mr-[16px] cursor-pointer' onClick={() => handleBackConstructionZone()}/>
                            <h2 className='page-detail-note cursor-pointer mr-[4px] line-1 break-all' onClick={() => handleBackConstructionZone()}>{constructionDetail?.name}</h2>
                            <div className='page-detail-note text-blue line-1 break-all'>/ {locationSite?.name}</div>
                        </div>
                    </div>
                    <div className='flex layout'>
                        <ul className='side-bar w-4/12'>
                            {
                                listMenuDetails.map((item) => {
                                    return (
                                        <li 
                                            key={item.id} 
                                            className={`side-bar-item ${location.pathname?.includes(item.path) ? 'active' : ''}`} 
                                            onClick={() => handleActiveSideBar(item.path)}
                                        >
                                            {item.name}
                                        </li>
                                    )
                                })
                            }
                        </ul> 
                        <div className='content-layout'>
                            {children}
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
