/* eslint-disable react-hooks/exhaustive-deps */
import 'moment/locale/ja';
import locale from 'antd/es/date-picker/locale/ja_JP';
import { Button, Input, Pagination, Select, Table, DatePicker } from 'antd'
import React, { useMemo, useState } from 'react'
import searchIcon from 'src/assets/images/search-icon.png'
import addIcon from 'src/assets/images/add-icon.png'
import constructionViewIcon from 'src/assets/images/view-icon.png'
import { useHistory } from 'react-router-dom'
import type { ColumnsType } from 'antd/es/table';
import { pageSizeList, staffAgencyStatus } from 'src/constants'
import iconDatePicker from 'src/assets/images/icon-calendar.png'
import {getClassStatusStaffAgency, handleGetStatusStaffAgency } from 'src/utils';
import { companyCreateType } from 'src/components/features/agency/type';
import iconSelect from 'src/assets/images/arrow-down-2.png'
import moment from 'moment';
import TableNoteNoData from 'src/components/common/tableNoteNoData';
import { useListAgencyAdmin } from 'src/api/data/admin/useListAgencyAdmin';
import editIcon from 'src/assets/images/edit-icon.png'

interface DataType {
    key: string;
    name: string;
    location: string;
    updated_at: string;
}

const columns: ColumnsType<DataType> = [
    {
        title: 'ID',
        key: 'id',
        dataIndex: 'id',
        width: 60
    },
    {
        title: '代理店名',
        dataIndex: 'name',
        key: 'name',
        width: 388
    },
    {
        title: 'メールアドレス',
        dataIndex: 'email',
        key: 'email',
        width: 388
    },
    {
        title: '電話番号',
        key: 'phone',
        dataIndex:'phone',
        width:160
    },
    {
        title: '代表者',
        key: 'presentative',
        dataIndex:'presentative',
        width:160
    },
    {
        title: '登録日時',
        key: 'updated_at',
        dataIndex:'updated_at',
        width:160
    },
    {
        title: '状態',
        key: 'status',
        dataIndex:'status',
        width:140
    },
    {
        title: () => {
          return <p className='text-center text-[16px] text-[#2E2E2E]'>アクション</p>
        },
        key: 'action',
        dataIndex:'action',
        width:100
    }
]

const { RangePicker } = DatePicker;
const AgencyListComponent = () => {
    const history = useHistory()
    const [keyword, setKeyword] = useState<string>('')
    const [keywordSearch, setKeywordSearch] = useState<string>('')
    const [status, setStatus] = useState<number | undefined>()
    const [date, setDate] = useState<string>('')
    const [page, setPage] = useState<number>(1)
    const [dateValue, setDateValue] = useState<any>('')
    const [sizePage, setSizePage] = useState<number>(10)
    const { data: agencyListData, isLoading } = useListAgencyAdmin(keywordSearch,status,date, page, sizePage, )
    const dataSource = useMemo(() => {
      if (agencyListData) {
        return agencyListData?.records?.map((agency: companyCreateType, index: number) => ({
          ...agency,
          key: agency?.id,
          name: (
            <span onClick={() =>handleGoAgencyDetail(agency?.id)} className='site-name line-1 cursor-pointer'>{agency?.name}</span>
          ),
          presentative: <span className='line-1 break-all'>{agency?.presentative}</span>,
          email: (
            <span className='line-1 break-all'>{agency?.email}</span>
          ),
          status: (
            <span className={`company-request-status inline-block ${getClassStatusStaffAgency(agency?.status)}`}>
              {
                handleGetStatusStaffAgency(agency?.status)
              }
            </span>
          ),
          action: (
            <div className='flex justify-center'>
              <img onClick={() =>handleGoAgencyDetail(agency?.id)} className='item-name-icon cursor-pointer mr-[10px]' src={constructionViewIcon} alt=""/>
              <img 
                onClick={() =>handleGoToEditAgency(agency?.id)}
                className='item-name-icon cursor-pointer mr-[10px]' 
                src={editIcon} 
                alt=""
              />
            </div>
          ),
        }))
      }
      return []
    }, [agencyListData])

    const handleChangeKeyword = (value:string) => {
      setKeyword(value)
    }

    const iconSearch = () => {
      return <div className='p-[5px] cursor-pointer' onClick={handleSearch}><img src={searchIcon} alt='' /></div>
    }

    const handleChangeSizePage = (value: number) => {
      setPage(1)
      setSizePage(value)
    }

    const handleChangePage = (page: number) => {
      setPage(page)
    }

    const handleGoAgencyDetail = (id: number | undefined) => {
      history.push(`/cms/agency/${id}`)
    }

    const handleSearch = () => {
        setPage(1)
        setKeywordSearch(keyword?.trim())
        setKeyword(keyword?.trim())
    }

    const renderIconDatePicker = () => {
        return <img src={iconDatePicker} alt='' />
    }

    const renderSeparator = () => {
        return <span>~</span>
    }

    const renderSelectIcon = () => {
      return <img src={iconSelect} alt='' />
    }

    const handleChangeStatus = (status: number) => {
      setPage(1)
      setStatus(status)
    }

    const handleCalendarChange = (date: any) => {
      setDateValue(date)
      const startDate = date?.[0] == null ? '1970/01/01' : moment(date?.[0])?.format('YYYY-MM-DD')
      const endDate = date?.[1] == null ? moment(date?.[0])?.add(1200,'M').format('YYYY-MM-DD') : moment(date?.[1])?.format('YYYY-MM-DD')
      const dateParam = startDate + ' ~ ' + endDate
      setPage(1)
      if(date) {
        setDate(dateParam)
      }else {
        setDate('')
      }
      
    }  

    const handleGoToCreatePage = () => {
      history.push('/cms/agency/create')
    }

    const handleGoToEditAgency = (id: number | undefined) => {
      history.push(`/cms/agency/edit/${id}`)
    }
    
    return (
        <div className='page-content company-page'>
            <div className='mb-[16px]'>
                <h2 className='page-content-title border-bottom-title'>代理店一覧</h2>
            </div>
            <div className='page-content-table'>
              <div className='flex justify-between items-center p-[16px]'>
                    <div className='flex items-center'>
                            <Input 
                                onPressEnter={handleSearch} 
                                className='input-search mr-[8px]' 
                                placeholder='代理店名を入力' 
                                suffix={iconSearch()} 
                                onChange={(e) => handleChangeKeyword(e.target.value)}
                                value={keyword}
                            />
                            <Select 
                                options={staffAgencyStatus}
                                placeholder="状態"
                                className='company-select'
                                allowClear={true}
                                onChange={(value: number) => handleChangeStatus(value)}
                                suffixIcon={renderSelectIcon()}
                            />
                            <RangePicker 
                                className={`ml-[8px] range-picker-search`}
                                suffixIcon={renderIconDatePicker()}
                                placeholder={['yyyy-mm-dd', 'yyyy-mm-dd']}
                                separator={renderSeparator()}
                                locale={locale}
                                onCalendarChange={handleCalendarChange}
                                format={['YYYY-MM-DD', 'YYYY-MM-DD']}
                                value={dateValue}
                                inputReadOnly={true}
                            />
                    </div>
                    <Button className='button-add' onClick={handleGoToCreatePage}>
                        <img className='button-add-icon' src={addIcon} alt='' />
                        代理店作成
                    </Button>
              </div>
              <div className='constuction-list'>
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    loading={isLoading}
                    locale={{
                      emptyText: (
                        <TableNoteNoData />
                      )
                    }}
                  />
              </div>
              <div className='py-[20px] px-[12px] flex justify-between items-center'>
                <div className='pagination-infor'>
                  {agencyListData?.limitFrom} - {agencyListData?.limitTo} 件表示/全 {agencyListData?.totalRow} 件中
                </div>
                <div className='flex'>
                  <Pagination 
                    current={page} 
                    total={agencyListData?.totalRow} 
                    className='pagination-main mr-[20px]' 
                    showSizeChanger={false}
                    pageSize={sizePage}
                    onChange={(page) => handleChangePage(page)}
                  />
                  <Select
                      defaultValue={10}
                      style={{ width: 153 }}
                      className='pagination-options'
                      onChange={(e) => handleChangeSizePage(e)}
                      options={pageSizeList}
                  />
                </div>
              </div>
            </div>
        </div>
    )
}

export default AgencyListComponent