/* eslint-disable jsx-a11y/anchor-is-valid */
import './headerLP.modules.scss';
import logoIcon from 'src/assets/images/landingPage/logo.svg';
import logoBlueIcon from 'src/assets/images/landingPage/logo_blue.svg';
import globeIcon from 'src/assets/images/landingPage/globe.svg';
import globeBlackIcon from 'src/assets/images/landingPage/globe_black.svg';
import arrowDownIcon from 'src/assets/images/landingPage/arrow_down.svg';
import arrowDownBlackIcon from 'src/assets/images/landingPage/arrow_down_black.svg';
import logoutIcon from 'src/assets/images/landingPage/logout.svg';

import avatarIcon from 'src/assets/images/avatar.svg';
import openMenuIcon from 'src/assets/images/landingPage/open_menu.svg';
import closeMenuIcon from 'src/assets/images/landingPage/close_menu.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDetectWindowSize } from 'src/utils/useDetectWindowSize';
import { useTranslation } from 'react-i18next';
import { getCookie } from 'src/utils/cookie';
import { Dropdown, MenuProps, Space, message } from 'antd';
import LogoutIcon from 'src/assets/icon/logoutIcon';
import { useMutation } from 'react-query';
import { logout } from 'src/api/auth';
import { clearToken } from 'src/utils/cookie';
import { AxiosError } from 'axios';
import CmsIcon from 'src/assets/icon/cmsIcon';
import { role } from 'src/constants';
import { useGetInfoUser } from 'src/api/data/admin/useGetInfoUser';

const HeaderLP = () => {
  const windowSize = useDetectWindowSize();
  const isMobile = windowSize <= 991;
  const history = useHistory();
  const location = useLocation();
  const queryParameters = new URLSearchParams(window.location.search);
  const lang = queryParameters.get('lang');

  const [showLanguage, setShowLanguage] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [language, setLanguage] = useState('日本語');
  const { data, isLoading: isLoadingInfo } = useGetInfoUser();

  const { i18n, t } = useTranslation();

  const LANGUAGES = [
    { label: '日本語', code: 'jp' },
    { label: 'English', code: 'en' },
  ];

  const handleChangeLanguage = (value: string) => {
    setShowLanguage(false);
    i18n.changeLanguage(value);
    setLanguage(LANGUAGES.find((item) => item.code === value)?.label || '日本語');
    history.replace({ pathname: location.pathname, search: `lang=${value}` });
  };

  useEffect(() => {
    if (lang) {
      if (lang !== i18n.language) {
        i18n.changeLanguage(lang);
        document.title = lang === 'en' ? 'AR Civil' : '助さん';
      }
      setLanguage(LANGUAGES.find((item) => item.code === lang)?.label || '日本語');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const handleBackRef = (id: string) => {
    history.push({ pathname: '/', search: lang ? `lang=${lang}` : '' });
    localStorage.setItem('backRef', id);
    localStorage.setItem('windowSize', JSON.stringify(windowSize));
  };

  const roleAcc = Number(localStorage.getItem('role'));
  const items: MenuProps['items'] = [
    {
      label: (
        <a
          className="user-infor-item"
          href={
            roleAcc === role.SYSTEM_ADMIN || roleAcc === role.ADMIN
              ? '/cms/request/company'
              : roleAcc === role.AGENCY || roleAcc === role.AGENCY_STAFF
              ? '/agency/company'
              : '/construction-site'
          }
        >
          <CmsIcon className={'mr-[12px] menu-icon-spec mt-1'} />
          <span>{t('go_to_cms')}</span>
        </a>
      ),
      key: 1,
    },
    {
      label: (
        <div className="user-infor-item" onClick={() => handleLogout()}>
          <LogoutIcon className={'mr-[12px] menu-icon'} />
          <span>{t('logout')}</span>
        </div>
      ),
      key: 2,
    },
  ];

  const handleLogout = () => {
    onLogout();
  };

  const handleDecodeUrl = (url: string) => {
    return decodeURIComponent(url);
  };

  const { mutateAsync: onLogout } = useMutation(() => logout(), {
    onSuccess: async () => {
      history.push('/login');
      clearToken();
      localStorage.clear();
    },
    onError: (err: AxiosError) => {
      if (err.response?.status === 400) {
        message.error(err.response.data.messages[0]);
        return;
      }
      message.error('システムエラー');
    },
  });

  const handleGetDropdownContainer = () => {
    return document.getElementById('header')!;
  };

  useEffect(() => {
    if (showMenu && isMobile) {
      document.body.style.overflowY = 'hidden';
      document.body.style.position = 'relative';
    } else {
      document.body.style.overflowY = 'unset';
      document.body.style.position = 'unset';
    }
  }, [showMenu, isMobile]);

  return (
    <div className="header_lp header_lp_black">
      <a href={`/${lang ? `?lang=${lang}` : ''}`}>
        <div className={`logo ${showMenu && isMobile ? 'logo_blue' : ''}`}>
          {showMenu && isMobile ? <img src={logoBlueIcon} alt="logo" /> : <img src={logoIcon} alt="logo" />}
          助さん
        </div>
      </a>

      {!isLoadingInfo && isMobile && (
        <div className="nav_btn">
          {!showMenu ? (
            <label className="show_menu_button" onClick={() => setShowMenu(true)}>
              <img src={openMenuIcon} alt="open" />
            </label>
          ) : (
            <label className="hide_menu_button" onClick={() => setShowMenu(false)}>
              <img src={closeMenuIcon} alt="close" />
            </label>
          )}
        </div>
      )}

      {isMobile ? (
        <div className={`menu_mobile ${showMenu && 'menu_mobile_show'}`}>
          <div className="wrap_login">
            {getCookie('token') && data ? (
              <>
                {!isLoadingInfo && (
                  <div className="wrap_avatar">
                    <div className="avatar">
                      <img
                        className="header__icon-avatar"
                        src={data?.avatar ? handleDecodeUrl(data?.avatar) : avatarIcon}
                        alt=""
                      />
                    </div>
                    <div className="avatar_info">
                      <p className="name">{data?.name}</p>
                      <p className="mail">{data?.email}</p>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="button_login">
                <button onClick={() => history.push('/login')}>{t('login')}</button>
              </div>
            )}
          </div>

          <div className="line"></div>

          <div className="menu">
            <div className="menu_item" onClick={() => handleBackRef('main_function')}>
              {t('main_function')}
            </div>
            <div className="menu_item" onClick={() => handleBackRef('cms')}>
              {t('cms')}
            </div>
            <div className="menu_item" onClick={() => handleBackRef('faq')}>
              {t('faq')}
            </div>
            <div className="menu_item" onClick={() => handleBackRef('notice')}>
              {t('notice')}
            </div>
            <div className="menu_item" onClick={() => handleBackRef('inquiry')}>
              {t('inquiry')}
            </div>
          </div>

          <div className="line"></div>

          <div className="language">
            <div className="language_base" onClick={() => setShowLanguage(!showLanguage)}>
              <div className="language_globe">
                <img className="img-3" src={globeBlackIcon} alt="global" />
                <div className="text-wrapper-25">{language}</div>
              </div>
              <img className="arrow_down" src={arrowDownBlackIcon} alt="arrow" />
            </div>

            {showLanguage && (
              <div className="language_popup">
                {LANGUAGES.map((lang, index) => {
                  return (
                    <p
                      key={'lang' + index}
                      className="language_popup_item"
                      onClick={() => {
                        handleChangeLanguage(lang.code);
                        history.go(0);
                      }}
                    >
                      {lang.label}
                    </p>
                  );
                })}
              </div>
            )}
          </div>

          {getCookie('token') && data && (
            <>
              <div className="line"></div>

              <a
                className="go_to_cms"
                href={
                  roleAcc === role.SYSTEM_ADMIN || roleAcc === role.ADMIN
                    ? '/cms/request/company'
                    : roleAcc === role.AGENCY || roleAcc === role.AGENCY_STAFF
                    ? '/agency/company'
                    : '/construction-site'
                }
              >
                <CmsIcon className={'mr-[12px] menu-icon'} />
                <span>{t('go_to_cms')}</span>
              </a>

              <div
                className="logout"
                onClick={() => {
                  handleLogout();
                }}
              >
                <img src={logoutIcon} alt="logout" />
                {t('logout')}
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="menu_button" id="header">
          <div className="menu">
            <div className="menu_item" onClick={() => handleBackRef('main_function')}>
              {t('main_function')}
            </div>
            <div className="menu_item" onClick={() => handleBackRef('cms')}>
              {t('cms')}
            </div>
            <div className="menu_item" onClick={() => handleBackRef('faq')}>
              {t('faq')}
            </div>
            <div className="menu_item" onClick={() => handleBackRef('notice')}>
              {t('notice')}
            </div>
            <div className="menu_item" onClick={() => handleBackRef('inquiry')}>
              {t('inquiry')}
            </div>
            <div className="menu_item language">
              <div className="language_base" onClick={() => setShowLanguage(!showLanguage)}>
                <div className="language_globe">
                  <img className="img-3" src={globeIcon} alt="global" />
                  <div className="text-wrapper-25">{language}</div>
                </div>
                <img className="arrow_down" src={arrowDownIcon} alt="arrow" />
              </div>

              {showLanguage && (
                <div className="language_popup">
                  {LANGUAGES.map((lang, index) => {
                    return (
                      <p
                        key={'lang' + index}
                        className="language_popup_item"
                        onClick={() => {
                          handleChangeLanguage(lang.code);
                          history.go(0);
                        }}
                      >
                        {lang.label}
                      </p>
                    );
                  })}
                </div>
              )}
            </div>
          </div>

          {!isLoadingInfo && (
            <>
              {getCookie('token') && data ? (
                <div className="avatar">
                  <Dropdown
                    overlayClassName="user-infor"
                    menu={{ items }}
                    trigger={['click']}
                    getPopupContainer={handleGetDropdownContainer}
                  >
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      <Space>
                        <img
                          className="header__icon-avatar"
                          src={data?.avatar ? handleDecodeUrl(data?.avatar) : avatarIcon}
                          alt=""
                        />
                      </Space>
                    </a>
                  </Dropdown>
                </div>
              ) : (
                <div className="button_login">
                  <button onClick={() => history.push('/login')}>{t('login')}</button>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default HeaderLP;
