import React from 'react'
import CompanyCreateComponent from 'src/components/features/agency/companyCreateComponent'
import { LayoutAgency } from 'src/components/layouts/layoutAgency'

const CompanyCreatePage = () => (
    <LayoutAgency>
       <CompanyCreateComponent />
    </LayoutAgency>
)

export default CompanyCreatePage