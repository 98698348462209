import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: 'jp',
  lng: 'jp',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    jp: {
      translation: {
        login: 'ログイン',
        go_to_cms: 'システム管理',
        main_function: '主な機能',
        cms: 'CMS管理',
        faq: 'FAQ',
        detail_faq: 'FAQ詳細',
        notice: 'お知らせ',
        inquiry: 'お問い合わせ',
        logout: 'ログアウト',
        banner_title: '高精度屋外AR測量システム助さん',
        main_function_title: '施工効率化に役立つ機能',
        AR_display: 'AR表示',
        AR_display_des:
          '高精度な自己位置推定機能＆正確なAR表示で、切削オーバーレイ工事を効率化。マーキング作業や廃棄合材を減らし、工事現場の生産性・品質向上を可能にします',
        toturial_title_1: 'マシンガイダンス',
        totuial_des_1:
          '切削機の施工エリアでの位置に応じてリアルタイムにその場所の切削値をディスプレイに表示。切削値を地面にマーキングする時間が大幅に短縮します。',
        toturial_title_2: '過積載管理',
        totuial_des_2: 'ダンプ1台ごとの積載到達ラインを現場にAR表示することでダンプの使用効率を高めます。',
        toturial_title_3: '合材トン数管理',
        totuial_des_3:
          'ダンプ1台ごとが舗装して到達するラインを事前の計画データに基づきAR表示が可能。無駄に発注してしまうアスファルトの量を抑え、アスファルトの発注費用削減をすることが可能です。',
        control_title: 'チームのコラボレーションを加速する機能',
        control_content_1:
          'クラウドで現場を一元管理。遠隔地でもスムーズなコミュニケーションを実現。CMSに入力した切削順序やダンプ積載量を現場のARアプリにオンラインで表示し、現場管理を効率化します。',
        control_content_2: 'CMSに入力した切削順序やダンプ積載量が現場のARアプリに表示され、現場管理を効率化します。',
        control_content_3: 'クラウド管理で簡単かつ安心なデータ操作。',
        control_content_4: '世界中どこからでもアクセス可能。クラウド管理で簡単かつ安心なデータ操作。',
        engineer_title_1: '切削順序図の作成',
        engineer_content_1: '切削厚、舗装厚などの計画値をCMS上に入力。データを元に、現場で切削順序がAR表示されます。',
        engineer_title_2: '過積載管理機能',
        engineer_content_2:
          '各ダンプの積載量や密度などを入力することで、ダンプ1台分の切削範囲をAR表示。面倒なマーキングが不要になります。',
        engineer_title_3: '合材トン数管理機能',
        engineer_content_3: 'CMSに入力したパラメーターに基づいて、必要なアスファルトなどの合材数を自動算出します。',
        engineer_title_4: '施工計画図をインポート',
        engineer_content_4: '施工計画図を一瞬でインポート。リアルタイムでチーム全員と共有できます。',
        see_more: 'もっと見る',
        notice_title: 'お知らせ',
        contact_title: 'お問い合わせ',
        name_company: '会社名',
        name: '氏名',
        email: 'メールアドレス',
        phone: '電話番号',
        note: 'メッセージ',
        note_placeholder: 'お問い合わせの具体的な内容をご記入ください。',
        button_submit: 'メッセージを送信',
        privacy_policy: 'プライバシーポリシー',
        operating_company: '運営会社',
        category: '質問のカテゴリー',
        about_service: 'サービスについて',
        about_applying: 'インタビュー案件への応募について',
        flow_after: '応募完了後の流れについて',
        regarding: 'インタビュー当日の対応について',
        about_rewards: '謝礼について',
        rules_manners: 'ルールとマナー、禁止行為',
        others: 'その他',
        enter_company_name: '会社名を入力してください',
        limit_255_company_name: '会社名は255文字以内で入力してください',
        enter_name: '氏名を入力してください',
        limit_255_enter_name: '氏名は255文字以内で入力してください',
        enter_email: 'メールアドレスを入力してください',
        enter_email_invalid: 'メールアドレスの形式が不正です',
        limit_255_email_invalid: 'メールアドレスは255文字以内で入力してください',
        enter_message: 'メッセージをご記入ください',
        limit_255_message: 'メッセージは1000文字以内で入力してください',
        mail_is: 'メールアドレスは',
        within_characters: '文字以内で入力してください',
        enter_phone: '電話番号を入力してください',
        enter_phone_between: '電話番号は半角数字10文字以上11文字以内で入力してください。',

        //policy
        title: 'プライバシーポリシー',
        content_title_0: 'プライバシーポリシー（個人情報保護方針）',
        content_desc_0:
          '株式会社シナスタジア（以下、「当社」といいます。）は，ユーザーの個人情報について以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。本ポリシーは、当社がどのような個人情報を取得し、どのように利用・共有するか、ユーザーがどのようにご自身の個人情報を管理できるかをご説明するものです。なお、本ポリシー中の「本サービス」とは、当社が運営する土木ARサービス「助さん」をいい、「ユーザー」とは、本サービスの利用者（当該利用者の役員及び従業員等を含みます。）をいいます。',
        content_title_1: '第１条　（事業者情報）',
        content_desc_1_0: '法人名：株式会社シナスタジア',
        content_desc_1_1: '住所：東京都品川区北品川1-12-10　ジャコムビル3F',
        content_desc_1_2: '代表者：代表取締役社長 有年 亮博',
        content_title_2: '第２条　（個人情報の取得方法）',
        content_desc_2_0:
          '当社はユーザーが利用登録をするとき、氏名・住所・電話番号・メールアドレスなど個人を特定できる情報を取得させていただきます。',
        content_desc_2_1:
          'お問い合わせフォームやコメントの送信時には、氏名・メールアドレス等を取得させていただきます。',
        content_title_3: '第３条　（個人情報の利用目的）',
        content_desc_3_0: '個人情報の利用目的は以下のとおりであり、利用目的を超えて利用することはありません。',
        content_desc_3_1: '（１）本サービスの利用契約及び登録に関する審査。',
        content_desc_3_2: '（２）利用者の個人認証及び利用者向け各種サービスの提供。',
        content_desc_3_3: '（３）本サービスの利用料の請求及び決済。',
        content_desc_3_4: '（４）本サービスの利用に伴う各種連絡・お知らせの配信。',
        content_desc_3_5: '（５）本サービスの運用に伴う一時的なバックアップ保管。',
        content_desc_3_6: '（６）本サービスに関するご意見、お問い合わせへの回答。',
        content_desc_3_7: '（７）サービス改善のための統計的調査。',
        content_desc_3_8: '（８）不正行為の防止と検出。',
        content_desc_3_9: '（９）法律上の義務遵守。',
        content_desc_3_10: '（１０）当社の商品・サービスに関する広告やオファーなどの情報の配信。',
        content_title_4: '第４条　（個人データを安全に管理するための措置）',
        content_desc_4_0:
          '当社は個人情報を正確かつ最新の内容に保つよう努め、不正なアクセス・改ざん・漏えい・滅失及び毀損から保護するよう、適切なセキュリティ対策の実施に努めています。個人情報は、一般の利用者がアクセスできない安全な環境下に保管しています。',
        content_title_5: '第５条　（個人データの第三者提供について）',
        content_desc_5_0: '当社は、第3条に規定する利用目的の範囲内において、以下のとおり共同利用を行います。',
        content_indent_5_0: '共同利用する会社',
        content_indent_5_1: 'AMELA Technology Joint Stock Company',
        content_indent_5_2:
          '住所 5F, A棟, Keangnamタワー, E6 Cau Giay, Pham Hung, Me Tri, Nam Tu Liem, Ha Noi, Vietnam ',
        content_indent_5_3: 'CEO ズオン・ミン・コア',
        content_indent_5_4: 'セキュリティ対策',
        content_indent_5_5:
          '同社は ISO 27001:2013 情報セキュリティマネジメントシステムの認証を取得しています。また、同社はOECDプライバシー８原則二対応する個人情報の保護のための措置を講じています。',
        content_indent_5_6: '共同して利用する者の利用目的',
        content_indent_5_7: '上記「利用目的」の内容と同様。',
        content_indent_5_8: '利用項目',
        content_indent_5_9: '氏名、住所、電話番号、メールアドレス等の個人情報',
        content_indent_5_10: 'ベトナムの個人情報保護に関する情報',
        content_title_6: '第６条　（越境移転）',
        content_desc_6_10:
          '当社は、原則として、お客様ご本人の同意を得ずに個人情報を第三者に提供しません。提供先・提供情報内容を特定したうえで、お客様の同意を得た場合に限り提供します。ただし、以下の場合は、関係法令に反しない範囲で、お客様の同意なく個人情報を提供することがあります。',
        content_desc_6_0:
          '利用者の情報は、Amazon Web Services,Inc（以下「AWS社」といいます。）のクラウドサービス上に保管されることがあります。また、ユーザーの情報は、AWS社のクラウドサービスを通じて日本国外に移転されることがあります。詳細につきましてはAWS社の規定をご確認ください。',
        content_desc_6_1:
          '（１）国の機関若しくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合で、お客様ご本人の同意を得ることによりその事務の遂行に支障を及ぼすおそれがある場合',
        content_desc_6_2:
          '（２）裁判所、検察庁、警察、弁護士会、消費者センターまたはこれらに準じた権限を有する機関から、個人情報についての開示を求められた場合',
        content_desc_6_3: '（３）お客様ご本人から明示的に第三者への開示または提供を求められた場合',
        content_desc_6_4: '（４）法令により開示または提供が許容されている場合',
        content_desc_6_5: '（５）利用目的の達成に必要な範囲内において個人情報取扱い業務の一部または全部を委託する場合',
        content_desc_6_6:
          '（６）合併その他の事由による事業の承継に伴い個人情報を提供する場合であって、承継前の利用目的の範囲で取り扱われる場合',
        content_title_7: '第７条　（第三者提供に関する免責事項）',
        content_desc_7_0:
          '１　利用者の情報は、Amazon Web Services,Inc（以下「AWS社」といいます。）のクラウドサービス上に保管されることがあります。また、ユーザーの情報は、AWS社のクラウドサービスを通じて日本国外に移転されることがあります。詳細につきましてはAWS社の規定をご確認ください。',
        content_desc_7_1:
          '２　サービスの開発および運営のため、利用者の情報を AMELA　Technology Joint Stock Company に移転することがあります。同社は ISO 27001:2013 情報セキュリティマネジメントシステムの認証を取得しています。',
        content_title_8: '第7条　（第三者提供に関する免責事項）',
        content_desc_8_0:
          'お客様ご本人以外がお客様個人を識別できる情報（ID・パスワード等）を入手した場合、第三者による個人情報の取得に関し、当社は何らの責任を負いません。',
        content_title_9: '第8条　（保有個人データの開示、訂正）',
        content_desc_9_0:
          '当社は本人から個人情報の開示を求められたときには、これを開示します。個人情報の利用目的の通知や訂正、追加、削除、利用の停止、第三者への提供の停止を希望される方は以下の住所宛てに書面郵送にてご請求ください。当社所定の手続きでご本人様であることを確認の上、原則として2週間以内に対応します。',
        content_desc_9_1: '送付先住所　〒140-0001 東京都品川区北品川1-12-10 ジャコムビル 3F',
        content_desc_9_2: '株式会社シナスタジア　土木ARサービス「助さん」プライバシーポリシー窓口',
        content_desc_9_3: 'ただし、以下の場合は応じないことがあります。',
        content_desc_9_4: '（１）ユーザー本人または第三者の生命、身体、財産その他の権利・利益を害するおそれがある場合',
        content_desc_9_5: '（２）本サービスの適正な実施に著しい支障を及ぼすおそれがある場合',
        content_desc_9_6: '（３）他の法令に違反することとなる場合',
        content_desc_9_7:
          'また、当該個人情報の利用停止・第三者への提供の停止（以下「利用停止等」といいます。）に多額の費用を要する場合､その他の利用停止等を行うことが困難な場合であって、ユーザーの権利・利益を保護するため必要なこれに代わるべき措置をとるときは、利用停止等に応じないことがあります。',
        content_title_10: '第9条　（個人情報の正確性）',
        content_desc_10_0:
          '当社は、ご提供いただいた個人情報を正確にデータ処理するように努めます。ただし、ご提供いただいた個人情報の内容が正確かつ最新であることについては、お客様が責任を負うものとします。',
        content_title_11: '第１0条　（個人情報取り扱いに関する相談や苦情の連絡先）',
        content_desc_11_0:
          '当社の個人情報の取り扱いに関するご質問やご不明点、苦情、その他のお問い合わせはお問い合わせフォームよりご連絡いただくか、第９条に記載の住所に書面郵送にてご連絡ください。',
        content_title_12: '第１1条　（本ポリシーの変更）',
        content_desc_12_0: '当社は、法令等の定めがある場合を除き、本ポリシーを随時変更することができるものとします。',
        content_title_13: '第１2条　（正文）',
        content_desc_13_0:
          '本ポリシーは、日本語を正文とします。本ポリシーにつき、参考のために英語による翻訳文が作成された場合でも、日本語の正文のみが効力を有するものとし、英語訳文にはいかなる効力も有しないものとします。',
        date_policy: '制定：２０２３年９月２５日',

        message_function: 'チャット機能',
        message_function_des_1: 'アプリ使用中にお困りの際は、直接チャットでカスタマーサポートとやりとりができます。',
        message_function_des_2: 'リアルタイムに疑問を解消でき、安心してご利用いただけます。',
      },
    },
    en: {
      translation: {
        login: 'Login',
        go_to_cms: 'System Management',
        main_function: 'Main Functions',
        cms: 'CMS Management',
        faq: 'FAQ',
        detail_faq: 'Detail FAQ',
        notice: 'Notices',
        inquiry: 'Contact Us',
        logout: 'Logout',
        banner_title: 'High-precision outdoor AR surveying system SUKESAN',
        main_function_title: 'Functions that help improve construction efficiency',
        AR_display: 'AR display',
        AR_display_des:
          'Highly accurate self-position estimation function and accurate AR display make cutting overlay work more efficient. It reduces marking work and waste material, making it possible to improve productivity and quality at construction sites.',
        toturial_title_1: 'Machine guidance',
        totuial_des_1:
          "Depending on the cutting machine's position in the construction area, the cutting value for that location is shown on the display in real time. The time required to mark cutting values on the ground is greatly reduced.",
        toturial_title_2: 'Overload management',
        totuial_des_2:
          'AR display of the loading reach line for each dump truck on site improves dump truck usage efficiency.',
        toturial_title_3: 'Lumber Tonnage Management',
        totuial_des_3:
          'The line that each dump truck paves to reach can be displayed in AR based on pre-planned data. It is possible to reduce the amount of asphalt that is ordered unnecessarily and to reduce the cost of ordering asphalt.',
        control_title: 'Features that accelerate team collaboration',
        control_content_1:
          'Centrally manage the site in the cloud. Achieve smooth communication even in remote locations. The cutting order and dump load amount entered in the CMS are displayed online on the on-site AR app, making on-site management more efficient.',
        control_content_2:
          'Cutting sequence and dump loadings entered into the CMS are displayed on the AR app on site, streamlining site management.',
        control_content_3: 'Easy and worry-free data manipulation with cloud management. ',
        control_content_4:
          'Accessible from anywhere in the world. Easy and secure data operation with cloud management. ',
        engineer_title_1: 'Creation of cutting sequence diagram',
        engineer_content_1:
          'Input planned values ​​such as cutting thickness and pavement thickness into CMS. Based on the data, the cutting order is displayed in AR on site.',
        engineer_title_2: 'Overload management function',
        engineer_content_2:
          'By inputting the loading capacity and density of each dump truck, AR displays the cutting area for one dump truck. No cumbersome marking is required.',
        engineer_title_3: 'Plywood tonnage management function',
        engineer_content_3:
          'Based on the parameters entered into CMS, the number of asphalt and other composite materials required is automatically calculated.',
        engineer_title_4: 'Import construction plans',
        engineer_content_4: 'Import construction plans in an instant. Share with your entire team in real time.',
        see_more: 'See more',
        notice_title: 'Notice',
        contact_title: 'Contact Us',
        name_company: 'Company name',
        name: 'Name',
        email: 'Email address',
        phone: 'Phone number',
        note: 'Message',
        note_placeholder: 'Please enter the specific content of your inquiry.',
        button_submit: 'Send a message',
        privacy_policy: 'Privacy policy',
        operating_company: 'Management Company',
        category: 'Question category',
        about_service: 'About the service',
        about_applying: 'About applying for interview projects',
        flow_after: 'Flow after completing the application about',
        regarding: 'Regarding the response on the day of the interview',
        about_rewards: 'About rewards',
        rules_manners: 'Rules and manners, Prohibited act',
        others: 'Others',
        enter_company_name: 'Please enter your company name',
        limit_255_company_name: 'Please enter your company name within 255 characters.',
        enter_name: 'Please enter your name',
        limit_255_enter_name: 'Please enter your name within 255 characters.',
        enter_email: 'Please enter your e-mail address',
        enter_email_invalid: 'E-mail address format is invalid',
        limit_255_email_invalid: 'Please enter your email address within 255 characters.',
        enter_message: 'Please enter your message',
        limit_255_message: 'Please enter your message within 1000 characters',
        mail_is: 'mail address is',
        within_characters: 'Please enter within characters',
        enter_phone: 'Please enter your phone number',
        enter_phone_between: 'Please enter the phone number between 10 and 11 half-width numbers.',

        //policy
        title: 'Privacy Policy',
        content_title_0: 'Privacy Policy (Personal Information Protection Policy)',
        content_desc_0:
          "Synesthesias Co., Ltd. (hereinafter referred to as the 'Company') has established the following privacy policy (hereinafter referred to as the 'Policy') regarding users' personal information. This policy explains what personal information we collect, how we use and share it, and how you can manage your personal information. In addition, 'this service' in this policy refers to the civil engineering AR service 'Suke-san' operated by our company, and 'user' refers to the user of this service (including officers and employees of the user) ).",
        content_title_1: 'Article 1 (Business Information)',
        content_desc_1_0: 'Corporate Name: Synesthesias Inc.',
        content_desc_1_1: 'Address: 3F Jacom Building, 1-12-10 Kita-Shinagawa, Shinagawa-ku, Tokyo',
        content_desc_1_2: 'Representative: Akihiro Aritoshi, President and Representative Director',
        content_title_2: 'Article 2 (Method of obtaining personal information)',
        content_desc_2_0:
          'When a user registers to use the service, we will obtain personally identifiable information such as name, address, telephone number, and e-mail address.',
        content_desc_2_1:
          'When you submit an inquiry form or comment, we will collect your name, e-mail address, and other personally identifiable information.',
        content_title_3: 'Article 3 (Purpose of Use of Personal Information)',
        content_desc_3_0:
          'Personal information will be used for the following purposes and will not be used for any other purposes.',
        content_desc_3_1: ' 1. Examination of contracts for use and registration of this service.',
        content_desc_3_2: ' 2. To authenticate users and provide various services for users.',
        content_desc_3_3: ' 3. Billing and settlement of usage fees for the Service.',
        content_desc_3_4: ' 4. To send various communications and notices in connection with the use of the Service.',
        content_desc_3_5: ' 5. Temporary backup storage for the operation of the Service.',
        content_desc_3_6: ' 6. To respond to opinions and inquiries regarding the Service.',
        content_desc_3_7: ' 7. To conduct statistical research to improve the Service.',
        content_desc_3_8: ' 8. To prevent and detect fraud.',
        content_desc_3_9: ' 9. To comply with legal obligations.',
        content_desc_3_10:
          ' 10. To distribute advertisements, offers, and other information regarding our products and services.',
        content_title_4: 'Article 4 (Measures to Securely Manage Personal Data)',
        content_desc_4_0:
          'The Company endeavors to keep personal data accurate and up-to-date, and to implement appropriate security measures to protect it from unauthorized access, alteration, leakage, loss, or damage. Personal information is stored in a secure environment that is inaccessible to general users.',
        content_title_5: 'Article 5 (Regarding provision of personal data to third parties)',
        content_desc_5_0:
          'The Company will jointly use personal data within the scope of the purposes of use stipulated in Article 3 as follows',
        content_indent_5_0: '- Company with which personal data will be jointly used',
        content_indent_5_1: 'AMELA Technology Joint Stock Company',
        content_indent_5_2:
          'Address 5F, Building A, Keangnam Tower, E6 Cau Giay, Pham Hung, Me Tri, Nam Tu Liem, Ha Noi, Vietnam',
        content_indent_5_3: 'CEO: Duong Minh Khoa',
        content_indent_5_4: '- Security Measures',
        content_indent_5_5:
          'The company is ISO 27001:2013 Information Security Management System certified. In addition, the company has implemented measures to protect personal information in compliance with the OECD Privacy 8 Principles.',
        content_indent_5_6: '- Purposes of Use by Joint Users',
        content_indent_5_7: 'Same as "Purposes of Use" above.',
        content_indent_5_8: '- Items to be used',
        content_indent_5_9: 'Personal information such as name, address, telephone number, e-mail address, etc.',
        content_indent_5_10: '- Information on personal information protection in Vietnam',
        content_title_6: 'Article 6 (Cross-border relocation)',
        content_desc_6_10:
          'In principle, we do not provide personal information to third parties without the consent of the customer. We will provide such information only after specifying the recipient and the content of the information to be provided and obtaining the consent of the customer. However, in the following cases, personal information may be provided without the consent of the customer to the extent that it does not violate related laws and regulations.',
        content_desc_6_0:
          "Users' information will be stored on the cloud services of Amazon Web Services, Inc. (hereinafter referred to as 'AWS'). User information may also be transferred outside Japan via AWS's cloud services. For details, please refer to AWS's regulations.",
        content_desc_6_1:
          "1. When it is necessary to cooperate with a national agency, a local government, or an individual or entity entrusted by either a national agency or local government to execute affairs prescribed by law, and obtaining the customer's consent is likely to impede the execution of those affairs.",
        content_desc_6_2:
          "2. When disclosure of personal information is requested by a court of law, public prosecutor's office, police department, bar association, consumer affairs center, or any other organization with equivalent authority.",
        content_desc_6_3:
          '3. When the customer himself/herself explicitly requests disclosure or provision of personal information to a third party',
        content_desc_6_4: '4. When disclosure or provision is permitted by law',
        content_desc_6_5:
          '5. When we outsource part or all of the operations handling personal information within the scope necessary to achieve the purpose of use',
        content_desc_6_6:
          '6. Cases in which personal information is provided in connection with the succession of business due to merger or other reasons, and is handled within the scope of the purposes of use before the succession.',
        content_title_7: 'Article 7 (Disclaimer regarding provision to third parties)',
        content_desc_7_0:
          "1. Users' information will be stored on the cloud services of Amazon Web Services, Inc. (hereinafter referred to as 'AWS'). User information may also be transferred outside Japan via AWS's cloud services. For details, please refer to AWS's regulations.",
        content_desc_7_1:
          '2. User information may be transferred to AMELA Technology Joint Stock Company for the development and operation of the service. AMELA Technology Joint Stock Company has acquired ISO 27001:2013 certification for its information security management system.',
        content_title_8: 'Article 7 (Disclaimer Regarding Disclosure to Third Parties)',
        content_desc_8_0:
          'AMELA Technology Joint Stock Company shall not be liable for any personal information obtained by a third party if any information that can identify an individual customer (ID, password, etc.) is obtained by a person other than the customer himself/herself.',
        content_title_9: 'Article 8 (Disclosure and Correction of Retained Personal Data)',
        content_desc_9_0:
          'Whenever the Company is requested by a customer to disclose his/her personal information, the Company shall disclose such information. Those who wish to be notified of the purpose of use of their personal information, or to request correction, addition, deletion, suspension of use, or suspension of provision to a third party of their personal information should send a written request to the following address by postal mail. We will respond to your request within two weeks after confirming your identity in accordance with our prescribed procedures.',
        content_desc_9_1: 'Address',
        content_desc_9_2:
          'Jacom Building 3F, 1-12-10 Kitashinagawa, Shinagawa-ku, Tokyo 140-0001 Synesthesias Co., Ltd. Civil engineering AR service “Suke-san” privacy policy counter',
        content_desc_9_3: 'However, we may not respond in the following cases.',
        content_desc_9_4:
          '1. When there is a risk of harm to the life, body, property, or other rights or interests of the user or a third party',
        content_desc_9_5: '2. If there is a risk of significant hindrance to the proper implementation of this service',
        content_desc_9_6: '3. When it would violate other laws and regulations.',
        content_desc_9_7:
          'In addition, in cases where it would require a large amount of money to suspend the use of the personal information or the provision of such personal information to a third party (hereinafter referred to as "suspension of use, etc."), or in cases where it is difficult to suspend the use of the personal information, etc. However, if necessary alternative measures are taken to protect the rights and interests of users, we may not respond to requests such as suspension of use.',
        content_title_10: 'Article 9 (Accuracy of Personal Information)',
        content_desc_10_0:
          'We will make every effort to accurately process the personal information provided by you. However, the customer is responsible for ensuring that the personal information provided is accurate and up-to-date.',
        content_title_11:
          'Article 10 (Contact for consultation and complaints regarding handling of personal information)',
        content_desc_11_0:
          'If you have any questions, concerns, complaints, or other inquiries regarding our handling of personal information, please contact us using the Contact Us form, or send a written letter by postal mail to the address stated in Article 9.',
        content_title_12: 'Article 11 (Changes to this Policy)',
        content_desc_12_0:
          'We reserve the right to change this policy at any time, except as otherwise provided by law.',
        content_title_13: 'Article 12 (Legal Text)',
        content_desc_13_0:
          'The official text of this policy is Japanese. Regarding this policy, even if an English translation is created for reference, only the original Japanese text shall have effect, and the English translation shall have no effect whatsoever.',
        date_policy: 'Established: September 25, 2023',

        message_function: 'Chat function',
        message_function_des_1:
          'If you have any problems while using the app, you can communicate directly with customer support via chat.',
        message_function_des_2: 'Questions can be resolved in real time and with peace of mind.',
      },
    },
  },
});

export default i18n;
