import { useQuery } from 'react-query'
import { api } from 'src/api/axios'
import { QUERY_KEY } from '../query-key'


export const useListStaffAgency = (id: number | undefined) => useQuery(
  [QUERY_KEY.LIST_STAFF_AGENCY, id],
  async () => {
    const res = await api.get(`/api/cms/${id}/list_agency_staff`)
      return res.data.data
  },
  {
    enabled: Boolean(id),
  }
)
